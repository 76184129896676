import React, { useState } from 'react';
import logoPedeJA from '../../img/logoPedeJA.png';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import * as Yup from "yup";
//import PythonShell from 'python-shell';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
//import { importCardapioAnotaai, importCardapioIfood } from '../../services/api'; 

const IntegracaoCardapio = () => {
    const location = useLocation();
    const { infoEmpresaObj, passwordObj, linkObj } = location.state || {};
    //console.log(infoEmpresaObj, passwordObj);

    const navigate = useNavigate();
    const [optSelected, setOptSelected] = useState('import');
    const [onIfoodAndAnotaai, setOnIfoodAndAnotaai] = useState(linkObj?linkObj.onIfoodAndAnotaai:'');
    const [importType, setImportType] = useState(linkObj?linkObj.importType:'');
    const [hasVideo, setHasVideo] = useState(false);

    const handleOptSelected = (opt) => {
        setOptSelected(opt);
        if(opt === "info") {
            navigate("/informacoes", {state: {infoEmpresaObj, passwordObj, linkObj}});
        }
        if(opt === "credential") {
            navigate("/credenciais", {state: {infoEmpresaObj, passwordObj, linkObj}});
        }

    }

    const handleBack = () => {
        navigate("/credenciais", {state: {infoEmpresaObj, passwordObj, linkObj}});
    }

    //Validações
    const SignUpSchema = Yup.object().shape({
        ifoodLink: importType==='linkIfood' && Yup.string().required('Campo obrigatório'),
        anotaAiLink: importType==='linkAnotaAi' && Yup.string().required('Campo obrigatório'),
    });

    const formik = useFormik({
        validationSchema: SignUpSchema,
        validateOnBlur: false,
        validateOnChange: false,
        //enableReinitialize: true,
        initialValues: {
            ifoodLink: linkObj&&linkObj.importType==="linkIfood"?linkObj.importLink:"",
            anotaAiLink: linkObj&&linkObj.importType==="linkAnotaAi"?linkObj.importLink:"",
        },
        onSubmit: (values) => {
          
          //const password = values.password;
          //const confirmPassword = values.confirmPassword;
          const ifoodLink = values.ifoodLink;
          const anotaAiLink = values.anotaAiLink

          //handleSubmitAPI();   
          if(importType==='linkIfood'){   
            //importaCardapioIfood(ifoodLink)
            const linkObj = {
                onIfoodAndAnotaai: onIfoodAndAnotaai,
                importType: 'linkIfood',
                importLink: ifoodLink
            }
            //importCardapioIfood(ifoodLink, 2);
            navigate("/endereco", {state: {infoEmpresaObj, passwordObj, linkObj}});
          } else if (importType==='linkAnotaAi'){
            //importaCardapioAnotaai(anotaAiLink)
            const linkObj = {
                onIfoodAndAnotaai: onIfoodAndAnotaai,
                importType: 'linkAnotaAi',
                importLink: anotaAiLink
            }
            //importCardapioAnotaai(anotaAiLink, 2);
            navigate("/endereco", {state: {infoEmpresaObj, passwordObj, linkObj}});
          }
          
        },
    });

    const handleNoImport = () => {
        setOnIfoodAndAnotaai('nao')
        setOptSelected('endereco');
        navigate("/endereco", {state: {infoEmpresaObj, passwordObj, linkObj}});
    }

    return (    
        <div className='containerAddEmpresa'>
            <div className='divSidePanel'>
                <div className='divSidePanelContent'>
                    <div className='divLogo'>
                        <img width={140} height={42} src={logoPedeJA} alt='logoPedeJA' />
                    </div>
                    <div className='divMenu'>
                        <div className='divMenuItem'>          
                            <div style={{display:'flex', flexDirection:'row'}}>                 
                                {optSelected==="info"?<div className='menuItemSelected'/>:null}
                                <p className={optSelected==="info"?'btnOptionsAddEmpresa checked':"btnOptionsAddEmpresa"} onClick={() => handleOptSelected("info")}>1. Informações da Conta</p>
                            </div>
                            <div style={{display:'flex', flexDirection:'row'}}>
                                {optSelected==="credential"?<div className='menuItemSelected'/>:null}
                                <p className={optSelected==="credential"?'btnOptionsAddEmpresa checked':"btnOptionsAddEmpresa"} onClick={() => handleOptSelected("credential")}>2. Senha</p>
                            </div>
                            <div style={{display:'flex', flexDirection:'row'}}>
                                {optSelected==="import"?<div className='menuItemSelected'/>:null}
                                <p className={optSelected==="import"?'btnOptionsAddEmpresa checked':"btnOptionsAddEmpresa"} onClick={() => handleOptSelected("import")}>3. Importar Cardápio</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>      



            <div className="containerAddEmpresaForm">
                <form onSubmit={formik.handleSubmit} className="formAddEmpresa">

                    <div className='informationsAccount'>

                    <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px', backgroundColor: 'white', color: '#333', margin: 'auto' }}>
                        <h3>Seu estabelecimento está cadastrado no iFood ou AnotaAi?</h3>
                        <p>Selecione 'Sim' para sincronizar automaticamente seu cardápio existente e evitar o cadastro manual.</p>
                        <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
                            <button type="button" 
                            className={onIfoodAndAnotaai==='sim'?'btnFwdCadastros':'btnBackCadastros'} 
                            onClick={()=>setOnIfoodAndAnotaai('sim')} style={{width:'50%'}}>
                                Sim
                            </button>
                            <button type="button" 
                            className={onIfoodAndAnotaai==='nao'?'btnFwdCadastros':'btnBackCadastros'} 
                            onClick={()=>handleNoImport()} style={{width:'50%'}}>
                                Não
                            </button>
                        </div>
                        {
                            onIfoodAndAnotaai==='sim' ?
                            <div>
                                <div>
                                    <h3>Como você deseja importar seu cardápio?</h3>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '20px' }}>
                                        <button type="button" 
                                        className={importType==='linkIfood'?'btnFwdCadastros':'btnBackCadastros'} 
                                        style={{ width:'100%' }} onClick={() => setImportType('linkIfood')}>
                                            Pelo link do meu cardápio IFood
                                        </button>
                                        <button type="button" 
                                        className={importType==='linkAnotaAi'?'btnFwdCadastros':'btnBackCadastros'} 
                                        style={{ width:'100%' }} onClick={() => setImportType('linkAnotaAi')}>
                                            Pelo link do meu cardápio AnotaAi
                                        </button>
                                        <button className='btnBackCadastros' style={{ width:'100%' }}>Não desejo importar meu cardápio</button>
                                    </div>
                                </div>
                                { 
                                    importType === 'linkIfood' ? 
                                    <div>
                                        <h3>Para finalizar, cole o link do seu cardápio iFood abaixo</h3>
                                        <p style={{ marginBottom: '5px', textDecoration:'underline', color: '#007bff', cursor: 'pointer' }} onClick={() => setHasVideo(true)}>Onde consigo o link da minha loja do iFood?</p>
                                        { hasVideo ? 
                                            <div>
                                                <p style={{ marginBottom: '20px' }}>Aprenda como copiar o link do seu cardápio iFood!</p>
                                                <div style={{ marginBottom: '20px', display:'flex', justifyContent:'center' }}>
                                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/example" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                </div>
                                                <button className='btnBackCadastros' style={{width:'100%', marginBottom:'10px'}} onClick={()=> setHasVideo(false)}>Fechar vídeo</button>
                                            </div>
                                            : null
                                        }                                        
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '20px' }}>
                                            <label htmlFor="ifoodLink">Link do cardápio iFood *</label>
                                            <input type="text" 
                                            id="ifoodLink" 
                                            name="ifoodLink"
                                            value={formik.values.ifoodLink}
                                            onChange={formik.handleChange}
                                            style={{ padding: '10px', border: '1px solid #ccc' }} 
                                            className='linkToImportInput'
                                            placeholder="https://www.ifood.com.br/delivery/cidade-uf/loja-exemplo-setor-exemplo/..." />
                                            {formik.errors.ifoodLink && (
                                                <div className="error">{formik.errors.ifoodLink}</div>
                                            )}
                                        </div>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            <button type="button" className='btnBackCadastros' style={{width:'50%'}} onClick={()=>handleBack()}>Voltar</button>
                                            <button type="submit" className='btnFwdCadastros' style={{width:'50%'}}>Próximo</button>
                                        </div>
                                    </div>
                                    : importType === 'linkAnotaAi' &&
                                    <div>
                                        <h3>Para finalizar, cole o link do seu cardápio AnotaAi abaixo</h3>
                                        <p style={{ marginBottom: '5px', textDecoration:'underline', color: '#007bff', cursor: 'pointer' }} onClick={() => setHasVideo(true)}>Onde consigo o link da minha loja do AnotaAi?</p>
                                        { hasVideo ? 
                                            <div>
                                                <p style={{ marginBottom: '20px' }}>Aprenda como copiar o link do seu cardápio AnotaAi!</p>
                                                <div style={{ marginBottom: '20px', display:'flex', justifyContent:'center' }}>
                                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/example" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                </div>
                                                <button className='btnBackCadastros' style={{width:'100%', marginBottom:'10px'}} onClick={()=> setHasVideo(false)}>Fechar vídeo</button>
                                            </div>
                                            : null
                                        }                                        
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '20px' }}>
                                            <label htmlFor="anotaAiLink">Link do cardápio AnotaAi *</label>
                                            <input type="text" 
                                            id="anotaAiLink" 
                                            name="anotaAiLink"
                                            value={formik.values.anotaAiLink}
                                            onChange={formik.handleChange}
                                            style={{ padding: '10px', border: '1px solid #ccc' }} 
                                            className='linkToImportInput'
                                            placeholder="https://pedido.anota.ai/loja/nome-da-loja-1?f=msa" />
                                            {formik.errors.anotaAiLink && (
                                                <div className="error">{formik.errors.anotaAiLink}</div>
                                            )}
                                        </div>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            <button type="button" className='btnBackCadastros' style={{width:'50%'}}>Voltar</button>
                                            <button type="submit" className='btnFwdCadastros' style={{width:'50%'}}>Próximo</button>
                                        </div>
                                    </div>
                                }
                                
                               
                            </div>
                            :
                            null
                        }
                                                
                    </div>
                
                    </div>
                    
                </form>
            </div>





        </div>     
    );
}   

export default IntegracaoCardapio;

