import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './style.css'
import { SidebarContext } from "../../AppRoutes";
import PermissionGate from "../../services/PermissionGate";
import LeftMenu from "../../components/LeftMenu";
import ConfirmDialog from "../../components/ConfirmDialog";
import styled from 'styled-components';
import QRCode from 'qrcode.react';
import { LuPlusCircle } from "react-icons/lu";
import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'
import { FaRegTrashAlt } from "react-icons/fa";
import CryptoJS from 'crypto-js';
import { resetPedidoManual, getEmpresaWithObjId, getUltimoPedidoID, getCompanyResponses, configurarResetPedido, updateEmpresaCancelamentoPedido } from "../../services/api";
import { toast } from "react-toastify";
import axios from "axios";
import moment from 'moment-timezone';
import backgroundWhatsApp from '../../assets/img/chat-background.png'

const Teste = styled.div`

    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;
    z-Index: 9;

    @media (max-width: 880px) {
        margin-left: 0;
    }
`;

const Message = styled.div`
  background: ${(props) => (props.fromMe ? '#dcf8c6' : '#ffffff')};
  padding: 10px;
  border-radius: 7.5px;
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
  font-size: 1rem;
  margin: 1px 0px 1px ${(props) => (props.fromMe ? 'auto' : '0px')};
  width: fit-content;
  max-width: 60%;
  margin-bottom: ${(props) => (props.fromMe ? '3px' : '10px')};
  white-space: pre-wrap; /* Adiciona suporte para quebras de linha */
  span {
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 0.45);
    display: block;
    text-align: right;
  }
`;

const RoboCfg = () => {

    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const userEncrypted = localStorage.getItem('user')
    const user = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
    const userParse = JSON.parse(user)
    const userID = userParse._id;
    const empresa = localStorage.getItem('empresa')
    const empresaParse = JSON.parse(empresa)
    const idEmpresa = empresaParse.id_empresa;
    const empresaObjId = empresaParse._id;
    const [atualSeqId, setAtualSeqId] = useState(0);
    const [daysToReset, setDaysToReset] = useState(0);
    const [lastResetedDate, setLastResetedDate] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [tela, setTela] = useState("tela1")
    const [cancelOption, setCancelOption] = useState("noPassword");
    const [cancelPassword, setCancelPassword] = useState("");
    const [showInputNewPassword, setShowInputNewPassword] = useState(false);
    const [newCancelPassword, setNewCancelPassword] = useState("");
    const [currentCancelPassword, setCurrentCancelPassword] = useState("");
    const [hasCancelPassword, setHasCancelPassword] = useState(false);
    const [definindoNovaSenha, setDefinindoNovaSenha] = useState(true);
    const { sidebar, setSidebar } = useContext(SidebarContext);; //TESTANDO CONST NO AppROUTES
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [companyResponses, setCompanyResponses] = useState([]);
    const [selectedResponse, setSelectedResponse] = useState(null)
    const navigate = useNavigate();

    useEffect(() => {
        // Função para buscar os dados do banco de dados
        const fetchData = async () => {
            try {
                // Supondo que getEmpresaWithObjId seja uma função que retorna uma Promise
                const empresaTemp = await getEmpresaWithObjId(empresaObjId);
                const companyResponsesTemp = await getCompanyResponses(empresaObjId);
                console.log("companyResponses:", companyResponsesTemp.data.responses);
                setCompanyResponses(companyResponsesTemp.data.responses);
                setSelectedResponse(companyResponsesTemp.data.responses[0]);
                if (empresaTemp.data.empresa?.dias_para_reset) {
                    setDaysToReset(empresaTemp.data.empresa.dias_para_reset)
                }
                if (empresaTemp.data.empresa?.last_reset_date) {
                    const dataFormatada = moment(empresaTemp.data.empresa.last_reset_date)
                        .tz("America/Sao_Paulo")
                        .format("DD/MM/YYYY HH:mm");
                    setLastResetedDate(dataFormatada);
                }
                if (empresaTemp.data.empresa?.has_cancel_password) {
                    setCancelOption("standardPassword");
                    setDefinindoNovaSenha(false);
                }
                console.log("empresaTemp.data.empresa.has_cancel_password>>", empresaTemp.data.empresa.has_cancel_password)
                setHasCancelPassword(empresaTemp.data.empresa.has_cancel_password);
                const ultimoPedidoId = await getUltimoPedidoID(idEmpresa)
                if (ultimoPedidoId.data?.ultimoPedidoId) {
                    setAtualSeqId(ultimoPedidoId.data.ultimoPedidoId)
                }
            } catch (error) {
                console.error("Erro ao buscar dados da empresa:", error);
                // Lidar com o erro, talvez definindo algum estado de erro na UI
            }
        };

        fetchData();
    }, [refresh]); // O array vazio [] indica que o useEffect será executado uma vez após a montagem do componente

    const handleBack = () => {
        //setSidebar(!sidebar)
        navigate("/");
    }

    const handleSelectQuestionAndAnswer = (message) => {
        setSelectedResponse(message);
    };

    // Função para atualizar o response do selectedResponse diretamente
    const handleResponseChange = (e) => {
        setSelectedResponse((prevSelectedResponse) => ({
            ...prevSelectedResponse,
            response: e.target.value,
        }));
    };

    // Função para salvar as alterações no companyResponses
    const handleSaveResponse = () => {
        if (selectedResponse) {
            // Atualiza a lista companyResponses com a nova resposta do selectedResponse
            const updatedResponses = companyResponses.map((response) =>
                response.questionIdentifier === selectedResponse.questionIdentifier
                    ? selectedResponse
                    : response
            );
            setCompanyResponses(updatedResponses);
            // Agora, selectedResponse está sincronizado com companyResponses
        }
    };

    const saveChanges = async () => {
        console.log("clicou no submit!")
    }

    return (
        <>
            <PermissionGate permissions={['default']}>


                <LeftMenu setSidebar={setSidebar} sidebar={sidebar} />

                {/*<ConfirmDialog
                    title="Resetar Contador de Pedidos?"
                    open={confirmOpen}
                    setOpen={setConfirmOpen}
                    onConfirm={handleReset}
                >
                    <div>
                        Tem certeza que deseja zerar o contador de pedidos?
                        <br />
                        <span style={{ fontSize: 12 }}>(O próximo pedido cadastrado será iniciado com o identificador 1)</span>
                    </div>
                </ConfirmDialog>*/}

                <Teste sidebar={sidebar}>
                    <div className="w-100 p-4">
                        {/*<form onSubmit={handleSubmitButton}>*/}
                        <form /*onSubmit={formik.handleSubmit}*/ >

                            <div className="form-header" style={{ marginBottom: "0px" }}>
                                <div className="title">
                                    <h1>Configurações do Robô</h1>
                                </div>
                            </div>

                            <div style={{ display: "flex", justifyContent: "right"/*, height:"80px"*/ }}>

                                <div className="div-buttons">
                                    <div className="continue-button">
                                        {tela === "tela1" ?
                                            <button type="button" onClick={saveChanges} disabled={isSubmitting}>
                                                <AiIcons.AiOutlineSave style={{ marginRight: "5px", fontSize: "22px", marginBottom: "2px" }} /><a>Salvar</a>
                                            </button>
                                            :
                                            <button type="button" /*onClick={alterarCancelamentoPedido}*/ disabled={isSubmitting}>
                                                <AiIcons.AiOutlineSave style={{ marginRight: "5px", fontSize: "22px", marginBottom: "2px" }} /><a>Salvar</a>
                                            </button>
                                        }
                                    </div>

                                    <div className="back-button">
                                        <button onClick={handleBack}>
                                            <SlIcons.SlActionUndo style={{ color: "#ff4c4c", marginRight: "5px", fontSize: "18px", marginBottom: "2px" }} /><a >Voltar</a>
                                        </button>
                                    </div>
                                </div>

                            </div>

                            <div className="contentItemComplete flex-column flex-md-row">
                                <div className="input-group inputGroup-etapasItem" style={{ height: 140 }}>
                                    <div className={tela === "tela1" ? "etapasAddItem etapaAtiva" : "etapasAddItem"}
                                        style={{ borderBottom: '1px solid lightgray' }} onClick={() => setTela("tela1")}
                                    >
                                        <label>1. Personalizar Mensagens</label>
                                    </div>
                                    {/*<div className="etapasAddItem" style={{borderBottom:'1px solid lightgray'}}>
                                    <label>2. Adicionais</label>
                                </div>
                                <div className="etapasAddItem" style={{borderBottom:'1px solid lightgray'}}>
                                    <label>3. Classificações</label>
                                </div>*/}
                                    <div className={tela === "tela2" ? "etapasAddItem etapaAtiva" : "etapasAddItem"}
                                        onClick={() => setTela("tela2")}
                                    >
                                        <label>2. Nada aqui</label>
                                    </div>
                                </div>

                                <div className="input-group mh-100" style={{ maxWidth: "80%" }}>

                                    <div className="formGroupRow">
                                        {tela === "tela1" &&
                                            <div className="roboCfg-container">
                                                <div className="roboCfg-sidebar">
                                                    <h3>Mensagens</h3>
                                                    <ul className="roboCfg-message-list">
                                                        {companyResponses.map((message, index) => (
                                                            <li
                                                                key={index}
                                                                className={`roboCfg-message-item ${selectedResponse?.questionIdentifier === message.questionIdentifier ? 'roboCfg-selected' : ''}`}
                                                                onClick={() => handleSelectQuestionAndAnswer(message)}
                                                            >

                                                                {message.questionType}
                                                                <div className="checkBoxContentMsg">
                                                                    <div className="roboCfg-edit-button-ativo">Ativo</div>
                                                                    <div className="wrapper">
                                                                        <div className="switch_box box_1">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="switch_1"
                                                                            //checked={statusBot}
                                                                            //onChange={handleSwitchChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <div className="roboCfg-main">
                                                    <div className="roboCfg-preview" style={{ backgroundImage: `url(${backgroundWhatsApp})` }}>
                                                        <div className="roboCfg-chat">

                                                            {selectedResponse?.question &&
                                                                <Message fromMe={false}>
                                                                    <p>{selectedResponse?.question}</p>
                                                                    <span>12:00</span>
                                                                </Message>
                                                            }


                                                            <Message fromMe={true}>
                                                                <p>{selectedResponse?.response}</p>
                                                                <span>12:01</span>
                                                            </Message>
                                                        </div>
                                                    </div>
                                                    <div className="roboCfg-editor">
                                                        <h4>Personalize a mensagem</h4>
                                                        <textarea
                                                            className="roboCfg-textarea"
                                                            value={selectedResponse?.response || ""}
                                                            onChange={handleResponseChange} // Altera diretamente o response do selectedResponse
                                                        />
                                                        <div className="roboCfg-buttons">
                                                            <button className="roboCfg-button">Nome do cliente</button>
                                                            <button className="roboCfg-button">Link do cardápio</button>
                                                            <button className="roboCfg-button">Separar mensagens</button>
                                                            <button className="roboCfg-button">Saudação</button>
                                                        </div>
                                                        <div className="roboCfg-save-cancel">
                                                            <button className="roboCfg-save-button" type="button" onClick={handleSaveResponse}>Salvar</button>
                                                            <button className="roboCfg-cancel-button">Cancelar</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {tela === "tela2" && (
                                            <div>NADA AQUI POR ENQUANTO</div>
                                        )}
                                    </div>

                                </div>



                            </div>

                        </form>
                    </div>
                </Teste>

            </PermissionGate>
        </>
    )
}

export default RoboCfg;