import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

/*const SidebarLink = styled(Link)`
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;
  background: ${({ subnav }) => (subnav ? 'lightgray' : undefined)};

  &:hover {
    background: ${({ subnav }) => (subnav ? 'lightgray' : 'rgb(250,250,250)')};
    border-left: 4px solid #4281FF;
    cursor: pointer;
  }
`;*/
const SidebarLink = styled(Link)`
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;
  background: ${({ subnav }) => (subnav ? '#eee' : 'none')};

  &:hover {
    background: ${({ subnav }) => (subnav ? '#eee' : 'rgb(250,250,250)')};
    border-left: 4px solid #4281FF;
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
`;

const DropdownLink = styled(Link)`
  background: rgb(245,245,245);
  height: 60px;
  padding-left: 2rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;

  &:hover {
    background: linear-gradient(to left, #4281FF, #51D2FF);
    border-radius:5px;
    cursor: pointer;
    color:white!important;
    .svg{
      color:white!important;
    }
  }
`;

const SubMenu = ({ item }) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      {/*<SidebarLink style={{color:"rgb(120,120,120)"}} to={item.path} onClick={item.subNav && showSubnav} subnav={subnav}>*/}
      <SidebarLink style={{color:"rgb(120,120,120)"}} to={item.path} onClick={item.subNav && showSubnav} subnav={subnav ? 1 : 0}>
        <div style={{paddingLeft:"15px", display:"flex"}}>
          {item.icon}
          <SidebarLabel>{item.title}</SidebarLabel>
        </div>
        <div style={{width:"4px"}}>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.subNav.map((item, index) => {
          return (
            <DropdownLink style={{color:"rgb(120,120,120)", paddingLeft:"45px"}} to={item.path} key={index}>
              {item.icon}
              <SidebarLabel>{item.title}</SidebarLabel>
            </DropdownLink>
          );
        })}
    </>
  );
};

export default SubMenu;
