import React, { useState, useContext, useEffect, useRef } from "react";
import '../../pages/Pdv/style.css';
import '../../pages/Pdv/styleResponsive.css';
import styled from 'styled-components';
import InputMask from 'react-input-mask';
import { toast } from "react-toastify";
import CryptoJS from 'crypto-js';
import { AuthContext } from "../../contexts/auth";
import { SidebarContext } from "../../AppRoutes";
import { updatePedidoFromPdv, getCategorias, getItens, getVinculoEmpresa, getAdicionaisCardapio, getCliente, registerClienteFromCardapio } from "../../services/api";
import { useNavigate } from "react-router-dom";
import { saveToLocalStorage, getFromLocalStorage, removeFromLocalStorage } from '../../utils/localStorage';
import LeftMenu from "../../components/LeftMenu";
import PermissionGate from "../../services/PermissionGate";
import ModalAdicionais from "../../pages/Pdv/ModalAdicionais";
import { FaFilter } from "react-icons/fa";
import { AiOutlineArrowRight } from "react-icons/ai";
import { IoMdExit } from "react-icons/io";
import { MdFastfood } from "react-icons/md";
import { MdShoppingCartCheckout } from "react-icons/md";
import ModalAddObsPedido from "../../pages/Pdv/ModalAddObsPedido";
import ModalAddObsItem from "../../pages/Pdv/ModalAddObsItem";
import ModalEntregaPdv from "../../pages/Pdv/ModalEntregaPdv";
import AsyncSelect from 'react-select/async';
import Loading from "react-loading";
import { calc } from "antd/es/theme/internal";
import { IoIosArrowBack } from "react-icons/io";
import ModalPagamentos from "../../pages/Pdv/ModalPagamentos";
import ModalCpfCnpj from "../../pages/Pdv/ModalCpfCnpj";
import { set } from "react-hook-form";

const Teste = styled.div`
    position: absolute;
    display: ${({ showModal }) => (!showModal ? "none" : "flex")};
    top: 0; /* Começa do topo da página */
    right: 0; /* Posiciona a div Teste no lado direito */
    width: calc(100vw - 35vw); /* Largura da div será 100% da tela menos 500px */
    height: 100vh; /* Preenche toda a altura da tela */
    --margin-left: ${({ sidebar }) => (sidebar ? '250px' : '100px')};
    --height: calc(100vh - 80px);
    --width: auto;
    transition: 150ms;
    background-color: rgb(247,247,247)!important;
    overflow: initial;
    border-left: 1px solid black;
    z-index: 99;

    /* Media query para telas menores (ex: celulares) */
    @media (max-width: 768px) {
        width: calc(100vw - 5vw); /* Ajusta a largura para telas menores */
    }
`;

const BtnSendToCartPdv = styled.button`
  display: inline-block;
  padding: 0.75rem 1.25rem;
  border-radius: 10rem;
  color: #fff;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 0.15rem;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background-color: transparent;
  border: 2px solid #0cf;
  cursor: pointer;
  transition: all 0.3s;
  animation: colorAnimation 1s infinite; /* Inicia a animação a cada 1 segundo */

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0cf;
    border-radius: 10rem;
    z-index: -2;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #009e9e; /* Cor mais escura */
    border-radius: 10rem;
    transition: all 0.3s;
    z-index: -1;
    animation: expandAnimation 2s infinite;
  }

  /* Animação do efeito de cor */
  @keyframes colorAnimation {
    0%, 100% {
      color: #fff;
    }
  }

  /* Animação de expansão da cor de fundo */
  @keyframes expandAnimation {
    0%, 100% {
      width: 0%;
    }
    50% {
      width: 100%;
    }
  }
`;

const ModalPdv = ({
    showModal,
    setShowModal,
    infoPedido,
    setInfoPedido,
    itensCarrinho,
    setItensCarrinho,
    nomeCliente,
    setNomeCliente,
    telefone,
    setTelefone,
    selectedCliente,
    setSelectedCliente,
    //formaEntrega,
    //setFormaEntrega
    savedFormaEntrega,
    setSavedFormaEntrega,
    selectedPayment,
    setSelectedPayment,
    cpfCnpjValue,
    setCpfCnpjValue,
    valueDescricao,
    setValueDescricao,
    setRefresh
}) => {
    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const userEncrypted = localStorage.getItem('user');
    const user = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
    const userParse = JSON.parse(user);
    const userID = userParse._id;
    //const empresa = localStorage.getItem('empresa');
    //const empresaParse = JSON.parse(empresa);
    //const empresaObjId = empresaParse._id;
    //const idEmpresa = empresaParse.id_empresa;
    //const vinculo_empresa = empresaParse.cnpj;
    const [empresa, setEmpresa] = useState(null);
    const [empresaObjId, setEmpresaObjId] = useState(null);
    const [idEmpresa, setIdEmpresa] = useState(null);
    const [vinculo_empresa, setVinculoEmpresa] = useState(null);
    const nomeEmpresaForUrl = empresa?.name.replace(/ /g, '-').toLowerCase();
    const enderecoEmpresa = empresa ? {
        bairro: empresa.bairro,
        cidade: empresa.municipio,
        estado: empresa.estado,
        numero: empresa.address_number,
        rua: empresa.logradouro
    } : null;
    const [tipoImpressao, setTipoImpressao] = useState("");
    const { sidebar, setSidebar } = useContext(SidebarContext);
    const [categorias, setCategorias] = useState([]);
    const [itens, setItens] = useState([]);
    const [selectedCategoria, setSelectedCategoria] = useState("");
    //const [celularCliente, setCelularCliente] = useState("");

    const [clientes, setClientes] = useState([]);
    const [itensSelecionados, setItensSelecionados] = useState([]); // Estado temporário para itens selecionados
    //const [itensCarrinho, setItensCarrinho] = useState(getFromLocalStorage('itensCarrinho') || []);
    //TEMP
    console.log("ITENS CARRINHO:", getFromLocalStorage('itensCarrinho'));
    console.log("infoPedido:", infoPedido);
    ////////
    const [editarPedido, setEditarPedido] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const containerRef = useRef(null);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [showAdicionaisModal, setShowAdicionaisModal] = useState(false);
    const [adicionais, setAdicionais] = useState([]);
    const [adicionaisCompletos, setAdicionaisCompletos] = useState([]);  // Adicione este estado
    const [quantidades, setQuantidades] = useState({});  // Adicione este estado
    const [showModalAddObsPedido, setShowModalAddObsPedido] = useState(true);
    const [currentEditingItem, setCurrentEditingItem] = useState(null);
    const [itemSelecionadoParaObs, setItemSelecionadoParaObs] = useState(null);
    const [obsItem, setObsItem] = useState("");
    const [showModalAddObsItem, setShowModalAddObsItem] = useState(true);
    const [showModalEntregaPdv, setShowModalEntregaPdv] = useState(true);
    const [formaEntrega, setFormaEntrega] = useState(null);
    const [showModalPagamentos, setShowModalPagamentos] = useState(true);
    const [formaPagamento, setFormaPagamento] = useState("Dinheiro");
    //const [selectedPayment, setSelectedPayment] = useState(null);
    const [valorEntrega, setValorEntrega] = useState(0);
    //const [savedFormaEntrega, setSavedFormaEntrega] = useState(null);
    const [showModalCpfCnpj, setShowModalCpfCnpj] = useState(true);
    //const [cpfCnpjValue, setCpfCnpjValue] = useState('');
    //const [valueDescricao, setValueDescricao] = useState('');
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true); // Estado de carregamento

    useEffect(() => {
        let isMounted = true;

        const fetchEmpresa = async () => {
            try {
                const response = await getVinculoEmpresa(userID);
                if (response.data.vinculo) {
                    const vinculo = response.data.vinculo;
                    if (isMounted) {
                        setEmpresa(vinculo);
                        setEmpresaObjId(vinculo._id);
                        setIdEmpresa(vinculo.id_empresa);
                        setVinculoEmpresa(vinculo.cnpj);
                        const categoriaTemp = await getCategorias(userID, vinculo.id_empresa, vinculo.cnpj, vinculo._id);
                        const itemTemp = await getItens(userID, vinculo.id_empresa, vinculo.cnpj, vinculo._id);
                        if (isMounted) {
                            setCategorias(categoriaTemp.data.categorias);
                            setItens(itemTemp.data.itens);
                            setLoading(false); // Dados carregados, definir loading como falso
                        }
                    }
                }
            } catch (error) {
                console.error("Erro ao buscar dados:", error);
                if (isMounted) {
                    setLoading(false); // Em caso de erro, definir loading como falso
                }
            }
        };

        fetchEmpresa();

        return () => {
            isMounted = false;
        };
    }, [userID]);

    useEffect(() => {
        saveToLocalStorage('itensCarrinho', itensCarrinho);
    }, [itensCarrinho]);

    const fetchAdicionais = async (item) => {
        try {
            const response = await getAdicionaisCardapio(idEmpresa, nomeEmpresaForUrl);
            const adicionaisFromDB = response.data.adicionais;
            //console.log("adicionaisFromDB:",adicionaisFromDB)
            if (item.has_adicional && item.adicionais && adicionaisFromDB) {
                const adicionaisEncontrados = adicionaisFromDB.filter(adicionalDB =>
                    item.adicionais.some(adicionalItem => adicionalItem.value === adicionalDB._id)
                );

                setAdicionaisCompletos(adicionaisEncontrados);
                setQuantidades({}); // Resetar as quantidades ao abrir o modal
                setShowAdicionaisModal(true);
            }
        } catch (error) {
            console.error('Erro ao buscar adicionais:', error);
        }
    };

    const handleCloseModal = () => {
        console.log("fechou!")
        setShowModal(!showModal);
        removeFromLocalStorage('itensCarrinho');
    }

    // Função de handleFinalizar para o ModalAdicionais
    const handleFinalizarAdicionais = (item, selectedAdicionais) => {
        const updatedItem = {
            ...item,
            adicionais: selectedAdicionais,
            quantity: 1 // ou a quantidade desejada
        };
        updateCartItem(updatedItem);
        setShowAdicionaisModal(false);
        setQuantidades({});
        setAdicionaisCompletos([]);
    };

    /*const handleItemClick = (item) => {
        setSelectedItem(item);
        if (item.has_adicional) {
            fetchAdicionais(item);
        } else {
            incrementCount(item);
        }
    };*/
    const handleItemClick = (item) => {
        const itemExistente = itensSelecionados.find(i => i._id === item._id);
        if (itemExistente) {
            incrementCount(item);
        } else {
            setSelectedItem(item);
            if (item.has_adicional) {
                fetchAdicionais(item);
            } else {
                incrementCount(item);
            }
        }
    };

    const updateCartItem = (updatedItem) => {
        setItensSelecionados(prevItens => {
            const index = prevItens.findIndex(item => item._id === updatedItem._id);
            if (index !== -1) {
                const newItensSelecionados = [...prevItens];
                newItensSelecionados[index] = updatedItem;
                return newItensSelecionados;
            }
            return [...prevItens, updatedItem];
        });
    };

    const handleEnviarParaCarrinho = () => {
        if (selectedPayment) {
            setSelectedPayment(null);
        }
        if (itensSelecionados.length === 0) {
            toast("Nenhum item selecionado", { autoClose: 2000, type: "warning" });
            return;
        }

        const updatedCarrinho = [...itensCarrinho];

        itensSelecionados.forEach(selecionado => {
            const existingItemIndex = updatedCarrinho.findIndex(i =>
                i._id === selecionado._id &&
                JSON.stringify(i.adicionais) === JSON.stringify(selecionado.adicionais)
            );

            //console.log("O que tem selecionado?",selecionado)

            const adicionaisAtualizados = selecionado.adicionais.map(grupo => ({
                ...grupo,
                calcular_maior_valor: grupo.calcular_maior_valor ?? false, // Garantir que seja false caso não exista
            }));

            const itemAtualizado = {
                ...selecionado,
                adicionais: adicionaisAtualizados,
            };

            if (existingItemIndex > -1) {
                updatedCarrinho[existingItemIndex].quantity += selecionado.quantity;
                updatedCarrinho[existingItemIndex].adicionais.forEach((grupo, groupIndex) => {
                    grupo.adicionais.forEach((adicional, adicionalIndex) => {
                        adicional.quantity += selecionado.adicionais[groupIndex].adicionais[adicionalIndex].quantity;
                    });
                });
            } else {
                updatedCarrinho.push(itemAtualizado);
            }
        });

        toast("Itens enviados para o carrinho", { autoClose: 1000, type: "success" });
        setItensCarrinho(updatedCarrinho);
        setItensSelecionados([]);
        saveToLocalStorage('itensCarrinho', updatedCarrinho);
    };


    const handleChange = (e, item) => {
        const newQuantity = parseInt(e.target.value, 10);
        if (isNaN(newQuantity) || newQuantity < 0) return;
        const index = itensSelecionados.findIndex(i => i._id === item._id);
        if (index !== -1) {
            const newItensSelecionados = [...itensSelecionados];
            newItensSelecionados[index].quantity = newQuantity;
            setItensSelecionados(newItensSelecionados);
        }
    };

    const handleAddObsPedido = (item) => {
        //toast("Observação adicionada", { autoClose: 2000, type: "success" });
        setShowModalAddObsPedido(!showModalAddObsPedido);
    }

    const handleAddObsItem = (item) => {
        setItemSelecionadoParaObs(item);
        setObsItem(item.obs_item || "");
        setShowModalAddObsItem(!showModalAddObsItem); // Presumo que você já tenha o modal configurado
    };

    const handleSaveObsItem = () => {
        const updatedCarrinho = itensCarrinho?.map(prod => {
            if (prod._id === itemSelecionadoParaObs._id) {
                return { ...prod, obs_item: obsItem };
            }
            return prod;
        });

        setItensCarrinho(updatedCarrinho);
        saveToLocalStorage('itensCarrinho', updatedCarrinho);
        //setShowModalAddObsPedido(false);
        toast("Observação adicionada ao item", { autoClose: 2000, type: "success" });
    };

    const handleRemoveItem = (item) => {
        if (selectedPayment) {
            setSelectedPayment(null);
        }
        const updatedCarrinho = itensCarrinho.filter(prod => prod._id !== item._id);
        setItensCarrinho(updatedCarrinho);
        saveToLocalStorage('itensCarrinho', updatedCarrinho);
        toast("Item removido do carrinho", { autoClose: 1000, type: "success" });
    };



    const normalizePhoneNumber = (phone) => {
        return phone.replace(/[^\d]/g, ''); // Remove todos os caracteres não numéricos
    };

    const fetchClientes = async (inputValue) => {
        /*if (inputValue.length < 4) {
            setClientes([]);
            return [];
        }*/

        try {
            const response = await getCliente(userID, idEmpresa, normalizePhoneNumber(inputValue));
            ////console.log("response.data>>", response.data);  
            if (response.status === 200) {
                const clientesData = response.data.clientes.map(cliente => ({
                    ...cliente // Inclui todos os demais dados do cliente
                }));
                setClientes(clientesData);
                return clientesData;
            }
            setClientes([]);
        } catch (error) {
            console.error("Erro ao buscar clientes:", error);
            setClientes([]);
            return [];
        }
    };

    /*useEffect(() => {
        // Carregar todos os clientes inicialmente ou quando necessário
        fetchClientes('');
    }, []);*/

    const handleNomeChange = (event) => {
        setNomeCliente(event.target.value);
        //if (selectedCliente) {
        setSelectedCliente({
            ...selectedCliente,
            nome: event.target.value
        });
        //}
        //////console.log("PASSOU AQUI TESTE!");
    };

    const handleTelefoneChange = async (event) => {
        const inputValue = event.target.value;
        setTelefone(inputValue);
        if (inputValue.length >= 4) {
            await fetchClientes(inputValue);
        }
        setSelectedCliente(null);
        setNomeCliente("");
    };

    const handleClienteClick = (cliente) => {
        //////console.log("ENTROU AQUI? cliente>>", cliente);
        setSelectedCliente(cliente);
        setNomeCliente(cliente.nome);
        setTelefone(cliente.telefone);
        setClientes([]); // Limpa a lista após seleção
    };

    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setClientes([]); // Limpa a lista se clicar fora
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (telefone === "") {
            setSelectedCliente(null);
        }
    }, [telefone]);

    const incrementCount = (item) => {
        const index = itensSelecionados.findIndex(i => i._id === item._id);
        if (index !== -1) {
            const newItensSelecionados = [...itensSelecionados];
            newItensSelecionados[index].quantity += 1;
            setItensSelecionados(newItensSelecionados);
        } else {
            setItensSelecionados([...itensSelecionados, { ...item, quantity: 1 }]);
        }
    };

    const decrementCount = (item) => {
        const index = itensSelecionados.findIndex(i => i._id === item._id);
        if (index !== -1) {
            const newItensSelecionados = [...itensSelecionados];
            if (newItensSelecionados[index].quantity > 1) {
                newItensSelecionados[index].quantity -= 1;

                // Diminuir os adicionais
                if (newItensSelecionados[index].adicionais) {
                    newItensSelecionados[index].adicionais.forEach(grupo => {
                        grupo.adicionais.forEach(adicional => {
                            adicional.quantity -= adicional.quantity / (newItensSelecionados[index].quantity + 1);
                        });
                    });
                }
            } else {
                newItensSelecionados.splice(index, 1);
            }
            setItensSelecionados(newItensSelecionados);
        }
    };

    const subTotal = Array.isArray(itensCarrinho) ? itensCarrinho.reduce((sum, item) => {
        const adicionaisSum = Array.isArray(item.adicionais) ? item.adicionais.reduce((acc, grupo) => {
            if (grupo.calcular_maior_valor) {
                const maxAdicionalValue = Array.isArray(grupo.adicionais)
                    ? Object.values(
                        grupo.adicionais.reduce((acc, adicional) => {
                            // Agrupando adicionais pelo `adicionalId`
                            if (!acc[adicional.adicionalId]) {
                                acc[adicional.adicionalId] = {
                                    ...adicional,
                                    total: adicional.price //* adicional.quantity
                                };
                            } else {
                                acc[adicional.adicionalId].total += adicional.price //* adicional.quantity;
                            }
                            return acc;
                        }, {})
                    ).reduce((max, adicional) => {
                        // Considerando o maior valor entre os totais dos adicionais
                        return adicional.total > max ? adicional.total : max;
                    }, 0)
                    : 0;
                return acc + maxAdicionalValue;
            } else {
                // Caso contrário, somamos todos os valores dos adicionais do grupo.
                const totalAdicionais = Array.isArray(grupo.adicionais)
                    ? grupo.adicionais.reduce((subAcc, adicional) => {
                        return subAcc + (adicional.price * adicional.quantity);
                    }, 0)
                    : 0;
                return acc + totalAdicionais;
            }
        }, 0) : 0;

        return sum + (item.quantity * item.price) + adicionaisSum;
    }, 0) : 0;


    const safeSubTotal = isNaN(Number(subTotal)) ? 0 : Number(subTotal);
    const safeValorEntrega = savedFormaEntrega && !isNaN(Number(savedFormaEntrega.valorEntrega))
        ? Number(savedFormaEntrega.valorEntrega)
        : 0;

    const valorTotal = safeSubTotal + safeValorEntrega;

    const renderAdicionais = (item, adicionais) => {
        if (!adicionais || !Array.isArray(adicionais)) return null;
        return adicionais.map(grupo => (
            <div key={grupo.grupoId} className="adicionais-group">
                <span style={{ fontWeight: 'bold' }}>{grupo.grupoTitle}</span>
                {Array.isArray(grupo.adicionais) && grupo.adicionais.map(adicional => (
                    <div key={adicional.adicionalId} className="adicional-item">
                        {editarPedido && (
                            <div className="pedeja-input">
                                <div className="input-number">
                                    <button className="decrement-item" onClick={() => decrementAdicionalCount(item, grupo.grupoId, adicional.adicionalId)} type="button">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ color: '#2b2b2b' }}><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                                    </button>
                                    <input type='number' readOnly value={adicional.quantity} />
                                    <button className="increment-item" onClick={() => incrementAdicionalCount(item, grupo.grupoId, adicional.adicionalId)} type="button">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ color: '#2b2b2b' }}><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                                    </button>
                                </div>
                            </div>
                        )}
                        <span className="titleAdicionalCart">{adicional.title} ({grupo.calcular_maior_valor ? adicional.quantity > grupo.adicionais.length ? `${grupo.adicionais.length}/${grupo.adicionais.length}` : `${adicional.quantity}/${grupo.adicionais.length}` : `x${adicional.quantity}`})</span>
                        <div className="line" />
                        <span style={{ minWidth: 60 }}>R$ {grupo.calcular_maior_valor ? `${(adicional.price).toFixed(2).replace('.', ',')}` : `${(adicional.price * adicional.quantity).toFixed(2).replace('.', ',')}`}</span>
                    </div>
                ))}
            </div>
        ));
    };


    const incrementAdicionalCount = (item, grupoId, adicionalId) => {
        const updatedCarrinho = itensCarrinho?.map(i => {
            if (i._id === item._id) {
                return {
                    ...i,
                    adicionais: i.adicionais.map(grupo => {
                        if (grupo.grupoId === grupoId) {
                            return {
                                ...grupo,
                                calcular_maior_valor: grupo.calcular_maior_valor ?? false, // Garantir que seja false caso não exista
                                adicionais: grupo.adicionais.map(adicional => {
                                    if (adicional.adicionalId === adicionalId) {
                                        return {
                                            ...adicional,
                                            quantity: adicional.quantity + 1
                                        };
                                    }
                                    return adicional;
                                })
                            };
                        }
                        return grupo;
                    })
                };
            }
            return i;
        });

        setItensCarrinho(updatedCarrinho);
        saveToLocalStorage('itensCarrinho', updatedCarrinho);
    };

    const decrementAdicionalCount = (item, grupoId, adicionalId) => {
        const updatedCarrinho = itensCarrinho?.map(i => {
            if (i._id === item._id) {
                return {
                    ...i,
                    adicionais: i.adicionais.map(grupo => {
                        if (grupo.grupoId === grupoId) {
                            return {
                                ...grupo,
                                calcular_maior_valor: grupo.calcular_maior_valor ?? false, // Garantir que seja false caso não exista
                                adicionais: grupo.adicionais.map(adicional => {
                                    if (adicional.adicionalId === adicionalId && adicional.quantity > 1) {
                                        return {
                                            ...adicional,
                                            quantity: adicional.quantity - 1
                                        };
                                    }
                                    return adicional;
                                })
                            };
                        }
                        return grupo;
                    })
                };
            }
            return i;
        });

        setItensCarrinho(updatedCarrinho);
        saveToLocalStorage('itensCarrinho', updatedCarrinho);
    };

    const subTotalAdicionais = (adicionais) => {
        if (!adicionais || !Array.isArray(adicionais)) return null;
        return adicionais.map(grupo => (
            grupo.adicionais.map(adicional => (
                (adicional.price * adicional.quantity).toFixed(2).replace('.', ',')
            ))

        ));
    };


    const handleScrollOnClick = (mongodbId) => {
        const element = document.getElementById(mongodbId);
        if (element) {
            setSelectedCategoria(mongodbId);
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleEditaPedido = () => {
        setEditarPedido(true);
    };

    const handleConcluirEdicao = () => {
        //toast('Edição concluída', { autoClose: 2000, type: "success" });
        saveToLocalStorage('itensCarrinho', itensCarrinho);
        setEditarPedido(false);
    };

    const handleChangeLocalStorage = (e, item) => {
        const newQuantity = parseInt(e.target.value, 10);
        if (isNaN(newQuantity) || newQuantity < 0) return;

        const updatedCarrinho = itensCarrinho?.map(i => {
            if (i._id === item._id) {
                return { ...i, quantity: newQuantity };
            }
            return i;
        });

        setItensCarrinho(updatedCarrinho);
        saveToLocalStorage('itensCarrinho', updatedCarrinho);
    };

    const incrementCountLocalStorage = (item) => {
        const updatedCarrinho = itensCarrinho?.map(i => {
            if (i._id === item._id && JSON.stringify(i.adicionais) === JSON.stringify(item.adicionais)) {
                return {
                    ...i,
                    quantity: i.quantity + 1,
                    adicionais: i.adicionais.map(grupo => ({
                        ...grupo,
                        calcular_maior_valor: grupo.calcular_maior_valor ?? false, // Garantir que seja false caso não exista
                        adicionais: grupo.adicionais.map(adicional => ({
                            ...adicional,
                            quantity: adicional.quantity // + 1
                        }))
                    }))
                };
            }
            return i;
        });

        setItensCarrinho(updatedCarrinho);
        saveToLocalStorage('itensCarrinho', updatedCarrinho);
    };

    const decrementCountLocalStorage = (item) => {
        const updatedCarrinho = itensCarrinho.reduce((acc, i) => {
            if (i._id === item._id && JSON.stringify(i.adicionais) === JSON.stringify(item.adicionais)) {
                if (i.quantity > 1) {
                    acc.push({
                        ...i,
                        quantity: i.quantity - 1,
                        adicionais: i.adicionais.map(grupo => ({
                            ...grupo,
                            calcular_maior_valor: grupo.calcular_maior_valor ?? false, // Garantir que seja false caso não exista
                            adicionais: grupo.adicionais.map(adicional => ({
                                ...adicional,
                                quantity: adicional.quantity // - 1
                            }))
                        }))
                    });
                }
                // Se a quantidade for 1, não adiciona de volta para `acc`, efetivamente removendo-o
            } else {
                acc.push(i);
            }
            return acc;
        }, []);

        setItensCarrinho(updatedCarrinho);
        saveToLocalStorage('itensCarrinho', updatedCarrinho);
    };

    useEffect(() => {
        if (itensCarrinho.length === 0) {
            setEditarPedido(false);
        }
    }, [itensCarrinho]);

    const handleDeletePedido = () => {
        setItensCarrinho([]);
        setEditarPedido(false);
        setValueDescricao('');
        toast("Itens excluídos", { autoClose: 2500, type: "success" });
    };

    const handleClickEntrega = async () => {
        setShowModalEntregaPdv(!showModalEntregaPdv)
        try {
            const response = await registerClienteFromCardapio(idEmpresa, nomeCliente, telefone);
            console.log("ENTROU AQUI? OQ CHEGOU NO RESPONSE?", response);
            if (response.status === 201 || response.status === 200) {
                let cliente = response.data.cliente;
                ////console.log("response:", cliente);
                setSelectedCliente(cliente)
            }
        } catch (error) {
            ////console.log("error:",error);
        }

    }

    const handleSubmitAPI = () => {
        const objIdPedido = infoPedido._id;
        const itens = itensCarrinho?.map(item => {
            ////console.log("ITEM:::", item);
            const { title, price, quantity, adicionais, obs_item } = item;

            const valor = price;
            const nomeItem = title;
            const quantidade = quantity;
            const grupo_adicionais = adicionais;
            return { nomeItem, valor, quantidade, grupo_adicionais, obs_item };
        });
        if (itens.length === 0) {
            toast("Nenhum item no carrinho, para atualizar o pedido adicione algum item!", { autoClose: 3000, type: "warning" });
            return;
        }
        ////console.log("Forma de pagamento salva:::", selectedPayment);
        const celular_cliente = telefone;//selectedCliente.telefone;
        const nome_cliente = nomeCliente;//selectedCliente.nome;
        const tipo_pagamento = selectedPayment.paymentType;
        const entrega = {
            tipo_entrega: savedFormaEntrega.formaEntrega,
            endereco: savedFormaEntrega.selectedAddress ? savedFormaEntrega.selectedAddress : enderecoEmpresa,
            valor: savedFormaEntrega.valorEntrega
        };
        const desconto = 0;
        const cpf_cnpj = cpfCnpjValue;
        const valor_troco = selectedPayment.paymentType === "Dinheiro" ? selectedPayment.valorTroco : 0;
        const valor_total = valorTotal;
        const descricao = valueDescricao;
        /*console.log({
            createdBy,
            idEmpresa,
            itens,
            celular_cliente,
            nome_cliente,
            tipo_pagamento,
            entrega,          
            desconto,
            cpf_cnpj,
            valor_troco,
            valor_total,
            descricao
        })*/
        updatePedidoFromPdv(objIdPedido, idEmpresa, itens, celular_cliente, nome_cliente, tipo_pagamento, entrega, desconto, cpf_cnpj, valor_troco, valor_total, descricao).then(cast => {
            //console.log("AQUI DEU BOM, CRIOU PEDIDO:::",cast)
            if (cast.status === 201) {
                if (cast.data.pedido) {
                    setInfoPedido(cast.data.pedido);
                    //console.log("cast.data::::",cast.data)
                }
                localStorage.removeItem('itensCarrinho');
                setValueDescricao('');
                setItensCarrinho([]);
                setSelectedPayment(null);
                setCpfCnpjValue('');
                setSelectedCliente(null);
                setTelefone('');
                setClientes([]);
                setValorEntrega(0);
                setSavedFormaEntrega(null);
                setNomeCliente('');
                handleCloseModal();
                setRefresh(true);
                toast(cast.data.msg, { autoClose: 5000, type: "success" });
            }
            else {
                toast(cast.data.msg, { autoClose: 5000, type: "error" });
            }

        }).catch(err => {
            ////console.log('Oh noooo!!');
            const msg = err.response.data.msg;
            //////console.log("MENSAGEM", msg)
            toast(msg, { autoClose: 5000, type: "error" });
        });
    };

    return (
        <PermissionGate permissions={['default']}>
            <ModalAddObsPedido showModalAddObsPedido={showModalAddObsPedido}
                setShowModalAddObsPedido={setShowModalAddObsPedido}
                itensCarrinho={itensCarrinho}
                setItensCarrinho={setItensCarrinho}
                valueDescricao={valueDescricao}
                setValueDescricao={setValueDescricao}
            />
            <ModalAddObsItem showModalAddObsItem={showModalAddObsItem}
                setShowModalAddObsItem={setShowModalAddObsItem}
                obsItem={obsItem}
                setObsItem={setObsItem}
                handleSaveObsItem={handleSaveObsItem}
            />
            <ModalEntregaPdv showModalEntregaPdv={showModalEntregaPdv}
                setShowModalEntregaPdv={setShowModalEntregaPdv}
                formaEntrega={formaEntrega}
                setFormaEntrega={setFormaEntrega}
                selectedCliente={selectedCliente}
                setSelectedCliente={setSelectedCliente}
                selectedAddress={selectedAddress}
                setSelectedAddress={setSelectedAddress}
                empresa={empresa}
                valorEntrega={valorEntrega}
                setValorEntrega={setValorEntrega}
                setSavedFormaEntrega={setSavedFormaEntrega}
            />
            <ModalPagamentos showModalPagamentos={showModalPagamentos}
                setShowModalPagamentos={setShowModalPagamentos}
                formaPagamento={formaPagamento}
                setFormaPagamento={setFormaPagamento}
                selectedPayment={selectedPayment}
                setSelectedPayment={setSelectedPayment}
                selectedCliente={selectedCliente}
                setSelectedCliente={setSelectedCliente}
                selectedAddress={selectedAddress}
                setSelectedAddress={setSelectedAddress}
                empresa={empresa}
                subTotal={subTotal}
                valorTotal={valorTotal}
                savedFormaEntrega={savedFormaEntrega}
            />
            <ModalCpfCnpj showModalCpfCnpj={showModalCpfCnpj}
                setShowModalCpfCnpj={setShowModalCpfCnpj}
                cpfCnpjValue={cpfCnpjValue}
                setCpfCnpjValue={setCpfCnpjValue}
            />
            <Teste showModal={showModal}>

                <div className="closeModalEditPedido" onClick={() => handleCloseModal()}>
                    <div
                        iconname="x"
                        iconcolor="#2B2B2B"
                        iconsize={18}
                        className="iconCancel"
                        style={{ height: 18, display: "flex" }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={18}
                            height={18}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#2B2B2B"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-x"
                        >
                            <line x1={18} y1={6} x2={6} y2={18} />
                            <line x1={6} y1={6} x2={18} y2={18} />
                        </svg>
                    </div>
                </div>


                {loading && <Loading type={"spinningBubbles"} className="calculandoDistanciaModalEntrega" />}
                <div className="m-5 pdv">

                    <div className="itens-cardapio">
                        <div style={{ width: '100%' }}>
                            <div className="form-header" style={{ display: 'inline-flex', alignItems: 'center', width: '100%', justifyContent: 'space-between', marginBottom: "0px" }}>
                                <div className="title">
                                    <h4 style={{ fontWeight: 'bold' }}>Pedidos balcão (PDV)</h4>
                                </div>
                            </div>
                        </div>
                        <div className="grid-cardapio" style={{ width: '100%' }}>
                            <div className="cardapio-filtro">
                                {/*<span><FaFilter style={{ fontSize: '12px' }} /> Filtrar</span>*/}
                                <div className="divBtnSendToCartPdv">
                                    <button onClick={handleEnviarParaCarrinho} className="btn btn-primary btnSendCartPdv">
                                        <span>Enviar ao Carrinho<MdShoppingCartCheckout className="anim-icon-cart" /></span>
                                    </button>
                                </div>
                            </div>
                            <div className="center-cardapio">
                                <div className="categoria-cardapio">
                                    {categorias.filter(cats => itens.some(item => item.category_item_id === cats._id))
                                        .map(c => (
                                            <div key={c._id} className="categoria-item" onClick={() => handleScrollOnClick(c._id)} style={{ background: c._id === selectedCategoria ? 'rgb(49, 140, 213)' : '', color: c._id === selectedCategoria ? 'white' : '' }}>
                                                <span>{c.title}</span>
                                            </div>
                                        ))}
                                </div>
                                {formaEntrega && console.log("formaEntrega:", formaEntrega)}
                                <div className="overflow-itens">
                                    {categorias.filter(category => itens.some(item => item.category_item_id === category._id))
                                        .map(category => (
                                            <div key={category._id + 1} className="same-category">
                                                <div id={category._id} className="categoria-item-title">
                                                    <span className="titulo-item">{category.title}</span>
                                                </div>
                                                <div className="itens-categoria">
                                                    {itens.filter(item => item.category_item_id === category._id).map((produtos, i) => {
                                                        const itemSelecionado = itensSelecionados.find(i => i._id === produtos._id);
                                                        return (
                                                            <div key={produtos._id} className={itemSelecionado ? 'itens selected' : 'itens'} onClick={() => handleItemClick(produtos)}>
                                                                <div className="divItemImg">
                                                                    {produtos.image && produtos.image !== "" ?
                                                                        <img width={100} height={75} src={produtos.image} alt={produtos.title} />
                                                                        :
                                                                        <div className="iconWhenImgNull">
                                                                            <MdFastfood style={{ fontSize: 75 }} />
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className="divItemName">
                                                                    <span>{produtos.title}</span>
                                                                </div>
                                                                {itemSelecionado ? (
                                                                    <div className="item-selected-amount">
                                                                        <div className="item-price-selected">
                                                                            R$ {(itemSelecionado.price +
                                                                                (Array.isArray(itemSelecionado.adicionais) ? itemSelecionado.adicionais.reduce((acc, grupo) => {
                                                                                    //////console.log("acc>>",grupo)
                                                                                    return acc + grupo.adicionais.reduce((subAcc, adicional) => subAcc, 0);
                                                                                }, 0) : 0)).toFixed(2).toString().replace('.', ',')}
                                                                        </div>
                                                                        <div className="pedeja-input">
                                                                            <div className="input-number">
                                                                                <button className="decrement-item"
                                                                                    onClick={(e) => { e.stopPropagation(); decrementCount(produtos); }}
                                                                                    type="button"
                                                                                >
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ color: '#2b2b2b' }}><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                                                                                </button>
                                                                                <input type='number' onChange={(e) => handleChange(e, produtos)} value={itemSelecionado.quantity || 0} />
                                                                                <button className="increment-item"
                                                                                    onClick={(e) => { e.stopPropagation(); incrementCount(produtos); }}
                                                                                    type="button"
                                                                                >
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ color: '#2b2b2b' }}><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        ))}
                                </div>

                            </div>
                        </div>
                    </div>

                    <div style={{ padding: '0px 10px 0px 10px' }}>
                        <div className="gerar-pedido">
                            <div className="buttons-edo">
                                <div className="buttons-edit-delete-obs">
                                    <button type="button" onClick={() => handleAddObsPedido()}>
                                        Observação
                                    </button>
                                </div>
                                <div className="buttons-edit-delete-obs">
                                    {editarPedido ?
                                        <button style={{ background: 'rgb(49, 140, 213)', color: 'white', border: 'none' }} onClick={handleConcluirEdicao}>
                                            Sair
                                        </button>
                                        :
                                        <button onClick={handleEditaPedido}>Editar</button>
                                    }
                                </div>
                                <div className="buttons-edit-delete-obs">
                                    <button onClick={handleDeletePedido}>Excluir</button>
                                </div>
                            </div>
                            <div className="itens-pedido">
                                <span>Itens do pedido</span>
                                <span>Subtotal</span>
                            </div>
                            <div className="content-pedidos" style={{ minHeight: 220 }}>
                                {itensCarrinho?.length === 0 && (
                                    <div className="emptyCartDivInfo">
                                        <span>Nenhum item no carrinho, clique nos itens ao lado e envie para o carrinho para que apareçam aqui.</span>
                                    </div>
                                )}
                                {Array.isArray(itensCarrinho) && itensCarrinho.map((produtos, i) => (
                                    <div key={i} className="pai">
                                        <div className="lista-pedidos">
                                            {editarPedido ? (
                                                <div className="pedeja-input">
                                                    <div className="input-number">
                                                        <button className="decrement-item" onClick={() => decrementCountLocalStorage(produtos)} type="button">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ color: '#2b2b2b' }}><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                                                        </button>
                                                        <input type='number' onChange={(e) => handleChangeLocalStorage(e, produtos)} value={produtos.quantity || 0} />
                                                        <button className="increment-item" onClick={() => incrementCountLocalStorage(produtos)} type="button">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ color: '#2b2b2b' }}><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>{produtos.quantity}x</div>
                                            )}
                                            <div style={{ minWidth: 140 }}>{produtos.title}</div>
                                            {/*renderAdicionais(produtos.adicionais)*/}
                                            <div className="line" />
                                            <div style={{ minWidth: 60 }}>
                                                R$ {(produtos.quantity * produtos.price
                                                ).toFixed(2).toString().replace('.', ',')}
                                            </div>
                                        </div>
                                        {renderAdicionais(produtos, produtos.adicionais)}
                                        <div>
                                            {produtos.obs_item && (
                                                <div className="divObsDoItemPDV">
                                                    <div style={{ minWidth: 140 }}>Observação do item: {produtos.obs_item}</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="line-separator" />
                                        <div className="divBtnsEditAndAddObsItem">
                                            <div style={{ width: '50%', borderRight: '1px solid lightgray' }}>
                                                <button className="btnAddObsItemRelative" style={{ fontSize: 10 }} onClick={() => handleAddObsItem(produtos)}>Adicionar Observação</button> {/* Botão para adicionar observação */}
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                <button className="btnRemoveItemRelative" onClick={() => handleRemoveItem(produtos)}>Excluir Item</button> {/* Botão para excluir item */}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {valueDescricao !== '' && (
                                    <div className="pai">
                                        <div className="divObsDoPedidoPDV">
                                            <div style={{ minWidth: 140 }}>Observação do pedido: {valueDescricao}</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="footer">
                                <div className="resumo-valores">
                                    <div className="valores-categoria">
                                        <span>Subtotal</span>
                                        <div>R$ {subTotal.toFixed(2).toString().replace('.', ',')}</div>
                                    </div>
                                    <div className="valores-categoria">
                                        <span>Desconto</span>
                                        <div>R$ 0,00</div>
                                    </div>
                                    <div className="valores-categoria">
                                        <span>Entrega
                                            {savedFormaEntrega?.formaEntrega === "Retirada" && <a style={{ fontWeight: 600 }}> - Retirar no local</a>}
                                            {savedFormaEntrega?.formaEntrega === "Entrega" && <a style={{ fontWeight: 600 }}> - Delivery</a>}
                                        </span>
                                        <div>{savedFormaEntrega?.formaEntrega === "Entrega" ? `R$ ${savedFormaEntrega.valorEntrega.toFixed(2).toString().replace('.', ',')}` : "Grátis"}</div>
                                    </div>
                                </div>
                                <div className="separador-valor">
                                    <span>Total</span>
                                    <span>R$ {valorTotal.toFixed(2).toString().replace('.', ',')}</span>
                                </div>
                                <div className="box-info">
                                    <div className="informacoes-pedidos">
                                        <div className="container-cliente-pedido">
                                            <div className="cliente-pedido-data" ref={containerRef}>
                                                <div className="telefone">
                                                    <div className="input-data-cliente">
                                                        <InputMask
                                                            mask="(99) 9 9999-9999"
                                                            id="celular"
                                                            type="tel"
                                                            name="celular"
                                                            style={{ width: '50%' }}
                                                            onChange={handleTelefoneChange}
                                                            value={telefone}
                                                            placeholder="(XX) X XXXX-XXXX"
                                                            autoComplete="off"
                                                        />
                                                        <div className="client-data-separator"></div>
                                                        <input
                                                            type="text"
                                                            value={nomeCliente}
                                                            onChange={handleNomeChange}
                                                            placeholder="Nome do cliente"
                                                            style={{ width: 'calc(50% - 15px)' }}
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                    {clientes.length > 0 && (
                                                        <div className={`clientes-list ${clientes.length > 0 ? 'visible' : 'hidden'}`}>
                                                            {clientes.map(cliente => (
                                                                <div
                                                                    key={cliente._id}
                                                                    className="cliente-item"
                                                                    onClick={() => handleClienteClick(cliente)}
                                                                >
                                                                    {cliente.telefone} - {cliente.nome}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-botoes-pedido">
                                        <div className="button-columnPDV">
                                            <button className={selectedCliente && selectedCliente.nome !== "" ? "btn-activated" : "btn-disabled"} disabled={!selectedCliente || selectedCliente.nome === "" ? true : false}
                                                style={{
                                                    cursor: !selectedCliente || selectedCliente?.nome === "" ? 'not-allowed' : 'pointer',
                                                    background: savedFormaEntrega ? 'rgb(49, 140, 213)' : 'white',
                                                    color: savedFormaEntrega ? 'white' : selectedCliente && selectedCliente?.nome !== "" && 'rgb(49, 140, 213)'
                                                }}
                                                onClick={() => handleClickEntrega()}>
                                                {console.log("SELECTED CLIENTE", selectedCliente)}
                                                Entrega
                                            </button>
                                            <button className={savedFormaEntrega ? "btn-activated" : "btn-disabled"} disabled={!selectedCliente || !savedFormaEntrega ? true : false}
                                                style={{
                                                    cursor: !selectedCliente || !savedFormaEntrega ? 'not-allowed' : 'pointer',
                                                    background: cpfCnpjValue !== "" ? 'rgb(49, 140, 213)' : 'white',
                                                    color: cpfCnpjValue !== "" ? 'white' : savedFormaEntrega && 'rgb(49, 140, 213)'
                                                }}
                                                onClick={() => setShowModalCpfCnpj(!showModalCpfCnpj)}>
                                                CPF/CNPJ
                                            </button>
                                        </div>
                                        <div className="button-columnPDV">
                                            <button className={savedFormaEntrega ? "btn-activated" : "btn-disabled"} disabled={!selectedCliente || !savedFormaEntrega ? true : false}
                                                style={{
                                                    cursor: !selectedCliente || !savedFormaEntrega ? 'not-allowed' : 'pointer',
                                                    background: selectedPayment ? 'rgb(49, 140, 213)' : 'white',
                                                    color: selectedPayment ? 'white' : savedFormaEntrega && 'rgb(49, 140, 213)'
                                                }}
                                                onClick={() => setShowModalPagamentos(!showModalPagamentos)}>
                                                Pagamentos
                                            </button>
                                            <button disabled={true}
                                                style={{
                                                    cursor: 'not-allowed',
                                                    background: 'white',
                                                    //color: 'rgb(49, 140, 213)',
                                                    //border: '1px solid rgb(49, 140, 213)'
                                                }}
                                                className="btn-disabled"
                                            >
                                                Desconto
                                            </button>
                                        </div>
                                    </div>
                                    <button className="button-enter" onClick={handleSubmitAPI} type="button"
                                        disabled={!selectedCliente || !savedFormaEntrega || !selectedPayment ? true : false}
                                        style={{
                                            cursor: !selectedCliente || !savedFormaEntrega || !selectedPayment ? 'not-allowed' : 'pointer',
                                            background: !selectedCliente || !savedFormaEntrega || !selectedPayment ? '#bbb' : 'rgb(49, 140, 213)'
                                        }}>
                                        Atualizar pedido
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {selectedItem && (
                    <ModalAdicionais
                        show={showAdicionaisModal}
                        onClose={() => setShowAdicionaisModal(false)} // Removendo reset
                        item={selectedItem}
                        idEmpresa={idEmpresa}
                        nomeEmpresaForUrl={nomeEmpresaForUrl}
                        quantidades={quantidades}
                        setQuantidades={setQuantidades}
                        adicionaisCompletos={adicionaisCompletos}
                        setAdicionaisCompletos={setAdicionaisCompletos}
                        handleFinalizarAdicionais={handleFinalizarAdicionais}
                    />
                )}
            </Teste>
        </PermissionGate>
    );
};

export default ModalPdv;
