import React, { useState, useRef, useEffect, useContext } from "react";
//import './style.css'
import styled from 'styled-components';
import InputMask from 'react-input-mask'
//import CurrencyInput from 'react-currency-input-field';
import ImageUploading from 'react-images-uploading';
import { CImage } from '@coreui/react'
//import Image from 'react-image'
//import Gallery from 'react-image-gallery'
import Map from './index'
import CryptoJS from 'crypto-js';
import AsyncSelect from 'react-select/async';
import { AuthContext } from "../../contexts/auth";
import { SidebarContext } from "../../AppRoutes";

import { getCategorias, registerItem, updateRaioEntregaEmpresa, getEmpresa, deleteRaioEntrega, updateTypeRegion, updateBairroEntregaEmpresa, updateEmpresaAddress } from "../../services/api";

import PermissionGate from "../../services/PermissionGate";

import { useNavigate } from "react-router-dom";
import LeftMenu from "../../components/LeftMenu";

import { useFormik } from "formik";
import * as Yup from "yup";

import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'
import { MdFastfood } from "react-icons/md";
import { BsPlusCircle } from "react-icons/bs";
import { FaEdit, FaTrash, FaExclamationCircle } from 'react-icons/fa'
import { MdLocationPin } from "react-icons/md";

import { toast } from "react-toastify";

const Teste = styled.div`

    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;
    z-Index: 9;

    @media (max-width: 880px) {
        margin-left: 0;
    }
`;


const EnderecoEstabelecimento = () => {
    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const userEncrypted = localStorage.getItem('user')
    const user = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
    const userParse = JSON.parse(user)
    const userID = userParse._id;
    const userName = userParse.name;
    const userEmail = userParse.email;
    var userImg = null;
    //console.log(empresa)
    const empresa = localStorage.getItem('empresa')
    const empresaParse = JSON.parse(empresa)
    const idEmpresa = empresaParse.id_empresa;
    const objIdEmpresa = empresaParse._id;

    const descriptionRef = useRef();
    const { sidebar, setSidebar } = useContext(SidebarContext);; //TESTANDO CONST NO AppROUTES


    const [isSubmitting, setIsSubmitting] = useState(false);

    const [refresh, setRefresh] = useState(false);

    const [latitudeEmpresa, setLatitudeEmpresa] = useState(null);
    const [longitudeEmpresa, setLongitudeEmpresa] = useState(null);

    useEffect(() => {
        (async () => {
            const response = await getEmpresa(userID, idEmpresa);
            //console.log(response.data.empresa.latitude)
            let latitude = parseFloat(response.data.empresa.latitude)
            let longitude = parseFloat(response.data.empresa.longitude)
            console.log("empresa:",response.data.empresa);
            setLatitudeEmpresa(latitude)
            setLongitudeEmpresa(longitude)
            formik.setFieldValue("cep", response.data.empresa.cep);
            formik.setFieldValue("logradouro", response.data.empresa.logradouro);
            formik.setFieldValue("address_number", response.data.empresa.address_number);
            formik.setFieldValue("municipio", response.data.empresa.municipio);
            formik.setFieldValue("estado", response.data.empresa.estado);
            formik.setFieldValue("bairro", response.data.empresa.bairro);
            formik.setFieldValue("complemento", response.data.empresa.complemento);
        })();
        //eslint-disable-next-line
    }, [refresh]);


    /*useEffect(() => {
        (async () => {
            
            // Get address from latitude & longitude.
            geocode(RequestType.LATLNG, "-16.722070,-49.278263")
            .then(({ results }) => {
            const address = results[0].formatted_address;
            console.log(address);
            })
            .catch(console.error);
            //const teste = [response.data]
            //setLoading(false);
            //console.log("diasHorarios",diasHorarios)
            //console.log("horariosUnicos>",horariosUnicos)
            
        })();
    },[]);*/

    //Validações
    const SignUpSchema = Yup.object().shape({
        cep: Yup.string().required("Obrigatório"),
        logradouro: Yup.string().required("Obrigatório"),
        address_number: Yup.string().required("Obrigatório"),
        municipio: Yup.string().required("Obrigatório"),
        estado: Yup.string().required("Obrigatório"),
        bairro: Yup.string().required("Obrigatório"),
    });

    const formik = useFormik({
        validationSchema: SignUpSchema,
        validateOnBlur: false,
        validateOnChange: false,
        initialValues: {
            cep: "",
            logradouro: "",
            address_number: "",
            municipio: "",
            estado: "",
            bairro: "",
            complemento: ""
        },
        onSubmit: (values) => {
            //alert(JSON.stringify(values, null, 2));
            //console.log(values);
            setIsSubmitting(true);
            //const description = descriptionRef.current.value;
            const cep = values.cep;
            const logradouro = values.logradouro;
            const address_number = values.address_number;
            const municipio = values.municipio;
            const estado = values.estado;
            const bairro = values.bairro;
            const complemento = values.complemento;
            //handleSaveImg();
            
            handleSubmitAPI(cep,logradouro,address_number,municipio,estado,bairro,complemento);

        },
    });

    const navigate = useNavigate();

    const handleBack = () => {
        //setSidebar(!sidebar)
        navigate("/");
    }


    const handleSubmitAPI = (cep,logradouro,address_number,municipio,estado,bairro,complemento) => {
        const latitude = latitudeEmpresa.toString();
        const longitude = longitudeEmpresa.toString();
        const _id = empresaParse._id;
        const id = empresaParse.id_empresa;
        console.log({cep,logradouro,address_number,municipio,estado,bairro,complemento, latitude, longitude});
        
        updateEmpresaAddress(_id,
            id,
            userID,
            cep,
            estado,
            municipio,
            bairro,
            address_number,
            complemento,
            latitude,
            longitude).then(cast => {
                if (cast.status === 200) {
                    toast(cast.data.msg, { autoClose: 5000, type: "success" });
                    setIsSubmitting(false);
                    //navigate("/list-item");
                }
                else {
                    toast(cast.data.msg, { autoClose: 5000, type: "error" });
                    setIsSubmitting(false);
                }
    
        }).catch(err => {
            console.log('Oh noooo!!');
            const msg = err.response.data.msg;
            //console.log("MENSAGEM", msg)
            toast(msg, { autoClose: 5000, type: "error" });
            setIsSubmitting(false);

        });
        //e.preventDefault();
        /*
        const createdBy = userID;
        const out = false;
        const category_item_id = selectData.value;
        const category_item_title = selectData.label;
        const disponibilidade = 'sempre';
        console.log("DEU SUBMIT")

        //Registrando o item
        console.log("submit categoria!", { createdBy, idEmpresa, category_item_id, category_item_title, title, description, out, images, price, disponibilidade });
        registerItem(createdBy, idEmpresa, category_item_id, category_item_title, title, description, out, images, price, disponibilidade).then(cast => {
            if (cast.status === 201) {
                toast(cast.data.msg, { autoClose: 5000, type: "success" });
                setIsSubmitting(false);
                navigate("/list-item");
            }
            else {
                toast(cast.data.msg, { autoClose: 5000, type: "error" });
                setIsSubmitting(false);
            }

        }).catch(err => {
            console.log('Oh noooo!!');
            const msg = err.response.data.msg;
            //console.log("MENSAGEM", msg)
            toast(msg, { autoClose: 5000, type: "error" });
            setIsSubmitting(false);

        });
        */
    }


    return (

        <>
            <PermissionGate permissions={['default']}>


                <LeftMenu setSidebar={setSidebar} sidebar={sidebar} />

                <Teste sidebar={sidebar}>
                    <div className="w-100 p-4">
                        {/*<form onSubmit={handleSubmitButton}>*/}
                        <form onSubmit={formik.handleSubmit} >

                            <div className="form-header" style={{ marginBottom: "0px" }}>
                                <div className="title">
                                    <h1>Endereço</h1>
                                </div>
                            </div>

                            <div className="flex-column flex-md-row" style={{ display: "flex", justifyContent: "right"/*, height:"80px"*/ }}>

                                <div className="div-buttons">
                                    <div className="continue-button">
                                        <button type="submit" disabled={isSubmitting}>
                                            <AiIcons.AiOutlineSave style={{ marginRight: "5px", fontSize: "22px", marginBottom: "2px" }} /><a>Salvar</a>
                                        </button>
                                    </div>

                                    <div className="back-button" style={{ textAlign: "start" }}>
                                        <button onClick={handleBack}>
                                            <SlIcons.SlActionUndo style={{ color: "#ff4c4c", marginRight: "5px", fontSize: "18px", marginBottom: "2px" }} /><a >Voltar</a>
                                        </button>
                                    </div>
                                </div>

                            </div>

                            <div className="contentItemComplete flex-column flex-md-row">
                                <div style={{ maxHeight: "40px" }} className="input-group inputGroup-etapasItem w-100 p-2">
                                    <div className="etapasAddItem etapaAtiva">
                                        <label>1. Endereço</label>
                                    </div>
                                </div>

                                <div className="input-group inputGroup-adicinaItem flex-column mw-100 mh-100">

                                    <div className="input-box w-100">
                                        <label>CEP<span style={{color:"red"}}>*</span></label>
                                        <InputMask
                                            placeholder="Digite o CEP"
                                            id="cep"
                                            type="cep"
                                            name="cep"
                                            mask={"99999-999"}
                                            onChange={formik.handleChange}
                                            value={formik.values.cep}
                                        />
                                        {formik.errors.cep && (
                                            <div className="error">{formik.errors.cep}</div>
                                        )}
                                    </div>

                                    <div className="input-box w-100">
                                        <label>Logradouro (rua, avenida, etc.)<span style={{color:"red"}}>*</span></label>
                                        <input
                                            placeholder="Digite o logradouro"
                                            id="logradouro"
                                            type="logradouro"
                                            name="logradouro"
                                            onChange={formik.handleChange}
                                            value={formik.values.logradouro}
                                        />
                                        {formik.errors.logradouro && (
                                            <div className="error">{formik.errors.logradouro}</div>
                                        )}
                                    </div>                                   

                                    <div className="input-box w-100">
                                        <label>Cidade<span style={{color:"red"}}>*</span></label>
                                        <input
                                            placeholder="Digite a cidade"
                                            id="municipio"
                                            type="municipio"
                                            name="municipio"
                                            onChange={formik.handleChange}
                                            value={formik.values.municipio}
                                        />
                                        {formik.errors.municipio && (
                                            <div className="error">{formik.errors.municipio}</div>
                                        )}
                                    </div>

                                    <div className="input-box w-100">
                                        <label>Estado (UF)<span style={{color:"red"}}>*</span></label>
                                        <input
                                            placeholder="Ex.: SP"
                                            id="estado"
                                            type="estado"
                                            name="estado"
                                            onChange={formik.handleChange}
                                            value={formik.values.estado}
                                        />
                                        {formik.errors.estado && (
                                            <div className="error">{formik.errors.estado}</div>
                                        )}
                                    </div>

                                    <div className="input-box w-100">
                                        <label>Número<span style={{color:"red"}}>*</span></label>
                                        <input
                                            placeholder="Ex.: 123"
                                            id="address_number"
                                            type="address_number"
                                            name="address_number"
                                            onChange={formik.handleChange}
                                            value={formik.values.address_number}
                                        />
                                        {formik.errors.address_number && (
                                            <div className="error">{formik.errors.address_number}</div>
                                        )}
                                    </div>
                                
                                    <div className="input-box w-100">
                                        <label>Bairro<span style={{color:"red"}}>*</span></label>
                                        <input
                                            placeholder="Digite o bairro"
                                            id="bairro"
                                            type="bairro"
                                            name="bairro"
                                            onChange={formik.handleChange}
                                            value={formik.values.bairro}
                                        />
                                        {formik.errors.bairro && (
                                            <div className="error">{formik.errors.bairro}</div>
                                        )}
                                    </div>

                                    <div className="input-box w-100">
                                        <label>Complemento</label>
                                        <input
                                            placeholder="Digite o complemento"
                                            id="complemento"
                                            type="complemento"
                                            name="complemento"
                                            onChange={formik.handleChange}
                                            value={formik.values.complemento}
                                        />
                                        {formik.errors.complemento && (
                                            <div className="error">{formik.errors.complemento}</div>
                                        )}
                                    </div>

                                    <div className="formGroupRow">
                                       
                                        <div className="divContainerAddItem Mapa">             
                                            <span style={{color:'black', marginBottom:5}}> <FaExclamationCircle /> Para mudar o local do estabelecimento arraste o marcador
                                                <span style={{color:'#EA4335'}}> <MdLocationPin /></span> para a localização desejada e clique em Salvar. 
                                            </span>                               
                                            {latitudeEmpresa && longitudeEmpresa ? (
                                                <Map latitudeEmpresa={latitudeEmpresa} longitudeEmpresa={longitudeEmpresa} 
                                                    setLatitudeEmpresa={setLatitudeEmpresa} setLongitudeEmpresa={setLongitudeEmpresa}
                                                />
                                            ) : (
                                                <p>Carregando o mapa...</p>
                                            )}

                                        </div>

                                    </div>

                                </div>



                            </div>

                        </form>
                    </div>
                </Teste>

            </PermissionGate>
        </>


    );
};

export default EnderecoEstabelecimento;