import React, { useState, useContext, useEffect } from "react";
import './style.css'
import styled from 'styled-components';
import InputMask from 'react-input-mask'
import cep from 'cep-promise'
import CryptoJS from 'crypto-js';
import { AuthContext } from "../../contexts/auth";
import { SidebarContext } from "../../AppRoutes";
import { getEstados, getMunicipios, registerCategoria } from "../../services/api";

import PermissionGate from "../../services/PermissionGate";

import { useNavigate } from "react-router-dom";
import LeftMenu from "../../components/LeftMenu";

import { useFormik } from "formik";
import * as Yup from "yup";

import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'
import { BsPlusCircle } from "react-icons/bs";
import { FaTrash } from 'react-icons/fa'

import { toast } from "react-toastify";

const Teste = styled.div`

    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;
    z-Index: 9;

    @media (max-width: 880px) {
        margin-left: 0;
    }
`;

const options = [
    { value: "sempre", label: "Sempre disponível" },
    { value: "pausado", label: "Pausado e não disponível no cardápio" },
    { value: "especificos", label: "Disponível em dias e horários específicos" }
];
  
const Days = [{domingo:"D", segunda:"S", terca:"T", quarta:"Q", quinta:"Q", sexta:"S", sabado:"S"}];

const CustomCheckbox = ({ checked, onChange, value, dayString, disabled }) => {
    return (
        <div className={`custom-checkbox ${checked ? 'checked' : ''}`} onClick={() => onChange(value)}>
            {checked ? <p className="fas fa-check">{dayString}</p> : <p>{dayString}</p> }
        </div>
    );
}


const Categoria = () =>{
    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const userEncrypted = localStorage.getItem('user')
    const user = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
    const userParse = JSON.parse(user)
    const userID = userParse._id;
    const userName = userParse.name;
    const userEmail = userParse.email;
    var userImg = null;
    //console.log(empresa)
    const empresa = localStorage.getItem('empresa')
    const empresaParse = JSON.parse(empresa)
    const idEmpresa = empresaParse.id_empresa;

    const { sidebar, setSidebar } = useContext(SidebarContext);; //TESTANDO CONST NO AppROUTES

    const [selectedOption, setSelectedOption] = useState("");
    const [selectedDays, setSelectedDays] = useState([]);
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [diasHorarios, setDiasHorarios] = useState([
        {
            dias: new Set(),
            startTime: "00:00",
            endTime: "23:59",
        }
    ]);

    useEffect(() => {
        (async () => {
                   
            //const teste = [response.data]
            //setLoading(false);
            console.log("diasHorarios",diasHorarios)
            console.log("horariosUnicos>",horariosUnicos)
            
        })();
    },[diasHorarios]);

    //Validações
    const SignUpSchema = Yup.object().shape({
        //email: Yup.string().email("Insira um e-mail válido").required("Obrigatório"),
        //cnpj: Yup.string().required("Obrigatório"),
        title: Yup.string().required("Obrigatório"),
        //disponibilidade: Yup.string().required("Obrigatório"),
    });

    const formik = useFormik({
        validationSchema: SignUpSchema,
        validateOnBlur: false,
        validateOnChange: false,
        initialValues: {
          title: "",
        },
        onSubmit: (values) => {
          //alert(JSON.stringify(values, null, 2));
          console.log(values);
          
          const title = values.title;

          handleSubmitAPI(title);
          
        },
    });

    const navigate = useNavigate();

    const handleBack = () => {
        //setSidebar(!sidebar)
        navigate("/list-categoria");
    }


    const handleSubmitAPI = (title) =>{
        //e.preventDefault();
        
        const createdBy = userID;
        const only_pdv = false;
        const only_qrcode = false;
        const only_delivery_take_local = false;
        let disponibilidade = selectedOption;
        if(!selectedOption){
            disponibilidade = "sempre";
        }

        //Registrando a empresa
        console.log("submit categoria!",{createdBy, idEmpresa, title, disponibilidade, horariosUnicos, only_pdv, only_qrcode, only_delivery_take_local});
        registerCategoria(createdBy, idEmpresa, title, disponibilidade, horariosUnicos, only_pdv, only_qrcode, only_delivery_take_local).then(cast => {
            console.log("AQUI DEU BOM, CRIOU CATEGORIA:::",cast)
            if(cast.status===201){
                toast(cast.data.msg, {autoClose: 5000, type: "success"});
                navigate("/list-categoria");
            }
            else {
                toast(cast.data.msg, {autoClose: 5000, type: "error"});
            }
            
        }).catch(err => {
            console.log('Oh noooo!!');
            const msg = err.response.data.msg;
            //console.log("MENSAGEM", msg)
            toast(msg, {autoClose: 5000, type: "error"});
            
          });
        
    }

    //const [horariosToDelete, setHorariosToDelete] = useState("");
    const handleDeleteHorario = (horarioIndex) => {
        setDiasHorarios((prevDiasHorarios) => {
          const newDiasHorarios = [...prevDiasHorarios];
          newDiasHorarios.splice(horarioIndex, 1);
          return newDiasHorarios;
        });
    };

    const handleOptionChange = (event) => {     
        if (event.target.value==="sempre"||event.target.value==="pausado"){
            setDiasHorarios([
                {
                  dias: new Set([""]),
                  startTime: "00:00",
                  endTime: "23:59",
                }
            ]);
        }
        setSelectedOption(event.target.value);
    };

    const horariosPorDia = diasHorarios.reduce((acc, { dias, startTime, endTime }) => {
        dias.forEach((dia) => {
          if (!acc[dia]) {
            acc[dia] = {
              dia,
              startTime,
              endTime,
            };
          } else {
            const { startTime: startTimeAcc, endTime: endTimeAcc } = acc[dia];
            const [startTimeHour, startTimeMin] = startTime.split(':').map(Number);
            const [startTimeAccHour, startTimeAccMin] = startTimeAcc.split(':').map(Number);
            const [endTimeHour, endTimeMin] = endTime.split(':').map(Number);
            const [endTimeAccHour, endTimeAccMin] = endTimeAcc.split(':').map(Number);
    
            if (startTimeHour < startTimeAccHour || (startTimeHour === startTimeAccHour && startTimeMin < startTimeAccMin)) {
              acc[dia].startTime = startTime;
            }
    
            if (endTimeAccHour < endTimeHour || (endTimeAccHour === endTimeHour && endTimeAccMin < endTimeMin)) {
              acc[dia].endTime = endTime;
            }
          }
        });
    
        return acc;
    }, {});
    
    const horariosUnicos = Object.values(horariosPorDia);
    
    const handleDayChange = (horarioIndex, key) => {
        const newDiasHorarios = [...diasHorarios];
        const horario = newDiasHorarios[horarioIndex];
      
        if (horario.dias.has(key)) {
          horario.dias.delete(key);
        } else {
          horario.dias.add(key);
        }
      
        setDiasHorarios(newDiasHorarios);
    };
    
    const validateTime = (value) => {
        const regex = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/;
        return regex.test(value);
    };
    const handleTimeChange = (horarioIndex, setTime, event) => {
        const value = event.target.value;
        if (validateTime(value)) {
          const newHorarios = [...diasHorarios];
          newHorarios[horarioIndex][setTime] = value;
          setDiasHorarios(newHorarios);
        }
    };
    
    const handleAddHorario = () => {
        setDiasHorarios(diasHorarios.concat({
            dias: new Set(),
            startTime: "00:00",
            endTime: "23:59",
        }));
    };

    const isDayAlreadySelectedInHorario = (day, currentHorarioIndex) => {
        return diasHorarios
          .filter((horario, index) => index !== currentHorarioIndex)
          .some((horario) => horario.dias.has(day));
    };
      

    return(

        <>
        <PermissionGate permissions={['default']}>
    

            <LeftMenu setSidebar={setSidebar} sidebar={sidebar}/>

            <Teste sidebar={sidebar}>
                <div className="w-100 p-4">
                    {/*<form onSubmit={handleSubmitButton}>*/}
                    <form onSubmit={formik.handleSubmit} >
                    
                        <div className="form-header" style={{marginBottom:"0px"}}>
                            <div className="title">
                                <h1>Adicionar Categoria</h1>
                            </div>
                        </div>

                        <div style={{display:"flex", justifyContent:"right"/*, height:"80px"*/}}>

                            <div className="div-buttons">
                                <div className="continue-button">                           
                                    <button type="submit">
                                        <AiIcons.AiOutlineSave style={{marginRight: "5px",fontSize: "22px",marginBottom: "2px"}}/><a>Salvar</a> 
                                    </button>                            
                                </div>

                                <div className="back-button">                                                       
                                    <button onClick={handleBack}>
                                        <SlIcons.SlActionUndo style={{color:"#ff4c4c",marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/><a >Voltar</a> 
                                    </button>
                                </div>
                            </div>

                        </div>
                    
                        <div className="input-group inputGroup-adicinaCategoria mh-100">


                            <div className="input-box" style={{  transition:sidebar?"200ms":"100ms", width: "100%" }}>
                                <label>Nome da categoria<span style={{color:"red"}}>*</span></label>
                                <input 
                                placeholder="Digite o nome da Categoria" 
                                id="title"
                                type="title"
                                name="title"
                                onChange={formik.handleChange}
                                value={formik.values.title}
                                />
                                {formik.errors.title && (
                                    <div className="error">{formik.errors.title}</div>
                                )}
                            </div>

                            <div className="disponibilidadeCategoria" style={{ transition:sidebar?"200ms":"100ms", width: "100%"}}>
                                <div className="contentHeaderDisponibilidade">
                                    <h1 className="disponibilidadeH1">Disponibilidade<span style={{color:"red"}}>*</span></h1>
                                    <div className="selectdiv disp">                                       
                                    <label style={{width:"100%"}}>
                                        <select value={selectedOption} onChange={handleOptionChange}>
                                            {options.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                            ))}
                                        </select></label>
                                    </div>
                                </div>


                               


                            </div>

                            <div className="contentInfoDisponibilidade">
                                {selectedOption === "especificos" && (
                                        <>
                                            {diasHorarios.map((horario, horarioIndex) => (
                                                <div key={horarioIndex} className="divDiaHorario">
                                                    <div className="columnDia">
                                                        <h6>Dias disponíveis</h6>
                                                        <div>                                        
                                                            {Days[0] &&
                                                            Object.entries(Days[0]).map(([key, value]) => (
                                                                <label key={key}>
                                                                <CustomCheckbox
                                                                checked={!isDayAlreadySelectedInHorario(horarioIndex, key) && horario.dias.has(key)}
                                                                onChange={() => handleDayChange(horarioIndex, key)}
                                                                value={key}
                                                                dayString={value}
                                                                horario={horario}
                                                                selectedDays={horario.dias} // Modificar esta linha
                                                                />
                                                                </label>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className="columnHorario">
                                                        <h6>Horários</h6>
                                                        <div key={horarioIndex} className="divTimeSet">
                                                            <input
                                                                type="time"
                                                                value={horario.startTime}
                                                                onChange={(event) => handleTimeChange(horarioIndex, "startTime", event)}
                                                            />
                                                            <span> às </span>
                                                            <input
                                                                type="time"
                                                                value={horario.endTime}
                                                                onChange={(event) => handleTimeChange(horarioIndex, "endTime", event)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="columnDeleteHorario">
                                                        <button
                                                        type="button"
                                                        className="btn btn-danger"
                                                        onClick={() => handleDeleteHorario(horarioIndex)}
                                                        >
                                                        <FaTrash size={14} color="white" />
                                                        </button>
                                                    </div>
                                                    
                                                </div>
                                            ))}
                                            <button type="button" className="buttonAddHorarios" onClick={handleAddHorario}><BsPlusCircle/> Adicionar Horário</button>
                                        </>
                                )}
                            </div>
    

                        </div>

                    </form>
                </div>
            </Teste>
                
        </PermissionGate>       
        </>
        

        );
    };
    
    export default Categoria;