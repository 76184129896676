import styled from "styled-components";
import React, { useRef } from 'react'
import './style.css'
import PermissionGate from "../../services/PermissionGate";
import moment from 'moment'
import CryptoJS from 'crypto-js';
import { useFormik } from "formik";
import * as Yup from "yup";
import Loading from "react-loading";
import * as AiIcons from 'react-icons/ai';
import { calcularDistancia, deleteEnderecoCliente, getRevendas, getEmpresas, updateTempoEntregaEmpresa, ImprimirPedido, updateUser } from "../../services/api";
import AsyncSelect from "react-select/async";
import InputMoney from "../ListItem/InputMoney";
import { AuthContext } from "../../contexts/auth";
import { useEffect, useContext, useState } from "react";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { FaMotorcycle, FaStore } from "react-icons/fa";
import { BiComment } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { GrLocation } from "react-icons/gr";
import { RiCloseCircleLine } from "react-icons/ri";
import { updateClienteAddress } from "../../services/api";
import {
    setKey,
    setDefaults,
    setLanguage,
    setRegion,
    fromAddress,
    fromLatLng,
    fromPlaceId,
    setLocationType,
    geocode,
    RequestType,
} from "react-geocode";
import {
    GoogleMap,
    Marker,
    LoadScript,
    Autocomplete,
    StandaloneSearchBox,
    DirectionsService,
    DirectionsRenderer,
} from "@react-google-maps/api";

const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';
const REACT_APP_GOOGLE_API_KEY = "AIzaSyDe-csDaMYbHHrjdzR-q-OyHvh04MfJnck"//import.meta.env.REACT_APP_GOOGLE_API_KEY

setDefaults({
    key: "AIzaSyDe-csDaMYbHHrjdzR-q-OyHvh04MfJnck", // Your API key here.
    language: "pt-BR", // Default language for responses.
    region: "br", // Default region for responses.
});

const DivPedidoContainer = styled.div`
  z-index:115;
  position: fixed;
  top: 0;
  left: 0;
  display: ${({ showModalEntregaPdv }) => (showModalEntregaPdv ? 'none' : 'flex')};
  float: left;
  min-width: 100%;
  min-height: 100%;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 5px 0;
  list-style: none;
  background-color: rgba(0,0,0,0.5);
  background-clip: padding-box;
  align-items:center;
  justify-content:center;
  opacity: ${({ showModalEntregaPdv }) => (showModalEntregaPdv ? '0' : '1')};
  transition: opacity .3s ease-in-out;

  // Add keyframes for the fade-in effect
  animation: fadeIn .3s ease-in-out;
  animation-fill-mode: forwards;

  // Define the keyframes for the animation
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const DivPedido = styled.div`
    z-index:120;
    position: absolute;
    top: 3%;
    left: 30%;
    float: left;
    min-width: 40%;
    min-height: 15%;
    max-width: 40%;
    padding: 5px 0;
    list-style: none;
    --background-color: white;
    background-color:rgb(247,247,247)!important;
    background-clip: padding-box;
    border-radius: 15px;
    padding:20px;

`;

const ModalEntregaPdv = ({
    showModalEntregaPdv,
    setShowModalEntregaPdv,
    formaEntrega,
    setFormaEntrega,
    selectedCliente,
    setSelectedCliente,
    selectedAddress,
    setSelectedAddress,
    empresa,
    valorEntrega,
    setValorEntrega,
    setSavedFormaEntrega
}) => {
    const idEmpresa = empresa?.id_empresa;
    const regionType = empresa?.region_type_delivery;
    const bairros_entrega = empresa?.bairros_entrega;
    const [calculandoDistancia, setCalculandoDistancia] = useState(false);
    const [valorEntregaToAdd, setValorEntregaToAdd] = useState(null);

    const [rua, setRua] = useState("");
    const [numero, setNumero] = useState("");
    const [bairro, setBairro] = useState("");
    const [bairroEditavel, setBairroEditavel] = useState(false);
    const [cidade, setCidade] = useState("");
    const [estado, setEstado] = useState("");
    const [cep, setCep] = useState("");
    const [complemento, setComplemento] = useState("");
    const [referencia, setReferencia] = useState("");
    const [latitudeToSave, setLatitudeToSave] = useState("");
    const [longitudeToSave, setLongitudeToSave] = useState("");
    const [enderecoToEdit, setEnderecoToEdit] = useState(null);
    const [address, setAddress] = useState("");
    const [autocomplete, setAutocomplete] = useState(null);
    const [addressComplete, setAddressComplete] = useState(false);
    const [registeringAddress, setRegisteringAddress] = useState(false);

    useEffect(() => {
        setValorEntrega(0);
        if (selectedAddress && formaEntrega === "Entrega") {
            calcularValorEntrega(selectedAddress);
        }
    }, [selectedAddress]);

    const closeModalAddObsItem = () => {
        setShowModalEntregaPdv(!showModalEntregaPdv);
        setValorEntregaToAdd(null);
    }

    const saveModalFormaEntrega = () => {
        //handleSaveObsItem();
        setShowModalEntregaPdv(!showModalEntregaPdv);
        setSavedFormaEntrega({
            formaEntrega: formaEntrega,
            valorEntrega: valorEntrega,
            selectedAddress: selectedAddress
        });
        setValorEntregaToAdd(null);
        toast('Forma de entrega adicionada com sucesso!', { autoClose: 1000, type: 'success' });
    }

    const handleSelectAddress = (address) => {
        setSelectedAddress(address);
        //if(formaEntrega==="Entrega") {
        //    calcularValorEntrega(address);
        //}
    }

    const [isFocused, setIsFocused] = useState(false);
    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const onChange = (event, nameObject, isNumber) => {
        setValorEntrega(isNumber ? Number(event.target.value) : event.target.value);
        setValorEntregaToAdd(isNumber ? Number(event.target.value) : event.target.value);
        //formik.setFieldValue("preco", isNumber ? Number(event.target.value) : event.target.value );
    };

    /*const handleSaveAddress = () => {
    
        if(rua !== "" && numero !== "" && bairro !== "" && cidade !== "" && estado !== "" && cep !== ""){
            const addressObj = {
                rua: rua,
                numero: numero,
                bairro: bairro,
                cidade: cidade,
                estado: estado,
                cep: cep,
                complemento: complemento,
                referencia: referencia
            }

            updateClienteAddress(idEmpresa, selectedCliente.telefone, addressObj, enderecoToEdit).then((response) => {
                ////console.log(response)
                if(response.status === 200){
                    toast('Endereço salvo com sucesso!', {autoClose:5000, type: 'success'});
                    //////console.log(response.data.response.endereco);
                    //console.log(response)
                    selectedCliente.endereco = response.data.response.endereco;
                    setRegisteringAddress(false);
                    ////console.log("response.data.response.endereco>>>>>>>>>>",response.data.response.endereco)
                    if(!enderecoToEdit){
                        setSelectedAddress(response.data.response.endereco[response.data.response.endereco.length-1]);
                        //console.log("O QUE TEM AQUI 11>>",response.data.response.endereco[response.data.response.endereco.length-1])
                        handleClearAddress();
                    }
                    if(enderecoToEdit) {
                        //console.log("O QUE TEM AQUI @2>>",response.data.response.endereco.find(endereco => endereco._id === enderecoToEdit._id))
                        //console.log("O QUE TEM AQUI 333>>",enderecoToEdit);
                        setSelectedAddress(response.data.response.endereco.find(endereco => endereco._id === enderecoToEdit._id));
                        handleClearAddress();
                    }
                } else {
                    toast('Erro ao salvar endereço!', {autoClose:5000, type: 'error'});
                }
            });
            //navigate("/finalizar-pedido", {state: {personInfoObj, addressObj}});
        } else {
            toast('Por favor, preencha todos os campos obrigatórios', {autoClose:5000, type: 'warning'});
        }
    }*/
    const handleSaveAddress = () => {
        if (rua !== "" && numero !== "" && bairro !== "" && cidade !== "" && estado !== "") {
            const addressObj = {
                rua: rua,
                numero: numero,
                bairro: bairro,
                cidade: cidade,
                estado: estado,
                cep: cep,
                complemento: complemento,
                referencia: referencia,
                latitude: latitudeToSave,
                longitude: longitudeToSave
            };

            updateClienteAddress(idEmpresa, selectedCliente.telefone, addressObj, enderecoToEdit).then((response) => {
                if (response.status === 200) {
                    //toast('Endereço salvo com sucesso!', { autoClose: 5000, type: 'success' });

                    const updatedAddresses = response.data.response.endereco;
                    selectedCliente.endereco = updatedAddresses;

                    if (!enderecoToEdit) {
                        const newAddress = updatedAddresses[updatedAddresses.length - 1];
                        setSelectedAddress(newAddress);
                        //console.log("Novo endereço adicionado:", newAddress);
                    } else {
                        //console.log("Endereço atualizado!");
                    }

                    handleClearAddress();
                    setRegisteringAddress(false);
                } else {
                    toast('Erro ao salvar endereço!', { autoClose: 3000, type: 'error' });
                }
            }).catch(error => {
                console.error("Erro ao atualizar endereço:", error);
                toast('Erro ao salvar endereço!', { autoClose: 3000, type: 'error' });
            });
        } else {
            toast('Por favor, preencha todos os campos obrigatórios', { autoClose: 3000, type: 'warning' });
        }
    };

    const handleEdit = (event, enderecoToEdit) => {
        event.stopPropagation();
        setSelectedAddress(null);
        setEnderecoToEdit(enderecoToEdit);
        setRua(enderecoToEdit.rua);
        setNumero(enderecoToEdit.numero);
        setBairro(enderecoToEdit.bairro);
        setCidade(enderecoToEdit.cidade);
        setEstado(enderecoToEdit.estado);
        setCep(enderecoToEdit.cep);
        setComplemento(enderecoToEdit.complemento);
        setReferencia(enderecoToEdit.referencia);
        setRegisteringAddress(true);
    }

    const handleDelete = (event, id) => {
        event.stopPropagation();
        deleteEnderecoCliente(idEmpresa, selectedCliente.telefone, id).then(response => {
            if (response.status === 200) {
                //const personInfoObj = estava errado
                //personInfoObj = response.data.response;
                const personInfoObj = response.data.response;
                setSelectedCliente(personInfoObj);

                toast('Endereço deletado com sucesso!', { autoClose: 1000, type: 'success' });
            }
            else {
                //console.log('Erro ao deletar endereço!');
            }
        });
    }

    const libraries = ["places"];

    const onLoad = (autocompleteInstance) => {
        setAutocomplete(autocompleteInstance);
    };

    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();

            // Pega a localização (lat, lng)
            const location = {
                lat: place.geometry?.location.lat() || 0,
                lng: place.geometry?.location.lng() || 0,
            };

            // Extrai os componentes do endereço diretamente do place
            const addressComponents = place.address_components;

            // Resetando estados para evitar informações desatualizadas
            setRua("");
            setNumero("");
            setBairro("");
            setCidade("");
            setEstado("");
            setCep("");

            // Itera sobre os componentes de endereço do autocomplete
            addressComponents.forEach(component => {
                const { long_name, short_name, types } = component;

                if (types.includes("street_number")) {
                    setNumero(long_name);
                }
                if (types.includes("route")) {
                    setRua(long_name);
                }
                if (types.includes("sublocality") || types.includes("neighborhood")) {
                    setBairro(long_name);
                }
                if (types.includes("administrative_area_level_1")) {
                    setEstado(short_name);
                }
                if (types.includes("administrative_area_level_2") || types.includes("locality")) {
                    setCidade(long_name);
                }
                if (types.includes("postal_code")) {
                    setCep(long_name);
                }
            });

            //console.log("console>", place.formatted_address);

            // Chama a geocodificação reversa apenas se precisar de algum dado adicional com base na lat/lng
            reverseGeocode({ latitude: location.lat, longitude: location.lng });
        } else {
            //console.log('Autocomplete is not loaded yet!');
        }
    };

    /*const reverseGeocode = ({ latitude: lat, longitude: lng }) => {
        const url = `${geocodeJson}?key=${REACT_APP_GOOGLE_API_KEY}&latlng=${lat},${lng}&region=BR`;
    
        fetch(url)
            .then(response => response.json())
            .then(data => {
                const results = data.results;
                let cepFound = false;
                let ruaFound = false;
    
                for (const result of results) {
                    const addressComponents = result.address_components;
    
                    // Resetando estados para evitar informações desatualizadas
                    setRua('');
                    setNumero('');
                    setBairro('');
                    setCidade('');
                    setEstado('');
                    setCep('');
                    //console.log("addressComponents>",addressComponents)
                    addressComponents.forEach(component => {
                        const { long_name, short_name, types } = component;
                        ////console.log("component>",component)
                        if (types.includes("street_number") && !cepFound && !ruaFound) {
                            setNumero(long_name);
                        }
                        if (types.includes("route") && !cepFound && !ruaFound) {
                            setRua(long_name);
                            ruaFound = true; // Marca que uma Rua foi encontrada
                        }
                        if ((types.includes("sublocality") || types.includes("neighborhood")) && !cepFound && ruaFound) {
                            setBairro(long_name);
                        }
                        if (types.includes("administrative_area_level_1") && !cepFound && ruaFound) {
                            setEstado(short_name);
                        }
                        if ((types.includes("administrative_area_level_2") || types.includes("locality")) && !cepFound && ruaFound) {
                            setCidade(long_name);
                        }
                        if (types.includes("postal_code") && !cepFound && ruaFound) {
                            setCep(long_name);
                            cepFound = true; // Marca que um CEP foi encontrado
                        }
                    });
    
                    // Se um CEP foi encontrado, não precisa buscar nos próximos resultados
                    if (cepFound && ruaFound) {
                        //console.log("Detalhes do endereço definidos.");
                        setAddressComplete(true); // Assumindo que esta função atualiza o estado para indicar que o endereço está completo
                        break; // Sai do loop uma vez que um CEP foi encontrado e as informações foram definidas
                    }
                }
    
                if (!cepFound && !ruaFound) {
                    //console.log('Nenhum CEP ou Rua encontrado para as coordenadas fornecidas.');
                    toast('Nenhum CEP ou Rua encontrado para as coordenadas fornecidas.', {autoClose:5000, type: 'warning'});
                    // Aqui, você pode implementar qualquer lógica para quando um CEP não é encontrado
                }
            })
            .catch(error => {
                console.error('Erro ao realizar geocodificação reversa:', error);
                toast('Erro ao realizar geocodificação reversa', {autoClose:5000, type: 'error'});
            });
    }*/
    const reverseGeocode = ({ latitude: lat, longitude: lng }) => {
        //console.log(lat, lng);
        setLatitudeToSave(lat);
        setLongitudeToSave(lng);
        const url = `${geocodeJson}?key=${REACT_APP_GOOGLE_API_KEY}&latlng=${lat},${lng}&region=BR`;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                const results = data.results;

                // Variáveis temporárias para armazenar valores
                //let numeroTemp = '';
                let ruaTemp = '';
                let bairroTemp = '';
                let cidadeTemp = '';
                let estadoTemp = '';
                let cepTemp = '';
                let cepFound = false;
                let ruaFound = false;
                let bairroFound = false;

                //console.log("RESULTS:", results);

                for (const result of results) {
                    const addressComponents = result.address_components;

                    //console.log("addressComponents>", addressComponents);
                    //console.log("LATITUDE E LONGITUDE:", lat, ",", lng);

                    // Coleta dados de addressComponents sem modificar o estado diretamente
                    addressComponents.forEach(component => {
                        const { long_name, short_name, types } = component;

                        /*if (types.includes("street_number") && !cepFound && !ruaFound && !numeroTemp) {
                            numeroTemp = long_name;
                        }*/
                        if (types.includes("route") && !cepFound && !ruaFound && !ruaTemp) {
                            ruaTemp = long_name;
                            ruaFound = true; // Marca que uma Rua foi encontrada
                        }
                        if ((types.includes("sublocality") || types.includes("neighborhood")) && !bairroFound && !bairroTemp) {
                            bairroTemp = long_name;
                            bairroFound = true;
                            //console.log("PASSOU AQUI? long_name:", long_name)
                        }
                        if (types.includes("administrative_area_level_1") && !cepFound && ruaFound && !estadoTemp) {
                            estadoTemp = short_name;
                        }
                        if ((types.includes("administrative_area_level_2") || types.includes("locality")) && !cepFound && ruaFound && !cidadeTemp) {
                            cidadeTemp = long_name;
                        }
                        if (types.includes("postal_code") && !cepFound && ruaFound && !cepTemp) {
                            cepTemp = long_name;
                            cepFound = true; // Marca que um CEP foi encontrado
                        }
                    });

                    // Se um CEP foi encontrado, não precisa buscar nos próximos resultados
                    if (cepFound && ruaFound && bairroFound) {
                        //console.log("Detalhes do endereço definidos.");
                        setAddressComplete(true); // Atualiza o estado para indicar que o endereço está completo
                        break; // Sai do loop uma vez que um CEP foi encontrado e as informações foram definidas
                    }
                }

                // Agora atualiza os estados, mas só para os campos que estão vazios
                setRua((prevRua) => prevRua || ruaTemp);
                //setNumero((prevNumero) => prevNumero || numeroTemp);
                setBairro((prevBairro) => prevBairro || bairroTemp);
                setCidade((prevCidade) => prevCidade || cidadeTemp);
                setEstado((prevEstado) => prevEstado || estadoTemp);
                setCep((prevCep) => prevCep || cepTemp);

                if (!cepFound && !ruaFound) {
                    //console.log('Nenhum CEP ou Rua encontrado para as coordenadas fornecidas.');
                    toast('Nenhum CEP ou Rua encontrado para as coordenadas fornecidas.', { autoClose: 3000, type: 'warning' });
                }
            })
            .catch(error => {
                console.error('Erro ao realizar geocodificação reversa:', error);
                toast('Erro ao realizar geocodificação reversa', { autoClose: 3000, type: 'error' });
            });
    };

    const handleClearAddress = () => {
        setRua("");
        setNumero("");
        setBairro("");
        setCidade("");
        setEstado("");
        setCep("");
        setComplemento("");
        setReferencia("");
        setAddress("");
        setAddressComplete(false);
        setEnderecoToEdit(null);
    }

    const calcularValorEntrega = async (endereco) => {
        //if(carrinho.length>0 && isSearchingClient===false) {
        setCalculandoDistancia(true);
        if (regionType === "bairro") {
            // Pega o bairro do endereço
            const bairroSelecionado = endereco.bairro;

            // Busca o bairro correspondente no array bairros_entrega
            const bairroEncontrado = bairros_entrega.find(bairro => bairro.bairro_entrega === bairroSelecionado);

            if (bairroEncontrado) {
                // Retorna o valor da entrega do bairro encontrado
                const valorEntrega = bairroEncontrado.valor_entrega;
                //console.log(`Valor da entrega para ${bairroSelecionado}: ${valorEntrega}`);
                toast("Região encontrada, valor da entrega inserido", { autoClose: 3000, type: "success" });
                setValorEntrega(valorEntrega);
                setCalculandoDistancia(false);
                setValorEntregaToAdd(1);
                return;
            } else {
                // Caso o bairro não seja encontrado
                console.error("Bairro não encontrado no array de bairros de entrega");
                toast('Erro ao calcular a distância, digite o valor da entrega', { autoClose: 4000, type: "error" });
                setCalculandoDistancia(false);
                setValorEntregaToAdd(1);
                return null;
            }
        }
        if (endereco) {
            try {
                //console.log("endereco>", endereco)
                //const empresa = JSON.parse(decryptedEmpresa);            
                //let cepFormatadoEmpresa = empresa.cep.replace(/-/g, '');
                //let cepFormatadoCliente = endereco.cep.replace(/-/g, '');
                //var distanciaCalculadaKm;
                //const response = await calcularDistancia(cepFormatadoEmpresa, cepFormatadoCliente);
                let coordenadasEmpresa = {
                    lat: empresa.latitude,
                    lng: empresa.longitude
                }
                let coordenadasCliente = {
                    lat: endereco.latitude,
                    lng: endereco.longitude
                }
                var distanciaCalculadaKm;
                const response = await calcularDistancia(coordenadasEmpresa, coordenadasCliente);
                //console.log("Distancia calculada>", response.data.distance)
                distanciaCalculadaKm = response.data.distance;
                // Convertendo a distanciaCalculada de km para metros
                const distanciaCalculadaMetros = distanciaCalculadaKm * 1000;
                // Ordenando os raios de entrega em ordem crescente
                //console.log(empresa);
                const raiosOrdenados = empresa.raios_entrega.sort((a, b) => a.raio_entrega - b.raio_entrega);
                // Encontrando o primeiro raio que é maior ou igual à distanciaCalculada
                const raioAdequado = raiosOrdenados.find(item => item.raio_entrega >= distanciaCalculadaMetros);
                if (!raioAdequado) {
                    !enderecoToEdit && toast("Região não encontrada, digite o valor da entrega", { autoClose: 4000, type: "error" });
                    //console.log("não atendemos essa região de entrega");
                } else {
                    //setSelectedAddressToDelivery(endereco);
                    //toast("Região encontrada, valor da entrega inserido", { autoClose: 1000, type: "success" });
                    //console.log("VALOR DA ENTREGA>", raioAdequado.valor_entrega);
                    setValorEntrega(raioAdequado.valor_entrega);
                }
            } catch (error) {
                console.error('Erro ao calcular a distância:', error);
                toast('Erro ao calcular a distância, digite o valor da entrega', { autoClose: 4000, type: "error" });
            } finally {
                // Garante que setIsSearchingClient(false) será chamado independente do resultado das operações acima
                setCalculandoDistancia(false);
                setValorEntregaToAdd(1);
            }

        } else {
            // Garantir que o estado seja atualizado mesmo se não entrar na lógica principal
            setCalculandoDistancia(false);
        }
    }

    const handleSelectType = (type) => {
        setFormaEntrega(type);
        if (type === "Retirada") {
            setValorEntrega(0);
            setValorEntregaToAdd(null);
            setSelectedAddress(null);
        }
    }

    return (
        <DivPedidoContainer showModalEntregaPdv={showModalEntregaPdv}>
            <DivPedido>
                <div className="divModalEntregaPDV" style={{ minHeight: !formaEntrega || formaEntrega === "Retirada" ? 140 : 400 }}>
                    {calculandoDistancia && <Loading type={"spinningBubbles"} className="calculandoDistanciaModalEntrega" />}
                    <div className="divTitleAndContentModalEntregaPDV" style={{overflowY:'scroll', maxHeight:'85vh'}}>
                        <div className="closeModalPedido" onClick={() => closeModalAddObsItem()}>
                            <div
                                iconname="x"
                                iconcolor="#2B2B2B"
                                iconsize={18}
                                className="iconCancel"
                                style={{ height: 18, display: 'flex' }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={18}
                                    height={18}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#2B2B2B"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-x"
                                >
                                    <line x1={18} y1={6} x2={6} y2={18} />
                                    <line x1={6} y1={6} x2={18} y2={18} />
                                </svg>
                            </div>
                        </div>

                        <h4>Forma de Entrega</h4>

                        <div className="divSelectFormaEntregaModal">
                            <div className="divFormaEntrega_1" onClick={() => handleSelectType('Entrega')}
                                style={{
                                    background: formaEntrega === "Entrega" ? '#1095F3' : '',
                                    color: formaEntrega === "Entrega" ? 'white' : ''
                                }}>
                                <span>Entrega</span>
                            </div>
                            <div className="divFormaEntrega_2" onClick={() => handleSelectType('Retirada')}
                                style={{
                                    background: formaEntrega === "Retirada" ? '#1095F3' : '',
                                    color: formaEntrega === "Retirada" ? 'white' : ''
                                }}>
                                <span>Retirada</span>
                            </div>
                        </div>

                        {formaEntrega === "Entrega" ?
                            <>
                                <div className="divBtnCadastroEndModal">
                                    <span>{registeringAddress ? "Novo endereço" : "Endereço de entrega:"}</span>
                                    {!registeringAddress ?
                                        <div>
                                            <button onClick={() => {
                                                //setEnderecoToEdit(null);
                                                if (!enderecoToEdit) {
                                                    handleClearAddress();
                                                }
                                                setRegisteringAddress(true);
                                            }}>
                                                Cadastrar Endereço
                                            </button>
                                        </div>
                                        : null
                                    }
                                </div>
                                {registeringAddress ?
                                    <div className="divAddEnderecoPadding">
                                        <span style={{ fontSize: 20, fontWeight: '700' }}>Digite seu endereço completo com a rua, número e bairro</span>
                                        {
                                            regionType === "bairro" ?
                                                <div className="divEnderecoCompleto">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                                            <label style={{ fontSize: 14, fontWeight: '600' }}>Bairro*</label>
                                                            <select
                                                                value={bairro}
                                                                onChange={(e) => setBairro(e.target.value)}
                                                                className="addressField field-input pa-3 whatsappNumberFinalizarPedidoInput"
                                                            >
                                                                <option value="">Selecione o Bairro</option>
                                                                {bairros_entrega.map((bairro, index) => (
                                                                    <option key={bairro._id} value={bairro.bairro_entrega}>
                                                                        {bairro.bairro_entrega}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>

                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                                            <label style={{ fontSize: 14, fontWeight: '600' }}>Rua*</label>
                                                            <input value={rua} onChange={(e) => setRua(e.target.value)} className="addressField field-input pa-3 whatsappNumberFinalizarPedidoInput"
                                                                placeholder="Ex.: Av. T-63" />
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div style={{ display: 'flex', flexDirection: 'column', width: '28%' }}>
                                                            <label style={{ fontSize: 14, fontWeight: '600' }}>Estado*</label>
                                                            <input value={estado} onChange={(e) => setEstado(e.target.value)} className="addressField field-input pa-3 whatsappNumberFinalizarPedidoInput"
                                                                placeholder="Ex: SP" />
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'column', width: '68%' }}>
                                                            <label style={{ fontSize: 14, fontWeight: '600' }}>Cidade*</label>
                                                            <input value={cidade} onChange={(e) => setCidade(e.target.value)} className="addressField field-input pa-3 whatsappNumberFinalizarPedidoInput"
                                                                placeholder="Ex.: São Paulo" />
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div style={{ display: 'flex', flexDirection: 'column', width: '28%' }}>
                                                            <label style={{ fontSize: 14, fontWeight: '600' }}>Numero*</label>
                                                            <input value={numero} onChange={(e) => setNumero(e.target.value)} className="addressField field-input pa-3 whatsappNumberFinalizarPedidoInput"
                                                                placeholder="Ex: 123" />
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'column', width: '68%' }}>
                                                            <label style={{ fontSize: 14, fontWeight: '600' }}>Complemento</label>
                                                            <input value={complemento} onChange={(e) => setComplemento(e.target.value)} className="addressField field-input pa-3 whatsappNumberFinalizarPedidoInput"
                                                                placeholder="Apto 101, Casa 02..." />
                                                        </div>
                                                    </div>
                                                    <div style={{ paddingTop: '10px' }}>
                                                        <label style={{ fontSize: 14, fontWeight: '600' }}>Ponto de Referência</label>
                                                        <input value={referencia} onChange={(e) => setReferencia(e.target.value)} className="addressField field-input w-100 pa-3 whatsappNumberFinalizarPedidoInput"
                                                            placeholder="Ao lado do supermercado exemplo..." />
                                                    </div>
                                                </div>
                                                :
                                                rua !== "" || bairro !== "" || cidade !== "" || estado !== "" ?

                                                    <div className="divEnderecoCompleto">
                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 10, alignItems: 'center', paddingBottom: 12, paddingTop: 12 }}>
                                                            <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                                                                <GrLocation style={{ fontSize: 22, color: 'rgb(180,180,180)' }} />
                                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                    <span style={{ fontSize: 16, fontWeight: '600', maxWidth: '320px' }}>{rua}</span>
                                                                    <span style={{ fontSize: 15, color: 'rgb(180,180,180)', maxWidth: '320px' }}>{!bairro ? "" : `${bairro}, `}{!cidade ? "" : `${cidade} - `}{!estado ? "" : estado}</span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <RiCloseCircleLine onClick={() => handleClearAddress()} style={{ fontSize: 22, color: 'orangered', marginRight: '10px' }} />
                                                            </div>
                                                        </div>
                                                        {/* Campo para editar o bairro, visível enquanto não preenchido */}
                                                        {bairro === "" || bairroEditavel ? (
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                                                    <label style={{ fontSize: 14, fontWeight: '600' }}>Bairro*</label>
                                                                    <input
                                                                        value={bairro}
                                                                        onChange={(e) => setBairro(e.target.value)}
                                                                        className="addressField field-input pa-3 whatsappNumberFinalizarPedidoInput"
                                                                        placeholder="Ex: Jardins..."
                                                                        onFocus={() => setBairroEditavel(true)} // Manter o input visível enquanto o usuário digita
                                                                        onBlur={() => bairro !== "" && setBairroEditavel(false)} // Ocultar o input se o bairro for preenchido
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', width: '28%' }}>
                                                                <label style={{ fontSize: 14, fontWeight: '600' }}>Numero*</label>
                                                                <input value={numero} onChange={(e) => setNumero(e.target.value)} className="addressField field-input pa-3 whatsappNumberFinalizarPedidoInput"
                                                                    placeholder="Ex: 123" />
                                                            </div>
                                                            <div style={{ display: 'flex', flexDirection: 'column', width: '68%' }}>
                                                                <label style={{ fontSize: 14, fontWeight: '600' }}>Complemento</label>
                                                                <input value={complemento} onChange={(e) => setComplemento(e.target.value)} className="addressField field-input pa-3 whatsappNumberFinalizarPedidoInput"
                                                                    placeholder="Apto 101, Casa 02..." />
                                                            </div>
                                                        </div>
                                                        <div style={{ paddingTop: '10px' }}>
                                                            <label style={{ fontSize: 14, fontWeight: '600' }}>Ponto de Referência</label>
                                                            <input value={referencia} onChange={(e) => setReferencia(e.target.value)} className="addressField field-input w-100 pa-3 whatsappNumberFinalizarPedidoInput"
                                                                placeholder="Ao lado do supermercado exemplo..." />
                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                        <label style={{ fontWeight: '500', fontSize: 14 }}>Insira seu endereço</label>
                                                        <div className="map">
                                                            <LoadScript googleMapsApiKey={REACT_APP_GOOGLE_API_KEY} libraries={libraries}>
                                                                <Autocomplete
                                                                    onLoad={onLoad}
                                                                    onPlaceChanged={onPlaceChanged}
                                                                    options={{ componentRestrictions: { country: "BR" } }}
                                                                >
                                                                    <input
                                                                        className="addressField field-input w-100 pa-3 whatsappNumberFinalizarPedidoInput"
                                                                        placeholder="Digite o endereço"
                                                                    />
                                                                </Autocomplete>
                                                            </LoadScript>
                                                        </div>

                                                        {/*<div onClick={findMyLocation} className="divBtnUseLocation">
                                                <FaLocationCrosshairs />
                                                <span>Usar minha localização</span>
                                            </div>
                                            */}
                                                    </>
                                        }
                                        <div className="divBtnSaveCancelModalEntrega">
                                            <button onClick={() => handleSaveAddress()}>Salvar</button>
                                            <button onClick={() => {
                                                setRegisteringAddress(false)
                                                setEnderecoToEdit(null);
                                                //setSelectedAddress(null);
                                                handleClearAddress();
                                            }}>Cancelar</button>
                                        </div>
                                    </div>
                                    :
                                    <div className="divEnderecosEntregaModal">
                                        {selectedCliente?.endereco?.map((endereco, index) => (
                                            <div key={index} className="divEnderecoEntregaMap" onClick={() => handleSelectAddress(endereco)}
                                                style={{
                                                    background: selectedAddress?._id === endereco._id ? '#1095F3' : '',
                                                    color: selectedAddress?._id === endereco._id ? 'white' : ''
                                                }}
                                            >
                                                <div style={{ width: '100%' }}>
                                                    <span>{endereco.rua} {endereco.numero}, Bairro: {endereco.bairro} - Cidade: {endereco.cidade}</span>
                                                </div>
                                                <div style={{ display: 'flex', gap: 20, paddingTop: 5 }}>
                                                    <span className="btnEditAddressModalEntrega"
                                                        onClick={(e) => handleEdit(e, endereco)}>Editar</span>
                                                    <span className="btnDeleteAddressModalEntrega"
                                                        onClick={(event) => handleDelete(event, endereco._id)}>Excluir</span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                }


                                <div className="divValorEntregaModal">
                                    <span>Valor da entrega:</span>
                                    <InputMoney
                                        onChange={(event) => onChange(event, 'price', true)}
                                        value={valorEntrega}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        margin="0px 0px 16px 0px"
                                        style={{
                                            outline: isFocused ? '2px solid #4070f4' : 'none',
                                            borderRadius: 10,
                                            margin: '0.6rem 0 0.2rem 0',
                                        }}
                                        className=""
                                        placeholder="Preço"
                                    />
                                </div>


                            </>
                            : null
                        }


                    </div>

                    <div className="divBtnAddFormaEntregaModal"
                        style={{
                            background: formaEntrega !== 'Retirada' && !selectedAddress || formaEntrega !== 'Retirada' && valorEntregaToAdd === null ? '#bbb' : '',
                            cursor: formaEntrega !== 'Retirada' && !selectedAddress || formaEntrega !== 'Retirada' && valorEntregaToAdd === null ? 'not-allowed' : ''
                        }}
                    >
                        <button onClick={() => saveModalFormaEntrega()} disabled={formaEntrega !== 'Retirada' && !selectedAddress || formaEntrega !== 'Retirada' && valorEntregaToAdd === null}
                            style={{
                                background:
                                    formaEntrega !== 'Retirada' && !selectedAddress || formaEntrega !== 'Retirada' && valorEntregaToAdd === null
                                        ? '#bbb' : '',
                                cursor:
                                    formaEntrega !== 'Retirada' && !selectedAddress || formaEntrega !== 'Retirada' && valorEntregaToAdd === null
                                        ? 'not-allowed' : ''
                            }}
                        >
                            Adicionar Forma de Entrega
                        </button>
                    </div>

                </div>

            </DivPedido>
        </DivPedidoContainer>
    )
}

export default ModalEntregaPdv;