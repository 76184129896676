import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import pt from 'date-fns/locale/pt'; // Importando a localização em português
import './DateRangePicker.css';
import CustomInput from './CustomInput';

// Registrando a localização
registerLocale('pt', pt);

const DateRangePicker = ({startDate, setStartDate, endDate, setEndDate, handleDateChange}) => {

  return (
    <div>
       <DatePicker
        selected={startDate}
        onChange={handleDateChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        dateFormat="dd/MM/yyyy"
        locale="pt"
        isClearable
        customInput={<CustomInput />}
      />
    </div>
  );
};

export default DateRangePicker;