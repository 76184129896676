import React, { useEffect, useState, useContext } from "react";
import Loading from 'react-loading';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LeftMenu from "../../components/LeftMenu";
import './styles.css';
import moment from 'moment';
import { useFormik } from "formik";
import * as Yup from "yup";
//import creditCardType from 'credit-card-type';
import valid from 'card-validator'; //import statement
import InputMask from 'react-input-mask'
import axios from 'axios';
import { getFilPlansIugu, searchClienteIuguAndGetInvoice, criarAssinaturaAsaasCartao, criarAssinaturaAsaasBolPix, criarCobrancaAsaas, criarCobrancaAsaasBolPix, listarAssinaturasIugu } from "../../services/api";
import { SidebarContext } from "../../AppRoutes";
import { RiErrorWarningLine } from "react-icons/ri";
import { MdPix } from "react-icons/md";
import { FaBarcode } from "react-icons/fa6";
import { IoIosCard } from "react-icons/io";
import { toast } from "react-toastify";
import { useAsync } from "react-select/async";
import CryptoJS from 'crypto-js'


const Teste = styled.div`
  display: flex;
  margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
  height:auto;
  width:auto;
  transition: 150ms;
  background-color:rgb(247,247,247)!important;
  --background-color:white!important;
  overflow: initial;
  z-Index: 9;

  @media (max-width: 880px) {
        margin-left: 0;
    }
`;


const Planos = () => {
    const navigate = useNavigate();
    const { sidebar, setSidebar } = useContext(SidebarContext);; //TESTANDO CONST NO AppROUTES
    const currentDay = moment().format('DD');

    const [payment_type, setPayment_type] = useState("");
    const [person_type, setPerson_type] = useState("cnpj");
    const [plan_cycle, setPlan_cycle] = useState("");
    const [plan_type, setPlan_type] = useState("silver"); //silver, gold, premium
    const [expirationDate, setExpirationDate] = useState(new Date());

    const [isFinalizando, setIsFinalizando] = useState(false);

    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const userEncrypted = localStorage.getItem('user')
    const user = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
    const userParse = JSON.parse(user)
    const userID = userParse._id;
    const empresa = localStorage.getItem('empresa')
    const empresaParse = JSON.parse(empresa)
    const idEmpresa = empresaParse.id_empresa;
    const cnpj = empresaParse.cnpj;
    const razao = empresaParse.razao;
    const nomeEmpresa = empresaParse.name;

    const [planos, setPlanos] = useState([]);
    const [priceToPay, setPriceToPay] = useState("");
    const [assinatura, setAssinatura] = useState({});

    useEffect(() => {
        (async () => {

            const response = await getFilPlansIugu(userID);

            setPlanos(response.data.planos.planos);
            console.log(response.data)
            //setLoading(false);
            //setRefresh(false);

        })();
    }, []);

    const handleDateChange = (date) => {
        setExpirationDate(date);
    };

    const [ip, setIp] = useState("");
    const getUserIp = async () => {
        const ip = await axios.get('https://api64.ipify.org?format=json');
        console.log(ip.data.ip);
        setIp(ip.data.ip)
    }

    useEffect(() => {
        getUserIp();
    }, []);

    useEffect(() => {
        const listarAssinaturas = async (empresa_id, customer_iugu_id) => {
            const assinaturas = await listarAssinaturasIugu(empresa_id, customer_iugu_id);
            console.log("Assinaturas>", assinaturas);
            setAssinatura(assinaturas.data.assinatura_obj)
        }
        listarAssinaturas(empresaParse._id, empresaParse.customer_iugu);

    }, []);

    const handlePaymentType = (tipo_pagamento) => {
        if (tipo_pagamento === "credito") {
            setPayment_type("credito");
        } else if (tipo_pagamento === "boleto") {
            setPayment_type("boleto");
        } else if (tipo_pagamento === "pix") {
            setPayment_type("pix");
        }
    }

    const handlePersonType = (tipo_pessoa) => {
        if (tipo_pessoa === "cnpj") {
            setPerson_type("cnpj");
        } else if (tipo_pessoa === "cpf") {
            setPerson_type("cpf");
        }
    }


    //Validações
    const SignUpSchema = Yup.object().shape({
        //tipo_pessoa: Yup.string().required("Campo obrigatório"),        
        cpf_cnpj: Yup.string().required("Campo obrigatório"),
        nomeOrRazao: Yup.string().required("Campo obrigatório"),
        email: Yup.string().email("Email inválido").required("Campo obrigatório"),
        cep: Yup.string().required("Campo obrigatório"),
        numero: Yup.string().required("Campo obrigatório"),
        //telefone: Yup.string().required("Campo obrigatório"),

        /*num_cartao: Yup.string()
        .test('test-number', // this is used internally by yup
        'Cartão de crédito invalido', //validation message
        value => valid.number(value).isValid) // return true false based on validation
        .required("Campo obrigatório"),  

        validade: Yup.string().required("Campo obrigatório"),

        cvv: Yup.string()
        .matches(/^[0-9]{3,4}$/, 'CVV inválido') 
        .required("Campo obrigatório")
        */

    });

    const formik = useFormik({
        validationSchema: SignUpSchema,
        validateOnBlur: false,
        validateOnChange: false,
        //enableReinitialize: true,
        initialValues: {
            tipo_pessoa: "",
            num_cartao: "",
            validade: "",
            cvv: "",
            cpf_cnpj: "",
            nomeOrRazao: "",
            parcelas: 1,
            email: "",
            cep: "",
            numero: "",
            telefone: "",
        },
        onSubmit: (values) => {

            //const tipo_pessoa = values.tipo_pessoa;
            const num_cartao = values.num_cartao;
            const validade = values.validade;
            const cvv = values.cvv;
            const cpf_cnpj = values.cpf_cnpj;
            const nomeOrRazao = values.nomeOrRazao;
            const parcelas = values.parcelas;
            const email = values.email;
            const cep = values.cep;
            const numero = values.numero;
            const telefone = values.telefone;

            console.log("PARCELAS>", parcelas)

            handleSubmitAPI(cpf_cnpj, nomeOrRazao, email, cep, numero);

        },
    });

    const handleSubmitApiBolPix = async () => {
        setIsFinalizando(true);
        console.log("nomeEmpresa>", nomeEmpresa)
        console.log("CNPJ>", cnpj)
        const retornaCliente = empresaParse.customer_asaas;
        console.log("retornaCliente>", retornaCliente);
        if (retornaCliente) {
            console.log("Cliente>", retornaCliente);
            const clienteId = retornaCliente.id;
            const cycle = plan_cycle === "anual" ? "YEARLY" : "MONTHLY";
            const value = 55;//Valor fixo para testes
            const nextDueDate = moment().format('YYYY-MM-DD');
            console.log(clienteId, payment_type, value, nextDueDate);
            const retornaCobranca = await criarAssinaturaAsaasBolPix(clienteId, payment_type, cycle, value, nextDueDate);
            if (retornaCobranca) {
                console.log("retornaCobrancaaa>", retornaCobranca)
                /*if(retornaCobranca.data.errors && retornaCobranca.data.errors.length > 0){
                    toast(retornaCobranca.data.errors[0].description, {autoClose: 3000, type: "error"})
                    setIsFinalizando(false);
                } else {
                    //toast("Assinatura efetuada com Sucesso!", {autoClose: 3000, type: "success"})
                    console.log("retornaCobranca>",retornaCobranca.data.invoiceUrl);
                    setIsFinalizando(false);
                    window.open(retornaCobranca.data.invoiceUrl);
                }*/
            } else {
                toast("Erro ao criar assinatura", { autoClose: 3000, type: "error" });
                console.log("Erro ao criar assinatura");
                setIsFinalizando(false);
            }

        }
    }

    const handleSubmitAPI = async (cpf_cnpj, nomeOrRazao, email, cep, numero) => {
        setIsFinalizando(true);

        const createdBy = userID;
        const name = nomeOrRazao;
        const zip_code = cep;
        const number = numero;
        const plan_identifier = plan_type;

        try {
            // Chama a função para buscar/registrar cliente e criar a assinatura
            const response = await searchClienteIuguAndGetInvoice(createdBy, email, name, cpf_cnpj, zip_code, number, plan_identifier);

            console.log("OQ VOLTOU>>>", response)
            // Verifica se o invoiceUrl foi retornado com sucesso
            if (response && response.data.invoiceUrl) {
                // Abre uma nova aba com a URL da invoice
                window.open(response.data.invoiceUrl, '_blank');
            } else {
                console.error('URL da fatura não encontrada!');
            }
        } catch (error) {
            console.error('Erro ao processar a assinatura:', error.response.data.msg);
            toast(error.response.data.msg, { autoClose: 3000, type: "error" });
        } finally {
            setIsFinalizando(false); // Finaliza o loading ou processo
        }

    }

    const valorCentsConvertido = (value) => {
        const valorConvertido = value / 100;
        return valorConvertido.toFixed(2).toString().replace('.', ',');
    }

    const handleChoosePlan = (plan_identifier, interval, valor_plano) => {
        setPlan_type(plan_identifier);
        setPlan_cycle(interval);
        const valueToPay = valorCentsConvertido(valor_plano);
        setPriceToPay(valueToPay);
    }

    return (
        <div>
            <LeftMenu setSidebar={setSidebar} sidebar={sidebar} />
            <Teste sidebar={sidebar}>
                <div className="w-100 p-4 planos">
                    <div className="input-group inputGroup-adicinaItem mw-100 mh-100">
                        <div className="formGroupRowPlanos">

                            <div className="subscription-layout">
                                {
                                    assinatura && Object.keys(assinatura).length > 0 && assinatura.plan_identifier !== "free_trial" ?
                                        <div>
                                            <h2>Detalhes da Assinatura</h2>
                                            <div>
                                                <p><strong>Nome do Cliente:</strong> {assinatura.customer_name}</p>
                                                <p><strong>E-mail do Cliente:</strong> {assinatura.customer_email}</p>
                                                <p><strong>Plano:</strong> {assinatura.plan_name}</p>
                                                {
                                                assinatura.plan_identifier!=="free_trial" &&
                                                <p><strong>Preço:</strong> {(assinatura.price_cents / 100).toLocaleString('pt-BR', { currency: assinatura.currency })}</p>
                                                }
                                                <p><strong>Status:</strong> {assinatura.active ? 'Ativa' : 'Inativa'}</p>
                                                {
                                                assinatura.plan_identifier!=="free_trial" &&
                                                <p><strong>Ciclo:</strong> {assinatura.cycled_at ? moment(assinatura.cycled_at).format('DD/MM/YYYY'):""}</p>
                                                }
                                                {
                                                assinatura.plan_identifier!=="free_trial" ? 
                                                <>
                                                    <h3>Fatura</h3>

                                                    <p><strong>Data de Vencimento:</strong> {moment(assinatura.recent_invoices[0]?.due_date).format('DD/MM/YYYY')}</p>
                                                    <p><strong>Data de Expiração:</strong> {moment(assinatura.expires_at).format('DD/MM/YYYY')}</p>
                                                    <p><strong>Status da Fatura:</strong> {assinatura.recent_invoices[0]?.status}</p>
                                                    <p><strong>Total:</strong> {assinatura.recent_invoices[0]?.total}</p>
                                                    <a href={assinatura.recent_invoices[0]?.secure_url} target="_blank" rel="noopener noreferrer">
                                                        Ver Fatura
                                                    </a>
                                                </>
                                                :
                                                <>
                                                    <p><strong>Data de Expiração:</strong> {moment(assinatura.expires_at).format('DD/MM/YYYY')}</p>
                                                    <p><strong>Status da Licença:</strong> {assinatura.active ? "Ativa":"Desativada"}</p>
                                                </>
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div className="header">
                                            <h1>Não perca mais vendas! Continue resolvendo seus problemas de atendimento de forma simples</h1>
                                            <p>Escolha o plano que melhor se encaixa no seu negócio e comece a vender mais</p>

                                            <div className="divChoosePlan">
                                                {
                                                    planos && planos.map((plano) => (
                                                        <div key={plano.id_iugu} className="choosePlanTypes plan" style={{
                                                            border: plan_type === plano.plan_identifier ? "1px solid #0a84ff" : "1px dashed lightgray",
                                                            background: plan_type === plano.plan_identifier ? "aliceblue" : "#FFF"
                                                        }}>
                                                            <div>
                                                                {plano.interval > 1 ?
                                                                    <div className="badge">RECOMENDADO</div>
                                                                    :
                                                                    <></>
                                                                }
                                                                <div className="title">{plano.name}</div>
                                                                <div className="price">R$ {valorCentsConvertido(plano.valor_plano / plano.interval)}
                                                                    <span>/Mês</span>
                                                                </div>
                                                            </div>
                                                            <div className="btnChoosePlan">
                                                                <button onClick={() => handleChoosePlan(plano.plan_identifier, plano.interval, plano.valor_plano)}>Contratar</button>
                                                            </div>
                                                        </div>

                                                    ))
                                                }                                               
                                            </div>

                                        </div>
                                }

                                {/* 
                            <div className="plans">
                                <div className="plan monthly" style={{
                                    border:plan_cycle==="mensal"?"1px solid #0a84ff":"1px dashed lightgray",
                                    background:plan_cycle==="mensal"?"aliceblue":"#FFF"
                                    }}>                                    
                                    <div>
                                        <div className="title">Mensal</div>
                                        <div className="price">R$ 209,99 <span>/mês</span></div>                                        
                                    </div>
                                    <div className="divBtnContratar">
                                        <button onClick={() => setPlan_cycle("mensal")}>Contratar</button>  
                                    </div> 
                                </div>
                                <div className="plan yearly recommended" style={{
                                    border:plan_cycle==="anual"?"1px solid #0a84ff":"1px dashed lightgray",
                                    background:plan_cycle==="anual"?"aliceblue":"#FFF"
                                    }}>
                                    <div>
                                        <div className="badge">RECOMENDADO</div>
                                        <div className="title">12 meses</div>
                                        <div className="price">R$ 178,49 <span>/mês</span></div>
                                        <div>
                                            <div className="save">Economize R$ 250,00</div>
                                        </div>
                                    </div>
                                    <div className="divBtnContratar">
                                        <button onClick={() => setPlan_cycle("anual")}>Contratar</button>  
                                    </div>                              
                                </div>
                            </div>
                            */}

                                {
                                    plan_cycle === "" ?
                                        <></>
                                        :
                                        <div className="payment-methods">
                                            <div className="info">
                                                <p><RiErrorWarningLine style={{ fontSize: 25, paddingBottom: 4 }} /> A data da cobrança do plano será alterada para todo dia {currentDay}</p>
                                            </div>

                                            {/* 
                                    <div className="title">Escolha uma forma de pagamento:</div>
                                    <div className="methods">
                                        <div className="method credit-card" onClick={() => handlePaymentType("credito")}
                                        style={{background:payment_type==="credito"?"aliceblue":"",
                                                border:payment_type==="credito"?"1px solid #007bff":""
                                        }}>
                                            <div style={{display:'flex', flexDirection:'row', alignItems: 'center', gap: '5px'}}>                                        
                                                <input type="radio" name="payment_type" value="credit-card" checked={payment_type==="credito"} className="inputBoxRadioPersonalized" readOnly/>                                     
                                                <div style={{display:'flex', flexDirection:'column'}}>
                                                    <span>Cartão de crédito</span>
                                                </div>
                                            </div>
                                            <div><IoIosCard style={{fontSize:30}}/></div>
                                        </div>
                                        <div className="method boleto" onClick={() => handlePaymentType("boleto")}
                                        style={{background:payment_type==="boleto"?"aliceblue":"",
                                        border:payment_type==="boleto"?"1px solid #007bff":""
                                        }}>
                                            <div style={{display:'flex', flexDirection:'row', alignItems: 'center', gap: '5px'}}>     
                                                <input type="radio" name="payment_type" value="credit-card" checked={payment_type==="boleto"} className="inputBoxRadioPersonalized" readOnly/>      
                                                <span>Boleto</span>                                        
                                            </div>
                                            <div><FaBarcode style={{fontSize:30}}/></div>
                                        </div>
                                        <div className="method pix" onClick={() => handlePaymentType("pix")}
                                        style={{background:payment_type==="pix"?"aliceblue":"",
                                        border:payment_type==="pix"?"1px solid #007bff":""
                                        }}>
                                            <div style={{display:'flex', flexDirection:'row', alignItems: 'center', gap: '5px'}}>     
                                                <input type="radio" name="payment_type" value="credit-card" checked={payment_type==="pix"} className="inputBoxRadioPersonalized" readOnly/>      
                                                <div style={{display:'flex', flexDirection:'column'}}>
                                                    <span>Pix</span>
                                                    
                                                </div>
                                            </div>
                                            <div><MdPix style={{fontSize:30}}/></div>
                                        </div>
                                    </div>
                                    */}



                                            <div className="credit-card-form">
                                                <form onSubmit={formik.handleSubmit}>
                                                    <div className="input-box">
                                                        <label>Escolha o tipo de pessoa:</label>

                                                        <div className="typePersonDiv">
                                                            <div style={{ display: 'flex', gap: 5 }}>
                                                                <input type="radio" name="person_type" value="CNPJ" checked={person_type === "cnpj"} className="inputBoxRadioPersonalized" readOnly
                                                                    onClick={() => handlePersonType("cnpj")} />
                                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                    <span style={{ fontSize: 12, fontWeight: 600 }}>CNPJ</span>
                                                                    <span style={{ fontSize: 12 }}>Pessoa Jurídica</span>
                                                                </div>
                                                            </div>
                                                            <div style={{ display: 'flex', gap: 5 }}>
                                                                <input type="radio" name="person_type" value="CPF" checked={person_type === "cpf"} className="inputBoxRadioPersonalized" readOnly
                                                                    onClick={() => handlePersonType("cpf")} />
                                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                    <span style={{ fontSize: 12, fontWeight: 600 }}>CPF</span>
                                                                    <span style={{ fontSize: 12 }}>Pessoa Física</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* 
                                            <div style={{justifyContent:"space-between", display:'flex'}}>
                                                <div className="input-box planosFormInputs" style={{width:"60%"}}>
                                                    <label>Número do cartão<span style={{color:"red"}}>*</span></label>
                                                    <InputMask 
                                                    placeholder="_ _ _ _  _ _ _ _  _ _ _ _  _ _ _ _"
                                                    id="num_cartao"
                                                    type="num_cartao"
                                                    name="num_cartao"
                                                    mask="9999 9999 9999 9999"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.num_cartao}
                                                    />
                                                    {formik.errors.num_cartao && (
                                                        <div className="error">{formik.errors.num_cartao}</div>
                                                    )}
                                                </div>

                                                <div className="input-box planosFormInputs" style={{width:"15%"}}>
                                                    <label>Validade<span style={{color:"red"}}>*</span></label>
                                                    <InputMask 
                                                    placeholder="_ _ / _ _ _ _"
                                                    mask="99/9999"
                                                    id="validade"
                                                    type="validade"
                                                    name="validade"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.validade}
                                                    />                                                   
                                                    {formik.errors.validade && (
                                                        <div className="error">{formik.errors.validade}</div>
                                                    )}
                                                </div>

                                                <div className="input-box planosFormInputs" style={{width:"15%"}}>
                                                    <label>CVV<span style={{color:"red"}}>*</span></label>
                                                    <InputMask 
                                                    placeholder="_ _ _"
                                                    mask="999"
                                                    id="cvv"
                                                    type="cvv"
                                                    name="cvv"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.cvv}
                                                    />
                                                    {formik.errors.cvv && (
                                                        <div className="error">{formik.errors.cvv}</div>
                                                    )}
                                                </div>
                                            </div>
                                            */}

                                                    <div style={{ justifyContent: "space-between", display: 'flex' }}>

                                                        <div className="input-box planosFormInputs" style={{ width: "47%" }}>
                                                            <label>{person_type === "cnpj" ? "CNPJ" : "CPF"}<span style={{ color: "red" }}>*</span></label>
                                                            <InputMask
                                                                placeholder={person_type === "cnpj" ? "Digite seu CNPJ" : "Digite seu CPF"}
                                                                id="cpf_cnpj"
                                                                type="cpf_cnpj"
                                                                name="cpf_cnpj"
                                                                mask={person_type === "cnpj" ? "99.999.999/9999-99" : "999.999.999-99"}
                                                                onChange={formik.handleChange}
                                                                value={formik.values.cpf_cnpj}
                                                            />
                                                            {formik.errors.cpf_cnpj && (
                                                                <div className="error">{formik.errors.cpf_cnpj}</div>
                                                            )}
                                                        </div>
                                                        <div className="input-box planosFormInputs" style={{ width: "47%" }}>
                                                            <label>{person_type === "cnpj" ? "Razão Social" : "Nome"}<span style={{ color: "red" }}>*</span></label>
                                                            <input
                                                                placeholder={person_type === "cnpj" ? "Escreva aqui sua Razão Social" : "Escreva aqui seu Nome"}
                                                                id="nomeOrRazao"
                                                                type="nomeOrRazao"
                                                                name="nomeOrRazao"
                                                                onChange={formik.handleChange}
                                                                value={formik.values.nomeOrRazao}
                                                            />
                                                            {formik.errors.nomeOrRazao && (
                                                                <div className="error">{formik.errors.nomeOrRazao}</div>
                                                            )}
                                                        </div>

                                                        {/* 
                                                <div className="input-box planosFormInputs" style={{width:"10%"}}>
                                                    <label>Parcelas<span style={{color:"red"}}>*</span></label>
                                                    <div className="selectdiv parcelasSelect" style={{minWidth:60}}>
                                                        <label style={{width:"100%"}}>
                                                            <select 
                                                            id="parcelas"
                                                            type="parcelas"
                                                            name="parcelas"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.parcelas || 1}
                                                            className={formik.values.parcelas === ""?'invalid-option':'beleza'}
                                                            style={{width:"100%", padding: "0px 13px"}}
                                                            >   
                                                            {
                                                                plan_cycle === "anual" ?    
                                                                <>                                                         
                                                                <option value={1}>1x</option>
                                                                <option value={2}>2x</option>
                                                                <option value={3}>3x</option>
                                                                <option value={4}>4x</option>
                                                                <option value={5}>5x</option>
                                                                <option value={6}>6x</option>
                                                                <option value={7}>7x</option>
                                                                <option value={8}>8x</option>
                                                                <option value={9}>9x</option>
                                                                <option value={10}>10x</option>
                                                                <option value={11}>11x</option>
                                                                <option value={12}>12x</option>
                                                                </>
                                                                :
                                                                <option value={1}>1x</option>
                                                            }
                                                            </select>
                                                            {formik.errors.parcelas && (
                                                            <div className="error" style={{fontWeight: "lighter"}}>{formik.errors.parcelas}</div>
                                                            )}
                                                        </label>
                                                    </div>
                                                    {formik.errors.parcelas && (
                                                        <div className="error">{formik.errors.parcelas}</div>
                                                    )}
                                                </div>  
                                                */}
                                                    </div>

                                                    <div style={{ justifyContent: "space-between", display: 'flex' }}>

                                                        <div className="input-box planosFormInputs" style={{ width: "40%" }}>
                                                            <label>Email<span style={{ color: "red" }}>*</span></label>
                                                            <InputMask
                                                                placeholder="Digite seu Email"
                                                                id="email"
                                                                type="email"
                                                                name="email"
                                                                //mask="99.999.999/9999-99"
                                                                onChange={formik.handleChange}
                                                                value={formik.values.email}
                                                            />
                                                            {formik.errors.email && (
                                                                <div className="error">{formik.errors.email}</div>
                                                            )}
                                                        </div>
                                                        <div className="input-box planosFormInputs" style={{ width: "25%" }}>
                                                            <label>CEP<span style={{ color: "red" }}>*</span></label>
                                                            <InputMask
                                                                placeholder="Digite seu CEP"
                                                                id="cep"
                                                                type="cep"
                                                                name="cep"
                                                                mask={"99999-999"}
                                                                onChange={formik.handleChange}
                                                                value={formik.values.cep}
                                                            />
                                                            {formik.errors.cep && (
                                                                <div className="error">{formik.errors.cep}</div>
                                                            )}
                                                        </div>

                                                        <div className="input-box planosFormInputs" style={{ width: "25%" }}>
                                                            <label>Número<span style={{ color: "red" }}>*</span></label>
                                                            <input
                                                                placeholder="Número do endereço"
                                                                id="numero"
                                                                type="numero"
                                                                name="numero"
                                                                onChange={formik.handleChange}
                                                                value={formik.values.numero}
                                                            />
                                                            {formik.errors.numero && (
                                                                <div className="error">{formik.errors.numero}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/*
                                            <div style={{justifyContent:"space-between", display:'flex'}}>

                                                <div className="input-box planosFormInputs" style={{width:"45%"}}>
                                                    <label>Telefone<span style={{color:"red"}}>*</span></label>
                                                    <InputMask 
                                                    placeholder="(62) 9 9999-9999"
                                                    id="telefone"
                                                    type="telefone"
                                                    name="telefone"
                                                    mask={"(99) 9 9999-9999"}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.telefone}
                                                    />
                                                    {formik.errors.telefone && (
                                                        <div className="error">{formik.errors.telefone}</div>
                                                    )}
                                                </div>

                                                <div className="input-box planosFormInputs" style={{width:"45%"}}>
                                                    <label>Cupom de desconto</label>
                                                    <input 
                                                    placeholder="Ex: CUPOM10HOJE"
                                                    id="cupom"
                                                    type="cupom"
                                                    name="cupom"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.cupom}
                                                    />
                                                    {formik.errors.cupom && (
                                                        <div className="error">{formik.errors.cupom}</div>
                                                    )}
                                                </div>
                                                                                            
                                            </div>
                                            */}

                                                    <div className="input-box planosFormInputs" style={{ width: "100%", paddingTop: "28px", marginBottom: "0px" }}>
                                                        {
                                                            isFinalizando ?
                                                                <button className="buttonFinalizarPagamento" readOnly={isFinalizando ? true : false} disabled={isFinalizando ? true : false} style={{ cursor: isFinalizando ? 'not-allowed' : '' }}>
                                                                    <Loading type='spin' color='white' height={24} width={24} />
                                                                </button>
                                                                :
                                                                <button type="submit" className="buttonFinalizarPagamento">
                                                                    <span style={{ color: 'white', fontWeight: '700', fontSize: 20 }}>FINALIZAR PAGAMENTO</span>
                                                                </button>
                                                        }
                                                    </div>

                                                    <div className="input-box planosFormInputs" style={{ width: "98%", paddingTop: "28px", marginBottom: "0px" }}>
                                                        <div className="info" style={{ display: 'flex', justifyContent: 'end' }}>
                                                            {/*<p style={{background:"rgb(217,255,230)", justifyContent:"space-between", display:"flex"}}>
                                                        <span>Cupom aplicado com sucesso!</span>                                                          
                                                    </p>   
                                                    */}
                                                            <span style={{ fontSize: 20, fontWeight: '700' }}>Total a pagar: R$ {priceToPay}</span>

                                                        </div>
                                                    </div>
                                                </form>
                                            </div>


                                        </div>
                                }

                                <div className="features">
                                    <div className="title">Tenha acesso a mais de 10 funcionalidades para alavancar suas vendas</div>
                                    <div className="feature-list">
                                        <div className="feature">Atendente Virtual WhatsApp</div>
                                        <div className="feature">Atendente Virtual para Facebook</div>
                                        <div className="feature">Modo Garçom</div>
                                        <div className="feature">PIX Automatizado*</div>
                                        <div className="feature">QR CODE do Cardápio para Mesas</div>
                                        <div className="feature">Serviço de Instalação</div>
                                        <div className="feature">Cadastro de Entregadores</div>
                                        <div className="feature">Painel de Pedidos</div>
                                        <div className="feature">Suporte Online</div>
                                        <div className="feature">Cardápio Digital</div>
                                        <div className="feature">Ponto de Venda</div>
                                    </div>
                                </div>
                                <div className="footerBtnPlanos">
                                    <button>Ver outros planos</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Teste>
        </div>
    );
}

export default Planos; 