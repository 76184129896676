import React, { useState, useEffect, useRef } from "react";
import * as AiIcons from 'react-icons/ai'
import styled from "styled-components";
import px2vw from "../../utils/px2vw";
import './styleMobile.css'
import imgTeste from '../../img/imgTeste.png'
import { useParams, Link, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { getCategoriasCardapio, getEmpresaWithObjId, getItensCardapio } from "../../services/api";
import { useQuery } from 'react-query';
import { useInView } from 'react-intersection-observer';
import Categoria from './Categoria'; // Ajuste o caminho conforme necessário
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  overflow: hidden;
`;

const Navbar = styled.div`
    position: fixed;
    bottom: 0;
    z-index: 999;
    width: 100%;
    height: 62px;
    background-color: #fff;
    box-shadow: 0 -15px 45px 0 rgba(10,10,10,.1);
    border-top: 2px solid #ebebeb;
`;

const NavbarContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    height: 100%;
`;


const MainContainer = styled.div`
    overflow: hidden;
    width: 100%;
`;

const CompanyHeader = styled.div`
    background-color: rgb(16, 149, 243);
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 0 16px;
    position: fixed;
    top: 0;
    z-index: 999;
    h1{
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        white-space: pre-line;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 0px!important;
    }
`;


const Cardapio = () => {
    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const location = useLocation();
    const { infoObjItens } = location.state || {};
    //const { itensAlreadyLoaded } = location.state || undefined;
    const navigate = useNavigate();
    //const [isLoading, setIsLoading] = useState(true);
    const scrollRef = useRef(null);
    // Obtém o external_id da URL atual, se existir
    const [searchParams] = useSearchParams();
    const externalId = searchParams.get('external_id');
    const { nomeEmpresaForUrl, idEmpresa } = useParams();
    const [categorias, setCategorias] = useState([])
    const [itens, setItens] = useState([])
    //console.log(itensAlreadyLoaded)
    const [empresa, setEmpresa] = useState({})
    //const empresaName = nomeEmpresaForUrl.replace(/-/g, ' ').toUpperCase();
    //console.log(nomeEmpresaForUrl, idEmpresa);
    const [selectedCategoria, setSelectedCategoria] = useState('');
    const [categoriaSelecionadaPorClique, setCategoriaSelecionadaPorClique] = useState(false);
    const [empresaObjId, setEmpresaObjId] = useState('');

    const [currentTime, setCurrentTime] = useState('');
    const [horariosFuncionamento, setHorariosFuncionamento] = useState({});
    const [openStatus, setOpenStatus] = useState('');
    const [statusLoja, setStatusLoja] = useState('');
    const [loadingData, setLoadingData] = useState(true); // Estado para controlar o carregamento de dadosconst [loadingData, setLoadingData] = useState(true); // Estado para controlar o carregamento de dados

    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");    

    const fetchMenuItems = async () => {
        const response = await getItensCardapio(idEmpresa, nomeEmpresaForUrl);
        if (response.status === 222) {
          throw new Error('Itens response was not ok');
        }
        //console.log(response.data.filteredItens)
        return response.data.filteredItens;
    };
    const fetchMenuCategorias = async () => {
        const response = await getCategoriasCardapio(idEmpresa, nomeEmpresaForUrl);
        if (response.status === 222) {
          throw new Error('Categorias response was not ok');
        }
        //console.log(response.data.filteredCategories)
        //setSelectedCategoria(response.data.filteredCategories[0]._id);   
        setEmpresaObjId(response.data.filteredCategories[0].empresaObjId);         
        return response.data.filteredCategories;
    };

    const { data: menuItems, loading, error } = useQuery('menuItems', fetchMenuItems); // Save Itens in cache
    const { data: menuCategorias, loadingCategorias, errorCategorias } = useQuery('menuCategorias', fetchMenuCategorias); // Save Categorias in cache

    if(menuCategorias){
        var arrayCategorias = menuCategorias.sort( (a, b) => {
            return a.order - b.order;
        });
    }

    if(menuItems){
        var arrayItens = menuItems.sort((a, b) => {
            return a.order - b.order;
        });
    }

    const [scrollPosition, setScrollPosition] = useState(0);
    useEffect(() => {
        const handleScroll = () => {
          setScrollPosition(window.scrollY);
        };
      
        window.addEventListener('scroll', handleScroll);
      
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const fetchTime = async () => {
            try {
                if (empresa && currentTime==="") {  // Only fetch if currentTime is not set or if data is not loading
                    const response = await axios.get('https://api.timezonedb.com/v2.1/get-time-zone', {
                        params: {
                            key: "7UIGZ1GOJE0U",
                            format: 'json',
                            by: 'zone',
                            zone: empresa.timezone
                        }
                    });
                    const newTime = response.data.formatted;
                    if (newTime !== currentTime) {  // Check if the new time is different
                        setCurrentTime(newTime);
                    }
                    console.log("Timezone data fetched:", newTime);
                    //setLoadingData(false);  // Set loading to false after fetching the time
                }
            } catch (error) {
                console.error('Erro ao obter o horário atual:', error);
                //setLoadingData(false);  // Also set loading to false on error
            }
        };
        fetchTime();
    }, [empresa, currentTime]);
    function isRestaurantOpen(horariosFuncionamento, currentTime) {
        const now = new Date();
        if (!currentTime || currentTime==="") return false;  // Ensure currentTime is available
        const currentTimeStr = currentTime.split(' ')[1]; // Assumes format "YYYY-MM-DD HH:MM:SS"
        // Set the hours, minutes, and seconds based on the currentTime string
        now.setHours(currentTimeStr.split(':')[0]);
        now.setMinutes(currentTimeStr.split(':')[1]);
        now.setSeconds(currentTimeStr.split(':')[2]);

        const currentDayIndex = now.getDay(); // Sunday is 0, Monday is 1, etc.
        const weekDays = ['domingo', 'segunda', 'terca', 'quarta', 'quinta', 'sexta', 'sabado'];
        const currentDayString = weekDays[currentDayIndex];
        const schedules = horariosFuncionamento[currentDayString]; // Get schedules for today

        if (!schedules) {
            return false; // No schedules defined for today
        }

        if (statusLojaTemporario) {
            return false;
        }

        return schedules.some(schedule => {
            const startTimeCompare = new Date(now);
            startTimeCompare.setHours(schedule.start.split(':')[0]);
            startTimeCompare.setMinutes(schedule.start.split(':')[1]);
            startTimeCompare.setSeconds(0);

            const endTimeCompare = new Date(now);
            endTimeCompare.setHours(schedule.end.split(':')[0]);
            endTimeCompare.setMinutes(schedule.end.split(':')[1]);
            endTimeCompare.setSeconds(0);

            // Adjust for crossing midnight
            if (endTimeCompare < startTimeCompare) {
                endTimeCompare.setDate(endTimeCompare.getDate() + 1);
            }

            return now >= startTimeCompare && now <= endTimeCompare;
        });
    }
    const [toastShown, setToastShown] = useState(false);
    //const isInitialMount = useRef(true);
    useEffect(() => {
               
        const checkRestaurantStatus = async () => {
            // Only proceed if loading is complete and toast has not been shown yet
            //if (!loadingData && !toastShown) {
            if (horariosFuncionamento && Object.keys(horariosFuncionamento).length !== 0) {
                const isRestaurantOpenStatus = isRestaurantOpen(horariosFuncionamento, currentTime);
                setOpenStatus(isRestaurantOpenStatus ? `${findNextClosingTime(horariosFuncionamento, currentTime)}` : `${findNextOpeningTime(horariosFuncionamento, currentTime)}`);
                console.log("PASSOU AQUI!", isRestaurantOpenStatus)
                if (!isRestaurantOpenStatus && !toastShown && loadingData === false) {
                    toast("O estabelecimento está fechado.", {autoClose: false, type: 'error', position: 'bottom-center'});
                    setToastShown(true);
                }
            } 
            //}
        };
    
        checkRestaurantStatus();
    }, [horariosFuncionamento, currentTime]);

    //COMETARIO TEMP
    useEffect(() => {
        const tabList = document.querySelector('.tab-list');
        const tabListContent = document.querySelector('.tab-list_content');
        let companyHeaderHeight = document.querySelector('.CompanyHeader');
        if(companyHeaderHeight){
            companyHeaderHeight = companyHeaderHeight.offsetHeight;
        }
        companyHeaderHeight -= 15;
        //console.log("companyHeaderHeight",companyHeaderHeight)
        if(tabList && tabListContent){
            if (scrollPosition >= companyHeaderHeight) {
            tabList.classList.add('fixed');
            tabListContent.classList.add('marginTop');
            } else {
            tabList.classList.remove('fixed');
            tabListContent.classList.remove('marginTop');
            }
        }
        
    }, [scrollPosition]);

    const getIndex = (arr, x) => {
        const idx = arr.findIndex(el => el >= x);
        return idx === -1 ? arr.length - 1 : idx;
    }

    // UseEffect para resetar o estado de clique após o scroll (se necessário)
    useEffect(() => {
        if (categoriaSelecionadaPorClique) {
            setCategoriaSelecionadaPorClique(false);
        }
    }, [categoriaSelecionadaPorClique]);

    const scrollToCategory = (categoryId) => {
        const headerContainer = scrollRef.current;
        const selectedItem = document.getElementById(`cat:${categoryId}`);
        if (headerContainer && selectedItem) {
            const scrollPosition = selectedItem.offsetLeft - headerContainer.offsetLeft - 60;
            headerContainer.scrollTo({ left: scrollPosition, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if (selectedCategoria) {
            scrollToCategory(selectedCategoria);
        }
    }, [selectedCategoria]);

    // Função para lidar com a seleção de categoria e scroll
    const handleSelectCategoria = (categoriaId) => {
        setSelectedCategoria(categoriaId);
        // Aqui você pode adicionar lógica adicional se necessário
    };
    // Função para realizar a rolagem direta para a categoria selecionada pelo clique
    const handleCategoriaClick = (categoriaId) => {
        setSelectedCategoria(categoriaId);
        //setIsProgrammaticScroll(true); // Indica que o próximo scroll será programático
    
        scrollToElement(categoriaId);
    };

    const scrollToElement = (elementId) => {
        const element = document.getElementById(elementId);
        if (element) {
            const headerOffset = 140;
            const elementPosition = element.getBoundingClientRect().top + window.pageYOffset - headerOffset;
    
            window.scrollTo({
                top: elementPosition,
                behavior: 'instant',
            });
    
            // Após o scroll, você pode precisar de um mecanismo para resetar isProgrammaticScroll
            // Isso pode ser feito com um callback após o scroll ou em um useEffect observando a posição do scroll
        }
    };

    const [statusLojaTemporario, setStatusLojaTemporario] = useState(false)

    useEffect(() => {
        (async () => {
            
            try {

                if(empresaObjId){
                    const empresaTemp = await getEmpresaWithObjId(empresaObjId);
                    if(empresaTemp){
                        setEmpresa(empresaTemp.data.empresa);
                        // Converte o objeto empresa para uma string JSON e criptografa para salvamento no sessionStorage
                        const empresaString = JSON.stringify(empresaTemp.data.empresa);
                        const encryptedEmpresa = CryptoJS.AES.encrypt(empresaString, secretKey).toString();
                        
                        if(empresaTemp.data.empresa.status_loja === "sempre"){
                            setStatusLoja("aberto");
                            setOpenStatus("Sempre aberto");
                        }  
                        if(empresaTemp.data.empresa.fechamento_temporario){
                            console.log("PASSOU AQUI");
                            setStatusLojaTemporario(true);
                        }                        
                        if(empresaTemp.data.empresa.status_loja === "especifico" && !empresaTemp.data.empresa.horario_funcionamento){
                            setHorariosFuncionamento("Sem horário de funcionamento definido");
                            setCurrentTime("Sem horário de funcionamento definido");
                        }
                        if(empresaTemp.data.empresa.status_loja === "especifico" && empresaTemp.data.empresa.horario_funcionamento){
                            setHorariosFuncionamento(empresaTemp.data.empresa.horario_funcionamento);
                        }
                        if(!empresaTemp.data.empresa.status_loja){
                            if(openStatus === "" || openStatus === "Fechado"){
                                toast("O estabelecimento está fechado.", {autoClose: false, type: 'error', position: 'bottom-center'});
                                setOpenStatus("Fechado");
                            }
                        }
                        // Armazena a string JSON no sessionStorage
                        sessionStorage.setItem("sessionCompany", encryptedEmpresa);
                        //setLoadingData(false);  // Set loading to false after all states are updated
                    }
                    else {
                        console.log("Nenhuma categoria encontrada!");
                        //setLoadingData(false);  // Set loading to false if no data is found
                    }
                }                
            
            } catch (error) {
                if (error.response && error.response.status === 404) {
                  // Tratar o erro 404 Not Found
                  setErrorMessage("Erro 404: Página não encontrada");
                  setShowError(true);
                } else {
                  // Tratar outros erros
                  console.error("Unexpected error:", error);
                  setErrorMessage("Ocorreu um erro inesperado");
                  setShowError(true);
                }
                setLoadingData(false);  // Set loading to false on error
            }
        })();
    },[empresaObjId]);

    const [itensCarrinho, setItensCarrinho] = useState([]);
    useEffect(() => {
        const itensCarrinhoTemp = JSON.parse(localStorage.getItem('itensCarrinho'));
        setItensCarrinho(itensCarrinhoTemp || []);
    }, []);

    /*const handleCarrinho = () => {
        console.log(nomeEmpresaForUrl, idEmpresa)
        navigate("/"+nomeEmpresaForUrl+"/"+idEmpresa+"/carrinho", {state: { infoObjItens }});
    }

    const handleOpenProfile = () => {
        navigate("/"+nomeEmpresaForUrl+"/"+idEmpresa+"/info-loja", {state: { infoEmpresa: empresa}});
    }*/
    const handleCarrinho = () => {
        console.log(nomeEmpresaForUrl, idEmpresa);
        
        // Se o external_id existir, inclui nos params, senão apenas navega sem ele
        const url = externalId 
            ? `/${nomeEmpresaForUrl}/${idEmpresa}/carrinho?external_id=${externalId}`
            : `/${nomeEmpresaForUrl}/${idEmpresa}/carrinho`;
        navigate(url, { state: { infoObjItens } });
    }
    
    const handleOpenProfile = () => {
        const url = externalId 
            ? `/${nomeEmpresaForUrl}/${idEmpresa}/info-loja?external_id=${externalId}`
            : `/${nomeEmpresaForUrl}/${idEmpresa}/info-loja`;
        navigate(url, { state: { infoEmpresa: empresa } });
    }

    const handleItemCard = (objIdItem) => {      
    }

    function findNextClosingTime(schedule, currentTime) {
        if (!currentTime || currentTime === "") {
            setLoadingData(true); 
            return "Carregando...";  // Ensure currentTime is available
        }
    
        // Corrigindo a obtenção do dia da semana para usar getDay()
        const weekDays = ['domingo', 'segunda', 'terca', 'quarta', 'quinta', 'sexta', 'sabado'];
        const now = new Date(currentTime);
        const currentDayIndex = now.getDay();
        const currentDayName = weekDays[currentDayIndex];
    
        const currentDaySchedules = schedule[currentDayName];
    
        if (currentDaySchedules) {
            for (const period of currentDaySchedules) {
                const startDateString = currentTime.split(' ')[0] + 'T' + period.start + ':00'; // Incluindo segundos
                const endDateString = currentTime.split(' ')[0] + 'T' + period.end + ':00'; // Incluindo segundos
                const startTime = new Date(startDateString);
                let endTime = new Date(endDateString);
    
                if (endTime < startTime) {
                    endTime.setDate(endTime.getDate() + 1);  // Ajustando para horários que cruzam a meia-noite
                }
    
                if (now >= startTime && now < endTime) {
                    setStatusLoja("aberto");
                    setLoadingData(false);
                    return `Aberto até ${endTime.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })}`;
                }
            }
        }
    
        // Se nenhum período válido foi encontrado para o dia atual
        setStatusLoja("fechado");
        setLoadingData(false);
        if (!toastShown) {
            toast("O estabelecimento está fechado.", {autoClose: false, type: 'error', position: 'bottom-center'});
            setToastShown(true);
        }
        return "Fechado";
    }
    
    
    function findNextOpeningTime(schedule, currentTime) {
        if (!currentTime || currentTime === "") {
            setLoadingData(true);
            return "Carregando...";  // Ensure currentTime is available
        }
        const weekDays = ['domingo', 'segunda', 'terca', 'quarta', 'quinta', 'sexta', 'sabado'];
        const now = new Date(currentTime);
        const currentDayIndex = now.getDay();
        let result = "Sem horário de abertura próximo";
        let daysChecked = 0;
    
        while (daysChecked < 7) {  // Only need to check one full week
            const checkDay = (currentDayIndex + daysChecked) % 7;
            const dayName = weekDays[checkDay];
            const periods = schedule[dayName];
            if (periods) {
                for (const period of periods) {
                    // Calculate the correct date for the period being checked
                    const checkDate = new Date(now);
                    checkDate.setDate(now.getDate() + daysChecked);
                    const startDateString = checkDate.toISOString().split('T')[0] + 'T' + period.start + ':00'; // Ensure seconds are included
                    const endDateString = checkDate.toISOString().split('T')[0] + 'T' + period.end + ':00'; // Ensure seconds are included
                    const startTime = new Date(startDateString);
                    const endTime = new Date(endDateString);
    
                    // Correct the day if the period crosses midnight
                    if (period.end < period.start) {
                        endTime.setDate(endTime.getDate() + 1);
                    }
    
                    // If currentTime is during a closing period, skip to the next period
                    if (now >= endTime) {
                        continue;
                    }
    
                    // If current time is earlier than the start time, we found our next opening time
                    if (now < startTime) {
                        const nextOpenDay = daysChecked === 0 ? "hoje" : weekDays[(currentDayIndex + daysChecked) % 7];
                        result = `Abre ${nextOpenDay} às ${startTime.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })}`;
                        setLoadingData(false);
                        return result;
                    }
                }
            }
            daysChecked++;
        }
    
        // If all days were checked and no future time was found
        setStatusLoja("fechado");
        setLoadingData(false);
        if (!toastShown) {
            toast("O estabelecimento está fechado.", {autoClose: false, type: 'error', position: 'bottom-center'});
            setToastShown(true);
        }
        return result;
    }
    
    
    

    if (loading){
        return <div className="loading loadingMobile"><AiIcons.AiOutlineLoading3Quarters style={{fontSize:"100px", color:"rgb(180,180,180)"}}/></div>;
    }

    if (loadingCategorias){
        return <div className="loading loadingMobile"><AiIcons.AiOutlineLoading3Quarters style={{fontSize:"100px", color:"rgb(180,180,180)"}}/></div>;
    }

    if (showError){
        return <div style={{height: '100vh', padding: '20px', fontSize:24, fontWeight:'600'}} dangerouslySetInnerHTML={{ __html: errorMessage }} />
    }

    if (error || errorCategorias) return 'An error has occurred: ' + error.message;

    else

    return (
        <Container>
            <Navbar>
                <div style={{width:'100%', height:'inherit'}}>
                    <NavbarContent>
                        <div className="nav-inicio">
                            <div className="icon-ativo"/>

                            <div style={{display:'flex', justifyContent:'center'}}>
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={20}
                                height={20}
                                viewBox="0 0 19.195 18"
                                >
                                <g id="Home" transform="translate(-8.999 -10)">
                                    <path
                                    id="Icon"
                                    d="M16.622,18H2.57a1.319,1.319,0,0,1-1.317-1.318V9.657a1.317,1.317,0,0,1-.79-2.318L8.744.312a1.318,1.318,0,0,1,1.7,0L18.73,7.339a1.32,1.32,0,0,1-.79,2.323v7.021A1.319,1.319,0,0,1,16.622,18ZM8.279,11.417h2.635a1.319,1.319,0,0,1,1.317,1.318v3.952h4.391V8.344h1.256L9.6,1.319,1.315,8.344H2.57v8.343H6.962V12.735A1.319,1.319,0,0,1,8.279,11.417Zm0,1.318v3.952h2.635V12.735Z"
                                    transform="translate(9 10)"
                                    fill="#1095F3"
                                    />
                                </g>
                                </svg>                                

                            </div>

                            <span style={{
                                color: 'grey',
                                fontSize: '10px',
                                fontWeight: '500'
                            }}
                            >
                                Início
                            </span>

                        </div>

                        <div className="nav-carrinho" onClick={() => handleCarrinho()}>
                            {/*<div className="icon-ativo"/>*/}

                            <div style={{display:'flex', justifyContent:'center'}}>
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={20}
                                height={20}
                                viewBox="0 0 23.326 20"
                                >
                                <path
                                    id="Shape"
                                    d="M8.454,20h0a2.563,2.563,0,0,1-2.563-2.567,6.2,6.2,0,0,0,.584-5.2L1.795,1.538H.77A.769.769,0,1,1,.77,0H2.308a.768.768,0,0,1,.7.462L4.036,2.82a1.535,1.535,0,0,1,.831-.256h16.92A1.538,1.538,0,0,1,23.2,4.707l-3.076,7.178a1.537,1.537,0,0,1-1.415.933H8.475a1.026,1.026,0,0,0,0,2.051H18.746a2.563,2.563,0,1,1-2.38,1.538H10.8A2.564,2.564,0,0,1,8.454,20ZM18.71,16.408a1.026,1.026,0,1,0,1.025,1.025A1.027,1.027,0,0,0,18.71,16.408Zm-10.255,0a1.026,1.026,0,1,0,1.025,1.025A1.027,1.027,0,0,0,8.455,16.408ZM4.866,4.1l3.076,7.178H18.71L21.786,4.1H4.866Z"
                                    fill="#9C9C9C"
                                />
                                </svg>                               

                            </div>

                            <span style={{
                                color: 'grey',
                                fontSize: '10px',
                                fontWeight: '500'
                            }}
                            >
                                Carrinho
                                {itensCarrinho.length>0 && 
                                    <>
                                    <i className="counterItensCarrinhoNum">{itensCarrinho.length}</i>
                                    <i className="counterItensCarrinhoNumAnimated"/>
                                    </>
                                }
                            </span>

                        </div>

                    </NavbarContent>

                </div>
            </Navbar>

            <MainContainer>
                <div>
                    <div className="header-wrapper">
                        <CompanyHeader className="CompanyHeader">
                            <div style={{display:'flex', alignItems:'center'}}>
                                <h1>{empresa&&empresa.name}</h1> 
                            </div>
                            <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
                                <div className="searchIconMobile">
                                    <div style={{display:'flex', justifyContent:'center'}}>
                                        <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="#1095F3"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-search"
                                        >
                                        <circle cx={11} cy={11} r={8} />
                                        <line x1={21} y1={21} x2="16.65" y2="16.65" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="searchIconMobile">
                                    <div style={{display:'flex', justifyContent:'center', paddingRight:2}}>
                                        <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="#1095F3"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-share-2"
                                        >
                                        <circle cx={18} cy={5} r={3} />
                                        <circle cx={6} cy={12} r={3} />
                                        <circle cx={18} cy={19} r={3} />
                                        <line x1="8.59" y1="13.51" x2="15.42" y2="17.49" />
                                        <line x1="15.41" y1="6.51" x2="8.59" y2="10.49" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </CompanyHeader>

                        <div className="company-info">
                            <div style={{display:'flex', alignItems:'center', gap:8}}>
                                <span className="text-mobile">{openStatus}</span>
                                <span className="dot"></span>
                                <span className="text-mobile">Sem pedido mínimo</span>
                            </div>
                            <div className="linkPerfilLoja" onClick={() => handleOpenProfile()}>Perfil da loja</div>
                        </div>

                    </div>

                    <div className="tab-wrapper">
                        <div>
                            <div className={"tab-list"} ref={scrollRef}>
                                <ul className="tab-item-list">
                                    {arrayCategorias && arrayCategorias.filter((cats) =>
                                        menuItems && menuItems.some((item) => item.category_item_id === cats._id)
                                    )
                                    .map((categoria, i) => (                                    
                                        <li key={categoria._id} id={`cat:${categoria._id}`} className="tab-list_item"                                    
                                        onClick={() => handleCategoriaClick(categoria._id)}>
                                            <span className="tab-list_item_categoria_nome"
                                            style={{color:selectedCategoria===categoria._id?"rgb(16, 149, 243)":"#2b2b2b"}}
                                            >{categoria.title}</span>
                                            {selectedCategoria===categoria._id ? 
                                            <div className="tab-list_item_ativo"/>
                                            : null }                                                                                        
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className={"tab-list_content"}>
                                <div className="categoria-container">
                                    {arrayCategorias && arrayCategorias
                                    .filter((category) =>
                                    menuItems && menuItems.some((item) => item.category_item_id === category._id)
                                    )                            
                                    .map((categoria, i)=>(
                                        <Categoria
                                        key={categoria._id}
                                        categoria={categoria}
                                        itens={arrayItens}
                                        nomeEmpresaForUrl={nomeEmpresaForUrl}
                                        idEmpresa={idEmpresa}
                                        handleSelectCategoria={handleSelectCategoria}
                                        statusLoja={statusLoja}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </MainContainer>
       
        </Container>
    );
};


export default Cardapio;