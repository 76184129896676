import styled from "styled-components";
import './style.css'
import { ImCheckboxChecked } from "react-icons/im";
import { FaMinus } from "react-icons/fa";
import { useParams, useNavigate, Link } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import { useState, useEffect } from "react";
import InputMoney from "../../pages/ListItem/InputMoney";
import { toast } from "react-toastify";


const DivBGtransparent = styled.div`
  z-index:15;
  position: fixed;
  top: 0;
  left: 0;
  display: ${({ showModalAddObsItem }) => (showModalAddObsItem ? 'flex' : 'none' )};
  float: left;
  min-width: 100%;
  min-height: 100%;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 5px 0;
  list-style: none;
  background-color: rgba(0,0,0,0.5);
  background-clip: padding-box;
  align-items:center;
  justify-content:center;
  opacity: ${({ showModalAddObsItem }) => (showModalAddObsItem ? '1':'0' )};
  transition: opacity .3s ease-in-out;

  // Add keyframes for the fade-in effect
  animation: fadeIn .3s ease-in-out;
  animation-fill-mode: forwards;

  // Define the keyframes for the animation
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const DivContainer = styled.div`
    z-index:20;
    position: absolute;
    top: 20%;
    left: 8%;
    float: left;
    min-width: 80%;
    min-height: 32%;
    --max-width: 50%;
    padding: 5px 0;
    list-style: none;
    --background-color: white;
    background-color:rgb(247,247,247)!important;
    background-clip: padding-box;
    border-radius: 15px;
    padding:10px;

`;

const ModalAddObsItemCarrinho = ({
    itensCarrinho,
    showModalAddObsItem,
    setShowModalAddObsItem,
    setItensCarrinho,
    currentItem,
    setCurrentEditingItem
}) => {
    
    const closeModal = () => {    
        handleSaveObservation();    
        setShowModalAddObsItem(!showModalAddObsItem);
    }

    const [currentObservation, setCurrentObservation] = useState(currentItem?.observacoes_item || '');

    useEffect(() => {
        if (currentItem) {
            setCurrentObservation(currentItem.observacoes_item || '');
        }
    }, [currentItem]);

    const handleSaveObservation = () => {
        const updatedItems = itensCarrinho.map(item => {
            if (item.id === currentItem.id) {
                return { ...item, observacoes_item: currentObservation };
            }
            return item;
        });
        setItensCarrinho(updatedItems);
        localStorage.setItem('itensCarrinho', JSON.stringify(updatedItems));
        setShowModalAddObsItem(false);
        //setCurrentEditingItemId(null);
        setCurrentEditingItem(null); // Resetando o item que estava sendo editado.
    };
    
    return (
        <DivBGtransparent showModalAddObsItem={showModalAddObsItem}>
            <DivContainer>
                <div className="divTitleItemAdicionado">
                    <span className="divTitleItemAdicionadoSpan">Observação</span> 
                </div>

              
                <div className="obsTextBoxContainerModalAddItem">
                    <div className="obsTextBoxData">
                        <textarea placeholder="Digite aqui a observação" 
                            value={currentObservation}
                            onChange={e => setCurrentObservation(e.target.value)}
                            maxLength="200"
                            className="text-areaObs"
                        />
                        <div className="divCounterChar">{currentObservation.length}/200</div>               
                    </div>
                </div>
          

                <div className="divBtnContinueCart">
                    {/*<button className="buttonContinue" onClick={()=> closeModal()}>Não preciso de troco</button>*/}
                    <div style={{width:'100%'}}>
                        <button className="buttonCart" onClick={() => closeModal()}>Confirmar</button>
                    </div>
                </div>

            </DivContainer>
        </DivBGtransparent>
    );
}

export default ModalAddObsItemCarrinho;