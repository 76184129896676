import React, { useState, useContext, useEffect, useMemo } from "react";
import './style.css'
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import { SidebarContext } from "../../AppRoutes";
import { AuthContext } from "../../contexts/auth";

import { getEmpresas, getEmpresa, deleteEmpresa } from "../../services/api";

import PermissionGate from "../../services/PermissionGate";

import { useNavigate } from "react-router-dom";
import LeftMenu from "../../components/LeftMenu";
import ConfirmDialog from "../../components/ConfirmDialog";

import { toast } from "react-toastify";

import moment from 'moment'

import * as FiIcons from 'react-icons/fi'
import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'
import * as FaIcons from 'react-icons/fa'

import ModalEditEmpresa from "../../components/ModalEditEmpresa";
import { Table } from 'antd';


const Teste = styled.div`
    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;
    z-Index: 9;

    @media (max-width: 880px) {
        margin-left: 0;
    }
`;

const columns = [
    {
      title: 'Código',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'CNPJ',
      dataIndex: 'cnpj',
      key: 'cnpj',
    },
    {
      title: 'Nome Fantasia',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
      responsive: ['lg'],
    },
    {
        title: 'Estado',
        dataIndex: 'state',
        key: 'state',
        responsive: ['lg'],
    },
    {
        title: 'Município',
        dataIndex: 'city',
        key: 'city',
        responsive: ['lg'],
    },
    {
        title: 'Data Inclusão',
        dataIndex: 'date_inclusion',
        key: 'date_inclusion',
        responsive: ['lg'],
    },
    {
        title: 'Ações',
        dataIndex: 'actions',
        key: 'actions',
    },
];                                                                    


const ListEmpresa = ( {list = []} ) =>{
    const { user } = useContext(AuthContext);

    const navigate = useNavigate();

    const [empresas, setEmpresas] = useState([]); 
    const [filter, setFilter] = useState('');
    const [result, setResult] = useState(false);

    const [order, setOrder] = useState(1)
    const [columnOrder, setColumnOrder] = useState('title')

    const { sidebar, setSidebar } = useContext(SidebarContext);;
    
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);

    const [showEditEmpresa, setEditEmpresa] = useState(true);

    useEffect(() => {
        (async () => {
            const response = await getEmpresas(user._id);
            console.log("TESTEEE:::",user._id)
            //const teste = [response.data]
            console.log(response.data)
            setEmpresas(response.data.empresa);
            setLoading(false);
            setRefresh(false);    
            console.log("passou aqui: "+empresas);
        })();
    },[refresh]);
    

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [shouldDelete, setShouldDelete] = useState(false);
    const [idToDelete, setIdToDelete] = useState('');
    const handleDelete = (idRecebido) => {
        // Abre Componente de Confirmação do delete
        setConfirmOpen(true)

        setIdToDelete(idRecebido)
        if(idRecebido){
            console.log("ID RECEBIDO:",idRecebido)
            setShouldDelete(true);
        }
    }
    const deleteReg = async () => {
        console.log("O ID CLICADO FOI :::",idToDelete);
        console.log("ID do Usuário que irá efetuar a exclusão", user._id);

        if(shouldDelete){
            deleteEmpresa(user._id, idToDelete).then( cast => {
                console.log("RESULT DELETE:",cast);
                if(cast.status===201){
                    toast(cast.data.msg,{ autoClose: 5000 , type: "success"});
                    setRefresh(true);
                }else{
                    toast(cast.data.msg,{ autoClose: 5000 , type: "error"});
                }
            }).catch( err => {
                console.log("ERROR:",err);
                toast(err.response.data.msg,{ autoClose: 5000 , type: "error"});
            });
        }

    }

    const [_idEmpresaEdit, set_idEmpresaEdit] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [nome, setNome] = useState('');
    const [razao, setRazao] = useState('');
    const [email, setEmail] = useState('');
    const [cepNumber, setCep] = useState('');
    const [estado, setEstado] = useState('');
    const [municipio, setMunicipio] = useState('');
    const [bairro, setBairro] = useState('');
    const [complemento, setComplemento] = useState('');
    const [telefone, setTelefone] = useState('');
    const [celular, setCelular] = useState('');
    const [type, setType] = useState('');
    const [status, setStatus] = useState('');
    const handleEdit = async idToEdit => {
        console.log("ID TO EDIT----",idToEdit);
        set_idEmpresaEdit(idToEdit)  
        setEditEmpresa(!showEditEmpresa);
        getEmpresa(user._id, idToEdit).then(
            response => {
                if(showEditEmpresa){        
                    response.data.empresa.cnpj ? setCnpj(response.data.empresa.cnpj) : setCnpj('');   
                    response.data.empresa.name ? setNome(response.data.empresa.name) : setNome('');
                    response.data.empresa.razao ? setRazao(response.data.empresa.razao): setRazao('');
                    response.data.empresa.email ? setEmail(response.data.empresa.email) : setEmail('');
                    response.data.empresa.cep ? setCep(response.data.empresa.cep) : setCep('');
                    response.data.empresa.estado ? setEstado(response.data.empresa.estado) : setEstado('');
                    response.data.empresa.municipio ? setMunicipio(response.data.empresa.municipio) : setMunicipio('');
                    response.data.empresa.bairro ? setBairro(response.data.empresa.bairro) : setBairro('');
                    response.data.empresa.complemento ? setComplemento(response.data.empresa.complemento) : setComplemento('');
                    response.data.empresa.telefone ? setTelefone(response.data.empresa.telefone) : setTelefone('');
                    response.data.empresa.celular ? setCelular(response.data.empresa.celular) : setCelular('');
                    response.data.empresa.type ? setType(response.data.empresa.type) : setType('');
                    response.data.empresa.bloqueado ? setStatus(response.data.empresa.bloqueado) : setStatus('');
                    console.log(response.data.empresa);                    
                }
            }
        ).catch(err => {
            console.log("ERROR:",err);
        })
    
    }

    const handleRefresh = () => {
        //window.location.reload(false);
        setLoading(true);
        setRefresh(true);
    }

    const handleOrder = fieldName => {
        setOrder(-order)
        setColumnOrder(fieldName)
        console.log("order:",order);
        console.log("fieldName",fieldName)
    }

    var arrayEmpresas = [];
    arrayEmpresas = empresas.sort( (a, b) => {
        return a[columnOrder] < b[columnOrder] ? -order : order;
    })

    
    if (filter){
        const exp = eval(`/${filter.replace(/[^\d\w]+/,'.*')}/i`)

        list = empresas.filter( item => exp.test( item.cnpj ) )

       

        console.log(list.length)
        if(list.length==0){
            list = empresas.filter( item => exp.test( item.name ) )
            //setResult('teste');
            //console.log(list);
        }
        // if(list.length==0){
        //     list = empresas.filter( item => exp.test( item.industry_email ) )
        //     //setResult(true);
        // }
        /*if(list.length==0){
            list = empresas.filter( item => exp.test( item.celular ) )
        }*/
    }

    const handleFilter = e => {
        setFilter(e.target.value);
        setResult(!result);
    }

    const handleCadastro = () => {
        navigate("/empresa");
    }

    const tableData = useMemo(() => {
        if(filter) return list.map((empresa) => {
            return {
                key: empresa._id,
                code: empresa.id_empresa,
                cnpj: empresa.cnpj,
                name: empresa.name,
                email: empresa.email,
                state: empresa.estado,
                city: empresa.municipio,
                date_inclusion: moment(empresa.createdAt).format("DD/MM/YYYY"),
                actions: (
                <div>
                    <a className="btn btn-sm btn-primary" onClick={e => handleEdit(empresa.id_empresa)}><FiIcons.FiEdit2 style={{color:"white"}}/></a>
                    <a className="btn btn-sm btn-danger" style={{marginLeft:"5px"}} onClick={e => handleDelete(empresa._id)}><FiIcons.FiTrash2 style={{color:"white"}}/></a>
                </div>
                ),
            };
        });
    
        return arrayEmpresas.map(({_id, id_empresa, cnpj, name, email, estado, municipio, createdAt}) => {
            return {
                key: _id,
                code: id_empresa,
                cnpj,
                name,
                email,
                state: estado,
                city: municipio,
                date_inclusion: moment(createdAt).format("DD/MM/YYYY"),
                actions: (
                <div>
                    <a className="btn btn-sm btn-primary" onClick={e => handleEdit(id_empresa)}><FiIcons.FiEdit2 style={{color:"white"}}/></a>
                    <a className="btn btn-sm btn-danger" style={{marginLeft:"5px"}} onClick={e => handleDelete(_id)}><FiIcons.FiTrash2 style={{color:"white"}}/></a>
                </div>
                ),
            };
        })
      }, [filter, list, arrayEmpresas]);

    if(loading){
        return(

            <>
            <div className="loading"><AiIcons.AiOutlineLoading3Quarters style={{fontSize:"100px", color:"rgb(180,180,180)"}}/></div>
            <PermissionGate permissions={['viewIndustry']}>

                <LeftMenu setSidebar={setSidebar} sidebar={sidebar}></LeftMenu>

                <Teste sidebar={sidebar}>

                    <div className="w-100 p-3">
                        <div className="list-header-categories">
                            <div className="title">
                                <h1>Empresas</h1>
                            </div>
                        </div>
                        <div className="flex-column flex-md-row" style={{display:"flex", justifyContent:"space-between"/*, height:"80px"*/}}>
                            <div className="input-box-list" style={{ width:"100%", maxWidth: "400px" }}>                
                                <input className="input-field" placeholder="Pesquisar" onChange={handleFilter}/>
                                <i className="icon"><FiIcons.FiSearch/></i>
                            </div>

                            <div className="div-buttons flex-column flex-md-row">
                                <div className="continue-button">                           
                                    <button onClick={handleCadastro}>
                                        <AiIcons.AiOutlinePlusCircle style={{marginRight: "5px",fontSize: "22px",marginBottom: "2px"}}/><a>Adicionar</a> 
                                    </button>                            
                                </div>

                                <div className="refresh-button" style={{ textAlign: "start" }}>                                                       
                                    <button className="refresh-button" onClick={handleRefresh}>
                                        <SlIcons.SlRefresh style={{color:"#4281FF",marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/><a >Atualizar</a> 
                                    </button>
                                </div>
                            </div>

                        </div>

                        <div className={result?"table result":"table"} style={{background:"white"}}>
                            <Table 
                                columns={columns} 
                                dataSource={[]} 
                                pagination={false}
                            />
                        </div>
                                
                    </div>
                </Teste>
        
        
            {/*</div>*/}

            </PermissionGate>       
            </>

        );
    }

    return(

        <>
        <PermissionGate permissions={['viewIndustry']}>
        {/*<div className="container">*/}

            <LeftMenu setSidebar={setSidebar} sidebar={sidebar}></LeftMenu>

            <ConfirmDialog
                title="Deletar Empresa?"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={deleteReg}
            >
                Tem certeza que deseja deletar a indústria?
            </ConfirmDialog>

            <ModalEditEmpresa setEditEmpresa={setEditEmpresa} showEditEmpresa={showEditEmpresa} 
            setRefresh={setRefresh}
            _idEmpresaEdit={_idEmpresaEdit}
            cnpj={cnpj}
            nome={nome}
            razao={razao}
            email={email}
            cepNumber={cepNumber}
            setCep={setCep}
            estado={estado}
            setEstado={setEstado}
            municipio={municipio}
            setMunicipio={setMunicipio}
            bairro={bairro}
            setBairro={setBairro}
            complemento={complemento}
            setComplemento={setComplemento}
            telefone={telefone}
            setTelefone={setTelefone}
            celular={celular}
            setCelular={setCelular}
            type={type}
            status={status}
            setStatus={setStatus}
            />

            <Teste sidebar={sidebar}>

                <div className="w-100 p-4">
                    <div className="list-header-categories">
                        <div className="title">
                            <h1>Empresas</h1>
                        </div>
                    </div>


                    <div className="flex-column flex-md-row" style={{display:"flex", justifyContent:"space-between"/*, height:"80px"*/}}>
                        <div className="input-box-list" style={{ maxWidth:"400px", width: "100%"}}>                
                            <input className="input-field" placeholder="Pesquisar" onChange={handleFilter}/>
                            <i className="icon"><FiIcons.FiSearch/></i>
                        </div>

                        <div className="div-buttons flex-column flex-md-row">
                            <div className="continue-button">                           
                                <button onClick={handleCadastro}>
                                    <AiIcons.AiOutlinePlusCircle style={{marginRight: "5px",fontSize: "22px",marginBottom: "2px"}}/><a>Adicionar</a> 
                                </button>                            
                            </div>

                            <div className="refresh-button" style={{ textAlign: "start" }}>                                                       
                                <button className="refresh-button" onClick={handleRefresh}>
                                    <SlIcons.SlRefresh style={{color:"#4281FF",marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/><a >Atualizar</a> 
                                </button>
                            </div>
                        </div>

                    </div>
                    <Table 
                        columns={columns} 
                        dataSource={tableData} 
                        pagination={false}
                        rowClassName="row-table"
                        rowHoverable={true}
                    />  
                  
                    {/* <table className={"table"}>
                        <thead>
                            <tr>                 
                                <th scope="col" onClick={ e => handleOrder('id_empresa')} style={{cursor:"pointer"}}>Código<FaIcons.FaSort/></th>
                                <th scope="col" onClick={ e => handleOrder('cnpj')} style={{cursor:"pointer"}}>CNPJ<FaIcons.FaSort/></th>
                                <th scope="col" onClick={ e => handleOrder('name')} style={{cursor:"pointer"}}>Nome Fantasia<FaIcons.FaSort/></th>
                                <th scope="col" onClick={ e => handleOrder('industry_email')} style={{cursor:"pointer"}}>E-mail<FaIcons.FaSort/></th>                               
                                <th scope="col" onClick={ e => handleOrder('estado')} style={{cursor:"pointer"}}>Estado<FaIcons.FaSort/></th>
                                <th scope="col" onClick={ e => handleOrder('municipio')} style={{cursor:"pointer"}}>Município<FaIcons.FaSort/></th>
                                <th scope="col" onClick={ e => handleOrder('createdAt')} style={{cursor:"pointer"}}>Data Inclusão<FaIcons.FaSort/></th>
                                <th scope="col" style={{paddingLeft:"15px"}}>Ações</th>
                            </tr>
                        </thead>

                        <tbody className={result?"result":"result search"}>
                            
                        {
                        filter ? 
                            list.map((empresa) => (
                                <tr key={empresa._id}>
                                    <td>{empresa.id_empresa}</td>
                                    <td>{empresa.cnpj}</td>
                                    <td>{empresa.name}</td>
                                    <td>{empresa.email}</td>
                                    <td>{empresa.estado}</td>
                                    <td>{empresa.municipio}</td>
                                    {empresa.createdAt ?
                                        <td>{moment(empresa.createdAt).format("DD/MM/YYYY")}</td>
                                        :
                                        <td></td>
                                    }
                                    <td>
                                        <a className="btn btn-sm btn-primary" onClick={e => handleEdit(empresa.id_empresa)}><FiIcons.FiEdit2 style={{color:"white"}}/></a>
                                        <a className="btn btn-sm btn-danger" style={{marginLeft:"5px"}} onClick={e => handleDelete(empresa._id)}><FiIcons.FiTrash2 style={{color:"white"}}/></a>
                                    </td>
                                </tr>
                            )) :
                            arrayEmpresas.map(({_id, id_empresa, cnpj, name, email, estado, municipio, createdAt}) => (
                                <tr key={_id}>
                                    <td>{id_empresa}</td>
                                    <td>{cnpj}</td>
                                    <td>{name}</td>
                                    <td>{email}</td>
                                    <td>{estado}</td>
                                    <td>{municipio}</td>
                                    {createdAt ?
                                        <td>{moment(createdAt).format("DD/MM/YYYY")}</td>
                                        :
                                        <td></td>
                                    }
                                    <td>
                                        <a className="btn btn-sm btn-primary" onClick={e => handleEdit(id_empresa)}><FiIcons.FiEdit2 style={{color:"white"}}/></a>
                                        <a className="btn btn-sm btn-danger" style={{marginLeft:"5px"}} onClick={e => handleDelete(_id)}><FiIcons.FiTrash2 style={{color:"white"}}/></a>
                                    </td>
                                </tr>
                            )) 
                        }
                            
                        </tbody>
                    </table> */}

                            
                </div>
            </Teste>
    
      
        {/*</div>*/}

        </PermissionGate>       
        </>
        

        );
    };
    
    export default ListEmpresa;