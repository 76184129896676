import React, { useState, useEffect } from 'react';
import { getAdicionaisCardapio } from "../../services/api";
import { Modal, Button } from 'react-bootstrap';
import { toast } from "react-toastify";
import { FaCheck } from 'react-icons/fa';

const ModalAdicionais = ({ show, onClose, item, idEmpresa, nomeEmpresaForUrl, updateCartItem, quantidades, setQuantidades, adicionaisCompletos, setAdicionaisCompletos, handleFinalizarAdicionais}) => {
    useEffect(() => {
        const fetchAdicionais = async () => {
            try {
                const response = await getAdicionaisCardapio(idEmpresa, nomeEmpresaForUrl);
                const adicionaisFromDB = response.data.adicionais;
                if (item.has_adicional && item.adicionais && adicionaisFromDB) {
                    const adicionaisEncontrados = adicionaisFromDB.filter(adicionalDB =>
                        item.adicionais.some(adicionalItem => adicionalItem.value === adicionalDB._id)
                    );
                    setAdicionaisCompletos(adicionaisEncontrados.map(adicional => ({
                        ...adicional,
                        quantidade: 0
                    })));
                }
            } catch (error) {
                console.error('Erro ao buscar adicionais:', error);
            }
        };
        fetchAdicionais();
    }, [idEmpresa, nomeEmpresaForUrl, item, setAdicionaisCompletos]);

    const handleIncrement = (idPai, id) => {
        setQuantidades(prev => {
            const maxTotal = adicionaisCompletos.find(adicional => adicional._id === idPai).max;
            const totalAtual = adicionaisCompletos.find(adicional => adicional._id === idPai).adicionais.reduce((acc, adicional) => acc + (prev[adicional._id] || 0), 0);

            if (totalAtual < maxTotal) {
                return { ...prev, [id]: (prev[id] || 0) + 1 };
            }
            return prev;
        });
    };

    const handleDecrement = (id) => {
        setQuantidades(prev => {
            if (prev[id] > 0) {
                return { ...prev, [id]: prev[id] - 1 };
            }
            return prev;
        });
    };

    const checkMandatoryAdicionais = () => {
        return adicionaisCompletos.every(grupo => {
            if (!grupo.mandatory) return true;
            const totalSelected = grupo.adicionais.reduce((acc, adicional) => acc + (quantidades[adicional._id] || 0), 0);
            return totalSelected >= grupo.min;
        });
    };

    /*const handleFinalizar = () => {
        if (!checkMandatoryAdicionais()) {
            toast("Selecione todos os adicionais obrigatórios e suas quantidades mínimas.", { autoClose: 2000, type: "warning" });
            return;
        }

        const selectedAdicionais = adicionaisCompletos.map(adicional => ({
            grupoId: adicional._id,
            grupoTitle: adicional.title,
            adicionais: adicional.adicionais.map(subAdicional => ({
                adicionalId: subAdicional._id,
                title: subAdicional.title,
                quantity: quantidades[subAdicional._id] || 0,
                price: subAdicional.price
            })).filter(subAdicional => subAdicional.quantity > 0)
        })).filter(adicional => adicional.adicionais.length > 0);

        handleFinalizarAdicionais(item, selectedAdicionais);
    };*/
    const handleFinalizar = () => {
        if (!checkMandatoryAdicionais()) {
            toast("Selecione todos os adicionais obrigatórios e suas quantidades mínimas.", { autoClose: 2000, type: "warning" });
            return;
        }
    
        const selectedAdicionais = adicionaisCompletos.map(adicional => ({
            grupoId: adicional._id,
            grupoTitle: adicional.title,
            calcular_maior_valor: adicional.calcular_maior_valor ?? false, // Inclua o campo calcular_maior_valor, garantindo que seja false se não estiver definido.
            adicionais: adicional.adicionais.map(subAdicional => ({
                adicionalId: subAdicional._id,
                title: subAdicional.title,
                quantity: quantidades[subAdicional._id] || 0,
                price: subAdicional.price
            })).filter(subAdicional => subAdicional.quantity > 0)
        })).filter(adicional => adicional.adicionais.length > 0);
    
        handleFinalizarAdicionais(item, selectedAdicionais);
    };

    const isSelected = (adicionalPai) => {
        const totalItems = adicionalPai.adicionais.reduce((acc, adicional) => {
            return acc + (quantidades[adicional._id] || 0);
        }, 0);
    
        return totalItems >= (adicionalPai.min || 1);
    };

    useEffect(() => {
        isRequirementMet();
    }, [quantidades]);  // Dependendo de quantidades para reavaliar quando mudar
    //console.log("adicionaisCompletos>>", adicionaisCompletos)
    const isRequirementMet = () => {
        return adicionaisCompletos.every(adicionalPai => {
            if (!adicionalPai.mandatory) {
                // Ignora se não for obrigatório
                return true;
            }
    
            if (adicionalPai.adicionais.length === 0) {
                // Considera atendido se não há itens adicionais (não deveria ocorrer se for obrigatório e tiver min > 0)
                return true;
            }
    
            // Calcula a soma total de itens adicionados no grupo obrigatório
            const totalItems = adicionalPai.adicionais.reduce((acc, adicional) => {
                return acc + (quantidades[adicional._id] || 0);
            }, 0);
    
            // Verifica se o total atende ao mínimo requerido, se houver um mínimo definido
            return adicionalPai.min > 0 ? totalItems >= adicionalPai.min : true;
        });
    };
    

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{item.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {adicionaisCompletos?.map((adicional, index) => (
                    <div key={adicional._id}>
                        <div  className="adicional-group">
                            <div className='childrenDivTitleAdicionalCardapio'>
                                <span>{adicional.title}</span>                                                         
                                {adicional.mandatory && (
                                    <>
                                        <label className={isSelected(adicional) ? "hidden" : "visible"}>Obrigatório</label>
                                        <FaCheck className={isSelected(adicional) ? "visible" : "hidden"} style={{ color: 'green', fontSize: '1.2em' }} />
                                    </>
                                )}                            
                            </div>   
                            <span className='textMandatoryQtd'>Escolha {adicional.mandatory && adicional.min>1 ? `no mínimo ${adicional.min},` : null} até {adicional.max===1?adicional.max+" opção.":adicional.max+" opções."}</span>                    
                            {adicional.adicionais.map(subAdicional => (
                                <div key={subAdicional._id} className="adicional-item">
                                    <span>{subAdicional.title} - R$ {subAdicional.price.toFixed(2).replace('.', ',')}</span>
                                    <div className="input-number">
                                        <button className="decrement-item" onClick={() => handleDecrement(subAdicional._id)}>
                                            -
                                        </button>
                                        <input type="number" value={quantidades[subAdicional._id] || 0} readOnly />
                                        <button className="increment-item" onClick={() => handleIncrement(adicional._id, subAdicional._id)}>
                                            +
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {index !== adicionaisCompletos.length - 1 && (
                            <div className='line' style={{marginTop:10}}></div>
                        )}
                    </div>
                ))}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Fechar
                </Button>
                <Button variant="primary" onClick={handleFinalizar}>
                    Incluir Adicionais
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalAdicionais;

