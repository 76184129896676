import React from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

const StyledSpan = styled.span`
  color: skyblue;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: #1095F3;
  }
`;

const ToastWithLink = ({ nomeEmpresaForUrl, idEmpresa, empresa, navigate }) => {
  const handleClick = () => {
    navigate(`/${nomeEmpresaForUrl}/${idEmpresa}/info-loja`, { state: { infoEmpresa: empresa } });
  };

  return (
    <div>
      Loja fechada no momento.{' '}
      <StyledSpan onClick={handleClick}>
        Clique aqui
      </StyledSpan> para ver os horários de funcionamento.
    </div>
  );
};

export default ToastWithLink;