import React, { useEffect, useState } from 'react';
import './styles.css';
import { GoogleMap, useJsApiLoader, Circle, Marker } from '@react-google-maps/api';

const libraries = ['places', 'geometry'];

const Map = ({ latitudeEmpresa, longitudeEmpresa, setLatitudeEmpresa, setLongitudeEmpresa }) => {
    const ApiKey = "AIzaSyDe-csDaMYbHHrjdzR-q-OyHvh04MfJnck";    

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        libraries: libraries,
        googleMapsApiKey: ApiKey,
        language: 'pt',
        region: 'BR'  
    });

    const mapContainerStyle = {
        height: "400px",
        width: "100%",
    };

    const [markerPosition, setMarkerPosition] = useState({
        lat: latitudeEmpresa,
        lng: longitudeEmpresa,
    });

    const center = {
        lat: markerPosition.lat,
        lng: markerPosition.lng,
    };

    const options = {
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: 1000,
        zIndex: 1,
    };

    const onLoad = (circle) => {
        // console.log('Circle onLoad circle: ', circle)
    };

    const onUnmount = (circle) => {
        // console.log('Circle onUnmount circle: ', circle)
    };

    const handleMarkerDragEnd = (event) => {
        const newLat = event.latLng.lat();
        const newLng = event.latLng.lng();
        setMarkerPosition({ lat: newLat, lng: newLng });
        setLatitudeEmpresa(newLat);
        setLongitudeEmpresa(newLng);
        // Aqui você deve chamar uma função para atualizar a latitude e longitude no seu banco de dados
        updateLocationInDatabase(newLat, newLng);
    };

    const updateLocationInDatabase = async (lat, lng) => {
        try {
            // Aqui você pode fazer a chamada para a API que atualiza o banco de dados
            // Exemplo: await api.put('/empresa/atualizar', { latitude: lat, longitude: lng });
            console.log('Atualizando no banco de dados:', { latitude: lat, longitude: lng });
        } catch (error) {
            console.error('Erro ao atualizar localização:', error);
        }
    };

    return (
        <div className='mapContainer'>
            {
                isLoaded ? (                   
                    <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        center={center}
                        zoom={14}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        options={{
                            className: 'custom-map',
                        }}
                    >
                        <Marker 
                            position={markerPosition} 
                            options={{
                                label: { 
                                    text: "Meu Estabelecimento",
                                    className: "mapMarker"
                                },
                                draggable: true,
                            }}
                            onDragEnd={handleMarkerDragEnd} // Chamado quando o usuário para de arrastar
                        />                    
                    </GoogleMap>
                ) : <></>
            }
        </div>
    );
};

export default Map;
