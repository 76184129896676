import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import imgTeste from '../../img/imgTeste.png'
import './styleMobile.css'
import CryptoJS from 'crypto-js';
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa";
import { toast } from "react-toastify";
import { FaRegTrashAlt } from "react-icons/fa";
import ModalAddObsItemCarrinho from "../../components/ModalAddObsItemCarrinho";

const Carrinho = () => {
    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    //const location = useLocation();
    const { nomeEmpresaForUrl, idEmpresa } = useParams();
    //const stateFromItemModal = location.state;
    //var fromItemAddModal = false;
    //if(stateFromItemModal !== null){
    //    fromItemAddModal = location.state.fromItemAddModal;
    //}   

    // Obtém o external_id da URL atual, se existir
    const [searchParams] = useSearchParams();
    const externalId = searchParams.get('external_id');

    const navigate = useNavigate();
    const handleBack = () => {
        if (externalId) {
            // Se external_id existir, inclui ele na navegação
            navigate(`/cardapio/${nomeEmpresaForUrl}/${idEmpresa}?external_id=${externalId}`);
        } else {
            // Caso contrário, navega sem external_id
            navigate(`/cardapio/${nomeEmpresaForUrl}/${idEmpresa}`);
        }
    };

    const [showModalAddObsItem, setShowModalAddObsItem] = useState(false);
    const [itensCarrinho, setItensCarrinho] = useState([]);
    const [carrinho, setCarrinho] = useState([]);
    const [currentEditingItem, setCurrentEditingItem] = useState(null);

    const showToastEmptyKart = () => {
        toast("Adicione itens ao carrinho para avançar", { autoClose: 3000, type: "warning" })
    }

    useEffect(() => {
        const itensCarrinhoTemp = JSON.parse(localStorage.getItem('itensCarrinho'));
        setItensCarrinho(itensCarrinhoTemp || []);
        setCarrinho(itensCarrinhoTemp || []);
    }, []);

    /*const handleDecrement = (id, index) => {
        setCarrinho(itens => {
            const itemToUpdate = itens[index];
            if (itemToUpdate.quantity === 1) {
                const filteredItems = itens.filter(item => item.id !== id);
                setItensCarrinho(filteredItems);
                localStorage.setItem('itensCarrinho', JSON.stringify(filteredItems));
                return filteredItems;
            } else {
                itemToUpdate.quantity--;
                const updatedItems = [...itens.slice(0, index), itemToUpdate, ...itens.slice(index + 1)];
                setItensCarrinho(updatedItems);
                localStorage.setItem('itensCarrinho', JSON.stringify(updatedItems));
                return updatedItems;
            }
        });
    }*/
        const handleDecrement = (id, index) => {
            setCarrinho(itens => {
                const itemToUpdate = itens[index];
        
                // Se a quantidade for 1, remove o item do carrinho.
                if (itemToUpdate.quantity === 1) {
                    const filteredItems = itens.filter(item => item.id !== id);
                    setItensCarrinho(filteredItems);
                    localStorage.setItem('itensCarrinho', JSON.stringify(filteredItems));
                    return filteredItems;
                } else {
                    // Decrementa a quantidade.
                    itemToUpdate.quantity--;
        
                    // Recalcula o novo total considerando o preço base do item e os adicionais.
                     // Descriptografa o valor do price
                    const decryptedPrice = CryptoJS.AES.decrypt(itemToUpdate.price, secretKey).toString(CryptoJS.enc.Utf8);
                    const valorItemBase = parseFloat(decryptedPrice); // Converte para número
                    //const valorItemBase = parseFloat(itemToUpdate.price); // Descriptografa se necessário.
                    const valorAdicionais = calcularValorAdicionais(itemToUpdate.grupos_adicionais_selecionados);
                    const newTotal = (valorItemBase + valorAdicionais) * itemToUpdate.quantity;
        
                    const encryptedTotal = CryptoJS.AES.encrypt(newTotal.toString(), secretKey).toString();
                    itemToUpdate.total = encryptedTotal;
        
                    const updatedItems = [...itens.slice(0, index), itemToUpdate, ...itens.slice(index + 1)];
                    setItensCarrinho(updatedItems);
                    localStorage.setItem('itensCarrinho', JSON.stringify(updatedItems));
                    return updatedItems;
                }
            });
        };

    const handleAvancar = () => {
        // Navega para a página de finalizar pedido, com ou sem o external_id
        navigate(
            externalId
                ? `/${nomeEmpresaForUrl}/${idEmpresa}/finalizar-pedido?external_id=${externalId}`
                : `/${nomeEmpresaForUrl}/${idEmpresa}/finalizar-pedido`
        );
    }

    const handleOpenModalAddObsItem = (item) => {
        setShowModalAddObsItem(true);
        //setCurrentEditingItemId(item.id); // Continua sendo útil se precisar desse ID para outras lógicas.
        const currentItem = itensCarrinho.find(i => i.id === item.id);
        setCurrentEditingItem(currentItem); // Armazenando diretamente o item que será editado.
    };

    /*const handleIncrement = (id, index) => {
        setCarrinho(itens => {
            const itemToUpdate = itens[index];
            itemToUpdate.quantity++;
            const updatedItems = [...itens.slice(0, index), itemToUpdate, ...itens.slice(index + 1)];
            setItensCarrinho(updatedItems);
            localStorage.setItem('itensCarrinho', JSON.stringify(updatedItems));
            return updatedItems;
        });
    }*/
        const handleIncrement = (id, index) => {
            setCarrinho(itens => {
                const itemToUpdate = itens[index];
                itemToUpdate.quantity++;
        
                // Recalcula o novo total considerando o preço base do item e os adicionais.
                 // Descriptografa o valor do price
                const decryptedPrice = CryptoJS.AES.decrypt(itemToUpdate.price, secretKey).toString(CryptoJS.enc.Utf8);
                const valorItemBase = parseFloat(decryptedPrice); // Converte para número
                //const valorItemBase = parseFloat(itemToUpdate.price); // Descriptografa se necessário.
                const valorAdicionais = calcularValorAdicionais(itemToUpdate.grupos_adicionais_selecionados);
                const newTotal = (valorItemBase + valorAdicionais) * itemToUpdate.quantity;
        
                const encryptedTotal = CryptoJS.AES.encrypt(newTotal.toString(), secretKey).toString();
                itemToUpdate.total = encryptedTotal;
        
                const updatedItems = [...itens.slice(0, index), itemToUpdate, ...itens.slice(index + 1)];
                setItensCarrinho(updatedItems);
                localStorage.setItem('itensCarrinho', JSON.stringify(updatedItems));
                return updatedItems;
            });
        };
        
        // Função para calcular o valor dos adicionais
        const calcularValorAdicionais = (gruposAdicionais) => {
            return gruposAdicionais.reduce((acc, grupo) => {
                if (grupo.calcular_maior_valor) {
                    const maxAdicional = grupo.adicionais.reduce((max, adicional) => {
                        return adicional.price > max ? adicional.price : max;
                    }, 0);
                    return acc + maxAdicional;
                } else {
                    return acc + grupo.adicionais.reduce((sum, adicional) => {
                        return sum + (adicional.price * adicional.quantity);
                    }, 0);
                }
            }, 0);
        };

    /*const obtemValorItem = (valorItemEncrypted, quantidade) => {
        const decryptedPrice = CryptoJS.AES.decrypt(valorItemEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
        const valorTotalItem = quantidade * parseFloat(decryptedPrice);
        let stringDescryptedPrice = valorTotalItem.toFixed(2).toString().replace('.', ',');
        return stringDescryptedPrice;
    }

    const calcularValorTotal = (itens) => {
        let total = 0;
        itens.forEach(item => {
            const valorItem = obtemValorItem(item.price, item.quantity);
            const valorItemFloat = parseFloat(valorItem.replace(',', '.'));
            total += valorItemFloat;
        });
        return total.toFixed(2).toString().replace('.', ',');
    }*/
        const obtemValorItem = (valorItemEncrypted, quantidade) => {
            const decryptedPrice = CryptoJS.AES.decrypt(valorItemEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
            const valorTotalItem = parseFloat(decryptedPrice);
            let stringDescryptedPrice = valorTotalItem.toFixed(2).toString().replace('.', ',');
            return stringDescryptedPrice;
        };

        const calcularValorTotal = (itens) => {
            let total = 0;
            itens.forEach(item => {
                console.log("item:",JSON.stringify(item))
                const decryptedTotal = parseFloat(CryptoJS.AES.decrypt(item.total, secretKey).toString(CryptoJS.enc.Utf8));
                total += decryptedTotal;
            });
            return total.toFixed(2).toString().replace('.', ',');
        };

    const clearItensCarrinho = () => {
        localStorage.removeItem('itensCarrinho');
        setItensCarrinho([]);
        setCarrinho([]);
    }

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" });
    }, []);

    return (

        <div className="flex flex-col max-w-sm mx-auto bg-white rounded-lg shadow-md overflow-hidden md:max-w-2xl">
            <div className="carrinhoHeader">
                <div className="backButtonCarrinho" onClick={() => handleBack()}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={28}
                        height={28}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="1.8"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-chevron-left"
                    >
                        <polyline points="15 18 9 12 15 6" />
                    </svg>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '85%' }}>
                    <span>Carrinho</span>
                    {carrinho.length >= 2 &&
                        <span className="limparAllItensCarrinho" onClick={() => clearItensCarrinho()}><FaRegTrashAlt fill="red" />Limpar</span>
                    }
                </div>
            </div>

            <div className="carrinhoContainer">

                <ModalAddObsItemCarrinho
                    currentItem={currentEditingItem}
                    showModalAddObsItem={showModalAddObsItem}
                    setShowModalAddObsItem={setShowModalAddObsItem}
                    itensCarrinho={itensCarrinho}
                    setItensCarrinho={setItensCarrinho}
                    setCurrentEditingItem={setCurrentEditingItem}
                />

                {
                    itensCarrinho && itensCarrinho.map((item, index) => (
                        <div style={{ marginBottom: "12px" }} key={item.id}>
                            <div className="itemNoCarrinho">
                                <div className="wrapItensCarrinho">
                                    <div className="rowItensStart">
                                        <div className="imgItemCarrinho">
                                            <img src={imgTeste}></img>
                                        </div>
                                        <div className="infoItemCarrinho">
                                            <span className="itemTitleCarrinho">{item.quantity}x {item.title}</span>
                                            <span className="itemPriceCarrinho">R$ {obtemValorItem(item.total, item.quantity)}</span>
                                            {item.grupos_adicionais_selecionados && item.grupos_adicionais_selecionados.map((grupoAdicional, index) => (
                                                <div className="containerAdicionalInfoCarrinho" key={index}>
                                                    <span>{grupoAdicional.grupoTitle}</span>
                                                    <div className="divAdicionalInfoCarrinho">
                                                        {grupoAdicional.adicionais.map((itemAdicional, index) => (
                                                            <span key={index}>
                                                                <a style={{ color: "#333" }}>
                                                                    {grupoAdicional.calcular_maior_valor
                                                                        ? itemAdicional.quantity > grupoAdicional.adicionais.length
                                                                            ? `${grupoAdicional.adicionais.length}/${grupoAdicional.adicionais.length}`
                                                                            : `${itemAdicional.quantity}/${grupoAdicional.adicionais.length}`
                                                                        : `x${itemAdicional.quantity}`
                                                                    }
                                                                </a>
                                                                {" "}
                                                                {itemAdicional.title}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="itemAdicionaisCarrinho"></div>
                                            <span className="itemObsCarrinho" onClick={() => handleOpenModalAddObsItem(item)}>
                                                Adicionar Observação
                                            </span>
                                        </div>
                                    </div>

                                    <div className="controlQtdItem">
                                        <div className="borderBoxQtdCarrinho">
                                            <div className="btnsForQtd">
                                                <div className="downAndCounter">
                                                    <button onClick={() => handleDecrement(item.id, index)}>
                                                        {item.quantity === 1 ?
                                                            <div className="trashIconCarrinho">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={18}
                                                                    height={18}
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="#808080"
                                                                    strokeWidth={2}
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    className="feather feather-trash-2"
                                                                >
                                                                    <polyline points="3 6 5 6 21 6" />
                                                                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                                                    <line x1={10} y1={11} x2={10} y2={17} />
                                                                    <line x1={14} y1={11} x2={14} y2={17} />
                                                                </svg>
                                                            </div>
                                                            :
                                                            <div className="trashIconCarrinho">
                                                                <FaMinus style={{ color: "grey", fontSize: "16px" }} />
                                                            </div>
                                                        }

                                                    </button>
                                                    <div className="counterQtdItemCarrinho">{item.quantity}</div>
                                                </div>
                                                <button className="btnAddCounter" onClick={() => handleIncrement(item.id, index)}>
                                                    <p><FaPlus style={{ fontSize: '20px' }} /></p>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }

                <div className="btnAdicionarItens">
                    <button onClick={() => handleBack()}>
                        <span>Adicionar mais itens</span>
                    </button>
                </div>
            </div>

            {carrinho.length > 0 ?
                <div className="avancarDetalhe">
                    <button onClick={() => handleAvancar()} className="btnAvancarDetalhe">
                        <p style={{ marginBottom: "0px" }}>Avançar</p>
                        <div className="btndata">R$ {calcularValorTotal(carrinho)}</div>
                    </button>
                </div>
                :
                <div className="avancarDetalhe">
                    <button className="btnAvancarDetalhe" style={{ background: "#eee", color: "#777" }} onClick={() => showToastEmptyKart()}>
                        <p style={{ marginBottom: "0px" }}>Avançar</p>
                        <div className="btndata" style={{ color: '#777' }}>R$ {calcularValorTotal(carrinho)}</div>
                    </button>
                </div>
            }

        </div>

    );
}

export default Carrinho;