import React, {useState, useContext} from 'react';
import imgLogoCliente from '../../img/logoBlue.png'
import imageUrl from '../../img/backgroundLogin.png'
import logoGoogle from '../../img/google_logo.png'
import './styles.css'

import { AuthContext } from '../../contexts/auth';

import { useNavigate } from "react-router-dom";	

const LoginPage = () =>{
    const { login } = useContext(AuthContext);

    const navigate = useNavigate();

    const handleClickRecuperar = (e) =>{
      e.preventDefault();
      console.log("submit Recuperar senha!");
    }

    const handleClickCadastro = () => {
      navigate('/informacoes');
    }

    const[email, setEmail] = useState("")
    const[password, setPassword] = useState("")

    const handleSubmit = (e) =>{
        e.preventDefault();
        console.log("submit!",{email, password});

        //integracao com contexto/api
        login(email, password);
    }

    return(
    
    <div className="container">

      {/*
      <div className="wrap-header">
        <img className="imgLogo" src={imgLogoCliente}></img>
        <div className="link-center">
          <a className="link1" href="google.com">Suporte</a>
          <a className="link2" href="google.com">Termos</a>
          <a className="link3" href="google.com">Privacidade</a>
        </div>
      </div>
      */}

      <div className="container-login"
      style={{background: `url(${imageUrl})no-repeat left`, backgroundSize: `cover`, backgroundHeight: `auto`,
      backgroundSize: '100% 100%',
      //border: 2px solid #e9385a;
      //marginLeft: '50%',
      //position: 'absolute',
      //zIndex: '0',
      }}
      >
        

        <div className="wrapContent" style={{width: '90%', height: '70%', background: 'white', boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.40)', borderRadius: 8, overflow: 'hidden', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex'}}>
          <div style={{width: '50%', alignSelf: 'stretch', background: '#F5F5F5', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 8, display: 'inline-flex'}} className='hide-on-mobile'>
            <div style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 24, display: 'flex'}}>
              <img style={{width: 321.70, height: 99.47}} src={imgLogoCliente} />
              <div style={{textAlign: 'center', color: '#074D82', fontSize: 32, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word'}}>Gerencie seus pedidos</div>
              <div style={{width: 347, textAlign: 'center', color: '#074D82', fontSize: 24, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word'}}>E automatize o seu atendimento de verdade</div>
            </div>
          </div>

          <div style={{flex: '1 1 0', alignSelf: 'stretch', justifyContent: 'center', alignItems: 'center', gap: 8, display: 'flex'}}>
            <div style={{width: 300, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 32, display: 'inline-flex'}}>
            <form className="login-form" onSubmit={handleSubmit}>
            
              {/*<span className="login-form-title">Bem Vindo!</span>
              <div className="login-form-title" style={{width: 300, height: 56, paddingLeft: 24, paddingRight: 24, paddingTop: 16, paddingBottom: 16, borderRadius: 16, border: '2px #074A80 solid', justifyContent: 'center', alignItems: 'center', gap: 12, display: 'inline-flex'}}>
                <div style={{width: 16, height: 16, paddingTop: 1, paddingBottom: 1.70, paddingLeft: 1.02, paddingRight: 1.67, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                  <img style={{width: 16, height: 16}} src={logoGoogle} />
                </div>
                <div style={{textAlign: 'center', color: '#074A80', fontSize: 14, fontFamily: 'Inter', fontWeight: '600', letterSpacing: 0.42, wordWrap: 'break-word'}}>Entrar com o Google</div>
              </div>*/}


              <div className="wrap-input">
                <input 
                  className={email !== "" ? 'has-val input' : 'input'} 
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
                <span className="focus-input" data-placeholder="E-mail"></span>
              </div>

              <div className="wrap-input">
                <input 
                  className={password !== "" ? 'has-val input' : 'input'} 
                  type="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
                <span className="focus-input" data-placeholder="Senha"></span>
              </div>

              <div className="container-login-form-btn">
                <button className="login-form-btn" type="submit">Login</button>
              </div>

              <div className="text-center">
                <span className="txt1">Esqueceu sua senha?</span>
                <a type="submit" className="txt2" onSubmit={handleClickRecuperar}>Recuperar.</a> {/* Com problema, ARRUMAR!! */}
              </div>

              <div style={{width: 300, height: 40, justifyContent: 'flex-start', alignItems: 'center', marginTop: 50, gap: 16, display: 'inline-flex'}}>
                <div style={{color: 'gray', fontSize: 14, fontFamily: 'Inter', fontWeight: '500', letterSpacing: 0.42, wordWrap: 'break-word'}}>Não tem uma conta?</div>
                <div style={{flex: '1 1 0', height: 40, paddingLeft: 16, paddingRight: 16, paddingTop: 8, paddingBottom: 8, borderRadius: 12,
                 border: '2px #074A80 solid', justifyContent: 'center', alignItems: 'center', gap: 12, display: 'flex', cursor:'pointer'}}
                 onClick={handleClickCadastro}
                >
                  <div style={{textAlign: 'center', color: '#074A80', fontSize: 14, fontFamily: 'Inter', fontWeight: '600', letterSpacing: 0.42, wordWrap: 'break-word'}}>Cadastre-se</div>
                </div>
              </div>

            </form> 

            </div>
          </div>

        </div>
            
      </div>
      
    </div>

    
    );
};

export default LoginPage;