import React, { useState, useContext, useEffect } from "react";
import './style.css'
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import { SidebarContext } from "../../AppRoutes";
import { AuthContext } from "../../contexts/auth";

import { getVendedor, deleteVendedor, getVendedores } from "../../services/api";

import PermissionGate from "../../services/PermissionGate";

import { useNavigate } from "react-router-dom";
import LeftMenu from "../../components/LeftMenu";
import ConfirmDialog from "../../components/ConfirmDialog";

import { toast } from "react-toastify";

import moment from 'moment';
import * as XLSX from 'xlsx';

import ModalEditVendedor from "../../components/ModalEditVendedor";

import * as FiIcons from 'react-icons/fi'
import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'
import * as FaIcons from 'react-icons/fa'


const Teste = styled.div`
    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;
    z-Index: 9;

    @media (max-width: 880px) {
        margin-left: 0;
    }
`;


const ListVendedor = ( {list = []} ) =>{
    const { user } = useContext(AuthContext);

    const navigate = useNavigate();

    const [vendedores, setVendedores] = useState([]);
    const [filter, setFilter] = useState('');
    const [result, setResult] = useState(false);

    const [order, setOrder] = useState(1)
    const [columnOrder, setColumnOrder] = useState('title')

    const { sidebar, setSidebar } = useContext(SidebarContext);;
    
    const [loading, setLoading] = useState(true);

    const [refresh, setRefresh] = useState(false);

    const [showEditVendedor, setEditVendedor] = useState(true);

    useEffect(() => {
        (async () => {
            const response = await getVendedores(user._id);
            //console.log("TESTEEE:::",user._id)
            //const teste = [response.data]
            //console.log(response.data)
            setVendedores(response.data.vendedor);
            setLoading(false);
            setRefresh(false);
        })();
    },[refresh]);
    

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [shouldDelete, setShouldDelete] = useState(false);
    const [idToDelete, setIdToDelete] = useState('');
    const handleDelete = (idRecebido) => {
        // Abre Componente de Confirmação do delete
        setConfirmOpen(true)

        setIdToDelete(idRecebido)
        if(idRecebido){
            //console.log("ID RECEBIDO:",idRecebido)
            setShouldDelete(true);
        }
    }
    const deleteReg = async () => {
        //console.log("O ID CLICADO FOI :::",idToDelete);
        //console.log("ID do Usuário que irá efetuar a exclusão", user._id);

        if(shouldDelete){
            deleteVendedor(idToDelete, user._id).then( cast => {
                //console.log("RESULT DELETE:",cast);
                if(cast.status===201){
                    toast(cast.data.msg,{ autoClose: 5000 , type: "success"});
                    setRefresh(true);
                }else{
                    toast(cast.data.msg,{ autoClose: 5000 , type: "error"});
                }
            }).catch( err => {
                //console.log("ERROR:",err);
                toast(err.response.data.msg,{ autoClose: 5000 , type: "error"});
            });
        }

    }


    const [_idVendedorEdit, set_idVendedorEdit] = useState('');
    const [documento, setDocumento] = useState('');
    const [nome, setNome] = useState('');   
    const [cepNumber, setCep] = useState('');
    const [estado, setEstado] = useState('');
    const [municipio, setMunicipio] = useState('');
    const [bairro, setBairro] = useState('');
    const [complemento, setComplemento] = useState('');
    const [telefone, setTelefone] = useState('');
    const [celular, setCelular] = useState('');
    const [tipo, setTipo] = useState('');
    const handleEdit = async idToEdit => {
        console.log("ID TO EDIT----",idToEdit);
        set_idVendedorEdit(idToEdit)  
        setEditVendedor(!showEditVendedor);
        getVendedor(user._id, idToEdit).then(
            response => {
                if(showEditVendedor){        
                    response.data.vendedor.documento ? setDocumento(response.data.vendedor.documento) : setDocumento('');  
                    response.data.vendedor.name ? setNome(response.data.vendedor.name) : setNome('');                    
                    response.data.vendedor.cep ? setCep(response.data.vendedor.cep) : setCep('');
                    response.data.vendedor.estado ? setEstado(response.data.vendedor.estado) : setEstado('');
                    response.data.vendedor.municipio ? setMunicipio(response.data.vendedor.municipio) : setMunicipio('');
                    response.data.vendedor.bairro ? setBairro(response.data.vendedor.bairro) : setBairro('');
                    response.data.vendedor.complemento ? setComplemento(response.data.vendedor.complemento) : setComplemento('');
                    response.data.vendedor.telefone ? setTelefone(response.data.vendedor.telefone) : setTelefone('');
                    response.data.vendedor.celular ? setCelular(response.data.vendedor.celular) : setCelular('');
                    response.data.vendedor.type ? setTipo(response.data.vendedor.type) : setTipo('');
                    console.log("response.data.vendedor.type:",response.data.vendedor);                    
                }
            }
        ).catch(err => {
            console.log("ERROR:",err);
        })
    
    }


    const handleRefresh = () => {
        //window.location.reload(false);
        setLoading(true);
        setRefresh(true);
    }

    const handleOrder = fieldName => {
        setOrder(-order)
        setColumnOrder(fieldName)
        //console.log("order:",order);
        //console.log("fieldName",fieldName)
    }

    var arrayVendedores = [];
    arrayVendedores = vendedores.sort( (a, b) => {
        return a[columnOrder] < b[columnOrder] ? -order : order;
    })

    
    if (filter){
        const exp = eval(`/${filter.replace(/[^\d\w]+/,'.*')}/i`)

        list = vendedores.filter( item => exp.test( item.documento ) )       

        if(list.length==0){
            list = vendedores.filter( item => exp.test( addZeroes(item.id_vendedor) ) )
        }
        //console.log(list.length)
        if(list.length==0){
            list = vendedores.filter( item => exp.test( item.name ) )
        }
        // if(list.length==0){
        //     list = revendas.filter( item => exp.test( item.revenda_email ) )
        //     //setResult(true);
        // }
        /*if(list.length==0){
            list = empresas.filter( item => exp.test( item.celular ) )
        }*/
    }

    const handleFilter = e => {
        setFilter(e.target.value);
        setResult(!result);
    }

    const handleCadastro = () => {
        navigate("/cadastro-vendedor");
    }

    const handleExport = () => {
        console.log(vendedores)
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(vendedores);

        XLSX.utils.book_append_sheet(wb, ws, "Vendedores");
        XLSX.writeFile(wb, "Vendedores.xlsx");
    }

    function addZeroes(num, len=6) {
        var numberWithZeroes = String(num);
        var counter = numberWithZeroes.length;
          
        while(counter < len) {        
            numberWithZeroes = "0" + numberWithZeroes;        
            counter++;    
        }
      
      return numberWithZeroes;
    }

    if(loading){
        return(

            <>
            <PermissionGate permissions={['viewCVO']}>
            <div className="loading"><AiIcons.AiOutlineLoading3Quarters style={{fontSize:"100px", color:"rgb(180,180,180)"}}/></div>
            

                <LeftMenu setSidebar={setSidebar} sidebar={sidebar}></LeftMenu>

                <Teste sidebar={sidebar}>

                    <div className="m-5">
                        <div className="list-header">
                            <div className="title">
                                <h1>Vendedores</h1>
                            </div>
                        </div>


                        <div style={{display:"flex", justifyContent:"space-between"/*, height:"80px"*/}}>
                            <div className="input-box-list" style={{width:"30%"}}>                
                                <input className="input-field" placeholder="Pesquisar" onChange={handleFilter}/>
                                <i className="icon"><FiIcons.FiSearch/></i>
                            </div>

                            <div className="div-buttons">
                                <div className="export-button">                           
                                    <button onClick={handleExport}>
                                        <AiIcons.AiOutlineFileExcel style={{marginRight: "5px",fontSize: "22px",marginBottom: "2px"}}/><a>Exportar</a> 
                                    </button>                            
                                </div>        

                                <div className="continue-button">                           
                                    <button onClick={handleCadastro}>
                                        <AiIcons.AiOutlinePlusCircle style={{marginRight: "5px",fontSize: "22px",marginBottom: "2px"}}/><a>Adicionar</a> 
                                    </button>                            
                                </div>

                                <div className="refresh-button">                                                       
                                    <button className="refresh-button" onClick={handleRefresh}>
                                        <SlIcons.SlRefresh style={{color:"#4281FF",marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/><a >Atualizar</a> 
                                    </button>
                                </div>
                            </div>

                        </div>

                        <div className={result?"table result":"table"} style={{background:"white"}}>
                            <table className={"table"}>
                                <thead>
                                    <tr>
                                        <th scope="col">Código</th>
                                        <th scope="col">CNPJ/CPF</th>
                                        <th scope="col">Nome</th>
                                        <th scope="col">Estado</th>
                                        <th scope="col">Município</th>
                                        <th scope="col">Data Inclusão</th>
                                        <th scope="col" style={{paddingLeft:"35px"}}>Ações</th>
                                    </tr>
                                </thead>

                                <tbody>                                                                                                      
                                </tbody>

                            </table>

                        </div>
                                
                    </div>
                </Teste>
        
        
            {/*</div>*/}

            </PermissionGate>       
            </>

        );
    }

    return(

        <>
        <PermissionGate permissions={['viewCVO']}>
        {/*<div className="container">*/}

            <LeftMenu setSidebar={setSidebar} sidebar={sidebar}></LeftMenu>

            <ConfirmDialog
                title="Deletar Vendedor?"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={deleteReg}
            >
                Tem certeza que deseja deletar o Vendedor?
            </ConfirmDialog>

            <ModalEditVendedor setEditVendedor={setEditVendedor} showEditVendedor={showEditVendedor} 
            setRefresh={setRefresh}
            _idVendedorEdit={_idVendedorEdit}      
            documento={documento}
            nome={nome}            
            cepNumber={cepNumber}
            setCep={setCep}
            estado={estado}
            setEstado={setEstado}
            municipio={municipio}
            setMunicipio={setMunicipio}
            bairro={bairro}
            setBairro={setBairro}
            complemento={complemento}
            setComplemento={setComplemento}
            telefone={telefone}
            setTelefone={setTelefone}
            celular={celular}
            setCelular={setCelular}
            tipo={tipo}
            />

            <Teste sidebar={sidebar}>

                <div className="m-5">
                    <div className="list-header">
                        <div className="title">
                            <h1>Vendedores</h1>
                        </div>
                    </div>


                    <div style={{display:"flex", justifyContent:"space-between"/*, height:"80px"*/}}>
                        <div className="input-box-list" style={{width:"30%"}}>                
                            <input className="input-field" placeholder="Pesquisar" onChange={handleFilter}/>
                            <i className="icon"><FiIcons.FiSearch/></i>
                        </div>

                        <div className="div-buttons">
                            <div className="export-button">                           
                                <button onClick={handleExport}>
                                    <AiIcons.AiOutlineFileExcel style={{marginRight: "5px",fontSize: "22px",marginBottom: "2px"}}/><a>Exportar</a> 
                                </button>                            
                            </div>        

                            <div className="continue-button">                           
                                <button onClick={handleCadastro}>
                                    <AiIcons.AiOutlinePlusCircle style={{marginRight: "5px",fontSize: "22px",marginBottom: "2px"}}/><a>Adicionar</a> 
                                </button>                            
                            </div>

                            <div className="refresh-button">                                                       
                                <button className="refresh-button" onClick={handleRefresh}>
                                    <SlIcons.SlRefresh style={{color:"#4281FF",marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/><a >Atualizar</a> 
                                </button>
                            </div>
                        </div>

                    </div>
                  
                    <table className={"table"}>
                        <thead>
                            <tr>
                                <th scope="col" onClick={ e => handleOrder('id_vendedor')} style={{cursor:"pointer"}}>Código<FaIcons.FaSort/></th>
                                <th scope="col" onClick={ e => handleOrder('documento')} style={{cursor:"pointer"}}>CPF/CNPJ<FaIcons.FaSort/></th>
                                <th scope="col" onClick={ e => handleOrder('name')} style={{cursor:"pointer"}}>Nome<FaIcons.FaSort/></th>                                
                                <th scope="col" onClick={ e => handleOrder('estado')} style={{cursor:"pointer"}}>Estado<FaIcons.FaSort/></th>
                                <th scope="col" onClick={ e => handleOrder('municipio')} style={{cursor:"pointer"}}>Município<FaIcons.FaSort/></th>
                                <th scope="col" onClick={ e => handleOrder('createdAt')} style={{cursor:"pointer"}}>Data Inclusão<FaIcons.FaSort/></th>
                                <th scope="col" style={{paddingLeft:"15px"}}>Ações</th>
                            </tr>
                        </thead>

                        <tbody className={result?"result":"result search"}>
                            
                        {
                        filter ? 
                            list.map((vendedor) => (
                                <tr key={vendedor._id}>
                                    <td>{addZeroes(vendedor.id_vendedor)}</td>
                                    <td>{vendedor.documento}</td>
                                    <td>{vendedor.name}</td>                          
                                    <td>{vendedor.estado}</td>
                                    {/* Só mostra data se existir */}
                                    {vendedor.createdAt ?
                                        <td>{moment(vendedor.createdAt).format("DD/MM/YYYY")}</td>
                                        :
                                        <td></td>
                                    }
                                    <td>
                                        <a className="btn btn-sm btn-primary" onClick={e => handleEdit(vendedor.id_vendedor)}><FiIcons.FiEdit2 style={{color:"white"}}/></a>
                                        <a className="btn btn-sm btn-danger" style={{marginLeft:"5px"}} onClick={e => handleDelete(vendedor._id)}><FiIcons.FiTrash2 style={{color:"white"}}/></a>
                                    </td>
                                </tr>
                            )) :
                            arrayVendedores.map(({_id, id_vendedor, documento, name, estado, municipio, createdAt}) => (
                                <tr key={_id}>
                                    <td>{addZeroes(id_vendedor)}</td>
                                    <td>{documento}</td>
                                    <td>{name}</td>                         
                                    <td>{estado}</td>
                                    <td>{municipio}</td>
                                    {/* Só mostra data se existir */}
                                    {createdAt ?
                                        <td>{moment(createdAt).format("DD/MM/YYYY")}</td>
                                        :
                                        <td></td>
                                    }
                                    <td>
                                        <a className="btn btn-sm btn-primary" onClick={e => handleEdit(id_vendedor)}><FiIcons.FiEdit2 style={{color:"white"}}/></a>
                                        <a className="btn btn-sm btn-danger" style={{marginLeft:"5px"}} onClick={e => handleDelete(_id)}><FiIcons.FiTrash2 style={{color:"white"}}/></a>
                                    </td>
                                </tr>
                            )) 
                        }
                            
                        </tbody>


                    </table>

                            
                </div>
            </Teste>
    
      
        {/*</div>*/}

        </PermissionGate>       
        </>
        

        );
    };
    
    export default ListVendedor;