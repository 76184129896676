import styled from "styled-components";
import React from 'react'

import PermissionGate from "../../services/PermissionGate";

import QRCode from 'qrcode.react';
import * as AiIcons from 'react-icons/ai';

import { AuthContext } from "../../contexts/auth";
import { useEffect, useContext, useState } from "react";

import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from "react-router-dom";	

const DivContainer = styled.div`
  z-index:15;
  position: fixed;
  top: 0;
  left: 0;
  display: ${({ showLinkCardapio }) => (showLinkCardapio ? 'flex' : 'none' )};
  float: left;
  min-width: 100%;
  min-height: 100%;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 5px 0;
  list-style: none;
  background-color: rgba(0,0,0,0.5);
  background-clip: padding-box;
  align-items:center;
  justify-content:center;
  opacity: ${({ showLinkCardapio }) => (showLinkCardapio ? '1' : '0' )};
  transition: opacity .3s ease-in-out;

  // Add keyframes for the fade-in effect
  animation: fadeIn .3s ease-in-out;
  animation-fill-mode: forwards;

  // Define the keyframes for the animation
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const DivWhiteBG = styled.div`
    z-index:20;
    position: absolute;
    top: 15%;
    left: 30%;
    float: left;
    min-width: 40%;
    min-height: 45%;
    max-width: 50%;
    padding: 5px 0;
    list-style: none;
    --background-color: white;
    background-color:rgb(247,247,247)!important;
    background-clip: padding-box;
    border-radius: 15px;
    padding:20px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;

`;


const CloseButton = styled(AiIcons.AiOutlineCloseCircle)`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
`;

const QRCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  padding: 20px;
  border: 2px dashed gray;
  border-radius: 10px;
  width: 80%;
  p{
    margin-top: 10px;
    font-size: 12px;
    text-align: center;
    margin-bottom: 0px!important;
  }
`;

// Substitua pelos estilos do botão que você deseja.
const Button = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  margin: 10px 0;
  cursor: pointer;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const DivLink = styled.div`
    display: flex;
    background-color: lightblue;
    color: #000;
    padding: 20px;
    border-radius: 10px;
`;

const BtnDiv = styled.div`
    display: flex;
    justify-content: space-between;
    gap:20px;
`;

const ModalLinkCardapio = ({setShowLinkCardapio, showLinkCardapio,
    nomeEmpresaForUrl,
}) => {
    
    const { user } = useContext(AuthContext);
    const empresa = localStorage.getItem('empresa')
    const empresaParse = JSON.parse(empresa)
    const empresaObjId = empresaParse._id;
    const idEmpresa = empresaParse.id_empresa

    // Substitua 'yourLink' pelo link que você quer mostrar e 'yourQRCodeValue' pelo valor do QR code
    const hostname = window.location.hostname === 'localhost' ? 'http://localhost:3000' : `https://${window.location.hostname}`;
    const yourLink = '/cardapio/' + nomeEmpresaForUrl + '/' + idEmpresa;


    const closeShowLinkCardapio = () => setShowLinkCardapio(!showLinkCardapio);

    const copyToClipboard = async (text) => {
        try {
          await navigator.clipboard.writeText(text);
          toast.success("Link copiado!");
        } catch (err) {
          toast.error("Falha ao copiar o link.");
        }
    };

    return (
        <DivContainer showLinkCardapio={showLinkCardapio}>
            <DivWhiteBG>
               
                <CloseButton onClick={() => closeShowLinkCardapio()} />
                <h2>O link do seu Cardápio Digital</h2>
                <p>Copie o link e cole onde quiser compartilhar!</p>
                <LinkContainer>
                    <DivLink>
                        <span>{hostname + yourLink}</span>                        
                    </DivLink>
                    <BtnDiv>
                        <Button onClick={() => window.open(yourLink, '_blank')}>Abrir cardápio</Button>
                        <Button onClick={() => copyToClipboard(hostname+yourLink)}>Copiar</Button>   
                    </BtnDiv>                 
                </LinkContainer>                
                <QRCodeContainer>
                    <QRCode value={hostname+yourLink} size={128} level={"H"} />
                    <p>Escaneie o QR Code abaixo com o seu celular e faça seu pedido</p>
                </QRCodeContainer>

            </DivWhiteBG>
        </DivContainer>
    )
}

export default ModalLinkCardapio;