import { ConfigProvider } from "antd";
import AppRoutes from "./AppRoutes";
import { ToastContainer } from "react-toastify";

function App() {

  return (

    <div translate="no" className='app'>
      <ToastContainer position="top-center"/>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerBg: 'rgb(66, 129, 255)',
              headerBorderRadius: '12',
              headerColor: 'white'
            },
          },
        }}
      >
          <AppRoutes/>
      </ConfigProvider>
    </div>

  );
}

export default App;
