import React, { useState, useRef, useEffect, useContext } from "react";
import { useLocation } from 'react-router-dom';
import './style.css'
import styled from 'styled-components';
//import InputMask from 'react-input-mask'
//import CurrencyInput from 'react-currency-input-field';
import ImageUploading from 'react-images-uploading';
import { CImage } from '@coreui/react'
//import Image from 'react-image'
//import Gallery from 'react-image-gallery'
import CryptoJS from 'crypto-js';
import AsyncSelect from 'react-select/async';
import { AuthContext } from "../../contexts/auth";
import { SidebarContext } from "../../AppRoutes";
import { getAdicionais, getCategorias, getItem, updateItens } from "../../services/api";
import ModalAddGroupAdicionais from "./ModalAddGroupAdicionais";
import PermissionGate from "../../services/PermissionGate";

import { useNavigate } from "react-router-dom";
import LeftMenu from "../../components/LeftMenu";

import InputMoney from "./InputMoney";

import { useFormik } from "formik";
import * as Yup from "yup";

import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'
import { MdFastfood } from "react-icons/md";
import { BsPlusCircle } from "react-icons/bs";
import { FaTrash } from 'react-icons/fa'
import { IoArrowRedo } from "react-icons/io5";
import { IoArrowUndo } from "react-icons/io5";
import { toast } from "react-toastify";
import { IoIosAddCircleOutline } from "react-icons/io";
import Input from "./Input";

const Teste = styled.div`

    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;
    z-Index: 9;

    @media (max-width: 880px) {
        margin-left: 0;
    }
`;


const EditItem = () =>{
    const location = useLocation();
    const { itemObjId } = location.state || {};

    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const userEncrypted = localStorage.getItem('user')
    const user = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
    const userParse = JSON.parse(user)
    const userID = userParse._id;
    const userName = userParse.name;
    const userEmail = userParse.email;
    var userImg = null;
    //console.log(empresa)
    const empresa = localStorage.getItem('empresa')
    const empresaParse = JSON.parse(empresa)
    const idEmpresa = empresaParse.id_empresa;
    const objIdEmpresa = empresaParse._id;
    const vinculo_empresa = empresaParse.cnpj;

    const [withAdicional, setWithAdicional] = useState(false);
    const [etapaAtiva, setEtapaAtiva] = useState(1);
    const descriptionRef = useRef();
    const { sidebar, setSidebar } = useContext(SidebarContext);; //TESTANDO CONST NO AppROUTES
    const [orderItem, setOrderItem] = useState(0);
    const [valuePreco, setValuePreco] = useState(0);
    const [showModalAddGroupAdd, setShowModalAddGroupAdd] = useState(true);

    const [imagesToString, setImagesToString] = React.useState("");
    const [selectedOption, setSelectedOption] = useState("");
    const [selectedDays, setSelectedDays] = useState([]);
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [diasHorarios, setDiasHorarios] = useState([
        {
            dias: new Set(),
            startTime: "00:00",
            endTime: "23:59",
        }
    ]);

    const [item, setItem] = useState({})

    const INITIAL_DATA = {
        value: "",
        label: 'Selecione a categoria',
    };
    const [selectData, setselectData] = useState(INITIAL_DATA);

 
    // Inicializa como um array vazio para múltiplas seleções
    const [selectDataAdicionais, setselectDataAdicionais] = useState([]);

    const mapResponseToValuesAndLabels = (data) => ({
        value: data._id,
        label: data.title,
    });

    async function callApi(value) {
    
        const data = await getCategorias(userID, idEmpresa, vinculo_empresa, objIdEmpresa)
        .then((response) => response.data.categorias)//response.json())
        .then((response) => response.sort((a, b) => a.order - b.order )) // Adicione esta linha para ordenar os dados
        .then((response) => response.map(mapResponseToValuesAndLabels))
        .then((response) => response.filter((i) => i.label.toLowerCase().includes(value.toLowerCase())) );
  
        return data;
    }

    // Função de chamada de API ajustada, se necessário
    async function callApiAdicionais(value) {
        const data = await getAdicionais(userID, idEmpresa, vinculo_empresa, objIdEmpresa)
        .then((response) => response.data.adicionais)
        .then((response) => response.map(mapResponseToValuesAndLabels))
        .then((response) => response.filter((i) => i.label.toLowerCase().includes(value.toLowerCase())));

        return data;
    }


    const [errImg, setErrImg] = React.useState(false);
    const [images, setImages] = React.useState([]);
    const maxNumber = 1;
    const maxMbFileSize = 2 * 1024 * 1024; // 2Mb

    /*const onChangeImg = (imageList, addUpdateIndex) => {
        // data for submit
        console.log(imageList, addUpdateIndex);
        setImages(imageList);
    };*/
    // Assuming 'onChangeImg' is your handler for updating images

    const onChangeImg = (imageList, addUpdateIndex) => {
        console.log(imageList, addUpdateIndex);
        setImages(imageList);
        // Update the images state with the new list of images
        // If you need to immediately trigger updates to the backend when images change,
        // you can include that logic here, similar to what you might have in your form submission handler.
        // For example:
        const imagesToString = imageList.length > 0 ? imageList.map(image => image.data_url).join(", ") : "";
        // You can then use imagesToString to update the database or handle it during form submission[]
        setImagesToString(imagesToString);
    };

    /*const handleChangeValuePreco = (event) => {
        const inputValue = event.target.value.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        setValuePreco(inputValue);
        if (event.onChange) {
            event.onChange(parseFloat(inputValue.replace(/,/g, '')));
        }
    };*/



    useEffect(() => {
        (async () => {
                   
            //console.log("itemObjId>",itemObjId);
            const response = await getItem(userID, idEmpresa, vinculo_empresa, objIdEmpresa, itemObjId)
            console.log(response.data.item);
            setItem(response.data.item);
            if (response.data.item) {
                setselectData({
                    value: response.data.item.category_item_id,
                    label: response.data.item.category_item_title,
                })
                if(response.data.item.adicionais.length>0){
                    const mappedAdicionais = response.data.item.adicionais.map(adicional => ({
                        value: adicional.value,
                        label: adicional.label,
                    }));
                    setselectDataAdicionais(mappedAdicionais);
                    setWithAdicional(true);
                }
                
                setOrderItem(response.data.item.order)
                setValueDescricao(response.data.item.description)
                setValuePreco(response.data.item.price)
                console.log("response.data.item.image>",response.data.item.image)
                // Verifica se a imagem é uma string (URL) e a transforma em um array
                console.log(typeof response.data.item.image)
                const imagesFromDb = response.data.item.image; // This should be your code to get images from DB
                // Assuming imagesFromDb is a string URL or an empty string
                const imagesArray = imagesFromDb === "" ? [] : [{ data_url: imagesFromDb }];
                setImages(imagesArray);
                setImagesToString(imagesFromDb);
                //const imagesArray = typeof response.data.item.image === 'string' ? [{ data_url: response.data.item.image }] : response.data.item.image;
                //setImages(imagesArray);
                formik.setValues({
                  vinculo_categoria: response.data.item.category_item_id,
                  title: response.data.item.title,
                  preco: response.data.item.price,
                });
              }
            //const teste = [response.data]
            //setLoading(false);
            //console.log("diasHorarios",diasHorarios)
            //console.log("horariosUnicos>",horariosUnicos)
            
        })();
    },[]);

    //Validações
    const SignUpSchema = Yup.object().shape({
        //email: Yup.string().email("Insira um e-mail válido").required("Obrigatório"),
        //cnpj: Yup.string().required("Obrigatório"),
        title: Yup.string().required("Obrigatório"),
        vinculo_categoria: !selectData.value && Yup.string().required("Obrigatório"),
        //disponibilidade: Yup.string().required("Obrigatório"),
    });

    const formik = useFormik({
        validationSchema: SignUpSchema,
        validateOnBlur: false,
        validateOnChange: false,
        initialValues: {
            vinculo_categoria: "",
            title: "",        
            preco: 0,  
        },
        onSubmit: (values) => {
          //alert(JSON.stringify(values, null, 2));
          console.log(values);
          
          //const description = descriptionRef.current.value;
          const description = valueDescricao;
          const title = values.title;
          const price = values.preco;
          //handleSaveImg();
          if(withAdicional && selectDataAdicionais.length===0){
            toast("Selecione os adicionais", {autoClose: 3000, type: "error"});
            return;
          }

          handleSubmitAPI(title, description, price);
          
        },
    });

    const navigate = useNavigate();

    const handleBack = () => {
        //setSidebar(!sidebar)
        navigate("/list-item");
    }


    const handleSubmitAPI = (title, description, price) =>{
        //e.preventDefault();
        
        const createdBy = userID;
        const out = false;
        const category_item_id = selectData.value;
        const category_item_title = selectData.label;
        const id_item = item.id_item;
        const has_adicional = withAdicional;
        const adicionais = selectDataAdicionais.length>0 ? selectDataAdicionais.map((adicional) => adicional) : [];
        // Assuming images is an array of objects, each with a 'data_url' key
        //const imagesToString = images.length > 0 ? images.map(image => image.data_url).join(", ") : "";
        console.log("DEU SUBMIT")

        //Registrando o item
        console.log("submit update item!",{createdBy, itemObjId, id_item, idEmpresa, orderItem, category_item_id, category_item_title, title, description, out, imagesToString, price, has_adicional, adicionais});
        updateItens(createdBy, itemObjId, id_item, idEmpresa, orderItem, category_item_id, category_item_title, title, description, out, imagesToString, price, has_adicional, adicionais).then(cast => {
            if(cast.status===200){
                toast(cast.data.msg, {autoClose: 5000, type: "success"});
                navigate("/list-item");
            }
            else {
                toast(cast.data.msg, {autoClose: 5000, type: "error"});
            }
            
        }).catch(err => {
            console.log('Oh noooo!!');
            const msg = err.response.data.msg;
            //console.log("MENSAGEM", msg)
            toast(msg, {autoClose: 5000, type: "error"});
            
        });
        
    }


    const [valueDescricao, setValueDescricao] = useState('');

    const handleMaxCharDescricao = (e) => {
        const newValue = e.target.value;
        if (newValue.length <= 1000) {
        setValueDescricao(newValue);
        }
    };

    //const [horariosToDelete, setHorariosToDelete] = useState("");
    const handleDeleteHorario = (horarioIndex) => {
        setDiasHorarios((prevDiasHorarios) => {
          const newDiasHorarios = [...prevDiasHorarios];
          newDiasHorarios.splice(horarioIndex, 1);
          return newDiasHorarios;
        });
    };

    const handleOptionChange = (event) => {     
        if (event.target.value==="sempre"||event.target.value==="pausado"){
            setDiasHorarios([
                {
                  dias: new Set([""]),
                  startTime: "00:00",
                  endTime: "23:59",
                }
            ]);
        }
        setSelectedOption(event.target.value);
    };

    const horariosPorDia = diasHorarios.reduce((acc, { dias, startTime, endTime }) => {
        dias.forEach((dia) => {
          if (!acc[dia]) {
            acc[dia] = {
              dia,
              startTime,
              endTime,
            };
          } else {
            const { startTime: startTimeAcc, endTime: endTimeAcc } = acc[dia];
            const [startTimeHour, startTimeMin] = startTime.split(':').map(Number);
            const [startTimeAccHour, startTimeAccMin] = startTimeAcc.split(':').map(Number);
            const [endTimeHour, endTimeMin] = endTime.split(':').map(Number);
            const [endTimeAccHour, endTimeAccMin] = endTimeAcc.split(':').map(Number);
    
            if (startTimeHour < startTimeAccHour || (startTimeHour === startTimeAccHour && startTimeMin < startTimeAccMin)) {
              acc[dia].startTime = startTime;
            }
    
            if (endTimeAccHour < endTimeHour || (endTimeAccHour === endTimeHour && endTimeAccMin < endTimeMin)) {
              acc[dia].endTime = endTime;
            }
          }
        });
    
        return acc;
    }, {});

    const onChange = (event, nameObject, isNumber) => {
        setValuePreco(isNumber ? Number(event.target.value) : event.target.value);
        formik.setFieldValue("preco", isNumber ? Number(event.target.value) : event.target.value );
    };
    
    const horariosUnicos = Object.values(horariosPorDia);
    
    const handleDayChange = (horarioIndex, key) => {
        const newDiasHorarios = [...diasHorarios];
        const horario = newDiasHorarios[horarioIndex];
      
        if (horario.dias.has(key)) {
          horario.dias.delete(key);
        } else {
          horario.dias.add(key);
        }
      
        setDiasHorarios(newDiasHorarios);
    };
    
    const validateTime = (value) => {
        const regex = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/;
        return regex.test(value);
    };
    const handleTimeChange = (horarioIndex, setTime, event) => {
        const value = event.target.value;
        if (validateTime(value)) {
          const newHorarios = [...diasHorarios];
          newHorarios[horarioIndex][setTime] = value;
          setDiasHorarios(newHorarios);
        }
    };
    
    const handleAddHorario = () => {
        setDiasHorarios(diasHorarios.concat({
            dias: new Set(),
            startTime: "00:00",
            endTime: "23:59",
        }));
    };

    const isDayAlreadySelectedInHorario = (day, currentHorarioIndex) => {
        return diasHorarios
          .filter((horario, index) => index !== currentHorarioIndex)
          .some((horario) => horario.dias.has(day));
    };

    const [isFocused, setIsFocused] = useState(false);
    const handleFocus = () => {
        setIsFocused(true);
    };
    
    const handleBlur = () => {
        setIsFocused(false);
    };
      

    return(

        <>
        <PermissionGate permissions={['default']}>
    

            <LeftMenu setSidebar={setSidebar} sidebar={sidebar}/>

            <ModalAddGroupAdicionais showModalAddGroupAdd={showModalAddGroupAdd} 
            setShowModalAddGroupAdd={setShowModalAddGroupAdd}
            selectDataAdicionais={selectDataAdicionais} setselectDataAdicionais={setselectDataAdicionais}
            />

            <Teste sidebar={sidebar}>
                <div className="w-100 p-5">
                    {/*<form onSubmit={handleSubmitButton}>*/}
                    <form onSubmit={formik.handleSubmit} >
                    
                        <div className="form-header" >
                            <div className="title">
                                <h1>Editar Item</h1>
                            </div>
                        </div>

                        <div style={{display:"flex", justifyContent:"right"/*, height:"80px"*/}}>

                            <div className="div-buttons">
                                <div className="continue-button">                           
                                    <button type="submit">
                                        <AiIcons.AiOutlineSave style={{marginRight: "5px",fontSize: "22px",marginBottom: "2px"}}/><a>Salvar</a> 
                                    </button>                            
                                </div>

                                <div className="back-button">                                                       
                                    <button onClick={handleBack}>
                                        <SlIcons.SlActionUndo style={{color:"#ff4c4c",marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/><a >Voltar</a> 
                                    </button>
                                </div>
                            </div>

                        </div>

                        <div className="contentItemComplete flex-column flex-lg-row">
                            <div className="input-group inputGroup-etapasItem w-100"> 
                                <div className={etapaAtiva===1?"etapasAddItem etapaAtiva":"etapasAddItem"} style={{borderBottom:'1px solid lightgray'}}>
                                    <label onClick={()=> setEtapaAtiva(1)}>1. Item</label>
                                </div> 
                                <div className={etapaAtiva===2?"etapasAddItem etapaAtiva":"etapasAddItem"} style={{borderBottom:'1px solid lightgray'}}>
                                    <label onClick={()=> setEtapaAtiva(2)}>2. Adicionais</label>
                                </div>
                                <div className={etapaAtiva===3?"etapasAddItem etapaAtiva":"etapasAddItem"} style={{borderBottom:'1px solid lightgray'}}>
                                    <label onClick={()=> setEtapaAtiva(1)}>3. Classificações</label>
                                </div>
                                <div className={etapaAtiva===4?"etapasAddItem etapaAtiva":"etapasAddItem"}>
                                    <label onClick={()=> setEtapaAtiva(1)}>4. Disponibilidade</label>
                                </div>                          
                            </div>
                        
                            { etapaAtiva === 1 && (
                                <>
                                <div className="input-group inputGroup-adicinaItem mw-100 vh-100 mw-100 mh-100 flex-column flex-lg-row ">
                                    <div className="formGroupRow flex-column align-items-center">
                                        <div className="divContainerAddItem" style={{ width: "100%" }}>
                                            <div className="input-box" style={{width:"100%", transition:"200ms"}}>
                                                <label>Categoria<span style={{color:"red"}}>*</span></label>
                                                {/*<!-- Custom select structure --> */}
                                                <div className="selectdiv-teste" style={{width:"100%", maxWidth:"100%"}}>
                                                <label style={{width:"100%"}}>
                                                <AsyncSelect
                                                    cacheOptions
                                                    loadOptions={callApi}
                                                    onChange={(data) => {
                                                    setselectData(data);
                                                    }}
                                                    value={selectData}
                                                    defaultOptions
                                                    id="vinculo_categoria"
                                                    type="vinculo_categoria"
                                                    name="vinculo_categoria"  
                                                    className="asyncSelectInput"                                     
                                                    styles={{
                                                        control: (baseStyles, state) => ({
                                                            ...baseStyles,                                                
                                                            margin:"0.6rem 0",
                                                            paddingLeft: "2.5rem",
                                                            height: "45px",
                                                            border:"none",
                                                            outline: state.isFocused?'1px solid #4070f4;':'0',
                                                            borderRadius: "10px",
                                                            boxShadow: "1px 1px 6px #0000001c",
                                                            fontSize: "0.8rem",                                               
                                                        }),
                                                        input:(style) => ({
                                                            ...style,
                                                            color:"black",
                                                        }),
                                                        singleValue: (baseStyles) => ({
                                                            ...baseStyles,
                                                            color:selectData.value?"black":"gray",
                                                        }),
                                                        valueContainer: (baseStyles) => ({
                                                            ...baseStyles,
                                                            fontWeight: "lighter",
                                                        }),
                                                        option: (styles) => {
                                                            return {
                                                            ...styles,
                                                            fontWeight: "lighter", 
                                                            color: "black",                                               
                                                            };
                                                        },                                           
                                                    }}
                                                />                                        
                                                    {formik.errors.vinculo_categoria && (
                                                    <div className="error" style={{fontWeight: "lighter"}}>{formik.errors.vinculo_categoria}</div>
                                                    )}
                                                </label>
                                                </div>
                                                {/*<!-- Custom select structure --> */}
                                            </div>  


                                            <div className="input-box" style={{width: "100%", transition:sidebar?"200ms":"100ms"}}>
                                                <label>Nome do item<span style={{color:"red"}}>*</span></label>
                                                <input 
                                                placeholder="Digite o nome do item" 
                                                id="title"
                                                type="title"
                                                name="title"
                                                onChange={formik.handleChange}
                                                value={formik.values.title}
                                                />                                        
                                                {formik.errors.title && (
                                                    <div className="error">{formik.errors.title}</div>
                                                )}
                                            </div>

                                            <div className="input-box descricaoItem" style={{width: "100%", transition:sidebar?"200ms":"100ms"}}>
                                                <label>Descrição</label>
                                                {/*<input 
                                                placeholder="Digite a descrição" 
                                                id="description"
                                                type="description"
                                                name="description"
                                                onChange={formik.handleChange}
                                                value={formik.values.description}
                                                />
                                                */}
                                                <textarea placeholder="Digite aqui a descrição desejada" 
                                                value={valueDescricao} onChange={handleMaxCharDescricao} maxLength="1000" className="text-wrap-description" ref={descriptionRef}/>
                                                <div className="divCounterChar">{valueDescricao.length}/1000</div>
                                                {formik.errors.description && (
                                                    <div className="error">{formik.errors.description}</div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="divImgAddItem">
                                            <ImageUploading
                                                multiple
                                                value={images}
                                                onChange={onChangeImg}
                                                maxNumber={maxNumber}
                                                maxFileSize={maxMbFileSize}
                                                acceptType={["jpg", "png"]}
                                                dataURLKey="data_url"
                                            >
                                            
                                                {({
                                                imageList,
                                                onImageUpload,
                                                onImageRemoveAll,
                                                onImageUpdate,
                                                onImageRemove,
                                                isDragging,
                                                dragProps,
                                                errors
                                                }) => (
                                                // write your building UI
                                                
                                                <div className="upload__image-wrapper imgItem"
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    width: "100%",
                                                    //marginLeft:"5%",
                                                    height: "100%",
                                                    padding: "10px"                        
                                                }}
                                                >                                   
                                                
                                                    <div  onClick={images.length===0?onImageUpload:null} {...dragProps} 
                                                    style={{border: imageList.length>0 ? "solid 1px lightgray" : "dashed 1px #4281FF", 
                                                    borderRadius:"5px", //width:"198px", height:"198px",
                                                    background:"rgb(247,247,247)",
                                                    cursor:images.length===0?'pointer':'',
                                                    display:"flex",
                                                    alignItems:"center", 
                                                    flexDirection:"column"                                                                                                     
                                                    }}
                                                    className="divUploadImgItem"
                                                    >
                                                        {Array.isArray(imageList) && imageList.length === 0 && (
                                                        <>
                                                            <div className="iconLanche">
                                                            <MdFastfood style={{ fontSize: 44 }} />
                                                            </div>
                                                            <div style={{ fontSize: 11, fontWeight: '700' }}>Escolha a foto</div>
                                                        </>
                                                        )}
                                                        {isDragging ? "Solte aqui..." : imageList.length>0 ? "" : <a style={{padding:'0 33px', fontSize:11}}>Clique aqui, ou arraste a foto para cá.</a>}
                                                        {/*Array.isArray(imageList) && */imageList.map((image, index) => (                                
                                                            <div key={index} className="image-item" style={{justifyContent:"center", display: "grid", gap:3}}>
                                                                {/*<div className="boxImgToFit">    
                                                                    <img src={image['data_url']} alt="" width="138"/>
                                                                </div> */}
                                                                <CImage rounded thumbnail fluid src={image['data_url']} /*width={150} height={150}*/ />                              
                                                                <div className="image-item__btn-wrapper addItemPart" style={{marginLeft:"0px"}}>
                                                                    <button className="btnAttImgItem" type="button" style={{fontSize:"14px"}} 
                                                                    onClick={(event) => {                                                                        
                                                                    event.stopPropagation();
                                                                    onImageUpdate(index)
                                                                    }
                                                                    }>
                                                                        <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="18px"
                                                                        height="18px"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        stroke="#5A5A5A"
                                                                        strokeWidth={2}
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        className="feather feather-refresh-ccw"
                                                                        >
                                                                        <polyline points="1 4 1 10 7 10" />
                                                                        <polyline points="23 20 23 14 17 14" />
                                                                        <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15" />
                                                                        </svg>
                                                                    </button>
                                                                    <button className="btnDelImgItem" type="button" style={{fontSize:"14px"}} onClick={(event) => {                                                                        
                                                                    event.stopPropagation();
                                                                    onImageRemove(index)
                                                                    }                                                                    
                                                                    }>
                                                                        <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="18px"
                                                                        height="18px"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        stroke="#5A5A5A"
                                                                        strokeWidth={2}
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        className="feather feather-trash-2"
                                                                        >
                                                                        <polyline points="3 6 5 6 21 6" />
                                                                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                                                        <line x1={10} y1={11} x2={10} y2={17} />
                                                                        <line x1={14} y1={11} x2={14} y2={17} />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>  
                                                                        

                                                    {errors && (
                                                        <div>
                                                        {errors.maxNumber && (
                                                            <span style={{color:"red"}}>Limite de {maxNumber} imagem!</span>
                                                        )}
                                                        {errors.acceptType && (
                                                            <span style={{color:"red"}}>Formato de arquivo não suportado!</span>
                                                        )}
                                                        {errors.maxFileSize && (
                                                            <span style={{color:"red", fontSize:12}}>Arquivo Excede o Tamanho Máximo Permitido!</span>
                                                        )}
                                                        {/* {errors.resolution && (
                                                            <span>Resolução muito baixa.</span>
                                                        )} */}
                                                        </div>
                                                    )}      

                                                    {errImg && (
                                                        <div>
                                                            <span style={{color:"red"}}>Imagem Obrigatória!</span>
                                                        </div>
                                                            )
                                                    }    
                                                    <div style={{display:"flex", justifyContent:"center", width:"100%",marginTop:"0px"}}> 
                                                        <div><a style={{fontSize:"11px", color:'gray'}}>(Serão aceitos somente arquivos JPG e PNG de no Máximo 2mb)</a></div>                   
                                                    </div>                 

                                                </div>

                                            
                                                            
                                                )}                
                                                                                
                                            </ImageUploading>
                                            
                                            <button type="button" className="btnAvancarTelasEditItem" style={{top:110}} onClick={()=> setEtapaAtiva(2)} >
                                                <span>Adicionais</span><IoArrowRedo />
                                            </button>

                            
                                        </div>
                                    </div>
                                        
                                    <div>
                                        <div className="input-box precoItemCustom" style={{width: "100%", transition:sidebar?"200ms":"100ms"}}>
                                            <label>Preço</label>  
                                            <InputMoney
                                                onChange={(event) => onChange(event, 'price', true)}
                                                value={valuePreco}
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                                margin="0px 0px 16px 0px"
                                                style={{
                                                outline: isFocused ? '2px solid #4070f4' : 'none',
                                                borderRadius:10,
                                                margin: '0.6rem 0 0.2rem 0',
                                                }}
                                                className=""
                                                placeholder="Preço"
                                            />                                
                                            {formik.errors.preco && (
                                                <div className="error">{formik.errors.preco}</div>
                                            )}
                                        </div>
                                    </div>                                    

                                </div>
                                </>
                            )}

                            { etapaAtiva === 2 && (
                                <div className="input-group inputGroup-adicinaItem mw-100 mh-100">
                                    <div className="formGroupRow flex-column flex-lg-row gap-4">
                                        <div className="divContainerAddItem" style={{ width: "100%" }}>

                                            <div className="radio-button-groupAdicionais" style={{ transition:sidebar?"200ms":"100ms", margin:20 }}>
                                                <div className="radio-optionAdicionais">
                                                    <input
                                                    id="opcional"
                                                    type="radio"
                                                    name="withAdicional"
                                                    value="opcional"
                                                    checked={!withAdicional}
                                                    onChange={() => {
                                                        setWithAdicional(false)
                                                        setselectDataAdicionais([]);
                                                        }
                                                    }
                                                    />
                                                    <div className="labelOptAdicionais">
                                                        <label htmlFor="opcional">Sem Adicional</label>
                                                    </div>
                                                </div>
                                                <div className="radio-optionAdicionais">
                                                    <input
                                                    id="obrigatorio"
                                                    type="radio"
                                                    name="withAdicional"
                                                    value="obrigatorio"
                                                    checked={withAdicional}
                                                    onChange={() => setWithAdicional(true)}
                                                    />
                                                    <div className="labelOptAdicionais">
                                                        <label htmlFor="obrigatorio">Com Adicional</label>
                                                    </div>
                                                </div>
                                            </div>

                                            {withAdicional && (
                                                <div className="input-box" style={{width:"100%", transition:"200ms"}}>
                                                    <label>Adicionais<span style={{color:"red"}}>*</span></label>
                                                    {/*<!-- Custom select structure --> */}
                                                    <div className="selectdiv-teste" style={{width:"100%", maxWidth:"100%"}}>
                                                    <label style={{width:"100%"}}>
                                                    <AsyncSelect
                                                        isMulti
                                                        height="auto"
                                                        cacheOptions
                                                        loadOptions={callApiAdicionais}
                                                        onChange={(data) => {
                                                            console.log("DATA>>",data)
                                                            setselectDataAdicionais(data);
                                                        }}
                                                        value={selectDataAdicionais}
                                                        defaultOptions
                                                        placeholder={"Selecione os adicionais"}
                                                        id="vinculo_adicional"
                                                        type="vinculo_adicional"
                                                        name="vinculo_adicional"  
                                                        className="asyncSelectInputAdicionais"                                     
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,                                                
                                                                margin:"0.6rem 0",
                                                                paddingLeft: "2.5rem",
                                                                height: "auto",
                                                                border:"none",
                                                                outline: state.isFocused?'1px solid #4070f4;':'0',
                                                                borderRadius: "10px",
                                                                boxShadow: "1px 1px 6px #0000001c",
                                                                fontSize: "0.8rem",                                               
                                                            }),
                                                            input:(style) => ({
                                                                ...style,
                                                                color:"black",
                                                            }),
                                                            singleValue: (baseStyles) => ({
                                                                ...baseStyles,
                                                                color:selectData.value?"black":"gray",
                                                            }),
                                                            valueContainer: (baseStyles) => ({
                                                                ...baseStyles,
                                                                fontWeight: "lighter",
                                                            }),
                                                            option: (styles) => {
                                                                return {
                                                                ...styles,
                                                                fontWeight: "lighter", 
                                                                color: "black",                                               
                                                                };
                                                            },                                           
                                                        }}
                                                    />                                        
                                                        {formik.errors.vinculo_adicional && (
                                                        <div className="error" style={{fontWeight: "lighter"}}>{formik.errors.vinculo_adicional}</div>
                                                        )}
                                                    </label>
                                                    </div>
                                                    {/*<!-- Custom select structure --> */}
                                                </div>
                                                )
                                            }

                                            <button type="button" className="btnCreateGroupAdicionais" onClick={()=> setShowModalAddGroupAdd(!showModalAddGroupAdd)}><IoIosAddCircleOutline fontSize={18} />Criar Grupo de Adicionais</button>
                                                

                                        </div>

                                        <div className="divBtnsBackFoward">
                                            <div className="btnsBackFoward">
                                                <button type="button" className="btnAvancarTelasEditItem" onClick={()=> setEtapaAtiva(1)} >
                                                    <span>Item</span><IoArrowUndo />
                                                </button>
                                                <button type="button" className="btnAvancarTelasEditItem" onClick={()=> setEtapaAtiva(2)} >
                                                    <span>Classificações</span><IoArrowRedo />
                                                </button>
                                            </div>
                                        </div>
                                    </div>                                   

                                </div>
                            )}
                            

                        </div>

                    </form>
                </div>
            </Teste>
                
        </PermissionGate>       
        </>
        

        );
    };
    
    export default EditItem;