import React, { useState, useEffect, createContext } from "react";

import { useNavigate } from "react-router-dom";

import { api, createSession, checkLicense, getEmpresa } from '../services/api';

import { toast } from "react-toastify";
import CryptoJS from 'crypto-js';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [hasLicense, setHasLicense] = useState(null);
    const [showToast, setShowToast] = useState(true); // Adiciona estado para controlar a exibição do Toast
    const [redirectToPlanos, setRedirectToPlanos] = useState(false);

    useEffect(() => {
        const userEncrypted = localStorage.getItem('user')
        var recoveredUser = null;
        if (userEncrypted) {
            recoveredUser = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
        }
        //const recoveredUser = localStorage.getItem('user');
        const token = localStorage.getItem("token");

        if (recoveredUser && token) {
            setUser(JSON.parse(recoveredUser));
            api.defaults.headers.Authorization = `Bearer ${token}`;

            //checkLicenseValidity();
        }

        setLoading(false);
    }, []);

    /*const checkLicenseValidity = async () => {
        if (!user) return;

        try {
            const response = await checkLicense(user._id);
            const licenseValid = response.data.hasLicense;
            setHasLicense(licenseValid);
            if (!licenseValid) {
                setShowToast(true); // Mostrar o Toast se a licença não for válida
            } else {
                setShowToast(false); // Ocultar o Toast se a licença for válida
            }
        } catch (error) {
            console.error('Error checking license:', error);
            toast.error(
                <div>
                    <span>Nenhuma licença ativa. Clique <a href="/planos">aqui</a> para verificar os planos.</span>
                </div>,
                {
                    autoClose: 2000,
                    closeButton: false,
                    draggable: false,
                }
            );
        }
    };*/

    const login = async (email, password) => {
        try {
            const response = await createSession(email, password);
            if (response.status === 200) {
                toast(response.data.msg, { type: "success", autoClose: 2000 });
                const loggedUser = response.data.user;
                const token = response.data.token;
                const empresa = response.data.empresa;
                const loggedUserEncrypted = CryptoJS.AES.encrypt(JSON.stringify(loggedUser), secretKey).toString();
                localStorage.setItem("user", loggedUserEncrypted);
                localStorage.setItem("token", token);
                localStorage.setItem("empresa", JSON.stringify(empresa));

                api.defaults.headers.Authorization = `Bearer ${token}`;

                //await checkLicenseValidity();

                setUser(loggedUser);
                navigate("/");
            } else {
                toast(response.msg, { type: "error" });
            }
        } catch (error) {
            console.log("error", error);
            toast(error?.response.data.msg || 'Verifique sua conexão com a internet ou entre em contato com um administrador do aplicativo.', { type: "error" });
        }


    };

    const logout = () => {
        console.log("logout");
        localStorage.removeItem("user");
        localStorage.removeItem("token");

        localStorage.removeItem("empresa");

        api.defaults.headers.Authorization = null;
        setUser(null);
        setHasLicense(false);
        navigate("/login");
    };

    return (
        <AuthContext.Provider value={{
            authenticated:!!user, 
            user, 
            loading, 
            hasLicense, 
            redirectToPlanos, 
            setRedirectToPlanos, 
            setHasLicense, 
            login, 
            logout
        }}>
            {children}
        </AuthContext.Provider>
    )
}