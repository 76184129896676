import styled from "styled-components";
import React from 'react'
import CryptoJS from 'crypto-js';
import PermissionGate from "../../services/PermissionGate";

import { useFormik } from "formik";
import * as Yup from "yup";

import * as AiIcons from 'react-icons/ai';
import { getRevendas, getEmpresas, updateTempoEntregaEmpresa, updateUser } from "../../services/api";
import AsyncSelect from "react-select/async";

import { AuthContext } from "../../contexts/auth";
import { useEffect, useContext, useState } from "react";

import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from "react-router-dom";	

const DivEditTempoEntregaContainer = styled.div`
  z-index:15;
  position: fixed;
  top: 0;
  left: 0;
  display: ${({ showEditTempoEntregaEmpresa }) => (showEditTempoEntregaEmpresa ? 'none' :'flex' )};
  float: left;
  min-width: 100%;
  min-height: 100%;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 5px 0;
  list-style: none;
  background-color: rgba(0,0,0,0.5);
  background-clip: padding-box;
  align-items:center;
  justify-content:center;
  opacity: ${({ showEditTempoEntregaEmpresa }) => (showEditTempoEntregaEmpresa ? '0' :'1' )};
  transition: opacity .3s ease-in-out;

  // Add keyframes for the fade-in effect
  animation: fadeIn .3s ease-in-out;
  animation-fill-mode: forwards;

  // Define the keyframes for the animation
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const DivEditTempoEntrega = styled.div`
    z-index:20;
    position: absolute;
    top: 10%;
    left: 25%;
    float: left;
    min-width: 40%;
    min-height: 45%;
    max-width: 50%;
    padding: 5px 0;
    list-style: none;
    --background-color: white;
    background-color:rgb(247,247,247)!important;
    background-clip: padding-box;
    border-radius: 15px;
    padding:20px;

`;

const ModalEditTempoEntrega = ({setEditTempoEntregaEmpresa, showEditTempoEntregaEmpresa, setRefresh, 
    _idEmpresaEdit,
    tempoBalcaoMinBD,
    tempoBalcaoMaxBD,
    tempoEntregaMinBD,
    tempoEntregaMaxBD,
    tipoImpressao
}) => {
    
    const { user } = useContext(AuthContext);
    const empresa = localStorage.getItem('empresa')
    const empresaParse = JSON.parse(empresa)
    const empresaObjId = empresaParse._id;
    const idEmpresa = empresaParse.id_empresa

    const [resetPassword, setResetPassword] = useState(false);


   
    var [tempoBalcaoMin, setTempoBalcaoMin] = useState(tempoBalcaoMinBD);
    var [tempoBalcaoMax, setTempoBalcaoMax] = useState(tempoBalcaoMaxBD);
    var [tempoEntregaMin, setTempoEntregaMin] = useState(tempoEntregaMinBD);
    var [tempoEntregaMax, setTempoEntregaMax] = useState(tempoEntregaMaxBD);
    var [switchState, setSwitchState] = useState(tipoImpressao=='manual'?false:true);

    useEffect(() => {
        (async () => {
            //console.log()
           
            //console.log("dados>",response.data.vinculo.tempoBalcaoMin);
            setTempoBalcaoMin(tempoBalcaoMinBD);
            setTempoBalcaoMax(tempoBalcaoMaxBD);
            setTempoEntregaMin(tempoEntregaMinBD);
            setTempoEntregaMax(tempoEntregaMaxBD);
            setSwitchState(tipoImpressao=='manual'?false:true);
           
        })();
    //eslint-disable-next-line
    },[tempoBalcaoMinBD, tipoImpressao]);
  

    const handleSwitchChange = (event) => {
        setSwitchState(event.target.checked);
        console.log(event.target.checked);
    };

    const mapResponseToValuesAndLabels = (data) => ({
    value: data.id_empresa,
    label: data.id_empresa+" - "+data.cnpj+" - "+data.name,
    });
      
    async function callApi(value) {
    
        const data = await getEmpresas(user._id)
        .then((response) => response.data.empresa)//response.json())
        .then((response) => response.map(mapResponseToValuesAndLabels))
        .then((response) =>
            response.filter((i) => i.label.toLowerCase().includes(value.toLowerCase()))
        );

        return data;
    }

    //Validações
    const SignUpSchema = Yup.object().shape({
        tempoBalcaoMin: Yup.number().required("Obrigatório"),
        tempoBalcaoMax: Yup.number().moreThan(Yup.ref("tempoBalcaoMin"),"O tempo máximo deve ser superior ao mínimo").required("Obrigatório"),
        tempoEntregaMin: Yup.number().required("Obrigatório"),
        tempoEntregaMax: Yup.number().moreThan(Yup.ref("tempoEntregaMin"),"O tempo máximo deve ser superior ao mínimo").required("Obrigatório"),
    });


    const formik = useFormik({
        validationSchema: SignUpSchema,
        validateOnBlur: false,
        validateOnChange: false,
        enableReinitialize: true,
        //enableReinitialize: showEditTempoEntregaEmpresa,
        initialValues: {
          //nome: nome,
          tempoBalcaoMin: tempoBalcaoMin,
          tempoBalcaoMax: tempoBalcaoMax,
          tempoEntregaMin: tempoEntregaMin,
          tempoEntregaMax: tempoEntregaMax
        },
        onSubmit: (values) => {
          //alert(JSON.stringify(values, null, 2));
          //console.log(values);
          //console.log("RoleEdit:::",roleEdit);

          const tempoBalcaoMin_ = tempoBalcaoMin;        
          const tempoBalcaoMax_ = tempoBalcaoMax;
          const tempoEntregaMin_ = tempoEntregaMin;
          const tempoEntregaMax_ = tempoEntregaMax;          

          console.log("DADOS TESTE::",tempoBalcaoMin_, tempoBalcaoMax_, tempoEntregaMin_, tempoEntregaMax_)
          //const vinculo='';
          var tipo = "";
          //console.log("values.tipo:::",values.tipo)
          if(values.tipo==="Administrador" || values.tipo==="Admin"){
            tipo = "Admin";
          }
          if(values.tipo=="Empresa" || values.tipo==="empresa"){
            tipo = "empresa";
          }
          if(values.tipo=="Revenda" || values.tipo==="revenda"){
            tipo = "revenda";
          }
          
          handleSubmitAPI(tempoBalcaoMin_, tempoBalcaoMax_, tempoEntregaMin_, tempoEntregaMax_);
          
        },
    });

    // Lógica para atualizar os dados do usuário no localStorage
    // Get the existing data
    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const userEncrypted = localStorage.getItem('user')
    var existing = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
    //var existing = localStorage.getItem('user');
    // Convert the localStorage string to an array
    existing = JSON.parse(existing);

    /*const handleSubmit = () => {
        console.log("TESTE SUBMIT:", tempoBalcaoMin)
    }*/

    const handleSubmitAPI = (tempoBalcaoMin_, tempoBalcaoMax_, tempoEntregaMin_, tempoEntregaMax_) => {
        const userID = user._id;
        let tipo_impressao = "";
        if(switchState){
            tipo_impressao = "automatico";
        } else {
            tipo_impressao = "manual";
        }
        console.log(_idEmpresaEdit, userID, tempoBalcaoMin_, tempoBalcaoMax_, tempoEntregaMin_, tempoEntregaMax_, tipo_impressao);
        
        updateTempoEntregaEmpresa( userID, empresaObjId, _idEmpresaEdit, tempoBalcaoMin_.toString(), tempoBalcaoMax_.toString(), tempoEntregaMin_.toString(), tempoEntregaMax_.toString(), tipo_impressao).then(cast => {
        
            console.log("Tempo de entrega editado com Sucesso!:::",cast)
            if(cast.status===200){
                toast(cast.data.msg,{ autoClose: 5000 , type: "success"});
                //navigate("/list-users");
                setRefresh(true);
                setEditTempoEntregaEmpresa(!showEditTempoEntregaEmpresa);
            }else{
                toast(cast.data.msg,{ autoClose: 5000 , type: "error"});
            }
                
        }).catch(err => {
            console.log('Ocorreu algum erro no servidor!',err);
            //console.log(err.response.data.msg);
            toast(err.response.data.msg,{ autoClose: 5000 , type: "error"});
        });
              
    }

    const closeEditTempoEntrega = () => setEditTempoEntregaEmpresa(!showEditTempoEntregaEmpresa);

    const handleChange = (type, value, delta) => {
        if (value + delta >= 0) {
            if (type === 'tempoBalcaoMin') {
                setTempoBalcaoMin(Number(value) + delta);
            } else if (type === 'tempoBalcaoMax') {
                setTempoBalcaoMax(Math.max(Number(value) + delta, tempoBalcaoMin));
            } else if (type === 'tempoEntregaMin') {
                setTempoEntregaMin(Number(value) + delta);
            } else if (type === 'tempoEntregaMax') {
                setTempoEntregaMax(Math.max(Number(value) + delta, tempoEntregaMin));
            }
        }
    };
    
    const handleBlur = (type, value) => {
        if (type === 'tempoBalcaoMin') {
            if (value > tempoBalcaoMax) {
                setTempoBalcaoMin(tempoBalcaoMax);
            }
        } else if (type === 'tempoBalcaoMax') {
            if (value < tempoBalcaoMin) {
                setTempoBalcaoMax(tempoBalcaoMin);
            }
        } else if (type === 'tempoEntregaMin') {
            if (value > tempoEntregaMax) {
                setTempoEntregaMin(tempoEntregaMax);
            }
        } else if (type === 'tempoEntregaMax') {
            if (value < tempoEntregaMin) {
                setTempoEntregaMax(tempoEntregaMin);
            }
        }
    };
    
    const handleDecrement = (type) => {
        handleChange(type, type === 'tempoBalcaoMin' ? tempoBalcaoMin : (type === 'tempoBalcaoMax' ? tempoBalcaoMax : (type === 'tempoEntregaMin' ? tempoEntregaMin : tempoEntregaMax)), -1);
    };
    
    const handleIncrement = (type) => {
        handleChange(type, type === 'tempoBalcaoMin' ? tempoBalcaoMin : (type === 'tempoBalcaoMax' ? tempoBalcaoMax : (type === 'tempoEntregaMin' ? tempoEntregaMin : tempoEntregaMax)), 1);
    };


    return (
        <DivEditTempoEntregaContainer showEditTempoEntregaEmpresa={showEditTempoEntregaEmpresa}>
            <DivEditTempoEntrega>

                <div className="m-5" style={{width:"95%"}}>
                <div style={{marginBottom:"20px", marginTop:"-30px", fontSize:"25px"}}> 
                    <h4>Tempo de Entrega</h4>
                </div>

                    {/* ///////////////////////////////// */}
                    <form onSubmit={formik.handleSubmit} style={{marginTop:"-30px", maxWidth:"920px"}}>    

                        <div style={{display:"flex", justifyContent:"right"/*, height:"80px"*/}}>
                            <div className="continue-button" style={{width:"150px", display:"flex", justifyContent:"right"}}>
                                <button style={{marginTop:"12px", height:"49.6px", width:"150px"}} type="submit">
                                <a><AiIcons.AiOutlineSave style={{marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/>Salvar</a> 
                                </button>
                            </div>                                 
                            <div className="back-button">                                                       
                                <button onClick={closeEditTempoEntrega} type="button">    
                                    <AiIcons.AiOutlineCloseCircle style={{color:"#ff4c4c",marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/><a>Fechar</a> 
                                </button>
                            </div>                            
                        </div>      

                        <span>Defina o intervalo em minutos</span>    

                        <div style={{display: 'flex',
                        alignItems: 'center',
                        paddingTop: '20px'}}>
                            <h5 className="subtitle-form-tempo-entrega" style={{width:'280px'}}>Retirada no balcão</h5>
                            <div className="line"></div>
                        </div>
                    
                        <div className="input-group-edit" style={{paddingBottom:"0px", marginBottom:"0px"}}>
                        

                            <div className="configure-tempo-entrega">
                                <span style={{fontWeight:'bold'}}>Tempo mínimo (em minutos)</span>
                                <div _ngcontent-ng-c1712864681="" className="input-box number">
                                    <button type="button" className={tempoBalcaoMin === 0 ? "decrement disable" : "decrement"} onClick={() => handleDecrement("tempoBalcaoMin")} //type="button" className={tempoBalcaoMin==0?"decrement disable":"decrement"} onClick={() => decrement("tempoBalcaoMin:"+tempoBalcaoMin)}
                                    >
                                        <pedeja-icon
                                        iconname="minus"
                                        style={{ height: 14 }}
                                        >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={14}
                                            height={14}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-minus"
                                        >
                                            <line x1={5} y1={12} x2={19} y2={12} />
                                        </svg>
                                        </pedeja-icon>
                                    </button>
                                    <input
                                        id='tempoBalcaoMin'
                                        name="tempoBalcaoMin"
                                        type="number"
                                        //placeholder={0}
                                        onKeyDown={ (evt) => 
                                            evt.key === 'e' && evt.preventDefault() ||
                                            evt.key === 'E' && evt.preventDefault() ||
                                            evt.key === '-' && evt.preventDefault() ||
                                            evt.key === '.' && evt.preventDefault() ||
                                            evt.key === ',' && evt.preventDefault() }
                                        value={tempoBalcaoMin} onChange={(e) => setTempoBalcaoMin(e.target.value)} onBlur={() => handleBlur('tempoBalcaoMin', tempoBalcaoMin)}
                                        max={9999}
                                        min={0}
                                        maxLength=""
                                    />                                   
                                    <button type="button" className="increment" onClick={() => handleIncrement("tempoBalcaoMin")}
                                    >
                                        <pedeja-icon style={{ height: 14 }}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={14}
                                            height={14}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-plus"
                                        >
                                            <line x1={12} y1={5} x2={12} y2={19} />
                                            <line x1={5} y1={12} x2={19} y2={12} />
                                        </svg>
                                        </pedeja-icon>
                                    </button>
                                    {formik.errors.tempoBalcaoMin && (
                                        <div className="error" style={{position:'absolute'}}>{formik.errors.tempoBalcaoMin}</div>
                                    )}
                                </div>
                            </div>

                            <div className="configure-tempo-entrega">
                                <span style={{fontWeight:'bold'}}>Tempo máximo (em minutos)</span>
                                <div _ngcontent-ng-c1712864681="" className="input-box number">
                                    <button type="button" className={tempoBalcaoMax === 0 || tempoBalcaoMax <= tempoBalcaoMin ? "decrement disable" : "decrement"} onClick={() => handleDecrement("tempoBalcaoMax")} //type="button" className={tempoBalcaoMax==0||tempoBalcaoMax<=tempoBalcaoMin?"decrement disable":"decrement"} onClick={() => decrement("tempoBalcaoMax:"+tempoBalcaoMax)}
                                    >
                                        <pedeja-icon
                                        iconname="minus"
                                        style={{ height: 14 }}
                                        >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={14}
                                            height={14}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-minus"
                                        >
                                            <line x1={5} y1={12} x2={19} y2={12} />
                                        </svg>
                                        </pedeja-icon>
                                    </button>
                                    <input
                                        id='tempoBalcaoMax'
                                        name="tempoBalcaoMax"
                                        type="number"
                                        //placeholder={0}
                                        onKeyDown={ (evt) => 
                                            evt.key === 'e' && evt.preventDefault() ||
                                            evt.key === 'E' && evt.preventDefault() ||
                                            evt.key === '-' && evt.preventDefault() ||
                                            evt.key === '.' && evt.preventDefault() ||
                                            evt.key === ',' && evt.preventDefault() }
                                        value={tempoBalcaoMax} onChange={(e) => setTempoBalcaoMax(e.target.value)} onBlur={() => handleBlur('tempoBalcaoMax', tempoBalcaoMax)}
                                        max={9999}
                                        min={0}
                                        maxLength=""
                                        className="ng-untouched ng-pristine ng-valid"
                                    />
                                    <button type="button" className="increment" onClick={() => handleIncrement("tempoBalcaoMax")} 
                                    >
                                        <pedeja-icon
                                        _ngcontent-ng-c1712864681=""
                                        iconname="plus"
                                        _nghost-ng-c3181319476=""
                                        style={{ height: 14 }}
                                        >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={14}
                                            height={14}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-plus"
                                        >
                                            <line x1={12} y1={5} x2={12} y2={19} />
                                            <line x1={5} y1={12} x2={19} y2={12} />
                                        </svg>
                                        </pedeja-icon>
                                    </button>
                                    {formik.errors.tempoBalcaoMax && (
                                        <div className="error" style={{position:'absolute'}}>{formik.errors.tempoBalcaoMax}</div>
                                    )}
                                </div>
                            </div>
                        </div>    


                        {/* Entrega delivery */}
                        <div style={{display: 'flex',
                        alignItems: 'center',
                        paddingTop: '20px'}}>
                            <h5 className="subtitle-form-tempo-entrega" style={{width:'240px'}}>Entrega delivery</h5>
                            <div className="line"></div>
                        </div>
                    
                        <div className="input-group-edit" style={{paddingBottom:"0px", marginBottom:"0px"}}>                            

                            <div className="configure-tempo-entrega">
                                <span style={{fontWeight:'bold'}}>Tempo mínimo (em minutos)</span>
                                <div className="input-box number">
                                    <button type="button" className={tempoEntregaMin === 0 ? "decrement disable" : "decrement"} onClick={() => handleDecrement("tempoEntregaMin")} //type="button" className={tempoEntregaMin==0?"decrement disable":"decrement"} onClick={() => decrement("tempoEntregaMin:"+tempoEntregaMin)}
                                    >
                                        <pedeja-icon
                                        iconname="minus"
                                        style={{ height: 14 }}
                                        >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={14}
                                            height={14}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-minus"
                                        >
                                            <line x1={5} y1={12} x2={19} y2={12} />
                                        </svg>
                                        </pedeja-icon>
                                    </button>
                                    <input
                                        id='tempoEntregaMin'
                                        name="tempoEntregaMin"
                                        type="number"
                                        //placeholder={0}
                                        onKeyDown={ (evt) => 
                                            evt.key === 'e' && evt.preventDefault() ||
                                            evt.key === 'E' && evt.preventDefault() ||
                                            evt.key === '-' && evt.preventDefault() ||
                                            evt.key === '.' && evt.preventDefault() ||
                                            evt.key === ',' && evt.preventDefault() }
                                        value={tempoEntregaMin} onChange={(e) => setTempoEntregaMin(e.target.value)} onBlur={() => handleBlur('tempoEntregaMin', tempoEntregaMin)} 
                                        max={9999}
                                        min={0}
                                        maxLength=""
                                        className="ng-untouched ng-pristine ng-valid"
                                    />
                                    <button type="button" className="increment" onClick={() => handleIncrement("tempoEntregaMin")}
                                    >
                                        <pedeja-icon style={{ height: 14 }}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={14}
                                            height={14}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-plus"
                                        >
                                            <line x1={12} y1={5} x2={12} y2={19} />
                                            <line x1={5} y1={12} x2={19} y2={12} />
                                        </svg>
                                        </pedeja-icon>
                                    </button>
                                    {formik.errors.tempoEntregaMin && (
                                        <div className="error" style={{position:'absolute'}}>{formik.errors.tempoEntregaMin}</div>
                                    )}
                                </div>
                            </div>

                            <div className="configure-tempo-entrega">
                                <span style={{fontWeight:'bold'}}>Tempo máximo (em minutos)</span>
                                <div className="input-box number">
                                    <button type="button" className={tempoEntregaMax === 0 || tempoEntregaMax <= tempoEntregaMin ? "decrement disable" : "decrement"} onClick={() => handleDecrement("tempoEntregaMax")} //type="button" className={tempoEntregaMax==0||tempoEntregaMax<=tempoEntregaMin?"decrement disable":"decrement"} onClick={() => decrement("tempoEntregaMax:"+tempoEntregaMax)}
                                    >
                                        <pedeja-icon
                                        iconname="minus"
                                        style={{ height: 14 }}
                                        >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={14}
                                            height={14}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-minus"
                                        >
                                            <line x1={5} y1={12} x2={19} y2={12} />
                                        </svg>
                                        </pedeja-icon>
                                    </button>
                                    <input
                                    id='tempoEntregaMax'
                                    name='tempoEntregaMax'
                                    type="number"
                                    //placeholder={0}
                                    onKeyDown={ (evt) => 
                                        evt.key === 'e' && evt.preventDefault() ||
                                        evt.key === 'E' && evt.preventDefault() ||
                                        evt.key === '-' && evt.preventDefault() ||
                                        evt.key === '.' && evt.preventDefault() ||
                                        evt.key === ',' && evt.preventDefault() }
                                    value={tempoEntregaMax} onChange={(e) => setTempoEntregaMax(e.target.value)} onBlur={() => handleBlur('tempoEntregaMax', tempoEntregaMax)}
                                    max={9999}
                                    min={0}
                                    maxLength=""
                                    className="ng-untouched ng-pristine ng-valid"
                                    />
                                    <button type="button" className="increment" onClick={() => handleIncrement("tempoEntregaMax")}
                                    >
                                        <pedeja-icon
                                        _ngcontent-ng-c1712864681=""
                                        iconname="plus"
                                        _nghost-ng-c3181319476=""
                                        style={{ height: 14 }}
                                        >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={14}
                                            height={14}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-plus"
                                        >
                                            <line x1={12} y1={5} x2={12} y2={19} />
                                            <line x1={5} y1={12} x2={19} y2={12} />
                                        </svg>
                                        </pedeja-icon>
                                    </button>
                                    {formik.errors.tempoEntregaMax && (
                                        <div className="error" style={{position:'absolute'}}>{formik.errors.tempoEntregaMax}</div>
                                    )}
                                </div>
                            </div>

                        </div>


                        {/* Aceite automático */}
                        <div style={{display: 'flex',
                        alignItems: 'center',
                        paddingTop: '20px'}}>
                            <h5 className="subtitle-form-tempo-entrega" style={{width:'280px'}}>Aceite automático</h5>
                            <div className="line"></div>
                        </div>
                    
                        <div className="input-group-edit" style={{paddingBottom:"0px", marginBottom:"0px"}}>                            

                            {/* Switch Personalized */}
                            <div className="wrapper">
                                <div className="switch_box box_1">
                                <input
                                    type="checkbox"
                                    className="switch_aceiteAutomatico"
                                    checked={switchState}
                                    onChange={handleSwitchChange}
                                />
                                </div>                                
                                <b style={{marginLeft:5, color:switchState?'#07C670':'#ff4c4c'}}>{switchState?'Ativado':'Desativado'}</b> 
                                <span style={{marginLeft:5}}> Clique no botão Salvar para aplicar as configurações.</span>
                            </div>

                        </div>

                    </form>
                    {/* ////////////////////////////////////   */}

                
                                            
                {/* <a className="btn btn-sm btn-danger" onClick={closeModelImg} style={{color:"rgb(120,120,120)", background:"white"}}>Fechar</a> */}

                </div>
                {/* Fim Upload Images */}
                

            </DivEditTempoEntrega>
        </DivEditTempoEntregaContainer>
    )
}

export default ModalEditTempoEntrega;