import {React, useState} from 'react';
import styled from "styled-components";

import CurrencyInput from 'react-currency-input-field';
import { useFormik } from "formik";
import * as Yup from "yup";

import * as AiIcons from 'react-icons/ai';

const DivModalAddItem = styled.div`
  z-index:15;
  position: fixed;
  top: 0;
  left: 0;
  display: ${({ showModalAddItem }) => (showModalAddItem ? 'flex' : 'none')};
  float: left;
  min-width: 100%;
  min-height: 100%;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 5px 0;
  list-style: none;
  background-color: rgba(0,0,0,0.5);
  background-clip: padding-box;
  align-items:center;
  justify-content:center;
`;

const DivAddItem = styled.div`
    z-index:20;
    position: absolute;
    top: 13%;
    left: 10%;
    float: left;
    min-width: 32%;
    min-height: 45%;
    padding: 5px 0;
    list-style: none;
    background-color: white;
    background-clip: padding-box;
    border-radius: 15px;
    max-width: 80%;
`;

const ModalAddItem = ({setShowModalAddItem, showModalAddItem, items, setItems, itemToEdit, setItemToEdit, type}) => {
    
    const closeModalItem = () => {
        //console.log(items);
        setPrecoUnitario('');
        setQuantidade('');
        setItemToEdit("");
        setShowModalAddItem(!showModalAddItem);
    }




    const [precoUnitario, setPrecoUnitario] = useState('');
    const [quantidade, setQuantidade] = useState('');
    /*if (itemToEdit!=='') {
        setQuantidade( toString(items.filter(item=> item.codigo === itemToEdit).map(item => item.quantidade)) );
    }*/
    //Validações
    const SignUpSchema = Yup.object().shape({        
        item: Yup.string().required("Obrigatório"),
        codigo: Yup.string().required("Obrigatório"),
        quantidade: quantidade==='' && Yup.number().required("Obrigatório"),
        preco_unitario: precoUnitario==='' && Yup.number().required("Obrigatório"),
    });    

    const formik = useFormik({
        validationSchema: SignUpSchema,
        validateOnBlur: false,
        validateOnChange: false,
        enableReinitialize: true,
        initialValues: {
          item: itemToEdit === '' ? "" : items.filter(item=> item.codigo === itemToEdit)[0].item, 
          codigo: itemToEdit === '' ? "" : items.filter(item=> item.codigo === itemToEdit)[0].codigo,
          descricao: itemToEdit === '' ? "" : items.filter(item=> item.codigo === itemToEdit)[0].descricao,   
          modelo: itemToEdit === '' ? "" : items.filter(item=> item.codigo === itemToEdit)[0].modelo,
          quantidade: itemToEdit === '' ? "" : parseFloat(items.filter(item=> item.codigo === itemToEdit)[0].quantidade),
          preco_unitario: itemToEdit === '' ? "" : items.filter(item=> item.codigo === itemToEdit)[0].preco_unitario,
          preco_total: itemToEdit === '' ? "" : items.filter(item=> item.codigo === itemToEdit)[0].preco_total, 
          acionamento: itemToEdit === '' ? "" : items.filter(item=> item.codigo === itemToEdit)[0].acionamento,
          grupo: itemToEdit === '' ? "" : items.filter(item=> item.codigo === itemToEdit)[0].grupo,
          tecido: itemToEdit === '' ? "" : items.filter(item=> item.codigo === itemToEdit)[0].tecido,
          colecao: itemToEdit === '' ? "" : items.filter(item=> item.codigo === itemToEdit)[0].colecao,
          cor_perfil: itemToEdit === '' ? "" : items.filter(item=> item.codigo === itemToEdit)[0].cor_perfil,
          motor: itemToEdit === '' ? "" : items.filter(item=> item.codigo === itemToEdit)[0].motor,
          largura: itemToEdit === '' ? "" : items.filter(item=> item.codigo === itemToEdit)[0].largura,
          altura: itemToEdit === '' ? "" : items.filter(item=> item.codigo === itemToEdit)[0].altura,
          altura_comando: itemToEdit === '' ? "" : items.filter(item=> item.codigo === itemToEdit)[0].altura_comando,
          lado_comando: itemToEdit === '' ? "" : items.filter(item=> item.codigo === itemToEdit)[0].lado_comando,
          observacao: itemToEdit === '' ? "" : items.filter(item=> item.codigo === itemToEdit)[0].observacao,
          lista_acessorios: itemToEdit === '' ? "" : items.filter(item=> item.codigo === itemToEdit)[0].lista_acessorios
        },
        onSubmit: (values) => {
          //alert(JSON.stringify(values, null, 2));
          //console.log(values);
          
          if(items.filter(item => item.codigo === values.codigo).length > 0 && itemToEdit === ''){
            alert("Código já cadastrado");
            return;
          }

          if (itemToEdit !== ''){
            let resultArrayEdited = items.reduce((acc, o) => {

                let obj = itemToEdit.includes(o.codigo) ? Object.assign(o, { 
                    item: values.item, 
                    codigo: values.codigo, 
                    descricao: values.descricao,
                    modelo: values.modelo,
                    quantidade: values.quantidade,
                    preco_unitario: values.preco_unitario,
                    preco_total: (values.quantidade * values.preco_unitario),
                    acionamento: values.acionamento,
                    grupo: values.grupo,
                    tecido: values.tecido,
                    colecao: values.colecao,
                    cor_perfil: values.cor_perfil,
                    motor: values.motor,
                    largura: values.largura,
                    altura: values.altura,
                    altura_comando: values.altura_comando,
                    lado_comando: values.lado_comando,
                    observacao: values.observacao,
                    lista_acessorios: values.lista_acessorios
                }) : o;
            
                acc.push(obj);
            
                return acc;
            
            }, []);
            setItems(resultArrayEdited);
            setItemToEdit('');
            values.item = "";
            values.codigo = "";
            values.descricao = "";
            values.modelo = "";
            values.quantidade = "";            
            values.preco_unitario = "";            
            values.preco_total = "";
            values.acionamento = "";
            values.grupo = "";
            values.tecido = "";
            values.colecao = "";
            values.cor_perfil = "";
            values.motor = "";
            values.largura = "";
            values.altura = "";
            values.altura_comando = "";
            values.lado_comando = "";
            values.observacao = "";
            values.lista_acessorios = "";

            //setItem(values.item);
            console.log("Deu submit no Edit");
            //handleSubmitAPI(email,password,confirmPassword,nome,tipo);
            closeModalItem();  
            return;
         }else
          setItems(items => [...items, { 
            //id: items.length+1, 
            item: values.item, 
            codigo: values.codigo, 
            descricao: values.descricao,
            modelo: values.modelo,
            quantidade: parseFloat(quantidade.replace(',','.') ),
            preco_unitario: parseFloat(precoUnitario.replace(',','.') ),
            preco_total: parseFloat(quantidade.replace(',','.') * precoUnitario.replace(',','.') ),
            acionamento: values.acionamento,
            grupo: values.grupo,
            tecido: values.tecido,
            colecao: values.colecao,
            cor_perfil: values.cor_perfil,
            motor: values.motor,
            largura: values.largura,
            altura: values.altura,
            altura_comando: values.altura_comando,
            lado_comando: values.lado_comando,
            observacao: values.observacao,
            lista_acessorios: values.lista_acessorios
          }]);
          values.item = "";
          values.codigo = "";
          values.descricao = "";
          values.modelo = "";
          //values.quantidade = "";
          setQuantidade('');
          //values.preco_unitario = "";
          setPrecoUnitario('');
          values.preco_total = "";
          values.acionamento = "";
          values.grupo = "";
          values.tecido = "";
          values.colecao = "";
          values.cor_perfil = "";
          values.motor = "";
          values.largura = "";
          values.altura = "";
          values.altura_comando = "";
          values.lado_comando = "";
          values.observacao = "";
          values.lista_acessorios = "";

          //setItem(values.item);
          console.log("Deu submit, items ");
          //handleSubmitAPI(email,password,confirmPassword,nome,tipo);
          closeModalItem();        
       
        },
    });


    // const handleSaveItem = () => {
    //     console.log("Save Iitem");
    // }

    return (
        <DivModalAddItem showModalAddItem={showModalAddItem}>
            <DivAddItem>               
                <div style={{marginTop:"-30px"}}>
                <div className="m-5" style={{width:"97%"}}>

                        <div style={{display:"inline-block", marginTop:"-30px", height:"60px", width:"50%", fontSize:"25px"}}>
                            <a style={{display:itemToEdit===''?'':'none'}}>Adicionar Item</a>
                            <a style={{display:type==='edicao'?'':'none'}}>Editar Item</a>
                            <a style={{display:type==='view'?'':'none'}}>Visualizar Item</a>
                        </div>

                    <form onSubmit={formik.handleSubmit} style={{marginTop:"-50px", maxWidth:"100%"}}>   
                        <div style={{display:"inline-block",width:"100%"}}>                            
                            <div style={{display:"flex",width:"100%", height:"60px", justifyContent:'right'}}>
                                {type==='view'? null :
                                <div className="continue-button" style={{width:"150px", display:"flex"}}>
                                    <button type="submit" style={{marginTop:"12px", height:"49.6px", width:"150px"}}>
                                    <a><AiIcons.AiOutlineSave style={{marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/>Salvar</a> 
                                    </button>
                                </div>       
                                }                          
                                <div className="back-button">                                                       
                                    <button onClick={closeModalItem} type="button">    
                                        <AiIcons.AiOutlineCloseCircle style={{color:"#ff4c4c",marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/><a>Fechar</a> 
                                    </button>
                                </div>                            
                            </div>  

                        </div>  
                   

                        <div className="input-group-edit" style={{paddingBottom:"0px", marginBottom:"0px"}}>                                                                         

                            <div className="input-box" style={{width: "24%"}}>
                                <label>Item</label>
                                <input 
                                readOnly={type==='view'?true:false}
                                disabled={type==='view'?true:false}
                                placeholder={type==='view'?'':"Digite o item"} 
                                id="item"
                                type="item"
                                name="item"
                                onChange={formik.handleChange}
                                value={formik.values.item}
                                />
                                {formik.errors.item && (
                                    <div className="error">{formik.errors.item}</div>
                                )}
                            </div>                    
                    

                            <div className="input-box" style={{width:"24%"}}>
                                <label>Código</label>
                                <input id="codigo" type="text" name="codigo" 
                                readOnly={type==='view'?true:false}
                                disabled={type==='view'?true:false}
                                onChange={formik.handleChange}
                                value={formik.values.codigo}
                                placeholder={type==='view'?'':"Ex.: 000001"} />
                                {formik.errors.codigo && (
                                    <div className="error">{formik.errors.codigo}</div>
                                )}
                            </div>

                            <div className="input-box" style={{width:"24%"}}>
                                <label>Descrição</label>
                                <input id="descricao" type="text" name="descricao"
                                readOnly={type==='view'?true:false}
                                disabled={type==='view'?true:false}
                                onChange={formik.handleChange}
                                value={formik.values.descricao}
                                placeholder={type==='view'?'':"Digite a descrição do item"} />
                            </div>                                                      
                            
                            
                            <div className="input-box" style={{width:"24%"}}>
                                <label htmlFor="modelo">Modelo</label>
                                <input id="modelo" type="text" name="modelo" 
                                readOnly={type==='view'?true:false}
                                disabled={type==='view'?true:false}
                                onChange={formik.handleChange}
                                value={formik.values.modelo}
                                placeholder="" />
                            </div>

                            <div className="input-box" style={{width:"24%"}}>
                                <label htmlFor="quantidade">Quantidade</label>
                                <CurrencyInput id="quantidade" type="text" name="quantidade" 
                                readOnly={type==='view'?true:false}
                                disabled={type==='view'?true:false}                                                               
                                //onChange={formik.handleChange}
                                onValueChange={(value, name) => { 
                                    if(value){            
                                        setQuantidade(value); 
                                        console.log(value.replace(',','.'));
                                        formik.setFieldValue("quantidade", parseFloat(value.replace(',','.')) );
                                    } 
                                    else{
                                        setQuantidade("");
                                        formik.setFieldValue("quantidade", "");
                                    }                                     
                                } }
                                //onKeyDown={e => handleKeyDown(e)}
                                value={itemToEdit === ''? quantidade : formik.values.quantidade}
                                placeholder="0" />
                                {formik.errors.quantidade && (
                                    <div className="error">{formik.errors.quantidade}</div>
                                )}
                            </div>

                            <div className="input-box" style={{width:"24%"}}>
                                <label>Preço Unitário</label>
                                <CurrencyInput id="preco_unitario" name="preco_unitario" type="text" 
                                readOnly={type==='view'?true:false}
                                disabled={type==='view'?true:false}
                                onValueChange={(value, name) => { 
                                    if(value){            
                                        setPrecoUnitario(value); 
                                        console.log(value.replace(',','.'));
                                        formik.setFieldValue("preco_unitario", parseFloat(value.replace(',','.')) );
                                    } 
                                    else{
                                        setPrecoUnitario("");
                                        formik.setFieldValue("preco_unitario", "");
                                    }                                     
                                } }
                                //onChange={formik.handleChange}
                                value={itemToEdit==='' ? precoUnitario : formik.values.preco_unitario}
                                placeholder="0,00" />
                                {formik.errors.preco_unitario && (
                                    <div className="error">{formik.errors.preco_unitario}</div>
                                )}
                            </div>

                            <div className="input-box" style={{width:"24%"}}>
                                <label>Preço Total</label>
                                <input id="preco_total" name="preco_total" type="text" 
                                onChange={formik.handleChange}
                                value={
                                    itemToEdit === "" ? (quantidade.replace(',','.') * precoUnitario.replace(',','.')).toFixed(2)
                                    : parseFloat(formik.values.quantidade * formik.values.preco_unitario).toFixed(2)
                                }
                                readOnly disabled
                                placeholder="" />
                            </div>
                            
                            <div className="input-box" style={{width:"24%"}}>
                                <label>Acionamento</label>
                                <input id="acionamento" name="acionamento" type="text" 
                                readOnly={type==='view'?true:false}
                                disabled={type==='view'?true:false}
                                onChange={formik.handleChange}
                                value={formik.values.acionamento}
                                //onChange={(e) => setCelular(e.target.value)}
                                //value={celular?celular:''}
                                placeholder="" />
                            </div>
                            <div className="input-box" style={{width:"24%"}}>
                                <label>Grupo</label>
                                <input id="grupo" name="grupo" type="text" 
                                readOnly={type==='view'?true:false}
                                disabled={type==='view'?true:false}
                                onChange={formik.handleChange}
                                value={formik.values.grupo}
                                placeholder="" />
                            </div>
                            <div className="input-box" style={{width:"24%"}}>
                                <label>Tecido</label>
                                <input id="tecido" name="tecido" type="text" 
                                readOnly={type==='view'?true:false}
                                disabled={type==='view'?true:false}
                                onChange={formik.handleChange}
                                value={formik.values.tecido}
                                placeholder="" />
                            </div>
                            <div className="input-box" style={{width:"24%"}}>
                                <label>Coleção</label>
                                <input id="colecao" name="colecao" type="text" 
                                readOnly={type==='view'?true:false}
                                disabled={type==='view'?true:false}
                                onChange={formik.handleChange}
                                value={formik.values.colecao}
                                placeholder="" />
                            </div>
                            <div className="input-box" style={{width:"24%"}}>
                                <label>Cor do perfil</label>
                                <input id="cor_perfil" name="cor_perfil" type="text" 
                                readOnly={type==='view'?true:false}
                                disabled={type==='view'?true:false}
                                onChange={formik.handleChange}
                                value={formik.values.cor_perfil}
                                placeholder="" />
                            </div>
                            <div className="input-box" style={{width:"24%"}}>
                                <label>Motor</label>
                                <input id="motor" name="motor" type="text" 
                                readOnly={type==='view'?true:false}
                                disabled={type==='view'?true:false}
                                onChange={formik.handleChange}
                                value={formik.values.motor}
                                placeholder="" />
                            </div>
                            <div className="input-box" style={{width:"24%"}}>
                                <label>Largura</label>
                                <input id="largura" name="largura" type="text" 
                                readOnly={type==='view'?true:false}
                                disabled={type==='view'?true:false}
                                onChange={formik.handleChange}
                                value={formik.values.largura}
                                placeholder="" />
                            </div>
                            <div className="input-box" style={{width:"24%"}}>
                                <label>Altura</label>
                                <input id="altura" name="altura" type="text" 
                                readOnly={type==='view'?true:false}
                                disabled={type==='view'?true:false}
                                onChange={formik.handleChange}
                                value={formik.values.altura}
                                placeholder="" />
                            </div>
                            <div className="input-box" style={{width:"24%"}}>
                                <label>Altura do comando</label>
                                <input id="altura_comando" name="altura_comando" type="text"
                                readOnly={type==='view'?true:false}
                                disabled={type==='view'?true:false} 
                                onChange={formik.handleChange}
                                value={formik.values.altura_comando}
                                placeholder="" />
                            </div>
                            <div className="input-box" style={{width:"24%"}}>
                                <label>Lado do comando</label>
                                <input id="lado_comando" name="lado_comando" type="text" 
                                readOnly={type==='view'?true:false}
                                disabled={type==='view'?true:false}
                                onChange={formik.handleChange}
                                value={formik.values.lado_comando}
                                placeholder="" />
                            </div>
                            <div className="input-box" style={{width:"48%"}}>
                                <label>Observação</label>
                                <input id="observacao" name="observacao" type="text" 
                                readOnly={type==='view'?true:false}
                                disabled={type==='view'?true:false}
                                onChange={formik.handleChange}
                                value={formik.values.observacao}
                                placeholder="" />
                            </div>
                            <div className="input-box" style={{width:"24%"}}>
                                <label>Lista de Acessórios</label>
                                <input id="lista_acessorios" name="lista_acessorios" type="text" 
                                readOnly={type==='view'?true:false}
                                disabled={type==='view'?true:false}
                                onChange={formik.handleChange}
                                value={formik.values.lista_acessorios}
                                placeholder="" />
                            </div>
                       

                        </div>
                    </form>



                </div>{/* Fim da div m-5*/}
                </div>
                

            </DivAddItem>
        </DivModalAddItem>
    )
}

export default ModalAddItem;