import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './style.css';
import PermissionGate from "../../services/PermissionGate";
import LeftMenu from "../../components/LeftMenu";
import styled from 'styled-components';
import { SidebarContext } from "../../AppRoutes";
import * as AiIcons from 'react-icons/ai';
import * as SlIcons from 'react-icons/sl';
import { getQrCodeWhatsapp } from "../../services/api";
import Loading from "react-loading";

const Teste = styled.div`
    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;
    z-Index: 9;
`;

const WhatsappSync = () => {
    const { sidebar, setSidebar } = useContext(SidebarContext);; //TESTANDO CONST NO AppROUTES
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const [qrCodeImg, setQrCodeImg] = useState('');
    const [generatingQrCode, setGeneratingQrCode] = useState(false);
    const [isLoged, setIsLoged] = useState(false);

    const fetchQrCode = () => {
        console.log('Fetching QR Code...');
        setGeneratingQrCode(true);
        getQrCodeWhatsapp('65b03b8c756df35f6333a9fb').then((response) => {
            console.log('API Response:', response);

            if (response.data.qrcode.error === 'is_loged') {
                console.log('User is logged in, stopping QR code regeneration.');
                setIsLoged(true);
                setGeneratingQrCode(false);
                return;
            }

            setQrCodeImg(response.data.qrcode.qr);

            const ttl = response.data.qrcode.ttl;
            const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
            console.log('Current Time:', currentTime);
            console.log('TTL:', ttl);

            const timeoutDuration = (ttl + 30 - currentTime) * 1000; // Time until 30 seconds after ttl in milliseconds
            console.log('Timeout Duration (ms):', timeoutDuration);

            if (timeoutDuration > 0) {
                setTimeout(() => {
                    console.log('Regenerating QR Code...');
                    fetchQrCode();
                }, timeoutDuration);
            } else {
                console.log('TTL has already passed.');
            }
        }).catch((error) => {
            console.log('Error:', error);
            setGeneratingQrCode(false);
        }).finally(() => {
            console.log('Finally block executed.');
            setGeneratingQrCode(false);
        });
    };

    useEffect(() => {
        fetchQrCode();
    }, []);

    const handleBack = () => {
        navigate("/list-item");
    };

    return (
        <>
            <PermissionGate permissions={['default']}>
                <LeftMenu setSidebar={setSidebar} sidebar={sidebar} />
                <Teste sidebar={sidebar}>
                    <div className="m-5">
                        <form style={{ marginTop: "-30px" }}>
                            <div className="form-header" style={{ marginBottom: "0px" }}>
                                <div className="title">
                                    <h1>Sincronizar WhatsApp</h1>
                                </div>
                            </div>

                            <div className="contentItemComplete">
                                <div className="input-group inputGroup-adicinaItem">
                                    <div className="formGroupRow">
                                        <div style={{ backgroundColor: 'white', padding: '20px', display: 'flex', flexDirection: 'column', gap: '20px', margin: 'auto' }}>
                                            <h2>Para sincronizar o WhatsApp faça os seguintes passos</h2>
                                            <ol style={{ paddingLeft: '20px' }}>
                                                <li>Abra o WhatsApp no seu celular.</li>
                                                <li>Toque em Mais opções no Android ou em Configurações no iPhone</li>
                                                <li>Toque em Dispositivos conectados e, em seguida, em Conectar dispositivo.</li>
                                                <li>Aponte seu celular para esta tela para escanear o QR code.</li>
                                            </ol>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <div style={{ padding: '10px', backgroundColor: 'white', border: '1px solid #E0E0E0', borderRadius: '8px' }}>
                                                    <div style={{ width: '340px', height: '340px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '8px' }}>
                                                        {
                                                            generatingQrCode ? <Loading type={"spinningBubbles"} className="generatingQrCodeLoading" />
                                                                :
                                                                <img src={`${qrCodeImg}`} alt="QR Code" />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </Teste>
            </PermissionGate>
        </>
    );
};

export default WhatsappSync;
