import React from 'react';
import { Input as InputAntd, InputProps as InputPropsAntd } from 'antd';

import { BoxInput, TitleInput } from './input.styles';

const Input = ({ title, margin, onChange, ...props }) => {
    //console.log("margin",margin)
    const newMargin = "0";
    return (
      <BoxInput style={{ newMargin }}>
        {title && <TitleInput>{title}</TitleInput>}
        <InputAntd onChange={onChange} {...props} />
      </BoxInput>
    );
};

export default Input;