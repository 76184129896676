import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import imgTeste from '../../img/imgTeste.png'
import './styleMobile.css'
import CryptoJS from 'crypto-js';
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa";
import ModalConfirmInfoCliente from "../../components/ModalConfirmInfoCliente";
import ModalPaymentCash from "../../components/ModalPaymentCash";
import InputMask from "react-input-mask";
import meuPdfPoliticaPrivacy from '../../assets/img/politica_privacidade.pdf';
import { deleteEnderecoCliente, registerPedido, calcularDistancia, registerClienteFromCardapio } from "../../services/api";
import { toast } from "react-toastify";
import { FcClock } from "react-icons/fc";
import { FaPix } from "react-icons/fa6";
import Loading from "react-loading";
import ToastWithLink from './ToastWithLink';  // Ajuste o caminho conforme necessário
import axios from 'axios';

const FinalizarPedido = () => {
    const location = useLocation();
    const { nomeEmpresaForUrl, idEmpresa } = useParams();
    const localPersonInfo = JSON.parse(localStorage.getItem('localPersonInfo'));
    var { personInfoObj } = location.state || {};
    const [valorTroco, setValorTroco] = useState(0);
    //console.log(personInfoObj);
    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';

    const empresaEncrypted = sessionStorage.getItem('sessionCompany');
    const empresaDecrypted = empresaEncrypted ? JSON.parse(CryptoJS.AES.decrypt(empresaEncrypted, secretKey).toString(CryptoJS.enc.Utf8)) : null;
    const empresa = empresaDecrypted;
    const regionType = empresa.region_type_delivery;
    const bairros_entrega = empresa.bairros_entrega;
    //const stateFromItemModal = location.state;
    //var fromItemAddModal = false;
    //if(stateFromItemModal !== null){
    //    fromItemAddModal = location.state.fromItemAddModal;
    //} 

    // Obtém o external_id da URL atual, se existir
    const [searchParams] = useSearchParams();
    const externalId = searchParams.get('external_id');

    const navigate = useNavigate();
    const handleBack = () => {
        navigate(
            externalId
              ? `/${nomeEmpresaForUrl}/${idEmpresa}/carrinho?external_id=${externalId}`
              : `/${nomeEmpresaForUrl}/${idEmpresa}/carrinho`
        );
    }

    const enderecoEmpresa = {
        rua: empresa.logradouro,
        numero: empresa.address_number,
        bairro: empresa.bairro,
        cidade: empresa.municipio,
        estado: empresa.estado
    }

    //const [itensCarrinho, setItensCarrinho] = useState([]);
    const [carrinho, setCarrinho] = useState([]);
    const [showConfirmInfo, setShowConfirmInfo] = useState(false);
    //const [infoPersonalData, setInfoPersonalData] = useState(false);
    const [whatsappData, setWhatsappData] = useState(localPersonInfo?localPersonInfo.telefone:"");
    const [namePersonData, setNamePersonData] = useState(localPersonInfo?localPersonInfo.nome:"");
    //const [idEmpresa, setIdEmpresa] = useState("");
    const [clientCompleteData, setClientCompleteData] = useState(personInfoObj?personInfoObj:"");
    const [selectedAddressToDelivery, setSelectedAddressToDelivery] = useState("");
    const [selectedAddressToRetirada, setSelectedAddressToRetirada] = useState("");
    const [paymentType, setPaymentType] = useState("");
    const [showModalPaymentCash, setShowModalPaymentCash] = useState(false);
    const [valorEntrega, setValorEntrega] = useState(0);
    const [calculandoDistancia, setCalculandoDistancia] = useState(false);

    const [currentTime, setCurrentTime] = useState(""); 

    const [valueDescricao, setValueDescricao] = useState('');
    const descriptionRef = useRef();
    const handleMaxCharDescricao = (e) => {
        const newValue = e.target.value;
        if (newValue.length <= 200) {
        setValueDescricao(newValue);
        }
    };
    const [isSearchingClient, setIsSearchingClient] = useState(true);
    useEffect(() => {
        const fetchClientData = async () => {
            if (carrinho.length===0) {
            }
            if (localPersonInfo) {
                try {
                    const response = await registerClienteFromCardapio(idEmpresa, localPersonInfo.nome, localPersonInfo.telefone);
                    if(response){ 
                        console.log('Cliente processado!');
                        setClientCompleteData(response.data.cliente);
                        setIsSearchingClient(false);
                    }
    
                    if (localPersonInfo.entrega.tipo_entrega === "Entrega") {
                        await calcularValorEntrega(localPersonInfo.entrega.endereco);
                        setPaymentType(localPersonInfo.tipo_pagamento);
                    } else if (localPersonInfo.entrega.tipo_entrega === "Retirada") {
                        setSelectedAddressToRetirada(enderecoEmpresa);
                        setPaymentType(localPersonInfo.tipo_pagamento);
                        setIsSearchingClient(false);
                    }
                } catch (error) {
                    console.log('Erro ao processar cliente:', error);
                    setIsSearchingClient(false);
                }
            } else {
                setIsSearchingClient(false);
            }
        }
        fetchClientData();
    }, []);

    useEffect(() => {
        const fetchTime = async () => {
            try {
                if (empresa && currentTime==="" && isSearchingClient===false) {  // Only fetch if currentTime is not set or if data is not loading
                    const response = await axios.get('https://api.timezonedb.com/v2.1/get-time-zone', {
                        params: {
                            key: "7UIGZ1GOJE0U",
                            format: 'json',
                            by: 'zone',
                            zone: empresa.timezone
                        }
                    });
                    const newTime = response.data.formatted;
                    if (newTime !== currentTime) {  // Check if the new time is different
                        setCurrentTime(newTime);
                    }
                    console.log("Timezone data fetched:", newTime);
                    //setLoadingData(false);  // Set loading to false after fetching the time
                }
            } catch (error) {
                console.error('Erro ao obter o horário atual:', error);
                //setLoadingData(false);  // Also set loading to false on error
            }
        };
        fetchTime();
    }, [empresa, currentTime]);

    useEffect(() => {
        const itensCarrinhoTemp = JSON.parse(localStorage.getItem('itensCarrinho'));
        if(!itensCarrinhoTemp){
            navigate(
                externalId
                  ? `/${nomeEmpresaForUrl}/${idEmpresa}/carrinho?external_id=${externalId}`
                  : `/${nomeEmpresaForUrl}/${idEmpresa}/carrinho`
            );
        }
        //setItensCarrinho(itensCarrinhoTemp || []);
        setCarrinho(itensCarrinhoTemp || []);
        console.log('carrinho>',itensCarrinhoTemp)
        //if(itensCarrinhoTemp){
        //    setIdEmpresa(itensCarrinhoTemp[0].id_empresa);
        //}
    }, []);

    /*const handleDecrement = (id, index) => {
        setCarrinho(itens => {
            const itemToUpdate = itens[index];
            if (itemToUpdate.quantity === 1) {
                const filteredItems = itens.filter(item => item.id !== id);
                setItensCarrinho(filteredItems);
                localStorage.setItem('itensCarrinho', JSON.stringify(filteredItems));
                return filteredItems;
            } else {
                itemToUpdate.quantity--;
                const updatedItems = [...itens.slice(0, index), itemToUpdate, ...itens.slice(index + 1)];
                setItensCarrinho(updatedItems);
                localStorage.setItem('itensCarrinho', JSON.stringify(updatedItems));
                return updatedItems;
            }
        });
    }

    const handleIncrement = (id, index) => {
        setCarrinho(itens => {
            const itemToUpdate = itens[index];
            itemToUpdate.quantity++;
            const updatedItems = [...itens.slice(0, index), itemToUpdate, ...itens.slice(index + 1)];
            setItensCarrinho(updatedItems);
            localStorage.setItem('itensCarrinho', JSON.stringify(updatedItems));
            return updatedItems;
        });
    }*/

    const handleChangeAddress = () => {
        const localPersonInfo = JSON.parse(localStorage.getItem('localPersonInfo'));
        if (localPersonInfo) {
            localPersonInfo.entrega = {};
            localStorage.setItem('localPersonInfo', JSON.stringify(localPersonInfo));
        }
        setSelectedAddressToDelivery("");
        setValorEntrega(0);
    }

    function isStoreOpen(status_loja, schedule, currentTime) {
        if(!schedule && status_loja!=='sempre'){ return false;}
        if(status_loja==='sempre'){ return true;}
        if(!currentTime || currentTime===""){ return false;}
        // Extrai a data e a hora atual
        const now = new Date(currentTime);
        const currentDayOfWeek = now.getDay(); // Dias da semana como número (0=domingo, 1=segunda, etc.)
        const weekDays = ['domingo', 'segunda', 'terca', 'quarta', 'quinta', 'sexta', 'sabado'];
        const currentDayName = weekDays[currentDayOfWeek];
    
        // Checa se há um horário definido para o dia atual
        const periods = schedule[currentDayName];
        if (!periods) {
            return false; // Sem horários definidos para o dia
        }

        console.log("schedule>",schedule)
    
        // Formata a hora e minuto atual para comparação
        const currentHour = now.getHours();
        const currentMinute = now.getMinutes();
        const currentTimeFormatted = currentHour * 100 + currentMinute; // HHMM como número
    
        // Verifica cada período de abertura

        for (const period of periods) {
            const startHourMinute = period.start.split(':').map(Number); // Converte "HH:MM" para [HH, MM]
            const endHourMinute = period.end.split(':').map(Number); // Converte "HH:MM" para [HH, MM]
            const startTime = startHourMinute[0] * 100 + startHourMinute[1]; // Converte para número HHMM
            const endTime = endHourMinute[0] * 100 + endHourMinute[1]; // Converte para número HHMM
    
            // Corrige o período que cruza a meia-noite
            if (endTime < startTime) {
                // Caso o tempo atual seja depois do início ou antes da "extensão" do período para o próximo dia
                if (currentTimeFormatted >= startTime || currentTimeFormatted < endTime) {
                    return true;
                }
            } else {
                // Caso o tempo atual esteja dentro do período normal
                if (currentTimeFormatted >= startTime && currentTimeFormatted < endTime) {
                    return true;
                }
            }
        }
    
        return false; // Nenhum período válido foi encontrado
    }

    /*const obtemValorItem = (valorItemEncrypted, quantidade) => {
        const decryptedPrice = CryptoJS.AES.decrypt(valorItemEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
        const valorTotalItem = quantidade * parseFloat(decryptedPrice);
        let stringDescryptedPrice = valorTotalItem.toFixed(2).toString().replace('.',',');
        return stringDescryptedPrice;
    }    

    const calcularValorSubTotal = (itens) => {
        let total = 0;
        itens.forEach(item => {
            const valorItem = obtemValorItem(item.price, item.quantity);
            const valorItemFloat = parseFloat(valorItem.replace(',', '.'));
            total += valorItemFloat;
        });
        return total.toFixed(2).toString().replace('.', ',');
    }

    const calcularValorTotalPedido = (itens) => {
        let total = 0;
        itens.forEach(item => {
            const valorItem = obtemValorItem(item.price, item.quantity);
            const valorItemFloat = parseFloat(valorItem.replace(',', '.'));
            total += valorItemFloat;
        });
        total+=valorEntrega;
        return total.toFixed(2).toString().replace('.', ',');
    }*/

        const obtemValorItemTotal = (valorTotalEncrypted) => {
            const decryptedTotal = CryptoJS.AES.decrypt(valorTotalEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
            const valorTotalItem = parseFloat(decryptedTotal);
            return valorTotalItem.toFixed(2).toString().replace('.', ',');
        };
        
        const calcularValorSubTotal = (itens) => {
            let total = 0;
            itens.forEach(item => {
                // Descriptografa o total diretamente, pois já está multiplicado pela quantidade
                const decryptedTotal = parseFloat(CryptoJS.AES.decrypt(item.total, secretKey).toString(CryptoJS.enc.Utf8));
                total += decryptedTotal; // Apenas soma o valor já descriptografado
            });
            return total.toFixed(2).toString().replace('.', ',');
        };
        
        const calcularValorTotalPedido = (itens) => {
            let total = 0;
            itens.forEach(item => {
                // Descriptografa o total diretamente, pois já está multiplicado pela quantidade
                const decryptedTotal = parseFloat(CryptoJS.AES.decrypt(item.total, secretKey).toString(CryptoJS.enc.Utf8));
                total += decryptedTotal; // Apenas soma o valor já descriptografado
            });
            total += valorEntrega; // Adiciona o valor da entrega
            return total.toFixed(2).toString().replace('.', ',');
        };

    const handleAvancar = () => {
        if(whatsappData!== "" && namePersonData!== ""){            
            setShowConfirmInfo(true)
            //setInfoPersonalData(true)
        }
        else
        toast('Preencha todos os campos!', {autoClose:3000,type: 'error'});
    }
    const valorTotalPedido = calcularValorTotalPedido(carrinho);
    const handlePaymentCash = () => {
        setShowModalPaymentCash(true);        
        //console.log(valorTroco);
        //setPaymentType(paymentType);
    }

    const handleCadastraEndereco = () => {
        personInfoObj = clientCompleteData;
        navigate(
            externalId
              ? `/${nomeEmpresaForUrl}/${idEmpresa}/cadastrar-endereco?external_id=${externalId}`
              : `/${nomeEmpresaForUrl}/${idEmpresa}/cadastrar-endereco`,
            { state: { personInfoObj } }
        );
    }

    const handleEdit = (id, e) => {
        e.stopPropagation();
        personInfoObj = clientCompleteData;
        const enderecoToEdit = personInfoObj.endereco.find(endereco => endereco._id === id);
        navigate(
            externalId
              ? `/${nomeEmpresaForUrl}/${idEmpresa}/cadastrar-endereco?external_id=${externalId}`
              : `/${nomeEmpresaForUrl}/${idEmpresa}/cadastrar-endereco`,
            { state: { personInfoObj, enderecoToEdit } }
        );
    }

    const handleDelete = (event, id) => {
        event.stopPropagation();
        deleteEnderecoCliente(idEmpresa, clientCompleteData.telefone, id).then(response => {
            if(response.status === 200){
                //const personInfoObj = estava errado
                personInfoObj = response.data.response;
                setClientCompleteData(personInfoObj);                
                toast('Endereço deletado com sucesso!', {autoClose:3000,type: 'success'});
            }
            else {
                console.log('Erro ao deletar endereço!');
            }
        });
    }

    /*useEffect(() => {
        console.log("Entrou no useEffect")
        calcularValorEntrega();
    }, [selectedAddressToDelivery, selectedAddressToRetirada]);*/

    const calcularValorEntrega = async (endereco) => {
        setCalculandoDistancia(true);
        if (regionType === "bairro") {
            // Pega o bairro do endereço
            const bairroSelecionado = endereco.bairro;
    
            // Busca o bairro correspondente no array bairros_entrega
            const bairroEncontrado = bairros_entrega.find(bairro => bairro.bairro_entrega === bairroSelecionado);
    
            if (bairroEncontrado) {
                // Retorna o valor da entrega do bairro encontrado
                const valorEntrega = bairroEncontrado.valor_entrega;
                console.log(`Valor da entrega para ${bairroSelecionado}: ${valorEntrega}`);
                setSelectedAddressToDelivery(endereco);
                setValorEntrega(valorEntrega);
                setCalculandoDistancia(false);
                return;
            } else {
                // Caso o bairro não seja encontrado
                console.error("Bairro não encontrado no array de bairros de entrega");
                toast("Não atendemos essa região de entrega", { autoClose: 5000, type: "error" });
                setCalculandoDistancia(false);
                return null;
            }
        }
        if(endereco){
            try {
                console.log("endereco>",endereco)
                // Recuperar o item
                //const empresaFromSession = sessionStorage.getItem("sessionCompany");
                //const decryptedEmpresa = CryptoJS.AES.decrypt(empresaFromSession, secretKey).toString(CryptoJS.enc.Utf8);
                //const empresa = JSON.parse(decryptedEmpresa);            
                //let cepFormatadoEmpresa = empresa.cep.replace(/-/g, '');
                //let cepFormatadoCliente = endereco.cep.replace(/-/g, '');
                let coordenadasEmpresa = {
                    lat:empresa.latitude,
                    lng:empresa.longitude
                }
                let coordenadasCliente = {
                    lat:endereco.latitude,
                    lng:endereco.longitude
                }
                var distanciaCalculadaKm;
                const response = await calcularDistancia(coordenadasEmpresa, coordenadasCliente);
                console.log("Distancia calculada>",response.data.distance)
                distanciaCalculadaKm = response.data.distance;
                // Convertendo a distanciaCalculada de km para metros
                const distanciaCalculadaMetros = distanciaCalculadaKm * 1000;
                // Ordenando os raios de entrega em ordem crescente
                console.log(empresa);
                const raiosOrdenados = empresa.raios_entrega.sort((a, b) => a.raio_entrega - b.raio_entrega);
                // Encontrando o primeiro raio que é maior ou igual à distanciaCalculada
                const raioAdequado = raiosOrdenados.find(item => item.raio_entrega >= distanciaCalculadaMetros);
                if (!raioAdequado) {
                    toast("Não atendemos essa região de entrega", { autoClose: 5000, type: "error" });
                    console.log("não atendemos essa região de entrega");
                } else {
                    setSelectedAddressToDelivery(endereco);
                    setValorEntrega(raioAdequado.valor_entrega);
                    console.log(raioAdequado.valor_entrega);
                }
            } catch (error) {
                console.error('Erro ao calcular a distância:', error);
                toast('Erro ao calcular a distância', { autoClose: 5000, type: "error" });
            } finally {
                // Garante que setIsSearchingClient(false) será chamado independente do resultado das operações acima
                setCalculandoDistancia(false);
                setIsSearchingClient(false);
            }

                //console.log("Empresa>", empresa);
                //return 0;
        } else {
            // Garantir que o estado seja atualizado mesmo se não entrar na lógica principal
            setCalculandoDistancia(false);
            setIsSearchingClient(false);
        }
            /*
            else if(selectedAddressToRetirada !== ""){
                console.log("selectedAddressToRetirada>",selectedAddressToRetirada)
                return 0;
            }
            else {
                console.log("Nenhuma condição atendida para calcular valor de entrega!")
                return 0;
            }*/
  
    }

    const handleClickRetirada = (endereco) => {
        setSelectedAddressToRetirada(endereco);
        setValorEntrega(0);
    }

    const handleFinalizarPedido = () => {
        //console.log("empresa.status_loja>",empresa.status_loja)
        const statusLoja = isStoreOpen(empresa.status_loja, empresa.horario_funcionamento, currentTime);
        if(!statusLoja) {
            toast.error(<ToastWithLink nomeEmpresaForUrl={nomeEmpresaForUrl}
                idEmpresa={idEmpresa}
                empresa={empresa}
                navigate={navigate} />);
            return;
        }

        const createdBy = "cardapio";
        const itens = carrinho.map(item => {
            console.log("ITEM>",item)
            const { title, price, quantity, grupos_adicionais_selecionados, observacoes_item } = item;
            const decryptedPrice = CryptoJS.AES.decrypt(price, secretKey).toString(CryptoJS.enc.Utf8);
            //const decryptedTotal = parseFloat(CryptoJS.AES.decrypt(item.total, secretKey).toString(CryptoJS.enc.Utf8));
            const valorOriginal = parseFloat(decryptedPrice);

            // Calcular a soma dos valores dos adicionais
            const valorAdicionais = grupos_adicionais_selecionados.reduce((totalGrupo, grupo) => {
                const totalAdicionais = grupo.adicionais.reduce((total, adicional) => {
                    return total + (adicional.price * adicional.quantity);
                }, 0);
                return totalGrupo + totalAdicionais;
            }, 0);

            // Subtrair o valor dos adicionais do valor original
            const valor = valorOriginal; //- valorAdicionais;
            const nomeItem = title;
            const quantidade = quantity;
            const grupo_adicionais = grupos_adicionais_selecionados;
            const obs_item = observacoes_item;
            return { nomeItem, valor, quantidade, grupo_adicionais, obs_item };
        });
        const celular_cliente = clientCompleteData.telefone;
        const nome_cliente = clientCompleteData.nome;
        const tipo_pagamento = paymentType;
        const entrega = {
            tipo_entrega: selectedAddressToDelivery !== "" ? "Entrega" : "Retirada",
            endereco: selectedAddressToDelivery !== "" ? selectedAddressToDelivery : enderecoEmpresa,
            valor: valorEntrega
        }
        const desconto = 0;
        const cpf_cnpj = "";
        const valor_troco = valorTroco;
        const valor_total = parseFloat(calcularValorTotalPedido(carrinho).replace(',', '.'));
        const descricao = valueDescricao;
        const external_id = externalId ? externalId : null;
        /*console.log({
            createdBy,
            idEmpresa,
            itens,
            celular_cliente,
            nome_cliente,
            tipo_pagamento,
            entrega,          
            desconto,
            cpf_cnpj,
            valor_troco,
            valor_total,
            descricao,
            external_id
        })*/
        
        registerPedido(createdBy, idEmpresa, itens, celular_cliente, nome_cliente, tipo_pagamento, entrega, desconto, cpf_cnpj,valor_troco,valor_total,descricao,external_id).then(cast => {
            console.log("AQUI DEU BOM, CRIOU PEDIDO:::",cast)
            if(cast.status===201){
                toast(cast.data.msg, {autoClose: 5000, type: "success"});
                localStorage.setItem('localPersonInfo', JSON.stringify({nome: nome_cliente, telefone: celular_cliente, entrega:entrega, tipo_pagamento: tipo_pagamento }));
                localStorage.removeItem('itensCarrinho');
                setCarrinho([]);
                //setItensCarrinho([]);
                navigate(
                    externalId
                      ? `/cardapio/${nomeEmpresaForUrl}/${idEmpresa}?external_id=${externalId}`
                      : `/cardapio/${nomeEmpresaForUrl}/${idEmpresa}`
                );
            }
            else {
                toast(cast.data.msg, {autoClose: 5000, type: "error"});
            }
            
        }).catch(err => {
            console.log('Oh noooo!!');
            const msg = err.response.data.msg;
            //console.log("MENSAGEM", msg)
            toast(msg, {autoClose: 5000, type: "error"});            
        });
    
    }

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" });
    }, []);

    return (

        <div className="flex flex-col max-w-sm mx-auto bg-white rounded-lg shadow-md overflow-hidden md:max-w-2xl">
            <div className="carrinhoHeader">    
                <div className="backButtonCarrinho" onClick={()=>handleBack()}>            
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={28}
                    height={28}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-chevron-left"
                    >
                    <polyline points="15 18 9 12 15 6" />
                    </svg>                      
                </div>
                <span>Finalizar Pedido</span> 
            </div>

            <div className="carrinhoContainer">
            {isSearchingClient ? <Loading type={"spinningBubbles"} className="calculandoDistanciaLoading"/> 
            :
            <> 
                {calculandoDistancia && <Loading type={"spinningBubbles"} className="calculandoDistanciaLoading"/>}
                { clientCompleteData === "" ?                
                    <div>
                        <form className="personal-data" style={{padding:10}}>
                            <div className="column items-start field-container mb-2">
                                <label className="whatsappNumberFinalizarPedido">
                                    Seu número de WhatsApp é:
                                </label>
                                <InputMask type="tel" mask="(99) 99999-9999" maskChar={''} value={whatsappData} onChange={(e) => setWhatsappData(e.target.value)} className="field-input w-100 pa-3 whatsappNumberFinalizarPedidoInput" />
                            </div>
                            <div className="column items-start field-container mb-2">
                                <label className="whatsappNumberFinalizarPedido">
                                    Seu nome e sobrenome:
                                </label>
                                <input type="text" value={namePersonData} onChange={(e) => setNamePersonData(e.target.value)} className="field-input w-100 pa-3 whatsappNumberFinalizarPedidoInput" />
                            </div>
                            <div className="avancarFinalizarPedido">
                                <button type="button" onClick={() => handleAvancar()} 
                                className={carrinho.length>0?"btnAvancarFinalizarPedido":"btnAvancarFinalizarPedido btnBlockNotEnabled"}
                                disabled={carrinho.length>0?false:true} readOnly={carrinho.length>0?false:true} >
                                    <p style={{marginBottom:"0px"}}>Avançar</p>                               
                                </button>
                            </div>
                            <p className="description-text font-1 mt-3" style={{textAlign:"center"}}>
                            Para realizar seu pedido vamos precisar de suas informações, este é um
                            ambiente protegido.
                            </p>
                        </form>
                    </div>
                    :
                    <>
                    <div className="checkout-form">
                        <div className="delivery-info">
                            <div>
                                <p>Este pedido será entregue a:</p>
                                <h2>{clientCompleteData.nome}</h2>
                                <p>{clientCompleteData.telefone}</p>
                                </div>
                            <div>
                                <button onClick={() => {
                                    setClientCompleteData("")
                                    setSelectedAddressToDelivery("")
                                    setSelectedAddressToRetirada("")
                                    setPaymentType("")
                                    localStorage.removeItem('localPersonInfo');
                                }} className="btnChangeFimPedido">Trocar</button>
                            </div>
                        </div>

                        <div className="delivery-method" style={{
                            border: selectedAddressToDelivery!=="" ? '2px solid rgb(230,230,230)' : selectedAddressToRetirada!=="" && '2px solid rgb(230,230,230)'
                        }}>

                            { selectedAddressToDelivery !== "" || selectedAddressToRetirada!=="" ?
                                <div className="withAddressSelected" style={{fontSize:20}}>
                                    {selectedAddressToDelivery !== "" ? "Entrega" : "Retirada"}                                
                                </div>
                                :
                                <div className="withAddress" style={{fontSize:clientCompleteData.endereco.length>0?16:22}}>
                                    Selecione a forma de entrega   
                                    {   clientCompleteData.endereco.length>0 &&           
                                        <button className="buttonNewAddress" onClick={()=> handleCadastraEndereco()}>
                                            <span style={{fontSize:13, fontWeight:700}}>Novo Endereço</span>
                                        </button>
                                    }                               
                                </div>  
                            }
                                     

                                {selectedAddressToDelivery !== "" ?
                                    <>
                                        <div className="delivery-options" style={{padding:'10px', display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}} onClick={()=> setSelectedAddressToDelivery("")}>
                                            <div className="endEntregaMap" style={{display:'flex', flexDirection:'column', lineHeight:'1'}}>
                                                <h6>Endereço de entrega</h6>
                                                <p style={{fontSize:13}}>{selectedAddressToDelivery.rua}, {selectedAddressToDelivery.numero}</p>
                                                <p style={{fontSize:13}}>{selectedAddressToDelivery.bairro}, {selectedAddressToDelivery.cidade}</p>
                                            </div>
                                            <div>
                                                <button onClick={()=> {
                                                    handleChangeAddress();
                                                }} 
                                                className="btnChangeFimPedido" style={{maxWidth:'80px', fontSize:12}}>Trocar</button>
                                            </div>
                                        </div>
                                        <div style={{padding:10, display:'flex', gap:5, alignItems:'center'}}>
                                            <FcClock /> 
                                            <span style={{fontSize:14, color:"gray"}}>Tempo de entrega estimado: {empresa.tempoEntregaMin} a {empresa.tempoEntregaMax} min</span>
                                        </div>        
                                    </>
                                    :
                                    selectedAddressToRetirada !=="" ?
                                    <>
                                        <div className="delivery-options" style={{padding:'10px', display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}} onClick={()=> setSelectedAddressToDelivery("")}>
                                            <div className="endEntregaMap" style={{display:'flex', flexDirection:'column', lineHeight:'1'}}>
                                                <h6>Retirada no estabelecimento</h6>
                                                <p style={{fontSize:13}}>{selectedAddressToRetirada.rua}, {selectedAddressToRetirada.numero}</p>
                                                <p style={{fontSize:13}}>{selectedAddressToRetirada.bairro}, {selectedAddressToRetirada.cidade}</p>
                                            </div>
                                            <div>
                                                <button onClick={()=> setSelectedAddressToRetirada("")} 
                                                className="btnChangeFimPedido" style={{maxWidth:'80px', fontSize:12}}>Trocar</button>
                                            </div>
                                        </div>
                                        <div style={{padding:10, display:'flex', gap:5, alignItems:'center'}}>
                                            <FcClock /> 
                                            <span style={{fontSize:14, color:"gray"}}>Tempo de entrega estimado: {empresa.tempoBalcaoMin} a {empresa.tempoBalcaoMax} min</span>
                                        </div>        
                                    </>
                                    :
                                    <>
                                    { clientCompleteData.endereco && clientCompleteData.endereco.length>0 ?
                                        clientCompleteData.endereco.map((endereco, index) => {                                    
                                            return (
                                            <div key={index}  className="delivery-options" style={{padding:'10px', display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}
                                            onClick={()=> calcularValorEntrega(endereco)}>
                                                <div className="endEntregaMap" style={{display:'flex', flexDirection:'column', lineHeight:'1'}}>
                                                    <h6>Endereço de entrega</h6>
                                                    <p style={{fontSize:13}}>{endereco.rua}, {endereco.numero}</p>
                                                    <p style={{fontSize:13}}>{endereco.bairro}, {endereco.cidade} - {endereco.estado}</p>
                                                    <div style={{display:'flex', gap:20, paddingTop:5}}>
                                                        <span style={{fontSize:12, color:'#1095F3', fontWeight:700, cursor:'pointer'}}
                                                        onClick={(e)=> handleEdit(endereco._id, e)}>Editar</span>
                                                        <span style={{fontSize:12, color:'red', fontWeight:700, cursor:'pointer'}}
                                                        onClick={(event)=> handleDelete(event, endereco._id)}>Excluir</span>
                                                    </div>
                                                </div>                                                    
                                                <div>
                                                    <div style={{cursor:'pointer'}} className="checkRetirada"/>
                                                </div>
                                            </div>
                                            )
                                        })
                                        :
                                        <div className="delivery-options">
                                            <div className="address">
                                                <h4>Endereço de entrega</h4>
                                                <button className="addAddressButton" onClick={() => handleCadastraEndereco()}
                                                style={{ 
                                                    width: '100%',
                                                    border: 'none',
                                                    borderRadius: '4px',
                                                    background: '#1095F3',
                                                    color: 'white',
                                                    fontWeight: '600',
                                                    fontSize: '14px',
                                                    height: '35px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px'}}
                                                >
                                                    Cadastrar endereço
                                                </button>
                                            </div>                                                                
                                        </div>
                                    }
                                    <div style={{padding:'10px', display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}
                                    onClick={()=> handleClickRetirada(enderecoEmpresa)}>
                                        <div className="pickup">
                                            <h6>Retirar no estabelecimento</h6>
                                            <p style={{fontSize:13}}>{empresa.logradouro}, {empresa.address_number}</p>
                                            <p style={{fontSize:13}}>{empresa.bairro}, {empresa.municipio} - {empresa.estado}</p>
                                        </div>
                                        <div>
                                            <div style={{cursor:'pointer'}} className="checkRetirada"/>
                                        </div>
                                    </div>
                                    </>
                                }                            
                        </div>

                        {selectedAddressToDelivery !== "" || selectedAddressToRetirada !== "" ?
                            <div className={paymentType===""?"payment-method-selected":"payment-method"}>
                                <h3 style={{padding:10, fontSize:20, fontWeight:'600'}}>
                                    {paymentType===""?"Selecione a forma de pagamento":"Forma de pagamento"}</h3>
                                {paymentType!=="" ?
                                    <div style={{background:'white'}}>
                                        <div style={{padding:'10px 10px 0px 10px', display:'flex', flexDirection:'column'}}>
                                            <span style={{color:'rgb(160,160,160)', fontSize:13}}>Forma de pagamento escolhida:</span>
                                            <span style={{color:'rgb(160,160,160)', fontSize:13}}>Pagar na entrega</span>
                                        </div>
                                        <div className="payment-option-selected">
                                            {paymentType==="Pix" ?
                                                <div style={{display:'flex', gap:10, alignItems:'center'}}>
                                                    <FaPix style={{fontSize:30, color:'rgb(55,180,170)'}}/>                                  
                                                    <label htmlFor="Pix">Pix</label>  
                                                </div>
                                                : paymentType==="Dinheiro" ?
                                                <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                                                    <div style={{display:'flex', gap:10, alignItems:'center'}}>
                                                        {svgMoney()}                                     
                                                        <label htmlFor="Dinheiro">Dinheiro</label>  
                                                    </div>
                                                    {valorTroco>0 &&
                                                    <span style={{fontSize:13, fontWeight:500}}>Troco para R$ {valorTroco.toString().replace('.',',')}</span>
                                                    }
                                                </div> 
                                                : paymentType==="Cartão" ?
                                                <div style={{display:'flex', gap:10, alignItems:'center'}}>
                                                    {svgCreditCard()}                                     
                                                    <label htmlFor="Cartão">Cartão</label>  
                                                </div> 
                                                : null
                                            }                                           
                                            <div>
                                                <button onClick={()=> setPaymentType("")} 
                                                className="btnChangeFimPedido" style={{maxWidth:'80px', fontSize:12}}>Trocar</button>
                                            </div>                              
                                        </div>
                                    </div>
                                    :
                                    <div className="payment-options">
                                        <div style={{background:'rgb(230,230,230)', display:'flex', flexDirection:'column', padding:10}}>
                                            <span style={{color:'rgb(90,90,90)', fontSize:14}}>Pagar na entrega</span>
                                            <span style={{color:'gray', fontSize:13}}>Realize o pagamento para o entregador</span>
                                        </div>
                                        <div className="payment-option" onClick={() => setPaymentType("Pix")}>
                                            <div style={{display:'flex', gap:10, alignItems:'center'}}>
                                                <FaPix style={{fontSize:30, color:'rgb(55,180,170)'}}/>                                     
                                                <label htmlFor="Pix">Pix</label>  
                                            </div>                                                                         
                                            <div style={{cursor:'pointer'}} className="checkRetirada"/>                                   
                                        </div>
                                        <div className="payment-option" onClick={() => handlePaymentCash()}>
                                            <div style={{display:'flex', gap:10, alignItems:'center'}}>
                                                {svgMoney()}                                    
                                                <label htmlFor="Dinheiro">Dinheiro</label>  
                                            </div> 
                                            <div style={{cursor:'pointer'}} className="checkRetirada"/>                                      
                                        </div>
                                        <div className="payment-option" onClick={() => setPaymentType("Cartão")}>
                                            <div style={{display:'flex', gap:10, alignItems:'center'}}>
                                                {svgCreditCard()}                                     
                                                <label htmlFor="Cartão">Cartão</label>  
                                            </div> 
                                            <div style={{cursor:'pointer'}} className="checkRetirada"/>                               
                                        </div>
                                    </div>
                                }
                               
                            </div>
                            :
                            <div className="payment-method">
                                <h3 style={{padding:10, fontSize:16}}>Selecione a forma de pagamento</h3>
                                <div className="payment-options" style={{height:40, alignItems:'center', display:'flex', justifyContent:'center'}}>
                                    <p style={{fontSize:13, textAlign:'center', fontWeight:'400'}}>Primeiro selecione a entrega para continuar</p>
                                </div>                                
                            </div>
                        }
                                           
                    </div>  
                    
                    <div className="observacoesDetalhe">
                        <div className="obsTextBox">
                            <div className="obsText">
                                <span>Observações</span>
                            </div>
                        </div>
                    </div>
                    <div className="obsTextBoxContainer obsContainerFinalizarPedido">
                        <div className="obsTextBoxData">
                            <textarea placeholder="Digite aqui a observação desejada" 
                            value={valueDescricao} onChange={handleMaxCharDescricao} 
                            maxLength="200" className="text-areaObs" 
                            ref={descriptionRef}
                            />
                            <div className="divCounterChar">{valueDescricao.length}/200</div>               
                        </div>
                    </div>
                    <div className="summary">
                        <div className="subtotal">
                            <span>Subtotal</span>
                            <span>R$ {calcularValorSubTotal(carrinho)}</span>
                        </div>
                        {selectedAddressToDelivery!=="" && selectedAddressToRetirada==="" ?
                            <div className="taxa-entrega">
                                <span>Taxa de entrega</span>                           
                                <span>R$ {valorEntrega.toFixed(2).toString().replace('.',',')}</span>                                                                                                
                            </div>
                            : null
                        }
                        <div className="total">
                            <span>Total</span>
                            <span>R$ {calcularValorTotalPedido(carrinho)}</span>
                        </div>
                    </div>
                    <div className="agreement">
                        <label htmlFor="privacy-policy">Concordei com a <a href={meuPdfPoliticaPrivacy} target="_blank" rel="noopener noreferrer">Política de Privacidade</a> do Pede Já</label>
                    </div>
                    {selectedAddressToDelivery==="" && selectedAddressToRetirada==="" ?
                        <div className="avancarDetalhe selectFinalizarPedidoDisabled" style={{boxShadow:'none'}}>
                            <button type="button" className="btnAvancarDetalhe">
                                <p style={{marginBottom:"0px"}}>Selecione a forma de entrega</p>
                            </button>
                        </div>
                        : paymentType==="" ?
                        <div className="avancarDetalhe selectFinalizarPedidoDisabled" style={{boxShadow:'none'}}>
                            <button type="button" className="btnAvancarDetalhe">
                                <p style={{marginBottom:"0px"}}>Selecione a forma de pagamento</p>
                            </button>
                        </div>
                        : 
                        <div className="avancarDetalhe selectFinalizarPedidoEnabled" style={{boxShadow:'none'}}>
                            <button onClick={() => handleFinalizarPedido()} className="btnAvancarDetalhe">
                                <p style={{marginBottom:"0px"}}>Fazer Pedido</p>
                            </button>
                        </div>
                    }
                    </>                    
                }
                               
                <ModalConfirmInfoCliente showConfirmInfo={showConfirmInfo}
                setShowConfirmInfo={setShowConfirmInfo}
                namePersonData={namePersonData}
                whatsappData={whatsappData}
                id_empresa={idEmpresa}
                setClientCompleteData={setClientCompleteData}
                />

                <ModalPaymentCash 
                showModalPaymentCash={showModalPaymentCash}
                setShowModalPaymentCash={setShowModalPaymentCash}
                valorTroco={valorTroco}
                setValorTroco={setValorTroco}
                setPaymentType={setPaymentType}
                valorTotalPedido={valorTotalPedido}
                />


                
                {/*<div className="btnAdicionarItens">
                    <button onClick={() => handleBack()}>
                        <span>Adicionar mais itens</span>
                    </button>
                </div>*/}
            </>}    
            </div>
            
        </div>

    );
}

export default FinalizarPedido;

const svgCreditCard = () => {
    return (
        <svg
            id="Layer_1"
            enableBackground="new 0 0 68 68"
            height={30}
            viewBox="0 0 68 68"
            width={30}
            xmlns="http://www.w3.org/2000/svg"
            >
            <g id="_x35_">
                <g>
                <g>
                    <g>
                    <g>
                        <path
                        d="m63.5814896 36.2649384-41.2045212 22.6769828c-2.2589283 1.243206-5.0979729.4197998-6.3411808-1.8391342l-12.5565136-22.8154182c-1.2432075-2.258934-.4198017-5.0979767 1.8391283-6.3411827l41.2045212-22.676981c2.2589302-1.243206 5.0979729-.4197984 6.3411789 1.8391328l12.5565147 22.8154221c1.2432089 2.2589297.4197989 5.0979723-1.8391275 6.3411784z"
                        fill="#febc1f"
                        />
                    </g>
                    </g>
                    <g>
                    <g>
                        <path
                        d="m62.6815987 34.6298218-41.2045212 22.676979c-2.2589283 1.243206-5.0979729.4197998-6.3411798-1.8391304l-12.5565146-22.815422c-1.2432075-2.2589302-.4198017-5.0979748 1.8391283-6.3411808l41.2045212-22.676981c2.2589302-1.2432063 5.0979729-.4197984 6.3411789 1.839133l12.5565176 22.8154214c1.2432022 2.2589302.4197998 5.0979748-1.8391304 6.3411808z"
                        fill="#ffd475"
                        />
                    </g>
                    </g>
                    <g>
                    <g>
                        <g>
                        <path
                            d="m45.6230049 3.6340563-41.2044864 22.6770265c-2.2589364 1.2432098-3.0823236 4.0822697-1.839144 6.3411503l12.556531 22.8154602s-11.7001696-22.3759117 34.6528778-51.9958496c-1.2919114-.5804889-2.829345-.5732968-4.1657784.1622126z"
                            fill="#febc1f"
                        />
                        </g>
                    </g>
                    </g>
                    <g>
                    <g>
                        <path
                        d="m6.2700973 35.4500999 1.5767589 2.8649979c.3974571.722187 1.3051071.9854317 2.0272923.5879745l43.4678116-23.9225845c.722187-.3974562.9854317-1.3051062.5879745-2.0272932l-1.5767593-2.8649998c-.3974563-.722187-1.3051063-.9854326-2.0272933-.5879764l-43.4678107 23.9225874c-.7221856.3974571-.9854307 1.3051071-.587974 2.0272941z"
                        fill="#1d2943"
                        />
                    </g>
                    </g>
                    <g>
                    <g>
                        <path
                        d="m5.8331218 34.656105 1.5767584 2.8650017c.3974571.7221832 1.3051066.9854317 2.0272927.5879745l43.4678116-23.9225883c.722187-.3974562.9854317-1.3051062.5879745-2.0272923l-1.5767593-2.8649998c-.3974571-.722187-1.3051071-.9854326-2.0272903-.5879765l-43.4678136 23.9225866c-.722186.3974571-.9854311 1.3051071-.587974 2.0272941z"
                        fill="#4c5c75"
                        />
                    </g>
                    </g>
                </g>
                <g>
                    <g>
                    <path
                        d="m56.1312866 64.9452744-47.0325269.0000228c-2.5784349 0-4.6686702-2.0902367-4.6686711-4.6686707l-.0000087-26.0424384c-.000001-2.578434 2.0902333-4.6686687 4.6686683-4.6686687l47.0325279-.0000191c2.578434 0 4.6686707 2.0902328 4.6686707 4.6686687l.0000076 26.0424385c-.0000009 2.578434-2.0902338 4.6686669-4.6686678 4.6686669z"
                        fill="#5094ff"
                    />
                    </g>
                </g>
                <g>
                    <g>
                    <path
                        d="m9.0987463 63.0788879 47.0325241.0000191c2.5784378 0 4.6686707-2.0902367 4.6686745-4.6686707l.0000076-26.0424347c0-2.5784359-2.0902328-4.6686726-4.6686668-4.6686726l-47.032528-.000019c-2.5784345 0-4.6686697 2.0902328-4.6686707 4.6686687l-.0000085 26.0424386c-.0000009 2.578434 2.0902329 4.6686706 4.6686678 4.6686706z"
                        fill="#57b3fe"
                    />
                    </g>
                </g>
                <g>
                    <g>
                    <g>
                        <path
                        d="m9.0987663 27.6990719 47.0325203.0000744c2.5784416 0 4.6686668 2.0902576 4.6686668 4.6686363l-.0000038 26.0424805s-.538311-25.2444992-55.4289742-28.8447552c.8519402-1.1314583 2.2023293-1.866436 3.7277909-1.866436z"
                        fill="#5094ff"
                        />
                    </g>
                    </g>
                </g>
                <g>
                    <g>
                    <g>
                        <g>
                        <path
                            d="m57.1417618 54.6996574v.7245789h-.0096512c.0188637.9798889-.7984848 1.8029976-1.7838097 1.8029976l-4.7369461.0000038c-.9678459 0-1.8029671-.8079567-1.7838097-1.8029976h-.0096511v-.7245789h.1157036c.2796631-.7038727 1.0414467-1.1859894 1.8416023-1.1859894l4.4091377-.0000038c.8002701 0 1.5620537.4821167 1.8417206 1.1859894z"
                            fill="#e3e7f0"
                        />
                        </g>
                    </g>
                    <g>
                        <g>
                        <path
                            d="m55.3433418 56.50811-4.5790215.0000687c-.9225769.0000114-1.7944107-.6264191-1.9239235-1.5398598-.1577072-1.1123047.7025833-2.0666733 1.7845535-2.0666847l4.5610123-.0000687c.9783401-.0000153 1.889431.709938 1.9490089 1.6864586.0661317 1.0839882-.7909735 1.9200745-1.7916297 1.9200859z"
                            fill="#fff"
                        />
                        </g>
                    </g>
                    </g>
                    <g>
                    <g>
                        <g>
                        <path
                            d="m43.5652237 54.6996613v.7245827h-.0096512c.0188637.9798851-.7984848 1.8029938-1.7838097 1.8029938l-4.7369461.0000038c-.9678459 0-1.8029671-.8079567-1.7838097-1.8029938h-.0096512v-.7245827h.1157036c.2796631-.7038727 1.0414467-1.1859894 1.8416023-1.1859894l4.4091377-.0000038c.8002701 0 1.5620537.4821167 1.8417206 1.1859894z"
                            fill="#e3e7f0"
                        />
                        </g>
                    </g>
                    <g>
                        <g>
                        <path
                            d="m41.7668037 56.5081139-4.5790253.0000687c-.9225769.0000153-1.7944069-.6264191-1.9239197-1.5398598-.1577072-1.1123009.7025833-2.0666695 1.7845535-2.0666809l4.5610123-.0000725c.9783363-.0000114 1.889431.7099419 1.9490089 1.6864624.0661317 1.0839843-.7909735 1.9200706-1.7916297 1.9200821z"
                            fill="#fff"
                        />
                        </g>
                    </g>
                    </g>
                    <g>
                    <g>
                        <g>
                        <path
                            d="m29.9886837 54.6996689v.7245789h-.0096512c.0188656.9798851-.7984829 1.8029976-1.7838097 1.8029976h-4.7369442c-.9678478 0-1.8029671-.8079529-1.7838097-1.8029938h-.0096531v-.7245827h.1157036c.279665-.7038689 1.0414486-1.1859894 1.8416023-1.1859894h4.4091377c.800272 0 1.5620556.4821167 1.8417206 1.1859894z"
                            fill="#e3e7f0"
                        />
                        </g>
                    </g>
                    <g>
                        <g>
                        <path
                            d="m28.1902657 56.5081177-4.5790234.0000725c-.9225769.0000114-1.7944088-.6264191-1.9239197-1.5398598-.1577091-1.1123047.7025814-2.0666733 1.7845497-2.0666847l4.5610161-.0000687c.9783363-.0000153 1.8894291.709938 1.9490051 1.6864586.0661316 1.0839844-.7909717 1.9200744-1.7916278 1.9200821z"
                            fill="#fff"
                        />
                        </g>
                    </g>
                    </g>
                    <g>
                    <g>
                        <g>
                        <path
                            d="m16.4121456 54.6996727v.7245827h-.0096512c.0188656.9798851-.7984838 1.8029938-1.7838097 1.8029938l-4.7369441.0000038c-.9678478 0-1.802968-.8079567-1.7838106-1.8029976h-.0096522v-.7245789h.1157036c.279665-.7038727 1.0414476-1.1859894 1.8416023-1.1859894l4.4091377-.0000038c.800272 0 1.5620556.4821167 1.8417206 1.1859894z"
                            fill="#e3e7f0"
                        />
                        </g>
                    </g>
                    <g>
                        <g>
                        <path
                            d="m14.6137276 56.5081253-4.5790243.0000687c-.9225769.0000114-1.7944078-.6264191-1.9239197-1.5398598-.1577077-1.1123009.7025814-2.0666733 1.7845497-2.0666847l4.5610161-.0000687c.9783363-.0000153 1.88943.709938 1.9490061 1.6864586.0661315 1.0839882-.7909708 1.9200745-1.7916279 1.9200859z"
                            fill="#fff"
                        />
                        </g>
                    </g>
                    </g>
                </g>
                <g>
                    <g>
                    <g>
                        <path
                        d="m8.0920725 35.925663.0000019 4.3564262c0 1.0981369.890214 1.9883499 1.988348 1.9883461l8.1573095-.0000229c1.098135 0 1.988348-.890213 1.988348-1.9883461l-.0000019-4.3564301c0-1.0981331-.8902149-1.9883461-1.988348-1.9883461l-8.1573105.0000229c-1.098134.0000039-1.988347.8902169-1.988347 1.98835z"
                        fill="#febc1f"
                        />
                    </g>
                    </g>
                    <g>
                    <g>
                        <path
                        d="m8.0920725 35.2468109.000001 4.3564262c.000001 1.0981331.890214 1.9883461 1.988348 1.9883461l8.1573105-.0000229c1.0981331 0 1.988348-.8902168 1.9883461-1.9883499v-4.3564262c-.0000019-1.0981331-.8902149-1.9883461-1.988348-1.9883461l-8.1573114.0000229c-1.0981342 0-1.9883472.890213-1.9883462 1.9883499z"
                        fill="#ffd475"
                        />
                    </g>
                    </g>
                </g>
                <g>
                    <g>
                    <g>
                        <path
                        d="m13.9477558 36.954998v-3.6965523h-.9444408v3.6965523h-4.9112435v.9444428h2.6869421v3.6921043h.9444418v-3.6921043h4.2524815v3.6921043h.9444065v-3.6921043h3.305748v-.9444428z"
                        fill="#febc1f"
                        />
                    </g>
                    </g>
                </g>
                <g>
                    <g>
                    <g>
                        <g>
                        <path
                            d="m56.2796402 40.2164574-5.3172913.0000305c-.443058.0000076-.8617401-.300827-.9239388-.7394943-.075737-.5341682.3374062-.9924927.8570061-.9924965l5.3086472-.0000343c.4698334-.0000076.9073715.3409386.9359818.8098984.0317611.5205688-.3798523.9220885-.860405.9220962z"
                            fill="#fff"
                        />
                        </g>
                    </g>
                    </g>
                    <g>
                    <g>
                        <g>
                        <path
                            d="m56.2796402 36.5089836-9.3353691.0000343c-.4430542.0000038-.8617401-.3008308-.9239349-.7394981-.075737-.5341682.3374023-.9924927.8570061-.9924965l9.3267212-.0000343c.4698334-.0000076.9073715.3409386.9359818.8099022.031761.5205688-.3798524.9220886-.8604051.9220924z"
                            fill="#fff"
                        />
                        </g>
                    </g>
                    </g>
                </g>
                </g>
                <g>
                <g>
                    <path
                    d="m54.8607178 16.3203678c2.2225723 2.8520584 4.0391045 5.9515629 5.4309731 9.2891464-2.225937-2.8498039-4.0357934-5.9533443-5.4309731-9.2891464z"
                    fill="#fff"
                    />
                </g>
                </g>
                <g>
                <g>
                    <path
                    d="m13.4443359 25.6074772c3.2388935-2.4783745 6.7269783-4.5519123 10.456749-6.2024593-3.2368565 2.4816837-6.7289753 4.5484409-10.456749 6.2024593z"
                    fill="#fff"
                    />
                </g>
                </g>
                <g>
                <g>
                    <path
                    d="m40.1918945 60.7349167c4.0498962-.4790192 8.107563-.4831581 12.1573486.0000458-4.0497855.4831161-8.1074485.4792175-12.1573486-.0000458z"
                    fill="#fff"
                    />
                </g>
                </g>
            </g>
        </svg>
    )
}

const svgMoney = () => {
    return (
        <svg
            id="Layer_1"
            enableBackground="new 0 0 50 50"
            height={30}
            viewBox="0 0 50 50"
            width={30}
            xmlns="http://www.w3.org/2000/svg"
            >
            <g>
                <g>
                <g>
                    <g>
                    <g enableBackground="new">
                        <g>
                        <path
                            d="m49 32.34555-.0152 4.5598-14.2916 8.3053.0152-4.5597z"
                            fill="#5eaf5f"
                        />
                        </g>
                        <g>
                        <path
                            d="m34.7084 40.65095-.0152 4.5597-33.6932-19.4527.0153-4.5597z"
                            fill="#7bc578"
                        />
                        </g>
                        <g>
                        <path
                            d="m49 32.34555-14.2916 8.3054-33.6931-19.4527 14.2916-8.3054z"
                            fill="#98d095"
                        />
                        </g>
                    </g>
                    </g>
                    <g>
                    <path
                        d="m36.7837 31.52105-.3891.2261c.3441.3097.3463.6854-.037.9082l-.5002-.2888c.1738-.101.0883-.3306-.1829-.4872-.2772-.16-.6738-.2088-.8476-.1078-.1735.1008-.0883.3306.1889.4907.5604.3235.6415.8.1864 1.0645-.3833.2228-1.0319.2227-1.5618.021l-.3892.2261-.5001-.2887.3891-.2262c-.3499-.3063-.3522-.682.0311-.9047l.5002.2888c-.1738.101-.0825.3271.1947.4872.2712.1566.662.2087.8358.1077.1796-.1044.088-.3304-.1832-.4869-.5604-.3236-.6472-.8038-.1921-1.0683.3833-.2227 1.0319-.2226 1.5677-.0243l.3891-.2262z"
                        fill="#46a046"
                    />
                    </g>
                    <g>
                    <path
                        d="m17.2645 20.25165-.3892.2261c.3441.3097.3524.6889-.0309.9116l-.5062-.2923c.1737-.1009.0943-.3271-.1829-.4871-.2772-.1601-.6739-.2088-.8476-.1078-.1735.1008-.0884.3306.1889.4906.5604.3236.6475.8036.1924 1.068-.3833.2228-1.038.2193-1.5679.0176l-.3891.2261-.5002-.2888.3892-.2261c-.35-.3063-.3522-.682.0311-.9048l.5002.2888c-.1738.101-.0825.3272.1947.4873.2772.16.662.2087.8358.1077.1796-.1044.094-.3269-.1832-.487-.5605-.3236-.6472-.8037-.1921-1.0682.3833-.2227 1.0319-.2227 1.5676-.0244l.3892-.2261z"
                        fill="#46a046"
                    />
                    </g>
                    <g>
                    <path
                        d="m16.6976 15.60325c.7654.4419.7694 1.1516.009 1.5936-.7542.4383-1.9858.4418-2.7511 0-.7593-.4384-.7632-1.1552-.009-1.5936.7604-.4419 1.9918-.4384 2.7511 0z"
                        fill="#46a046"
                    />
                    </g>
                    <g>
                    <path
                        d="m29.9871 24.59375-.9637.5601c.857.7586.8624 1.684-.0897 2.2373l-1.2354-.7133c.431-.2505.2176-.8106-.4634-1.2038-.6749-.3897-1.6487-.5149-2.0797-.2645-.431.2505-.2116.8141.4634 1.2038 1.386.8003 1.5968 1.9763.4713 2.6304-.9459.5497-2.5497.5532-3.856.0557l-.9699.5636-1.2354-.7132.9699-.5637c-.8632-.755-.8684-1.6875.0775-2.2372l1.2354.7133c-.4311.2505-.2116.8141.4634 1.2038.6809.3931 1.6486.515 2.0797.2645.431-.2505.2176-.8107-.4634-1.2038-1.38-.7968-1.591-1.9797-.4713-2.6304.9521-.5533 2.5557-.5497 3.8682-.0558l.9637-.5601z"
                        fill="#46a046"
                    />
                    </g>
                    <g>
                    <path
                        d="m8.4413 20.40135c.7653.4419.7693 1.1516.0092 1.5933-.7545.4385-1.9799.4385-2.7452-.0033-.7593-.4384-.7694-1.1517-.0148-1.5902.7601-.4417 1.9915-.4382 2.7508.0002z"
                        fill="#46a046"
                    />
                    </g>
                    <g>
                    <path
                        d="m44.3102 31.54535c.7654.4419.7694 1.1517.009 1.5936-.7543.4383-1.9858.4419-2.7511 0-.7593-.4384-.7572-1.1517-.003-1.5901.7605-.4419 1.9858-.4419 2.7451-.0035z"
                        fill="#46a046"
                    />
                    </g>
                    <g>
                    <path
                        d="m36.0539 36.34345c.7653.4419.7693 1.1517.0092 1.5934-.7545.4385-1.9799.4385-2.7452-.0034-.7593-.4384-.7634-1.1482-.0088-1.5867.7601-.4417 1.9854-.4417 2.7448-.0033z"
                        fill="#46a046"
                    />
                    </g>
                    <g>
                    <path
                        d="m15.3202 14.19765 31.4272 18.1445-12.0461 7.0004-12.9445-7.4735-18.4827-10.671zm3.7006 16.05c3.3265 1.9205 8.7145 1.917 12.0134-.0001s3.2874-5.0381-.0391-6.9586c-3.3206-1.9171-8.7146-1.9171-12.0135 0s-3.2814 5.0416.0392 6.9587m-10.1546-8.0198 6.8157 3.9072c.2215-1.1759 1.1042-2.3172 2.6549-3.2184s3.5119-1.4125 5.5475-1.5483l-6.7916-3.9212c1.0056-.5844 1.0006-1.51.0123-2.0806-.9823-.5671-2.5863-.5705-3.5683.0001-.9819.5707-.9647 1.4962.0176 2.0633l-4.6756 2.7521c-1.0124-.5846-2.6164-.5879-3.5922-.0209-.9877.5741-.9705 1.4996.0118 2.0667.9883.5706 2.5801.5741 3.5679 0m27.595 13.8789 4.6816-2.7486c1.0124.5845 2.6042.588 3.5861.0174.982-.5707.977-1.4963-.0114-2.0669-.9822-.5671-2.5802-.567-3.5622.0037l-6.8153-3.9074c-.2338 1.1761-1.1103 2.3137-2.661 3.2149s-3.518 1.4161-5.5415 1.5518l6.7917 3.9212c-1.0118.588-1.0006 1.51-.0183 2.0771.9883.5706 2.5801.5741 3.5679.0001.9757-.5671.9707-1.4927-.0176-2.0633m-16.7862-18.7221-.892.5184 3.6399 2.1015.8919-.5184zm17.9162 10.344-.892.5183 3.6339 2.098.892-.5183zm-25.1609-1.9171.8861-.515-3.6398-2.1014-.8862.5149zm17.9102 10.3405.8862-.515-3.6339-2.098-.8861.515z"
                        fill="#46a046"
                    />
                    </g>
                </g>
                <g>
                    <g enableBackground="new">
                    <g>
                        <path
                        d="m35.7416 24.69085-14.2916 8.3053-.658-.3799 14.2916-8.3054z"
                        fill="#86aedc"
                        />
                    </g>
                    <g>
                        <path
                        d="m39.5801 26.90695-14.2916 8.3054-3.8385-2.2162 14.2916-8.3053z"
                        fill="#e3e6f0"
                        />
                    </g>
                    <g>
                        <path
                        d="m40.2342 27.28455-14.2916 8.3054-.6541-.3776 14.2916-8.3054z"
                        fill="#86aedc"
                        />
                    </g>
                    </g>
                </g>
                <g>
                    <g enableBackground="new">
                    <g>
                        <path
                        d="m21.4496 32.99595-.0299 4.5611-.6577-.3797.03-4.5611z"
                        fill="#678cc8"
                        />
                    </g>
                    <g>
                        <path
                        d="m25.285 35.21035-.03 4.561-3.8353-2.2143.0299-4.5611z"
                        fill="#d4dbe9"
                        />
                    </g>
                    <g>
                        <path
                        d="m25.9426 35.58995-.0299 4.5611-.6577-.3797.03-4.561z"
                        fill="#678cc8"
                        />
                    </g>
                    </g>
                </g>
                </g>
                <g>
                <g>
                    <g>
                    <g enableBackground="new">
                        <g>
                        <path
                            d="m49 24.24205-.0152 4.5597-14.2916 8.3054.0152-4.5597z"
                            fill="#5eaf5f"
                        />
                        </g>
                        <g>
                        <path
                            d="m34.7084 32.54745-.0152 4.5597-33.6932-19.4527.0153-4.5598z"
                            fill="#7bc578"
                        />
                        </g>
                        <g>
                        <path
                            d="m49 24.24205-14.2916 8.3054-33.6931-19.4528 14.2916-8.3053z"
                            fill="#98d095"
                        />
                        </g>
                    </g>
                    </g>
                    <g>
                    <path
                        d="m36.7837 23.41755-.3891.2261c.3441.3097.3463.6854-.037.9081l-.5002-.2887c.1738-.101.0883-.3306-.1829-.4872-.2772-.16-.6738-.2088-.8476-.1078-.1735.1008-.0883.3306.1889.4906.5604.3236.6415.8001.1864 1.0646-.3833.2227-1.0319.2227-1.5618.021l-.3892.2261-.5001-.2888.3891-.2261c-.3499-.3063-.3522-.682.0311-.9047l.5002.2887c-.1738.101-.0825.3272.1947.4873.2712.1565.662.2087.8358.1077.1796-.1044.088-.3304-.1832-.487-.5604-.3235-.6472-.8037-.1921-1.0682.3833-.2227 1.0319-.2227 1.5677-.0244l.3891-.2261z"
                        fill="#46a046"
                    />
                    </g>
                    <g>
                    <path
                        d="m17.2645 12.14805-.3892.2262c.3441.3096.3524.6888-.0309.9116l-.5062-.2923c.1737-.101.0943-.3271-.1829-.4871-.2772-.1601-.6739-.2088-.8476-.1078-.1735.1008-.0884.3305.1889.4906.5604.3236.6475.8036.1924 1.068-.3833.2228-1.038.2192-1.5679.0175l-.3891.2262-.5002-.2888.3892-.2262c-.35-.3062-.3522-.6819.0311-.9047l.5002.2888c-.1738.101-.0825.3272.1947.4872.2772.1601.662.2088.8358.1078.1796-.1044.094-.327-.1832-.487-.5605-.3236-.6472-.8038-.1921-1.0682.3833-.2228 1.0319-.2227 1.5676-.0244l.3892-.2261z"
                        fill="#46a046"
                    />
                    </g>
                    <g>
                    <path
                        d="m16.6976 7.49975c.7654.4419.7694 1.1516.009 1.5935-.7542.4383-1.9858.4419-2.7511.0001-.7593-.4384-.7632-1.1553-.009-1.5936.7604-.4419 1.9918-.4384 2.7511 0z"
                        fill="#46a046"
                    />
                    </g>
                    <g>
                    <path
                        d="m29.9871 16.49025-.9637.5601c.857.7585.8624 1.684-.0897 2.2372l-1.2354-.7132c.431-.2505.2176-.8106-.4634-1.2038-.6749-.3897-1.6487-.515-2.0797-.2645s-.2116.8141.4634 1.2038c1.386.8002 1.5968 1.9763.4713 2.6304-.9459.5497-2.5497.5532-3.856.0557l-.9699.5636-1.2354-.7132.9699-.5637c-.8632-.755-.8684-1.6875.0775-2.2372l1.2354.7133c-.4311.2505-.2116.8141.4634 1.2038.6809.3931 1.6486.5149 2.0797.2644.431-.2505.2176-.8106-.4634-1.2037-1.38-.7968-1.591-1.9798-.4713-2.6304.9521-.5533 2.5557-.5498 3.8682-.0558l.9637-.5601z"
                        fill="#46a046"
                    />
                    </g>
                    <g>
                    <path
                        d="m8.4413 12.29785c.7653.4418.7693 1.1516.0092 1.5933-.7545.4385-1.9799.4385-2.7452-.0034-.7593-.4383-.7694-1.1516-.0148-1.5901.7601-.4417 1.9915-.4382 2.7508.0002z"
                        fill="#46a046"
                    />
                    </g>
                    <g>
                    <path
                        d="m44.3102 23.44185c.7654.4419.7694 1.1517.009 1.5936-.7543.4383-1.9858.4419-2.7511 0-.7593-.4384-.7572-1.1518-.003-1.5901.7605-.4419 1.9858-.4419 2.7451-.0035z"
                        fill="#46a046"
                    />
                    </g>
                    <g>
                    <path
                        d="m36.0539 28.23995c.7653.4419.7693 1.1516.0092 1.5933-.7545.4386-1.9799.4385-2.7452-.0033-.7593-.4384-.7634-1.1482-.0088-1.5867.7601-.4417 1.9854-.4417 2.7448-.0033z"
                        fill="#46a046"
                    />
                    </g>
                    <g>
                    <path
                        d="m15.3202 6.09405 31.4272 18.1446-12.0461 7.0004-12.9445-7.4735-18.4827-10.671zm3.7006 16.05c3.3265 1.9206 8.7145 1.9171 12.0134 0s3.2874-5.0381-.0391-6.9587c-3.3206-1.9171-8.7146-1.917-12.0135.0001s-3.2814 5.0415.0392 6.9586m-10.1546-8.0197 6.8157 3.9072c.2215-1.176 1.1042-2.3172 2.6549-3.2184s3.5119-1.4126 5.5475-1.5483l-6.7916-3.9212c1.0056-.5844 1.0006-1.51.0123-2.0806-.9823-.5672-2.5863-.5705-3.5683.0001-.9819.5707-.9647 1.4962.0176 2.0633l-4.6756 2.752c-1.0124-.5845-2.6164-.5878-3.5922-.0208-.9877.574-.9705 1.4995.0118 2.0667.9883.5706 2.5801.574 3.5679 0m27.595 13.8789 4.6816-2.7486c1.0124.5845 2.6042.588 3.5861.0174.982-.5707.977-1.4963-.0114-2.0669-.9822-.5671-2.5802-.567-3.5622.0036l-6.8153-3.9074c-.2338 1.1761-1.1103 2.3138-2.661 3.215-1.5507.9011-3.518 1.4161-5.5415 1.5518l6.7917 3.9211c-1.0118.5881-1.0006 1.5101-.0183 2.0772.9883.5706 2.5801.5741 3.5679 0 .9757-.567.9707-1.4926-.0176-2.0632m-16.7862-18.7221-.892.5184 3.6399 2.1014.8919-.5183zm17.9162 10.3439-.892.5184 3.6339 2.098.892-.5183zm-25.1609-1.917.8861-.515-3.6398-2.1015-.8862.515zm17.9102 10.3405.8862-.515-3.6339-2.098-.8861.5149z"
                        fill="#46a046"
                    />
                    </g>
                </g>
                <g>
                    <g enableBackground="new">
                    <g>
                        <path
                        d="m35.7416 16.58735-14.2916 8.3053-.658-.3799 14.2916-8.3054z"
                        fill="#86aedc"
                        />
                    </g>
                    <g>
                        <path
                        d="m39.5801 18.80345-14.2916 8.3054-3.8385-2.2162 14.2916-8.3053z"
                        fill="#e3e6f0"
                        />
                    </g>
                    <g>
                        <path
                        d="m40.2342 19.18105-14.2916 8.3054-.6541-.3776 14.2916-8.3054z"
                        fill="#86aedc"
                        />
                    </g>
                    </g>
                </g>
                <g>
                    <g enableBackground="new">
                    <g>
                        <path
                        d="m21.4496 24.89245-.0299 4.5611-.6577-.3797.03-4.5611z"
                        fill="#678cc8"
                        />
                    </g>
                    <g>
                        <path
                        d="m25.285 27.10675-.03 4.5611-3.8353-2.2143.0299-4.5611z"
                        fill="#d4dbe9"
                        />
                    </g>
                    <g>
                        <path
                        d="m25.9426 27.48645-.0299 4.5611-.6577-.3797.03-4.5611z"
                        fill="#678cc8"
                        />
                    </g>
                    </g>
                </g>
                </g>
            </g>
        </svg>
    )
}