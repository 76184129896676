import styled from "styled-components";
import React, { useRef } from 'react'

import PermissionGate from "../../services/PermissionGate";
import InputMask from 'react-input-mask';
import InputMoney from "../ListItem/InputMoney";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MdFastfood } from "react-icons/md";
import * as AiIcons from 'react-icons/ai';
import { registerGrupoAdicionais, deleteGrupoAdicional, deleteItemAdicional, registerAdicionais } from "../../services/api";
import AsyncSelect from "react-select/async";
import ImageUploading from 'react-images-uploading';
import { CImage } from '@coreui/react'
import { AuthContext } from "../../contexts/auth";
import { useEffect, useContext, useState } from "react";
import * as SlIcons from 'react-icons/sl'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import CryptoJS from 'crypto-js';
import * as FiIcons from 'react-icons/fi'
import { IoMdCloseCircleOutline } from "react-icons/io";
import { TbDotsVertical } from "react-icons/tb";
import { FaRegEdit } from "react-icons/fa";
import { FaRegTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ModalAddItemAdicional from "./ModalAddItemAdicionais";

const DivModalContainer = styled.div`
  z-index:15;
  position: fixed;
  top: 0;
  left: 0;
  display: ${({ showModalAddGroupAdd }) => (showModalAddGroupAdd ? 'none' : 'flex')};
  float: left;
  min-width: 100%;
  min-height: 100%;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 5px 0;
  list-style: none;
  background-color: rgba(0,0,0,0.5);
  background-clip: padding-box;
  align-items:center;
  justify-content:center;
`;

const DivModal = styled.div`
    z-index:20;
    position: absolute;
    top: 5%;
    left: 20%;
    float: left;
    min-width: 60%;
    min-height: 45%;
    max-height: 85%;
    max-width: 60%;
    padding: 5px 0;
    list-style: none;
    --background-color: white;
    background-color:rgb(247,247,247)!important;
    background-clip: padding-box;
    border-radius: 15px;
`;

const ModalAddGroupAdicionais = ({
    showModalAddGroupAdd,
    setShowModalAddGroupAdd,
    selectDataAdicionais,
    setselectDataAdicionais,
}) => {

    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const userEncrypted = localStorage.getItem('user')
    const user = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
    const userParse = JSON.parse(user)
    const userID = userParse._id;
    var userImg = null;
    const navigate = useNavigate();
    //console.log(empresa)
    const empresa = localStorage.getItem('empresa')
    const empresaParse = JSON.parse(empresa)
    const idEmpresa = empresaParse.id_empresa;

    const [mandatory, setMandatory] = useState(false);
    const [minimo, setMinimo] = useState(mandatory ? 1 : 0);
    const [maximo, setMaximo] = useState(1);
    const [valuePreco, setValuePreco] = useState(0);
    const [grupoAdicionaisCadastrado, setGrupoAdicionaisCadastrado] = useState(null);
    const [showModalAddItemAdd, setShowModalAddItemAdd] = useState(true);

    const [visibleDropdown, setVisibleDropdown] = useState(null);
    const dropdownRef = useRef(null);

    const [errImg, setErrImg] = React.useState(false);
    const [images, setImages] = React.useState([]);
    const maxNumber = 1;
    const maxMbFileSize = 2 * 1024 * 1024; // 2Mb

    const onChangeImg = (imageList, addUpdateIndex) => {
        // data for submit
        console.log(imageList, addUpdateIndex);
        setImages(imageList);
    };

    const closeModal = () => setShowModalAddGroupAdd(!showModalAddGroupAdd);

    const handleCadastroItemAdicional = (idGrupoAdicional) => {
        console.log("ID GRUPO ADICIONAL", idGrupoAdicional)
        setShowModalAddItemAdd(!showModalAddItemAdd);
    }
    const handleMinimoChange = (event) => {
        const value = Math.min(Math.max(0, event.target.valueAsNumber), maximo);
        setMinimo(value);
    };

    const handleMaximoChange = (event) => {
        const value = Math.max(minimo, event.target.valueAsNumber);
        setMaximo(value);
    };

    const incrementMinimo = () => {
        setMinimo((prevMinimo) => (prevMinimo < maximo ? prevMinimo + 1 : prevMinimo));
    };

    const decrementMinimo = () => {
        setMinimo((prevMinimo) => (prevMinimo > 0 ? prevMinimo - 1 : prevMinimo));
    };

    const incrementMaximo = () => {
        setMaximo((prevMaximo) => prevMaximo + 1);
    };

    const decrementMaximo = () => {
        setMaximo((prevMaximo) => (prevMaximo > minimo ? prevMaximo - 1 : prevMaximo));
    };

    const handleSaveRefreshItem = () => {
        if (grupoAdicionaisCadastrado) {
            //console.log("GRUPO ADICIONAL:", grupoAdicionaisCadastrado);
            const value = grupoAdicionaisCadastrado._id;
            const label = grupoAdicionaisCadastrado.title;
            // Criar um novo objeto com value e label
            const newItem = { value, label };
            // Verificar se o item já existe na lista
            const exists = selectDataAdicionais.some(item => item.value === value);
            if (!exists) {
                // Atualizar a lista com o novo objeto se ele não existir
                setselectDataAdicionais([...selectDataAdicionais, newItem]);
                console.log("selectedDataAdicionais:", JSON.stringify(selectDataAdicionais));
            } else {
                console.log("Item já existe na lista.");
            }
            //setRefresh(!refresh);
            setShowModalAddGroupAdd(!showModalAddGroupAdd);
        }
    }

    const handleDeleteGroup = (categoriaToDelete) => {
        const idRecebido = categoriaToDelete._id;
        deleteGrupoAdicional(userID, idRecebido).then(cast => {
            console.log("RESULT DELETE:", cast);
            if (cast.status === 201) {
                setGrupoAdicionaisCadastrado(null);
                setShowModalAddItemAdd(true);
                toast(cast.data.msg, { autoClose: 5000, type: "success" });
                setShowModalAddGroupAdd(!showModalAddGroupAdd);
            } else {
                toast(cast.data.msg, { autoClose: 5000, type: "error" });
            }
        }).catch(err => {
            console.log("ERROR:", err);
            toast(err.response.data.msg, { autoClose: 5000, type: "error" });
        });
    }

    const handleDeleteItem = (id_grupo, id) => {
        console.log("ID DO ITEM A SER DELETADO:", id);
        const id_subadicional = id;
        const data = {
            userID,
            id_subadicional,
            id_grupo,
        };

        deleteItemAdicional(data).then(response => {
            if (response.status === 200) {
                toast(response.data.msg, { autoClose: 5000, type: "success" });
                setGrupoAdicionaisCadastrado(response.data.grupoAdicional);
                // Atualize a lista de adicionais ou faça outra ação necessária
                //setRefresh(true);
            } else {
                toast(response.data.msg, { autoClose: 5000, type: "error" });
            }
        }).catch(err => {
            toast(err.response.data.msg, { autoClose: 5000, type: "error" });
        });
    }

    useEffect(() => {
        // Ajusta minimo quando mandatory muda
        if (mandatory && minimo === 0) {
            setMinimo(1);
        } else if (!mandatory && minimo !== 0) {
            setMinimo(0);
        }
    }, [mandatory]);

    useEffect(() => {
        // Ajusta mandatory quando minimo muda
        setMandatory(minimo > 0);
    }, [minimo]);

    //Validações
    const SignUpSchema = Yup.object().shape({
        title: Yup.string().required("Obrigatório"),
        //description: Yup.string().required("Obrigatório"),
    });

    const formik = useFormik({
        validationSchema: SignUpSchema,
        validateOnBlur: false,
        validateOnChange: false,
        initialValues: {
            title: "",
            //description: "",
        },
        onSubmit: (values) => {
            //alert(JSON.stringify(values, null, 2));
            console.log(values);

            const title = values.title;
            //const description = values.description;
            //const preco = values.preco;

            handleSubmitAPI(title);

        },
    });

    const onChange = (event, nameObject, isNumber) => {
        setValuePreco(isNumber ? Number(event.target.value) : event.target.value);
        formik.setFieldValue("preco", isNumber ? Number(event.target.value) : event.target.value);
    };

    const [isFocused, setIsFocused] = useState(false);
    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleBack = () => {
        //setSidebar(!sidebar)
        navigate("/list-adicional");
    }


    const handleSubmitAPI = (title) => {
        //e.preventDefault();

        const createdBy = userID;
        const out = false;
        //Registrando a empresa
        console.log("submit adicional!", { createdBy, idEmpresa, title, minimo, maximo, mandatory, out });
        registerGrupoAdicionais(createdBy, idEmpresa, title, minimo, maximo, mandatory, out).then(cast => {
            console.log("CRIOU ADICIONAL:::", cast)
            if (cast.status === 201) {
                toast(cast.data.msg, { autoClose: 5000, type: "success" });
                //navigate("/list-adicional");
                setGrupoAdicionaisCadastrado(cast.data.adicional);
            }
            else {
                toast(cast.data.msg, { autoClose: 5000, type: "error" });
            }

        }).catch(err => {
            console.log('Oh noooo!!');
            const msg = err.response.data.msg;
            //console.log("MENSAGEM", msg)
            toast(msg, { autoClose: 5000, type: "error" });

        });

    }
    return (
        <DivModalContainer showModalAddGroupAdd={showModalAddGroupAdd}>

            <DivModal>
                <div className="closeModalPedido" onClick={() => closeModal()}>
                    <div
                        iconname="x"
                        iconcolor="#2B2B2B"
                        iconsize={18}
                        className="iconCancel"
                        style={{ height: 18, display: 'flex' }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={18}
                            height={18}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#2B2B2B"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-x"
                        >
                            <line x1={18} y1={6} x2={6} y2={18} />
                            <line x1={6} y1={6} x2={18} y2={18} />
                        </svg>
                    </div>
                </div>

                <div className="m-5" style={{ width: "95%", overflowY:"scroll", maxHeight:700 }}>
                    <div style={{ marginBottom: "20px", marginRight:'10px', fontSize: "25px", color: "#555" }}>
                        <h4>Criar Grupo de Adicionais</h4>
                    </div>


                    <form onSubmit={formik.handleSubmit} style={{ marginTop: "-30px" }}>

                        <div style={{ display: "flex", justifyContent: "right"/*, height:"80px"*/ }}>

                            <div className="div-buttons">
                                <div className="continue-button">
                                    {grupoAdicionaisCadastrado ?
                                    <button type="button" onClick={()=>handleSaveRefreshItem()}>
                                        <AiIcons.AiOutlineSave style={{ marginRight: "5px", fontSize: "22px", marginBottom: "2px" }} /><a>Adicionar</a>
                                    </button>
                                    :
                                    <button type="submit">
                                        <AiIcons.AiOutlineSave style={{ marginRight: "5px", fontSize: "22px", marginBottom: "2px" }} /><a>Continuar</a>
                                    </button>
                                    }
                                    
                                </div>

                                <div className="back-button">
                                    <button type="button" onClick={() => closeModal()}>
                                        <SlIcons.SlActionUndo style={{ color: "#ff4c4c", marginRight: "5px", fontSize: "18px", marginBottom: "2px" }} /><a >Voltar</a>
                                    </button>
                                </div>
                            </div>

                        </div>

                        <div className="input-group inputGroup-Adicionais">
                            {grupoAdicionaisCadastrado ?
                                <>
                                    <div style={{ width: '100%' }}>
                                        <div
                                            //className="categoria-individual"
                                            style={{
                                                //...provided.draggableProps.style,
                                                backgroundColor: 'white',
                                                //boxShadow: draggedItem === grupoAdicionaisCadastrado._id ? '1px 1px 6px #a4a4a4' : 'none',
                                            }}
                                            className={`${'resultCategoriaAdicionais even'}`}
                                        >
                                            <div style={{ display: 'flex', flexDirection: 'row', gap: 80, alignItems: 'center', width: '90%', justifyContent: 'space-between', paddingLeft: 10 }}>
                                                <div style={{ maxWidth: '50vw' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                                                        <div className="nomeCategoria">{grupoAdicionaisCadastrado.title}</div>
                                                        <div className={grupoAdicionaisCadastrado.mandatory ? "divMandatory" : "divOpcional"}>
                                                            <span>{grupoAdicionaisCadastrado.mandatory ? "Obrigatório" : "Opcional"}</span>
                                                        </div>
                                                        <div>
                                                            <span>Adicionais selecionáveis de {grupoAdicionaisCadastrado.min} até {grupoAdicionaisCadastrado.max}</span>
                                                        </div>
                                                    </div>
                                                    <div className="divDescricaoAdicionais">{grupoAdicionaisCadastrado.description}</div>
                                                </div>
                                                {/*<div className="valorDoAdicional">R$ {categoria.price.toFixed(2).toString().replace('.',',')}</div>*/}

                                            </div>
                                            <div style={{ minWidth: 43 }}>
                                                <a
                                                    className="btn btn-sm btn-danger deletaCategoria"
                                                    style={{ marginLeft: '5px' }}
                                                onClick={(e) => handleDeleteGroup(grupoAdicionaisCadastrado)}
                                                >
                                                    <FiIcons.FiTrash2 style={{ color: 'white' }} />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="resultAdicionaisChildren">
                                            {
                                                grupoAdicionaisCadastrado.adicionais && grupoAdicionaisCadastrado.adicionais.length > 0 && grupoAdicionaisCadastrado.adicionais.map((adicional, i) => (
                                                    <div key={adicional._id} index={i}>
                                                        <div className="divItemAdicionalInfo">
                                                            <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
                                                                {
                                                                    adicional.image === "" || !adicional.image ?
                                                                        <div>
                                                                            <MdFastfood style={{ fontSize: 50 }} />
                                                                        </div>
                                                                        :
                                                                        <img width={50} height={50} src={adicional.image}></img>
                                                                }
                                                                {adicional.title}
                                                            </div>
                                                            <div className="divEditRmvSubAdicionais">
                                                                {adicional.price > 0 ?
                                                                    <div>A partir de R$ {adicional.price.toFixed(2).toString().replace('.', ',')}</div>
                                                                    :
                                                                    <div>Adicional Grátis</div>
                                                                }
                                                                <IoMdCloseCircleOutline fill="red"
                                                                onClick={() => handleDeleteItem(grupoAdicionaisCadastrado._id, adicional._id)} 
                                                                />
                                                                {/*visibleDropdown === adicional._id && (
                                                                    <div className="optionsDropdown" ref={dropdownRef} style={{ top: 22 }}>
                                                                        <div
                                                                            className="optionItem"
                                                                        //onClick={() => handleEditClick(grupoAdicionaisCadastrado._id, adicional)}
                                                                        >
                                                                            <FaRegEdit /> Editar
                                                                        </div>
                                                                        <div
                                                                            className="optionItem"
                                                                        //onClick={() => handleDeleteItem(grupoAdicionaisCadastrado._id, adicional._id)}
                                                                        >
                                                                            <FaRegTrashAlt /> Excluir
                                                                        </div>
                                                                    </div>
                                                                )*/}
                                                            </div>
                                                        </div>
                                                    </div>

                                                ))

                                            }
                                            <div className="divCadastroAdicionalChildren"
                                                onClick={() => handleCadastroItemAdicional(grupoAdicionaisCadastrado._id)}
                                            >
                                                <span>Cadastrar Adicional</span>
                                            </div>

                                        </div>



                                    </div>

                                </>

                                :
                                <div className="containerBox-adicionais">

                                    <div className="row-adicionais-1">
                                        <div className="input-box" style={{ width: "95%" }}>
                                            <label>Nome do Grupo<span style={{ color: "red" }}>*</span></label>
                                            <input
                                                placeholder="Digite o nome do grupo de adicionais"
                                                id="title"
                                                type="title"
                                                name="title"
                                                onChange={formik.handleChange}
                                                value={formik.values.title}
                                            />
                                            {formik.errors.title && (
                                                <div className="error">{formik.errors.title}</div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="row-adicionais-2">
                                        <div className="input-box" style={{ width: "48%" }}>
                                            <label style={{ color: 'gray', paddingBottom: 8 }}>Quantidade de adicionais que o cliente pode escolher?</label>
                                            <div className="quantity-control">
                                                <div className="quantity-control-individual">
                                                    <label>Mínimo</label>
                                                    <div className="input-groupCadastroAdicionais">
                                                        <button type="button" className="btnDecrement_" onClick={decrementMinimo}>-</button>
                                                        <input type="number" value={minimo} onChange={handleMinimoChange} />
                                                        <button type="button" className="btnIncrement_" onClick={incrementMinimo}>+</button>
                                                    </div>
                                                </div>
                                                <div className="quantity-control-individual">
                                                    <label>Máximo</label>
                                                    <div className="input-groupCadastroAdicionais">
                                                        <button type="button" className="btnDecrement_" onClick={decrementMaximo}>-</button>
                                                        <input type="number" value={maximo} onChange={handleMaximoChange} />
                                                        <button type="button" className="btnIncrement_" onClick={incrementMaximo}>+</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="radio-button-groupAdicionais" style={{ width: "48%" }}>
                                            <div className="radio-optionAdicionais">
                                                <input
                                                    id="opcional"
                                                    type="radio"
                                                    name="mandatory"
                                                    value="opcional"
                                                    checked={!mandatory}
                                                    onChange={() => setMandatory(false)}
                                                />
                                                <div className="labelOptAdicionais">
                                                    <label htmlFor="opcional">Opcional</label>
                                                    <span>Seu cliente pode optar por não escolher adicionais</span>
                                                </div>
                                            </div>
                                            <div className="radio-optionAdicionais">
                                                <input
                                                    id="obrigatorio"
                                                    type="radio"
                                                    name="mandatory"
                                                    value="obrigatorio"
                                                    checked={mandatory}
                                                    onChange={() => setMandatory(true)}
                                                />
                                                <div className="labelOptAdicionais">
                                                    <label htmlFor="obrigatorio">Obrigatório</label>
                                                    <span>Seu cliente precisa escolher adicionais</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div >

                    </form >


                    <ModalAddItemAdicional
                        showModalAddItemAdd={showModalAddItemAdd}
                        setShowModalAddItemAdd={setShowModalAddItemAdd}
                        id_grupo={grupoAdicionaisCadastrado ? grupoAdicionaisCadastrado._id : null}
                        setGrupoAdicionaisCadastrado={setGrupoAdicionaisCadastrado}
                    />

                </div >


            </DivModal >
        </DivModalContainer >
    )
}

export default ModalAddGroupAdicionais;