import React, { forwardRef } from 'react';
import { FaCalendarAlt } from 'react-icons/fa';
import './CustomInput.css';

const CustomInput = forwardRef(({ value, onClick }, ref) => (
  <div className="custom-input-container" onClick={onClick} ref={ref}>
    <input
      type="text"
      value={value}
      readOnly
      className="custom-input"
    />
    <FaCalendarAlt className="calendar-icon" />
  </div>
));

export default CustomInput;