import styled from "styled-components";
import React from 'react'
import InputMask from 'react-input-mask'
import PermissionGate from "../../services/PermissionGate";

import { useFormik } from "formik";
import * as Yup from "yup";

import * as AiIcons from 'react-icons/ai';
import { getEstados, getMunicipios, updateCliente } from "../../services/api";
import cep from 'cep-promise'

import { AuthContext } from "../../contexts/auth";
import { useEffect, useContext, useState } from "react";

import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from "react-router-dom";	

const DivEditClienteContainer = styled.div`
  z-index:15;
  position: fixed;
  top: 0;
  left: 0;
  display: ${({ showEditCliente }) => (showEditCliente ? 'none' :'flex' )};
  float: left;
  min-width: 100%;
  min-height: 100%;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 5px 0;
  list-style: none;
  background-color: rgba(0,0,0,0.5);
  background-clip: padding-box;
  align-items:center;
  justify-content:center;
`;

const DivEditCliente = styled.div`
    z-index:20;
    position: absolute;
    top: 10%;
    left: 12%;
    float: left;
    min-width: 32%;
    min-height: 45%;
    max-width: 80%;
    padding: 5px 0;
    list-style: none;
    background-color: white;
    background-clip: padding-box;
    border-radius: 15px;
`;

const ModalEditCliente = ({setEditCliente, showEditCliente, setRefresh, 
    _idClienteEdit,
    documento,
    nome,
    razao,
    contato,
    emailEdit,
    cepNumber,
    setCep,
    estado,
    setEstado,
    municipio,
    setMunicipio,
    bairro,
    setBairro,
    complemento,
    setComplemento,
    telefone,
    setTelefone,
    celular,
    setCelular,
    tipo,
}) => {
    
    const { user } = useContext(AuthContext);

    const navigate = useNavigate();

    //const [cepNumberEdit, setCep] = useState(cepNumber);
    const [ufs, setUfs] = useState([]);
    const [cities, setCities] = useState('');
    
    //const [estadoEdit, setEstado] = useState(estado);
    //const [municipioEdit, setMunicipio] = useState(municipio);
    //const [bairroEdit, setBairro] = useState(bairro);
    //const [complementoEdit, setComplemento] = useState(complemento);
    //const [telefoneEdit, setTelefone] = useState(telefone);
    //const [celularEdit, setCelular] = useState(celular);

    //UseEffect
    useEffect(() => {
        (async () => {
            
            const response = await getEstados();
            //console.log("TESTE ESTADOS:::",response)
            //const teste = [response.data]
            setUfs(response.data);
            //setLoading(false);

        })();
    },[]);

    useEffect(() => {
        getMunicipios(estado).then((response)=>{
            //console.log("MUNICIPIOS:::",response);
            setCities(response.data);
        })
    },[estado]);

    //Validações
    const SignUpSchema = Yup.object().shape({       
        documento: Yup.string().required("Obrigatório"),
        nome: Yup.string().required("Obrigatório"),
        razao: tipo==='J' && Yup.string().required("Obrigatório"),
        email: Yup.string().email("Insira um e-mail válido").required("Obrigatório"),
    });

    

    const formik = useFormik({
        validationSchema: SignUpSchema,
        validateOnBlur: false,
        validateOnChange: false,
        enableReinitialize: true,
        //enableReinitialize: showEditCliente,
        initialValues: {          
            documento: documento,
            nome: nome,
            razao: razao,
            contato: contato,
            email: emailEdit,
            // cepNumber: cepNumber,
            // estado: estadoEdit,
            // municipio: municipioEdit,
            // bairro: bairroEdit,
            // complemento: complementoEdit,
            // telefone: telefoneEdit,
            // celular: celularEdit,
        },
        onSubmit: (values) => {
          //alert(JSON.stringify(values, null, 2));
          //console.log(values);
          const documento = values.documento;
          const nome = values.nome;
          const razao = values.razao;
          const contato = values.contato;
          const email = values.email;
          
          handleSubmitAPI(documento, nome, razao, contato, email, cepNumber, estado, municipio, bairro, complemento, telefone, celular);
          
        },
    });

    const handleSubmitAPI = (documento, nome, razao, contato, email, cepNumber, estado, municipio, bairro, complemento, telefone, celular) => {
        const userID = user._id;
        console.log(_idClienteEdit, userID, documento, nome, razao, contato, email, cepNumber, estado, municipio, bairro, complemento, telefone, celular, tipo);
        
        updateCliente(_idClienteEdit, userID, documento, nome, razao, contato, email, cepNumber, estado, municipio, bairro, complemento, telefone, celular, tipo).then(cast => {
            console.log("Usuário editado com Sucesso!:::",cast)
            if(cast.status===200){
                toast(cast.data.msg,{ autoClose: 5000 , type: "success"});
                //navigate("/list-users");
                setRefresh(true);
                setEditCliente(!showEditCliente);
            }else{
                toast(cast.data.msg,{ autoClose: 5000 , type: "error"});
            }
            //navigate("/");
        }).catch(err => {
            console.log('Ocorreu algum erro no servidor!');
            console.log(err.response.data.msg);
            toast(err.response.data.msg,{ autoClose: 5000 , type: "error"});
        });
              
    }

    const testeVerificaCep = () =>{
        //const idEstado = document.getElementById("estado");
        //console.log("x"+cepNumber+"x");
        if(cepNumber!=='_____-___'){
            //console.log(cepNumber);
            cep(cepNumber).then( response => {
                console.log(response)      
                //idEstado.value = response.state
                //setEstado(idEstado.value)
                setEstado(response.state)
                setMunicipio(response.city)
                setBairro(response.neighborhood)
                setComplemento(response.street)
                //console.log(estado);
            })    
            
        }
    }
    
    const closeEditCliente = () => setEditCliente(!showEditCliente);



    return (
        <DivEditClienteContainer showEditCliente={showEditCliente}>
            <DivEditCliente>

                {/* // Teste Toastify */}                       
                {/* <ToastContainer position="top-center"/> */}

                {/* Teste Upload Images */}
                {/* <AiIcons.AiOutlineCloseCircle  onClick={closeEditCliente} style={{marginLeft:"96%", fontSize:"25px", cursor:"pointer"}}/> */}

                <div className="m-5" style={{width:"97%"}}>
                <div style={{marginBottom:"20px", marginTop:"-30px", fontSize:"25px"}}>
                    <a>Editar Cliente</a>
                </div>

                    {/* ///////////////////////////////// */}
                    <form onSubmit={formik.handleSubmit} style={{marginTop:"-30px", maxWidth:"100%"}}>    

                        <div style={{display:"flex", justifyContent:"right"/*, height:"80px"*/}}>
                            <div className="continue-button" style={{width:"150px", display:"flex", justifyContent:"right"}}>
                                <button style={{marginTop:"12px", height:"49.6px", width:"150px"}} type="submit">
                                <a><AiIcons.AiOutlineSave style={{marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/>Salvar</a> 
                                </button>
                            </div>                                 
                            <div className="back-button">                                                       
                                <button onClick={closeEditCliente} type="button">    
                                    <AiIcons.AiOutlineCloseCircle style={{color:"#ff4c4c",marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/><a>Fechar</a> 
                                </button>
                            </div>                            
                        </div>                
                    
                        <div className="input-group-edit" style={{paddingBottom:"0px", marginBottom:"0px"}}>                            

                            <div className="input-box" style={{width:"18%"}}>
                                <label style={{display:tipo==='J'?'none':''}}>CPF<span style={{color:"red"}}>*</span></label>
                                <label style={{display:tipo==='F'?'none':''}}>CNPJ<span style={{color:"red"}}>*</span></label>
                                <InputMask 
                                mask={tipo==='F'?"999.999.999-99":"99.999.999/9999-99"}
                                placeholder={tipo==='F'?"Digite CPF do Cliente":"Digite CNPJ do Cliente"} 
                                id="documento"
                                type="documento"
                                name="documento"
                                onChange={formik.handleChange}
                                value={formik.values.documento}
                                />
                                {formik.errors.documento && (
                                    <div className="error">{formik.errors.documento}</div>
                                )}
                            </div>


                            <div className="input-box" style={{width: "28%"}}>                                
                                <label style={{display:tipo==='F'?'none':''}}>Nome Fantasia<span style={{color:"red"}}>*</span></label>
                                <label style={{display:tipo==='J'?'none':''}}>Nome<span style={{color:"red"}}>*</span></label>
                                <input 
                                placeholder={tipo==='F'?"Digite o nome do Cliente":"Digite o nome Fantasia"} 
                                id="nome"
                                type="nome"
                                name="nome"
                                onChange={formik.handleChange}
                                value={formik.values.nome}
                                />
                                {formik.errors.nome && (
                                    <div className="error">{formik.errors.nome}</div>
                                )}
                            </div>



                            <div className="input-box" 
                            style={{
                            width: "28%",                     
                            display:tipo==='F'?'none':''
                            }}
                            >
                                <label>Razão Social<span style={{color:"red"}}>*</span></label>
                                <input 
                                placeholder="Digite a Razão Social" 
                                id="razao"
                                type="razao"
                                name="razao"
                                onChange={formik.handleChange}
                                value={formik.values.razao}
                                />
                                {formik.errors.razao && (
                                    <div className="error">{formik.errors.razao}</div>
                                )}
                            </div>


                            <div className="input-box" style={{
                            width:"28%", 
                            display:tipo==='J'?'none':''
                            }}
                            >
                                <label>Nome Contato</label>
                                <input 
                                placeholder="Digite o nome do Contato" 
                                id="contato"
                                type="contato"
                                name="contato"
                                onChange={formik.handleChange}
                                value={formik.values.contato}
                                />
                                {formik.errors.contato && (
                                    <div className="error">{formik.errors.contato}</div>
                                )}
                            </div>


                            <div className="input-box" style={{width: "24%"}}>
                                <label>E-mail do Cliente</label>
                                <input 
                                placeholder="Digite e-mail do Cliente" 
                                id="email"
                                type="email"
                                name="email"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                />
                                {formik.errors.email && (
                                    <div className="error">{formik.errors.email}</div>
                                )}
                            </div>

                            <div className="input-box" style={{width:"18%"}}>
                                <label>CEP</label>
                                <InputMask mask="99999-999" id="cep" type="text" name="cep" 
                                //onChange={(e) => setCep(e.target.value)} 
                                //value={cepNumber} 
                                onChange={(e) => setCep(e.target.value)}
                                value={cepNumber?cepNumber:''}
                                onBlur={testeVerificaCep} 
                                placeholder="Digite o CEP" />
                            </div>

                            <div className="input-box" style={{width:"24%"}}>
                                <label>Estado</label>
                                {/*<!-- Custom select structure --> */}
                                <div className="selectdiv">
                                    <label style={{width:"100%"}}>
                                        <select 
                                        id="estado"
                                        type="text"
                                        name="estado"
                                        onChange={(e) => setEstado(e.target.value)}
                                        value={estado?estado:''}
                                        className={estado === ""?'invalid-option':'beleza'}
                                        style={{width:"100%"}}
                                        >
                                            <option value="" /*className="invalid-option"*/>Escolha o Estado</option>
                                            {ufs.map((uf)=>(
                                                <option key={uf.id} value={uf.sigla} style={{color:"black"}}>
                                                    {uf.nome}
                                                </option>
                                            ))}
                                            
                                        </select>                                      
                                    </label>
                                </div>
                                {/*<!-- Custom select structure --> */}
                                {/* <input id="estado" type="text" name="estado" placeholder="Digite o Estado" /> */}
                            </div>

                            <div className="input-box" style={{width:"28%"}}>
                                <label>Município</label>
                                {/*<!-- Custom select structure --> */}
                                <div className="selectdiv">
                                    <label style={{width:"100%"}}>
                                        <select 
                                        id="municipio"
                                        type="text"
                                        name="municipio"
                                        onChange={(e) => setMunicipio(e.target.value)}
                                        value={municipio?municipio:''}
                                        className={municipio === ""?'invalid-option':'beleza'}
                                        style={{width:"100%"}}
                                        >
                                            <option value="" /*className="invalid-option"*/>Escolha o Município</option>
                                            {cities && cities.map((city)=>(
                                                <option key={city.id} value={city.nome} style={{color:"black"}}>
                                                    {city.nome}
                                                </option>
                                            ))}
                                            
                                        </select>                                      
                                    </label>
                                </div>
                                {/*<!-- Custom select structure --> */}
                                {/* <input id="municipio" type="text" name="municipio" placeholder="Digite o Município" /> */}
                            </div>

                            <div className="input-box" style={{width:"28%"}}>
                                <label>Bairro</label>
                                <input id="bairro" type="text" name="bairro" 
                                onChange={(e) => setBairro(e.target.value)}
                                value={bairro?bairro:''}
                                placeholder="Digite o Bairro" />
                            </div>

                            <div className="input-box" style={{width:"50%"}}>
                                <label>Complemento do Endereço</label>
                                <input id="complemento" type="text" name="complemento"
                                onChange={(e) => setComplemento(e.target.value)}
                                value={complemento?complemento:''}
                                placeholder="Digite o Complemento" />
                            </div>                                                      
                            
                            <div className="input-box" style={{width:"24%"}}>
                                <label htmlFor="telefone">Telefone</label>
                                <InputMask mask="(99)9999-9999" id="telefone" type="tel" name="telefone" 
                                onChange={(e) => setTelefone(e.target.value)}
                                value={telefone?telefone:''}
                                placeholder="(xx) xxxx-xxxx" />
                            </div>

                            <div className="input-box" style={{width:"24%"}}>
                                <label htmlFor="celular">Celular</label>
                                <InputMask mask="(99)99999-9999" id="celular" type="tel" name="celular" 
                                onChange={(e) => setCelular(e.target.value)}
                                value={celular?celular:''}
                                placeholder="(xx) x xxxx-xxxx" />
                            </div>


                        </div>

                        
                    </form>
                    {/* ////////////////////////////////////   */}

                
                                            
                {/* <a className="btn btn-sm btn-danger" onClick={closeModelImg} style={{color:"rgb(120,120,120)", background:"white"}}>Fechar</a> */}

                </div>
                {/* Fim Upload Images */}
                

            </DivEditCliente>
        </DivEditClienteContainer>
    )
}

export default ModalEditCliente;