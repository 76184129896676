import styled from "styled-components";
import React, { useRef } from 'react'
import './style.css'
import PermissionGate from "../../services/PermissionGate";
import moment from 'moment'
import CryptoJS from 'crypto-js';
import { useFormik } from "formik";
import * as Yup from "yup";

import * as AiIcons from 'react-icons/ai';
import { updatePedidoWithEntregador, getEntregadores } from "../../services/api";
import AsyncSelect from "react-select/async";

import { AuthContext } from "../../contexts/auth";
import { useEffect, useContext, useState } from "react";

import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { FaMotorcycle, FaStore } from "react-icons/fa";
import { BiComment } from "react-icons/bi";

import { useNavigate } from "react-router-dom";

const DivPedidoContainer = styled.div`
  z-index:15;
  position: fixed;
  top: 0;
  left: 0;
  display: ${({ showModalAddEntregador }) => (showModalAddEntregador ? 'none' : 'flex')};
  float: left;
  min-width: 100%;
  min-height: 100%;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 5px 0;
  list-style: none;
  background-color: rgba(0,0,0,0.5);
  background-clip: padding-box;
  align-items:center;
  justify-content:center;
  opacity: ${({ showModalAddEntregador }) => (showModalAddEntregador ? '0' : '1')};
  transition: opacity .3s ease-in-out;

  // Add keyframes for the fade-in effect
  animation: fadeIn .3s ease-in-out;
  animation-fill-mode: forwards;

  // Define the keyframes for the animation
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const DivPedido = styled.div`
    z-index:20;
    position: absolute;
    top: 15%;
    left: 30%;
    float: left;
    min-width: 40%;
    min-height: 25%;
    --max-width: 50%;
    padding: 5px 0;
    list-style: none;
    --background-color: white;
    background-color:rgb(247,247,247)!important;
    background-clip: padding-box;
    border-radius: 15px;
    padding:20px;

`;

const ModalAddEntregador = ({
    showModalAddEntregador,
    setShowModalAddEntregador,
    pedidoToAddEntregador,
    objIdEmpresa,
    userID,
    setRefresh
}) => {

    const closeModalAddEntregador = () => {
        setShowModalAddEntregador(!showModalAddEntregador);
    }

    const saveModalAddAddEntregador = () => {
        handleSaveEntregador();
        setShowModalAddEntregador(!showModalAddEntregador);
    }

    const [selectData, setSelectData] = useState({
        value: '',
        label: 'Selecione o entregador',
    });

    useEffect(() => {
        if (pedidoToAddEntregador && pedidoToAddEntregador.entregador) {
          const entregador = pedidoToAddEntregador.entregador;
          setSelectData({
            value: entregador,
            label: `${entregador.name} | ${entregador.veiculo}`,
          });
        } else {
          setSelectData({
            value: '',
            label: 'Selecione o entregador',
          });
        }
    }, [pedidoToAddEntregador]);

    const mapResponseToValuesAndLabels = (data) => ({
        value: data,
        label: data.name + " | " + data.veiculo,
    });

    async function callApi(value) {
        const data = await getEntregadores(objIdEmpresa)
        .then((response) => response.data.entregadores)//response.json())
        .then((response) => response.sort((a, b) => a.order - b.order )) // Adicione esta linha para ordenar os dados
        .then((response) => response.map(mapResponseToValuesAndLabels))
        .then((response) => response.filter((i) => i.label.toLowerCase().includes(value.toLowerCase())) );
  
        return data;
    }

    const handleSaveEntregador = async () => {
        if (!selectData.value) {
          toast.error('Selecione um entregador');
          return;
        }
    
        try {
          const response = await updatePedidoWithEntregador(userID, pedidoToAddEntregador._id, pedidoToAddEntregador.id_pedido, selectData.value);
          toast.success('Entregador adicionado ao pedido com sucesso');
          setRefresh(true);
          setShowModalAddEntregador(!showModalAddEntregador);
        } catch (error) {
          toast.error('Erro ao adicionar entregador ao pedido');
        }
    };

    return (
        <DivPedidoContainer showModalAddEntregador={showModalAddEntregador}>
            <DivPedido>
                <div className="closeModalPedido" onClick={() => closeModalAddEntregador()}>
                    <div
                        iconname="x"
                        iconcolor="#2B2B2B"
                        iconsize={18}
                        className="iconCancel"
                        style={{ height: 18, display: 'flex' }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={18}
                            height={18}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#2B2B2B"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-x"
                        >
                            <line x1={18} y1={6} x2={6} y2={18} />
                            <line x1={6} y1={6} x2={18} y2={18} />
                        </svg>
                    </div>
                </div>


                <div className="divTitlePedido">
                    <h2>Adicionar Entregador</h2>
                </div>
                <div className="divInputPedido">
                    <span style={{fontWeight:600}}>Pedido {pedidoToAddEntregador.id_pedido}</span>
                    {/*<!-- Custom select structure --> */}
                    <div className="selectdiv-teste" style={{ width: "100%", maxWidth: "100%" }}>
                        <label style={{ width: "100%" }}>
                            <AsyncSelect
                                cacheOptions
                                loadOptions={callApi}
                                onChange={(data) => {
                                    setSelectData(data);
                                }}
                                value={selectData}
                                defaultOptions
                                id="vinculo_categoria"
                                type="vinculo_categoria"
                                name="vinculo_categoria"
                                className="asyncSelectInput"
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        margin: "0.6rem 0",
                                        paddingLeft: "2.5rem",
                                        height: "45px",
                                        border: "none",
                                        outline: state.isFocused ? '1px solid #4070f4;' : '0',
                                        borderRadius: "10px",
                                        boxShadow: "1px 1px 6px #0000001c",
                                        fontSize: "0.8rem",
                                    }),
                                    input: (style) => ({
                                        ...style,
                                        color: "black",
                                    }),
                                    singleValue: (baseStyles) => ({
                                        ...baseStyles,
                                        color: selectData.value ? "black" : "gray",
                                    }),
                                    valueContainer: (baseStyles) => ({
                                        ...baseStyles,
                                        //fontWeight: "lighter",
                                    }),
                                    option: (styles) => {
                                        return {
                                            ...styles,
                                            //fontWeight: "lighter",
                                            color: "black",
                                        };
                                    },
                                }}
                            />
                        </label>
                    </div>
                    {/*<!-- Custom select structure --> */}
                </div>


                <div className="divBtnContinueCart">
                    {/*<button className="buttonContinue" onClick={()=> closeModal()}>Não preciso de troco</button>*/}
                    <div style={{ width: '100%', display: 'flex', gap: 10 }}>
                        <button className="buttonCart" onClick={() => saveModalAddAddEntregador()}>Salvar</button>
                        <button className="buttonCancelPadrao" onClick={() => closeModalAddEntregador()}>Cancelar</button>
                    </div>
                </div>

            </DivPedido>
        </DivPedidoContainer>
    )
}

export default ModalAddEntregador;