import styled from "styled-components";
import React from 'react'

import PermissionGate from "../../services/PermissionGate";
import InputMask from 'react-input-mask';
import { useFormik } from "formik";
import * as Yup from "yup";

import * as AiIcons from 'react-icons/ai';
import { updateEntregador } from "../../services/api";
import AsyncSelect from "react-select/async";

import { AuthContext } from "../../contexts/auth";
import { useEffect, useContext, useState } from "react";

import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import CryptoJS from 'crypto-js';

import { useNavigate } from "react-router-dom";

const DivEditUserContainer = styled.div`
  z-index:15;
  position: fixed;
  top: 0;
  left: 0;
  display: ${({ showEditEntregador }) => (showEditEntregador ? 'none' : 'flex')};
  float: left;
  min-width: 100%;
  min-height: 100%;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 5px 0;
  list-style: none;
  background-color: rgba(0,0,0,0.5);
  background-clip: padding-box;
  align-items:center;
  justify-content:center;
`;

const DivEditUser = styled.div`
    z-index:20;
    position: absolute;
    top: 10%;
    left: 25%;
    float: left;
    min-width: 32%;
    min-height: 45%;
    max-width: 50%;
    padding: 5px 0;
    list-style: none;
    --background-color: white;
    background-color:rgb(247,247,247)!important;
    background-clip: padding-box;
    border-radius: 15px;

    @media (max-width: 1100px){
        max-width: 95vw;
        left: 10px;
    }
`;

const ModalEditEntregador = ({ setEditEntregador, showEditEntregador, setRefresh,
    _idEntregadorEdit,
    nomeEntregadorEdit,
    veiculoEdit,
    telefoneEdit,
    placaEdit,
    userID,
    id_empresa
}) => {


    //Validações
    const SignUpSchema = Yup.object().shape({
        tipo: Yup.string().required("Obrigatório"),
        telefone: Yup.string().required("Obrigatório"),
        nome: Yup.string().required("Obrigatório"),
    });

    const formik = useFormik({
        validationSchema: SignUpSchema,
        validateOnBlur: false,
        validateOnChange: false,
        enableReinitialize: true,
        //enableReinitialize: showEditEntregador,
        initialValues: {
            tipo: veiculoEdit,
            placa: placaEdit,
            //confirmPassword: "",
            telefone: telefoneEdit,
            //vinculo: empresaVinculoID ? empresaVinculoID+" | "+empresaVinculoCnpj +" | "+empresaVinculoNome : "" ,
            nome: nomeEntregadorEdit,
        },
        onSubmit: (values) => {
            //alert(JSON.stringify(values, null, 2));

            const veiculo = values.tipo;
            const telefone = values.telefone;
            const name = values.nome;
            const placa = values.placa;
            //console.log("vinculo_empresa",vinculo_empresa)
            //const vinculo='';
            //console.log("values.tipo:::",values.tipo)
          
            handleSubmitAPI(_idEntregadorEdit, name, veiculo, telefone, placa);

        },
    });

    // Lógica para atualizar os dados do usuário no localStorage
    // Get the existing data
    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const userEncrypted = localStorage.getItem('user')
    var existing = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
    //var existing = localStorage.getItem('user');
    // Convert the localStorage string to an array
    existing = JSON.parse(existing);

    const handleSubmitAPI = (_idEntregadorEdit,  name, veiculo, telefone, placa) => {        

        console.log(_idEntregadorEdit, userID, id_empresa, name, veiculo, telefone, placa);
        updateEntregador(_idEntregadorEdit, userID, id_empresa, name, veiculo, telefone, placa).then(cast => {
            if (cast.status === 200) {

                toast(cast.data.msg, { autoClose: 5000, type: "success" });
                setRefresh(true);
                setEditEntregador(!showEditEntregador);
            } else {
                toast(cast.data.msg, { autoClose: 5000, type: "error" });
            }
        }).catch(err => {
            toast(err.response.data.msg, { autoClose: 5000, type: "error" });
        });
    }

    const closeEditEntregador = () => setEditEntregador(!showEditEntregador);



    return (
        <DivEditUserContainer showEditEntregador={showEditEntregador}>
            <DivEditUser>

                {/* // Teste Toastify */}
                {/* <ToastContainer position="top-center"/> */}

                {/* Teste Upload Images */}
                {/* <AiIcons.AiOutlineCloseCircle  onClick={closeEditEntregador} style={{marginLeft:"96%", fontSize:"25px", cursor:"pointer"}}/> */}

                <div className="w-100 p-5" style={{ width: "95%" }}>
                    <div style={{ marginBottom: "20px", marginTop: "-30px", fontSize: "25px" }}>

                        <h4>Editar Entregador</h4>

                    </div>

                    {/* ///////////////////////////////// */}
                    <form onSubmit={formik.handleSubmit} style={{ marginTop: "-30px", maxWidth: "920px" }}>

                        <div style={{ display: "flex", justifyContent: "right"/*, height:"80px"*/ }}>
                            <div className="continue-button" style={{ width: "150px", display: "flex", justifyContent: "right" }}>
                                <button style={{ marginTop: "12px", height: "49.6px", width: "150px" }} type="submit">
                                    <a><AiIcons.AiOutlineSave style={{ marginRight: "5px", fontSize: "18px", marginBottom: "2px" }} />Salvar</a>
                                </button>
                            </div>
                            <div className="back-button">
                                <button onClick={closeEditEntregador} type="button">
                                    <AiIcons.AiOutlineCloseCircle style={{ color: "#ff4c4c", marginRight: "5px", fontSize: "18px", marginBottom: "2px" }} /><a>Fechar</a>
                                </button>
                            </div>
                        </div>

                        <div className="input-group-edit" style={{ paddingBottom: "0px", marginBottom: "0px" }}>

                            <div className="input-box" style={{ width: "49%" }}>
                                <label>Nome<span style={{color:"red"}}>*</span></label>
                                <input
                                    placeholder="Digite o nome do Entregador"
                                    id="nome"
                                    type="nome"
                                    name="nome"
                                    //onChange={e => setUsernameEdit(e.target.value)/*formik.handleChange*/}
                                    //value={nomeEntregadorEdit}
                                    onChange={formik.handleChange}
                                    value={formik.values.nome}
                                />
                                {formik.errors.nome && (
                                    <div className="error">{formik.errors.nome}</div>
                                )}
                            </div>


                            <div className="input-box" style={{width:"49%"}}>
                                <label>Veículo<span style={{color:"red"}}>*</span></label>
                                {/*<!-- Custom select structure --> */}
                                <div className="selectdiv">
                                <label style={{width:"100%"}}>
                                    <select 
                                    id="tipo"
                                    type="tipo"
                                    name="tipo"
                                    onChange={formik.handleChange}
                                    value={formik.values.tipo}
                                    className={formik.values.tipo === ""?'invalid-option':'beleza'}
                                    style={{width:"100%"}}
                                    >
                                        <option value="" className="invalid-option">Selecione um Tipo</option>
                                        <option>Bicicleta</option>
                                        <option>Carro</option>
                                        <option>Motocicleta</option>
                                    </select>
                                    {formik.errors.tipo && (
                                    <div className="error" style={{fontWeight: "lighter"}}>{formik.errors.tipo}</div>
                                    )}
                                </label>
                                </div>
                                {/*<!-- Custom select structure --> */}
                            </div> 
                            

                            <div className="input-box" style={{width: "48%"}}>
                                <label htmlFor="telefone">Telefone<span style={{color:"red"}}>*</span></label>
                                <InputMask type="tel" id="telefone" mask="(99) 99999-9999" maskChar={''} placeholder="(__) _ ____-____" 
                                value={formik.values.telefone}
                                onChange={formik.handleChange}
                                style={{ width: '100%', padding: '10px', marginBottom: '10px', height:'45px', borderRadius: '10px' }} />
                                {formik.errors.telefone && (
                                        <div className="error">{formik.errors.telefone}</div>
                                    )}
                            </div>

                            

                            <div className="input-box" style={{width:"48%"}}>
                                <label htmlFor="placa">Placa</label>                            
                                <InputMask 
                                placeholder="Digita a placa do veículo"                                     
                                id="placa"
                                name="placa"
                                type="placa"
                                //value={placa}
                                //onChange={e => setPassword(e.target.value)}
                                onChange={formik.handleChange}
                                value={formik.values.placa}
                                />
                                {formik.errors.placa && (
                                    <div className="error">{formik.errors.placa}</div>
                                )}
                            </div>    

                        </div>

                    </form>
                    {/* ////////////////////////////////////   */}



                    {/* <a className="btn btn-sm btn-danger" onClick={closeModelImg} style={{color:"rgb(120,120,120)", background:"white"}}>Fechar</a> */}

                </div>
                {/* Fim Upload Images */}


            </DivEditUser >
        </DivEditUserContainer >
    )
}

export default ModalEditEntregador;