import styled from "styled-components";
import './style.css'
import { ImCheckboxChecked } from "react-icons/im";
import { FaMinus } from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import { FaUserCircle } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { registerClienteFromCardapio } from "../../services/api";


const DivBGtransparent = styled.div`
  z-index:15;
  position: fixed;
  top: 0;
  left: 0;
  display: ${({ showConfirmInfo }) => (showConfirmInfo ? 'flex' : 'none' )};
  float: left;
  min-width: 100%;
  min-height: 100%;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 5px 0;
  list-style: none;
  background-color: rgba(0,0,0,0.5);
  background-clip: padding-box;
  align-items:center;
  justify-content:center;
  opacity: ${({ showConfirmInfo }) => (showConfirmInfo ? '1':'0' )};
  transition: opacity .3s ease-in-out;

  // Add keyframes for the fade-in effect
  animation: fadeIn .3s ease-in-out;
  animation-fill-mode: forwards;

  // Define the keyframes for the animation
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const DivContainer = styled.div`
    z-index:20;
    position: absolute;
    top: 20%;
    left: 11%;
    float: left;
    min-width: 80%;
    min-height: 40%;
    --max-width: 50%;
    padding: 5px 0;
    list-style: none;
    --background-color: white;
    background-color:rgb(247,247,247)!important;
    background-clip: padding-box;
    border-radius: 15px;
    padding:20px;

`;

const ModalConfirmInfoCliente = ({showConfirmInfo, 
    setShowConfirmInfo,
    namePersonData,
    whatsappData,
    id_empresa,
    setClientCompleteData,
    setItensCarrinho,
    itensCarrinho,
    selectedItemId,
    showPedido, setRefresh, 
    //tipoImpressao
}) => {
    const navigate = useNavigate();

    const handleKeepBuy = () => {
        setShowConfirmInfo(!showConfirmInfo);
        navigate(-1);
    }

    const editInfo = () => {
        setShowConfirmInfo(!showConfirmInfo);        
    }

    const handleSaveOnServer = () => {
        registerClienteFromCardapio(id_empresa, namePersonData, whatsappData).then((response) => {
            if(response.status === 201){
                console.log('Cliente registrado com sucesso!');
                setClientCompleteData(response.data.cliente);
                setShowConfirmInfo(!showConfirmInfo);
            } else if (response.status === 200){
                console.log('Cliente ja registrado!');
                setClientCompleteData(response.data.cliente);
                setShowConfirmInfo(!showConfirmInfo);
            } else {
                console.log('Erro ao registrar cliente!');
                setShowConfirmInfo(!showConfirmInfo);
            }
        });
        
    }

    return (
        <DivBGtransparent showConfirmInfo={showConfirmInfo}>
            <DivContainer>

                {/*<div className="divTitleItemAdicionado">
                    <span className="divTitleItemAdicionadoSpan">Item teste</span> 
                    <span className="divTitleItemAdicionadoSpan">adicionado ao carrinho!</span>
                </div>*/}

                <div style={{textAlign:'center',marginBottom: '10px'}}>
                    <span className="titleConfirmInfo">Confirme as informações</span>
                </div>

                <div className="nomeInfoContainer">
                    <span className="quantityText">Seu nome é:</span>                                
                    <div className="divPaiInputsInfoCliente">
                        <FaUserCircle style={{fontSize:'30px',color:'gray'}}/>
                        <span className="spanModalInfoCliente">{namePersonData}</span> 
                    </div>                     
                </div>

                <div className="nomeInfoContainer">
                    <span className="quantityText">Seu WhatsApp:</span>                                
                    <div className="divPaiInputsInfoCliente">
                        <FaWhatsapp style={{fontSize:'30px',color:'gray'}}/>
                        <span className="spanModalInfoCliente">{whatsappData}</span> 
                    </div>                     
                </div>

                <div className="divBtnContinueCart">
                    <button className="buttonContinue" onClick={()=> editInfo()}>Editar informações</button>
                    <div style={{width:'100%'}}>
                        <button onClick={()=> handleSaveOnServer()} className="buttonCart">Confirmar</button>
                    </div>
                </div>

            </DivContainer>
        </DivBGtransparent>
    );
}

export default ModalConfirmInfoCliente