import Loading from 'react-loading';
import React, { useState } from "react";
import '../style.css'
import '../styleResponsive.css'
import styled from 'styled-components';

import moment from 'moment'
import * as AiIcons from 'react-icons/ai'
import { FaMotorcycle, FaRegClock, FaStore } from "react-icons/fa";
import { IoMdPin } from "react-icons/io";

const Ordercolumn = styled.div`
  min-width: 25%;
  width: 100%;
`;

export function MobileKanban({
  tempoBalcaoMinBD,
  tempoBalcaoMaxBD,
  tempoEntregaMinBD,
  tempoEntregaMaxBD,
  pedidosSimples,
  pedidosFinalizados,
  tipoImpressao,
  pedidosAnalise,
  pedidosProducao,
  pedidosEntrega,
  pedidosFinalizado,
  filterIsLoading,
  handleShowPedido,
  handleEditTempoEntrega,
  handleAddEntregador,
  handleAvancar,
  userID,
}){
  const [currentScreen, setCurrentScreen] = useState('Em analise')

  const screens = {
    'Em analise': () => (
        <Ordercolumn
            typecolumn="analysis"
            className="column"
        >
            <div className="containerAnalise">
                <div className="header-column-analise">

                    <div className="header-column--align">
                        <h4>Em análise</h4>
                    </div>
                    <h4>{pedidosAnalise.length}</h4>

                </div>
                <div className="blocoAnalise">
                    <div className="column-container column-container--first">
                        <div className="time">
                            <div>
                                <p className="titleOptEmp">
                                    <b>Balcão: </b>
                                    <span>{tempoBalcaoMinBD} a {tempoBalcaoMaxBD} min</span>
                                </p>
                                <p className="titleOptEmp">
                                    <b>Delivery: </b>
                                    <span>{tempoEntregaMinBD} a {tempoEntregaMaxBD} min</span>
                                </p>
                                <p className="titleOptEmp">
                                    <b>Aceite automático: </b>
                                    <span>{tipoImpressao == 'manual' ? 'Desativado' : tipoImpressao == 'automatico' ? 'Ativado' : 'Não definido'}</span>
                                </p>
                            </div>
                            <div className="text-edit" onClick={() => handleEditTempoEntrega(userID)} style={{ cursor: "pointer" }}>
                                {" "}
                                Editar{" "}
                            </div>
                        </div>
                    </div>
                    {
                        pedidosAnalise.length == 0 ?
                            <div key={Math.random()} className="column-container column-container--first">
                                <div className="text">
                                    <p style={{ marginBottom: 0, justifyContent: 'center', textAlign: 'center', display: 'flex' }}>Nenhum pedido no momento.</p>
                                    <p style={{ marginBottom: 0, justifyContent: 'center', textAlign: 'center', display: 'flex' }}>
                                        Compartilhe os seus links nas redes sociais e receba pedidos!
                                    </p>
                                </div>
                            </div>
                            :
                            //pedidos && pedidos.map((dados, i) => (
                            pedidosSimples && pedidosSimples.map((dados, i) => (
                                dados.status_pedido == '1' ?
                                    <div key={Math.random()} className="bloco-pedidos" onClick={() => handleShowPedido(dados._id, dados.id_empresa)}>
                                        <div className="pedido-time">
                                            <div className="tag-pedido">
                                                <div className="icon-pedido">
                                                    {dados.entrega.tipo_entrega == "Retirada" ? <FaStore /> : <FaMotorcycle />}
                                                </div>
                                                <span className="bold">Pedido #{dados.id_pedido_counter ?? dados.id_pedido}</span>

                                            </div>
                                            <div className="time-container">
                                                <div className="time-div">
                                                    <FaRegClock />
                                                    <span>{moment.parseZone(dados.createdAt).local().format("HH:mm")}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="cliente-total">
                                            <div className="bloco">
                                                <div className="linha">
                                                    <div className="texto">{dados.nome_cliente}</div>
                                                    {dados.counter_qtd_pedido > 1 ?
                                                        <div className="label-recorrencia-pedidoMoreThanOne">
                                                            <div className="caixaNumero">
                                                                <span style={{ fontSize: 10 }}>{dados.counter_qtd_pedido}</span>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="label-recorrencia-pedido">
                                                            <div className="caixaNumero">
                                                                <span style={{ fontSize: 10 }}>1º</span>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="linha">
                                                    <div className="texto">
                                                        {dados.celular_cliente}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bloco">
                                                <div className="linha">
                                                    <div className="texto bold">Total:</div>
                                                    <div className="texto bold">
                                                        R$ {dados.valor_total && dados.valor_total.toFixed(2).toString().replace('.', ',')}
                                                    </div>
                                                </div>
                                                <div className="linha">
                                                    <div className="texto">Pagamento:</div>
                                                    <div className="texto">{dados.tipo_pagamento}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="bloco-entrega">
                                            {dados.entrega.tipo_entrega == "Retirada" ?
                                                <div className="flex-entrega">
                                                    <div className="bloco-footer">
                                                        <div className="pin" style={{ display: 'flex', marginTop: 1 }}>
                                                            <IoMdPin className="pin" />
                                                        </div>
                                                        <div className="texto">Retirada no Local</div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="flex-entrega">
                                                    <div className="bloco-footer">
                                                        <div className="pin">
                                                            <IoMdPin className="pin" />
                                                        </div>
                                                        <div className="texto">
                                                            {`${dados.entrega.endereco.rua}, ${dados.entrega.endereco.numero} 
                                                ${dados.entrega.endereco.complemento && " - " + dados.entrega.endereco.complemento}, ${dados.entrega.endereco.bairro}`}
                                                        </div>
                                                    </div>
                                                    <div className="bloco" onClick={(event) => handleAddEntregador(event, dados)}>
                                                        {dados.entregador ?
                                                            <div className="texto underline">{dados.entregador.name}</div>
                                                            :
                                                            <div className="texto underline">Escolher Entregador</div>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        <div style={{ marginTop: 10 }}>
                                            <div className="button-avancar-pedido">
                                                <button onClick={(event) => handleAvancar(event, dados._id, dados.id_pedido, dados.status_pedido)}>
                                                    <div>
                                                        <a>Avançar Pedido</a>
                                                        <AiIcons.AiOutlineArrowRight style={{ marginLeft: "2px", fontSize: "22px", marginBottom: "2px", fontColor: 'white' }} />
                                                    </div>
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                    :
                                    null

                            ))

                    }
                </div>
            </div>
        </Ordercolumn>
    ),
    'Em producao': () => (
        <Ordercolumn
            typecolumn="production"
            className="column"
        //style={{display:'table-row'}}
        >
            <div className="containerProducao">
                <div className="header-column-producao">
                    <div className="header-column--align">
                        <h4>Em produção</h4>
                    </div>
                    <h4>{pedidosProducao.length}</h4>
                </div>

                <div className="blocoProducao">

                    {pedidosProducao.length == 0 ?
                        <div key={Math.random()} className="column-container column-container--first">
                            <div className="text">
                                <p style={{ marginBottom: 0, justifyContent: 'center', textAlign: 'center', display: 'flex' }}>Nenhum pedido no momento.</p>
                                <p style={{ marginBottom: 0, justifyContent: 'center', textAlign: 'center', display: 'flex' }}>
                                    Receba pedidos e visualize os que estão em produção.
                                </p>
                            </div>
                        </div>
                        :
                        pedidosSimples && pedidosSimples.map((dados, i) => (
                            dados.status_pedido == '2' ?
                                <div key={Math.random()} className="bloco-pedidos" onClick={() => handleShowPedido(dados._id, dados.id_empresa)}>
                                    <div className="pedido-time">
                                        <div className="tag-pedido">
                                            <div className="icon-pedido">
                                                {dados.entrega.tipo_entrega == "Retirada" ? <FaStore /> : <FaMotorcycle />}
                                            </div>
                                            <span className="bold">Pedido #{dados.id_pedido_counter ?? dados.id_pedido}</span>

                                        </div>
                                        <div className="time-container">
                                            <div className="time-div">
                                                <FaRegClock />
                                                <span>{moment.parseZone(dados.createdAt).local().format("HH:mm")}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="cliente-total">
                                        <div className="bloco">
                                            <div className="linha">
                                                <div className="texto">{dados.nome_cliente}</div>
                                                {dados.counter_qtd_pedido > 1 ?
                                                    <div className="label-recorrencia-pedidoMoreThanOne">
                                                        <div className="caixaNumero">
                                                            <span style={{ fontSize: 10 }}>{dados.counter_qtd_pedido}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="label-recorrencia-pedido">
                                                        <div className="caixaNumero">
                                                            <span style={{ fontSize: 10 }}>1º</span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="linha">
                                                <div className="texto">
                                                    {dados.celular_cliente}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bloco">
                                            <div className="linha">
                                                <div className="texto bold">Total:</div>
                                                <div className="texto bold">
                                                    R$ {dados.valor_total && dados.valor_total.toFixed(2).toString().replace('.', ',')}
                                                </div>
                                            </div>
                                            <div className="linha">
                                                <div className="texto">Pagamento:</div>
                                                <div className="texto">{dados.tipo_pagamento}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="bloco-entrega">
                                        {dados.entrega.tipo_entrega == "Retirada" ?
                                            <div className="flex-entrega">
                                                <div className="bloco-footer">
                                                    <div className="pin" style={{ display: 'flex', marginTop: 1 }}>
                                                        <IoMdPin className="pin" />
                                                    </div>
                                                    <div className="texto">Retirada no Local</div>
                                                </div>
                                            </div>
                                            :
                                            <div className="flex-entrega">
                                                <div className="bloco-footer">
                                                    <div className="pin">
                                                        <IoMdPin className="pin" />
                                                    </div>
                                                    <div className="texto">
                                                        {`${dados.entrega.endereco.rua}, ${dados.entrega.endereco.numero} 
                                                ${dados.entrega.endereco.complemento && " - " + dados.entrega.endereco.complemento}, ${dados.entrega.endereco.bairro}`}
                                                    </div>
                                                </div>
                                                <div className="bloco" onClick={(event) => handleAddEntregador(event, dados)}>
                                                    {dados.entregador ?
                                                        <div className="texto underline">{dados.entregador.name}</div>
                                                        :
                                                        <div className="texto underline">Escolher Entregador</div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div style={{ marginTop: 10 }}>
                                        <div className="button-avancar-pedido">

                                            <button onClick={(event) => handleAvancar(event, dados._id, dados.id_pedido, dados.status_pedido)}>
                                                <div>
                                                    <a>Avançar Pedido</a>
                                                    <AiIcons.AiOutlineArrowRight style={{ marginLeft: "2px", fontSize: "22px", marginBottom: "2px", fontColor: 'white' }} />
                                                </div>
                                            </button>

                                        </div>
                                    </div>

                                </div>
                                :
                                null
                        ))
                    }

                </div>

            </div>

        </Ordercolumn>
    ),
    'Prontos': () => (
        <Ordercolumn
            _ngcontent-ng-c2041748172=""
            typecolumn="ready"
            className="column"
            _nghost-ng-c2960941283=""
            style={{ display: 'table-row' }}
        >
            <div className="containerPronto">
                <div className="header-column-pronto">
                    <div className="header-column--align" style={{ display: 'inline-flex' }}>
                        <h4>Prontos</h4>
                    </div>
                    <h4>{pedidosEntrega.length}</h4>
                </div>

                <div className="blocoPronto">
                    {pedidosEntrega.length == 0 ?
                        <div key={Math.random()} className="column-container column-container--first">
                            <div className="text">
                                <p style={{ marginBottom: 0, justifyContent: 'center', textAlign: 'center', display: 'flex' }}>Nenhum pedido no momento.</p>
                                <p style={{ marginBottom: 0, justifyContent: 'center', textAlign: 'center', display: 'flex' }}>
                                    Receba pedidos e visualize os que estão prontos para entrega.
                                </p>
                            </div>
                        </div>
                        :
                        pedidosSimples && pedidosSimples.map((dados, i) => (
                            dados.status_pedido == '3' ?
                                <div key={Math.random()} className="bloco-pedidos" onClick={() => handleShowPedido(dados._id, dados.id_empresa)}>
                                    <div className="pedido-time">
                                        <div className="tag-pedido">
                                            <div className="icon-pedido">
                                                {dados.entrega.tipo_entrega == "Retirada" ? <FaStore /> : <FaMotorcycle />}
                                            </div>
                                            <span className="bold">Pedido #{dados.id_pedido_counter ?? dados.id_pedido}</span>

                                        </div>
                                        <div className="time-container">
                                            <div className="time-div">
                                                <FaRegClock />
                                                <span>{moment.parseZone(dados.createdAt).local().format("HH:mm")}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="cliente-total">
                                        <div className="bloco">
                                            <div className="linha">
                                                <div className="texto">{dados.nome_cliente}</div>
                                                {dados.counter_qtd_pedido > 1 ?
                                                    <div className="label-recorrencia-pedidoMoreThanOne">
                                                        <div className="caixaNumero">
                                                            <span style={{ fontSize: 10 }}>{dados.counter_qtd_pedido}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="label-recorrencia-pedido">
                                                        <div className="caixaNumero">
                                                            <span style={{ fontSize: 10 }}>1º</span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="linha">
                                                <div className="texto">
                                                    {dados.celular_cliente}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bloco">
                                            <div className="linha">
                                                <div className="texto bold">Total:</div>
                                                <div className="texto bold">
                                                    R$ {dados.valor_total && dados.valor_total.toFixed(2).toString().replace('.', ',')}
                                                </div>
                                            </div>
                                            <div className="linha">
                                                <div className="texto">Pagamento:</div>
                                                <div className="texto">{dados.tipo_pagamento}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="bloco-entrega">
                                        {dados.entrega.tipo_entrega == "Retirada" ?
                                            <div className="flex-entrega">
                                                <div className="bloco-footer">
                                                    <div className="pin" style={{ display: 'flex', marginTop: 1 }}>
                                                        <IoMdPin className="pin" />
                                                    </div>
                                                    <div className="texto">Retirada no Local</div>
                                                </div>
                                            </div>
                                            :
                                            <div className="flex-entrega">
                                                <div className="bloco-footer">
                                                    <div className="pin">
                                                        <IoMdPin className="pin" />
                                                    </div>
                                                    <div className="texto">
                                                        {`${dados.entrega.endereco.rua}, ${dados.entrega.endereco.numero} 
                                                ${dados.entrega.endereco.complemento && " - " + dados.entrega.endereco.complemento}, ${dados.entrega.endereco.bairro}`}
                                                    </div>
                                                </div>
                                                <div className="bloco" onClick={(event) => handleAddEntregador(event, dados)}>
                                                    {dados.entregador ?
                                                        <div className="texto underline">{dados.entregador.name}</div>
                                                        :
                                                        <div className="texto underline">Escolher Entregador</div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div style={{ marginTop: 10 }}>
                                        <div className="button-avancar-pedido">

                                            <button onClick={(event) => handleAvancar(event, dados._id, dados.id_pedido, dados.status_pedido)}>
                                                <div>
                                                    <a>Avançar Pedido</a>
                                                    <AiIcons.AiOutlineArrowRight style={{ marginLeft: "2px", fontSize: "22px", marginBottom: "2px", fontColor: 'white' }} />
                                                </div>
                                            </button>

                                        </div>
                                    </div>

                                </div>
                                :
                                null
                        ))
                    }
                </div>
            </div>

        </Ordercolumn>
    ),
    'Finalizados': () => (
        <Ordercolumn
            _ngcontent-ng-c2041748172=""
            typecolumn="ready"
            className="column"
            _nghost-ng-c2960941283=""
            style={{ display: 'table-row' }}
        >
            <div className="containerFinalizado">
                <div className="header-column-finalizado">
                    <div className="header-column--align" style={{ display: 'inline-flex' }}>
                        <h4>Finalizados</h4>
                        <div className="header-column-clear" style={{ display: 'flex', alignItems: 'center', marginLeft: 6 }}>
                            <pedeja-icon
                                iconname="check-circle"
                                iconsize={18}
                                pedejatooltip="Finalizar todos os pedidos não relacionados a mesa"
                                iconcolor="#FFFFFF"
                                style={{ height: 18, display: 'flex', alignItems: 'center' }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={18}
                                    height={18}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#FFFFFF"
                                    strokeWidth={3}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-check-circle"
                                >
                                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                                    <polyline points="22 4 12 14.01 9 11.01" />
                                </svg>
                            </pedeja-icon>
                            {/**/}
                        </div>
                    </div>
                    <h4>{pedidosFinalizado.length}</h4>
                </div>

                <div className="blocoFinalizado">
                    {pedidosFinalizado.length == 0 ?
                        <div key={Math.random()} className="column-container column-container--first">
                            <div className="text">
                                <p style={{ marginBottom: 0, justifyContent: 'center', textAlign: 'center', display: 'flex' }}>Nenhum pedido no momento.</p>
                                <p style={{ marginBottom: 0, justifyContent: 'center', textAlign: 'center', display: 'flex' }}>
                                    Receba pedidos e visualize os finalizados.
                                </p>
                            </div>
                        </div>
                        :
                        pedidosFinalizados && pedidosFinalizados.map((dados, i) => (
                            dados.status_pedido == '4' ?
                                <div key={Math.random()} className="bloco-pedidos" onClick={() => handleShowPedido(dados._id, dados.id_empresa)}>
                                    <div className="pedido-time">
                                        <div className="tag-pedido">
                                            <div className="icon-pedido">
                                                {dados.entrega.tipo_entrega == "Retirada" ? <FaStore /> : <FaMotorcycle />}
                                            </div>
                                            <span className="bold">Pedido #{dados.id_pedido_counter ?? dados.id_pedido}</span>
                                            {dados.cancelado &&
                                                <div className="divSpanCancelado">
                                                    <span>CANCELADO</span>
                                                </div>
                                            }
                                        </div>
                                        <div className="time-container">
                                            <div className="time-div">
                                                <FaRegClock />
                                                <span>{moment.parseZone(dados.createdAt).local().format("HH:mm")}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="cliente-total">
                                        <div className="bloco">
                                            <div className="linha">
                                                <div className="texto">{dados.nome_cliente}</div>
                                                {dados.counter_qtd_pedido > 1 ?
                                                    <div className="label-recorrencia-pedidoMoreThanOne">
                                                        <div className="caixaNumero">
                                                            <span style={{ fontSize: 10 }}>{dados.counter_qtd_pedido}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="label-recorrencia-pedido">
                                                        <div className="caixaNumero">
                                                            <span style={{ fontSize: 10 }}>1º</span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="linha">
                                                <div className="texto">
                                                    {dados.celular_cliente}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bloco">
                                            <div className="linha">
                                                <div className="texto bold">Total:</div>
                                                <div className="texto bold">
                                                    R$ {dados.valor_total && dados.valor_total.toFixed(2).toString().replace('.', ',')}
                                                </div>
                                            </div>
                                            <div className="linha">
                                                <div className="texto">Pagamento:</div>
                                                <div className="texto">{dados.tipo_pagamento}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="bloco-entrega">
                                        {dados.entrega.tipo_entrega == "Retirada" ?
                                            <div className="flex-entrega">
                                                <div className="bloco-footer">
                                                    <div className="pin" style={{ display: 'flex', marginTop: 1 }}>
                                                        <IoMdPin className="pin" />
                                                    </div>
                                                    <div className="texto">Retirada no Local</div>
                                                </div>
                                            </div>
                                            :
                                            <div className="flex-entrega">
                                                <div className="bloco-footer">
                                                    <div className="pin">
                                                        <IoMdPin className="pin" />
                                                    </div>
                                                    <div className="texto">
                                                        {`${dados.entrega.endereco.rua}, ${dados.entrega.endereco.numero} 
                                                ${dados.entrega.endereco.complemento && " - " + dados.entrega.endereco.complemento}, ${dados.entrega.endereco.bairro}`}
                                                    </div>
                                                </div>
                                                <div className="bloco">
                                                    {dados.entregador &&
                                                        <div className="texto underline">{dados.entregador.name}</div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>



                                </div>
                                :
                                null

                        ))
                    }
                </div>
            </div>

        </Ordercolumn>
    )
  }

  const screensColors = {
    'Em analise': '#7C7C7C',
    'Em producao': '#8F5F00',
    'Prontos': '#046338',
    'Finalizados': '#00A2AB'
  }

  return (
    <div className="d-flex flex-column gap-4">
      <div className="d-flex gap-3">
        {Object.values(screens).filter(screen => screen.name !== currentScreen).map((screen, index) => (
            <button
              key={screen}
              onClick={() => setCurrentScreen(screen.name)}
              style={{
                  backgroundColor: screensColors[screen.name],
                  padding: "0 10px 0 10px",
                  color: 'white',
                  border: '8px',
                  cursor: 'pointer',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  outline: 'none',
                  height: 'fit-content',
                  minHeight: '50px',
                  borderRadius: '5px'
              }}
          >
              {screen.name}
          </button>
        ))}
      </div>
      <div className="Ordercolumn" style={{ display: 'inline-flex', height: '674px', boxShadow: '0px 0px 5px 2px rgb(0,0,0,.1)' }}>
          {filterIsLoading ? (
              <Loading type='spin' color='rgb(49, 140, 213)' height={56} width={56} className="loadingSpinHomeFilters" />
          )
              : null
          }
          {screens[currentScreen]()}
      </div>
    </div>
  )
}