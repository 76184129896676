import styled from "styled-components";
import './style.css'
import { ImCheckboxChecked } from "react-icons/im";
import { FaMinus } from "react-icons/fa";
import { useParams, useNavigate, Link, useSearchParams } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import CryptoJS from 'crypto-js';

const DivBGtransparent = styled.div`
  z-index:15;
  position: fixed;
  top: 0;
  left: 0;
  display: ${({ addedToCart }) => (addedToCart ? 'flex' : 'none')};
  float: left;
  min-width: 100%;
  min-height: 100%;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 5px 0;
  list-style: none;
  background-color: rgba(0,0,0,0.5);
  background-clip: padding-box;
  align-items:center;
  justify-content:center;
  opacity: ${({ addedToCart }) => (addedToCart ? '1' : '0')};
  transition: opacity .3s ease-in-out;

  // Add keyframes for the fade-in effect
  animation: fadeIn .3s ease-in-out;
  animation-fill-mode: forwards;

  // Define the keyframes for the animation
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const DivContainer = styled.div`
    z-index:20;
    position: absolute;
    top: 20%;
    left: 11%;
    float: left;
    min-width: 80%;
    min-height: 46%;
    --max-width: 50%;
    padding: 5px 0;
    list-style: none;
    --background-color: white;
    background-color:rgb(247,247,247)!important;
    background-clip: padding-box;
    border-radius: 15px;
    padding:20px;

`;

const ModalItemAdicionado = ({ addedToCart,
    setAddedToCart,
    setItensCarrinho,
    itensCarrinho,
    selectedItemId,
    selectedItemNome,
    showPedido, setRefresh
    //tipoImpressao
}) => {
    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const navigate = useNavigate();
    // Obtém o external_id da URL atual, se existir
    const [searchParams] = useSearchParams();
    const externalId = searchParams.get('external_id');
    const { nomeEmpresaForUrl, idEmpresa } = useParams();
    console.log(nomeEmpresaForUrl, idEmpresa);
    const handleKeepBuy = () => {
        setAddedToCart(!addedToCart);
        navigate(-1);
    }

    return (
        <DivBGtransparent addedToCart={addedToCart}>
            <DivContainer>
                <div className="produtoAddChecked">
                    <ImCheckboxChecked />
                </div>
                <div className="divTitleItemAdicionado">
                    <span className="divTitleItemAdicionadoSpan">{selectedItemNome}</span>
                    <span className="divTitleItemAdicionadoSpan">adicionado ao carrinho!</span>
                </div>

                <div className="qtdBoxContainer">
                    <span className="quantityText">Quantidade</span>
                    <div className="qtdDataCounter">
                        <div className="downAndCounter">
                            <button style={{ background: "transparent" }}
                                onClick={() => {
                                    setItensCarrinho(itensCarrinho.map(item => {
                                        if (item.id === selectedItemId) {
                                            // Recalcular o totalValue quando a quantidade mudar
                                            const decryptedPrice = CryptoJS.AES.decrypt(item.price, secretKey).toString(CryptoJS.enc.Utf8);
                                            const newQuantity = Math.max(item.quantity - 1, 1);
                                            const newTotalValue = newQuantity * parseFloat(decryptedPrice);
                                            const encryptedTotalValue = CryptoJS.AES.encrypt(newTotalValue.toString(), secretKey).toString();

                                            // Atualizar o item com a nova quantidade e o novo totalValue
                                            return { ...item, quantity: newQuantity, total: encryptedTotalValue };
                                        } else {
                                            return item;
                                        }
                                    }));
                                }}
                            >
                                <div className="trashIconCarrinho">
                                    <FaMinus style={{ color: "grey", fontSize: "16px" }} />
                                </div>
                            </button>
                            <div className="counterQtdItemCarrinho">
                                {itensCarrinho.find(item => item.id === selectedItemId)?.quantity || 1}
                            </div>
                        </div>
                        <button className="btnAddCounter" onClick={() => {
                            setItensCarrinho(itensCarrinho.map(item => {
                                if (item.id === selectedItemId) {
                                    // Recalcular o totalValue quando a quantidade mudar
                                    const decryptedPrice = CryptoJS.AES.decrypt(item.price, secretKey).toString(CryptoJS.enc.Utf8);
                                    const newQuantity = (item.quantity || 1) + 1;
                                    const newTotalValue = newQuantity * parseFloat(decryptedPrice);
                                    const encryptedTotalValue = CryptoJS.AES.encrypt(newTotalValue.toString(), secretKey).toString();

                                    // Atualizar o item com a nova quantidade e o novo totalValue
                                    return { ...item, quantity: newQuantity, total: encryptedTotalValue };
                                } else {
                                    return item;
                                }
                            }));
                        }}>
                            <p><FaPlus style={{ fontSize: '20px' }} /></p>
                        </button>
                    </div>
                </div>

                <div className="divBtnContinueCart">
                    <button className="buttonContinue" onClick={() => handleKeepBuy()}>Continuar comprando</button>
                    <Link
                        to={
                            externalId
                                ? `/${nomeEmpresaForUrl}/${idEmpresa}/carrinho?external_id=${externalId}`
                                : `/${nomeEmpresaForUrl}/${idEmpresa}/carrinho`
                        }
                        state={{ fromItemAddModal: true }}
                    >
                        <button className="buttonCart">Ir para o carrinho</button>
                    </Link>
                </div>

            </DivContainer>
        </DivBGtransparent>
    );
}

export default ModalItemAdicionado