import React from 'react';
import { MenuProfile } from '../../contexts/MenuProfileContext';
import WhatsAppWeb from './whatsapp';

const MainComponentWhats = () => {

    return (    
        <MenuProfile>
            <WhatsAppWeb />
        </MenuProfile>
    );
}

export default MainComponentWhats;