import React, { useState, useContext, useEffect } from "react";
import './style.css'
import styled from 'styled-components';
import InputMask from 'react-input-mask'
import cep from 'cep-promise'
import CryptoJS from 'crypto-js';
import { AuthContext } from "../../contexts/auth";
import InputMoney from "../ListItem/InputMoney";
import { SidebarContext } from "../../AppRoutes";
import { registerAdicionais } from "../../services/api";
import ImageUploading from 'react-images-uploading';
import { CImage } from '@coreui/react'
import PermissionGate from "../../services/PermissionGate";
import { useNavigate, useLocation } from "react-router-dom";
import LeftMenu from "../../components/LeftMenu"; //TESTE
import Loading from "react-loading";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MdFastfood } from "react-icons/md";
import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'
import { BsPlusCircle } from "react-icons/bs";
import { FaTrash } from 'react-icons/fa'

import { toast } from "react-toastify";

const DivModalContainer = styled.div`
  z-index:99;
  --position: fixed;
  --top: 0;
  --left: 0;
  display: ${({ showModalAddItemAdd }) => (showModalAddItemAdd ? 'none' : 'flex')};
  --float: left;
  --min-width: 100%;
  --min-height: 100%;
  --width: 100vw;
  --height: 100vh;
  --overflow: hidden;
  --padding: 5px 0;
  --list-style: none;
  --background-color: rgba(0,0,0,0.5);
  --background-clip: padding-box;
  --align-items:center;
  --justify-content:center;
`;

const DivModal = styled.div`
    z-index:20;
    position: absolute;
    top: 10%;
    left: 25%;
    float: left;
    min-width: 50%;
    min-height: 45%;
    max-width: 55%;
    padding: 5px 0;
    list-style: none;
    --background-color: white;
    background-color:rgb(247,247,247)!important;
    background-clip: padding-box;
    border-radius: 15px;
`;

const ModalAddItemAdicional = ({
    showModalAddItemAdd,
    setShowModalAddItemAdd,
    id_grupo,
    setGrupoAdicionaisCadastrado
}) => {
    const location = useLocation();
    //const { id_grupo } = location.state || {};
    console.log(id_grupo)
    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const userEncrypted = localStorage.getItem('user')
    const user = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
    const userParse = JSON.parse(user)
    const userID = userParse._id;
    var userImg = null;
    const navigate = useNavigate();
    //console.log(empresa)
    const empresa = localStorage.getItem('empresa')
    const empresaParse = JSON.parse(empresa)
    const idEmpresa = empresaParse.id_empresa;

    const { sidebar, setSidebar } = useContext(SidebarContext);; //TESTANDO CONST NO AppROUTES

    const [mandatory, setMandatory] = useState(false);
    const [minimo, setMinimo] = useState(mandatory ? 1 : 0);
    const [maximo, setMaximo] = useState(1);
    const [valuePreco, setValuePreco] = useState(0);
    const [savingAdicional, setSavingAdicional] = useState(false);

    const [errImg, setErrImg] = React.useState(false);
    const [images, setImages] = React.useState([]);
    const maxNumber = 1;
    const maxMbFileSize = 2 * 1024 * 1024; // 2Mb

    const onChangeImg = (imageList, addUpdateIndex) => {
        // data for submit
        console.log(imageList, addUpdateIndex);
        setImages(imageList);
    };

    const closeModal = () => setShowModalAddItemAdd(!showModalAddItemAdd);

    const handleMinimoChange = (event) => {
        const value = Math.min(Math.max(0, event.target.valueAsNumber), maximo);
        setMinimo(value);
    };

    const handleMaximoChange = (event) => {
        const value = Math.max(minimo, event.target.valueAsNumber);
        setMaximo(value);
    };

    const incrementMinimo = () => {
        setMinimo((prevMinimo) => (prevMinimo < maximo ? prevMinimo + 1 : prevMinimo));
    };

    const decrementMinimo = () => {
        setMinimo((prevMinimo) => (prevMinimo > 0 ? prevMinimo - 1 : prevMinimo));
    };

    const incrementMaximo = () => {
        setMaximo((prevMaximo) => prevMaximo + 1);
    };

    const decrementMaximo = () => {
        setMaximo((prevMaximo) => (prevMaximo > minimo ? prevMaximo - 1 : prevMaximo));
    };

    useEffect(() => {
        // Ajusta minimo quando mandatory muda
        if (mandatory && minimo === 0) {
            setMinimo(1);
        } else if (!mandatory && minimo !== 0) {
            setMinimo(0);
        }
    }, [mandatory]);

    useEffect(() => {
        // Ajusta mandatory quando minimo muda
        setMandatory(minimo > 0);
    }, [minimo]);

    //Validações
    const SignUpSchema = Yup.object().shape({
        title: Yup.string().required("Obrigatório"),
        //description: Yup.string().required("Obrigatório"),
    });

    const formik = useFormik({
        validationSchema: SignUpSchema,
        validateOnBlur: false,
        validateOnChange: false,
        initialValues: {
            title: "",
            //description: "",
        },
        onSubmit: (values) => {
            //alert(JSON.stringify(values, null, 2));
            console.log(values);

            const title = values.title;
            //const description = values.description;
            const preco = values.preco;

            handleSubmitAPI(title, preco);

        },
    });

    const onChange = (event, nameObject, isNumber) => {
        setValuePreco(isNumber ? Number(event.target.value) : event.target.value);
        formik.setFieldValue("preco", isNumber ? Number(event.target.value) : event.target.value);
    };

    const [isFocused, setIsFocused] = useState(false);
    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleBack = () => {
        //setSidebar(!sidebar)
        navigate("/list-adicional");
    }


    const handleSubmitAPI = (title, preco) => {

        //setSavingAdicional(true);
        const createdBy = userID;
        const out = false;
        var price;
        if (preco) {
            price = preco;
        } else {
            price = 0;
        }
        //Registrando a empresa
        console.log("submit adicional!", { createdBy, id_grupo, idEmpresa, title, price, out, images });

        registerAdicionais(createdBy, id_grupo, idEmpresa, title, price, out, images).then(cast => {
            console.log("CRIOU ADICIONAL:::", cast)
            if (cast.status === 201) {
                toast(cast.data.msg, { autoClose: 5000, type: "success" });
                setGrupoAdicionaisCadastrado(cast.data.createdAdicional)
                //navigate("/list-adicional");
            }
            else {
                toast(cast.data.msg, { autoClose: 5000, type: "error" });
            }

        }).catch(err => {
            console.log('Oh noooo!!');
            const msg = err.response.data.msg;
            //console.log("MENSAGEM", msg)
            toast(msg, { autoClose: 5000, type: "error" });

        }).finally(() => {
            setSavingAdicional(false);
            setShowModalAddItemAdd(!showModalAddItemAdd);
        });

    }


    return (

        <>

            <DivModalContainer showModalAddItemAdd={showModalAddItemAdd}>


                {/*<form onSubmit={handleSubmitButton}>*/}
                <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>



                    <div className="input-group inputGroup-Adicionais" style={{marginRight:'10px'}}>

                        <div className="containerBox-adicionaisModalRow" style={{ minHeight: 400 }}>

                            <div className="row-adicionais-1" style={{ flexDirection: 'column', width: '50%' }}>
                                <div className="input-box" style={{ width: "90%" }}>
                                    <label>Nome do Adicional<span style={{ color: "red" }}>*</span></label>
                                    <input
                                        placeholder="Digite o nome do adicional"
                                        id="title"
                                        type="title"
                                        name="title"
                                        onChange={formik.handleChange}
                                        value={formik.values.title}
                                    />
                                    {formik.errors.title && (
                                        <div className="error">{formik.errors.title}</div>
                                    )}
                                </div>

                                <div className="input-box precoItemCustom" style={{ width: "90%" }}>
                                    <label>Preço</label>
                                    <InputMoney
                                        onChange={(event) => onChange(event, 'price', true)}
                                        value={valuePreco}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        margin="0px 0px 16px 0px"
                                        style={{
                                            outline: isFocused ? '2px solid #4070f4' : 'none',
                                            borderRadius: 10,
                                            margin: '0.6rem 0 0.2rem 0',
                                        }}
                                        className=""
                                        placeholder="Preço"
                                    />
                                    {formik.errors.preco && (
                                        <div className="error">{formik.errors.preco}</div>
                                    )}
                                </div>

                            </div>

                            <div style={{ width: "50%", display: 'flex', justifyContent: 'center' }}>
                                <div className="divImgAddItem">
                                    <ImageUploading
                                        multiple
                                        value={images}
                                        onChange={onChangeImg}
                                        maxNumber={maxNumber}
                                        maxFileSize={maxMbFileSize}
                                        acceptType={["jpg", "png"]}
                                        dataURLKey="data_url"
                                    >

                                        {({
                                            imageList,
                                            onImageUpload,
                                            onImageRemoveAll,
                                            onImageUpdate,
                                            onImageRemove,
                                            isDragging,
                                            dragProps,
                                            errors
                                        }) => (
                                            // write your building UI

                                            <div className="upload__image-wrapper imgItem"
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    width: "100%",
                                                    //marginLeft:"5%",
                                                    height: "100%",
                                                    padding: "10px"
                                                }}
                                            >

                                                <div onClick={images.length === 0 ? onImageUpload : null} {...dragProps}
                                                    style={{
                                                        border: imageList.length > 0 ? "solid 1px lightgray" : "dashed 1px #4281FF",
                                                        borderRadius: "5px", minWidth: "198px", minHeight: "198px",
                                                        background: "rgb(247,247,247)",
                                                        cursor: images.length === 0 ? 'pointer' : '',
                                                        display: "flex",
                                                        alignItems: "center",
                                                        flexDirection: "column"
                                                    }}
                                                    className="divUploadImgItem"
                                                >
                                                    {imageList.length > 0 ? "" :
                                                        <>
                                                            <div className="iconLanche">
                                                                <MdFastfood style={{ fontSize: 44 }} />
                                                            </div>
                                                            <div style={{ fontSize: 11, fontWeight: '700' }}>Escolha a foto</div>
                                                        </>
                                                    }
                                                    {isDragging ? "Solte aqui..." : imageList.length > 0 ? "" : <a style={{ padding: '0 33px', fontSize: 11 }}>Clique aqui, ou arraste a foto para cá.</a>}
                                                    {imageList.map((image, index) => (
                                                        <div key={index} className="image-item" style={{ justifyContent: "center", display: "grid", gap: 3 }}>
                                                            {/*<div className="boxImgToFit">    
                                                                    <img src={image['data_url']} alt="" width="138"/>
                                                                </div> */}
                                                            <CImage rounded thumbnail fluid src={image['data_url']} /*width={150} height={150}*/ />
                                                            <div className="image-item__btn-wrapper addItemPart" style={{ marginLeft: "0px" }}>
                                                                <button className="btnAttImgItem" type="button" style={{ fontSize: "14px" }}
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        onImageUpdate(index)
                                                                    }
                                                                    }>
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="18px"
                                                                        height="18px"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        stroke="#5A5A5A"
                                                                        strokeWidth={2}
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        className="feather feather-refresh-ccw"
                                                                    >
                                                                        <polyline points="1 4 1 10 7 10" />
                                                                        <polyline points="23 20 23 14 17 14" />
                                                                        <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15" />
                                                                    </svg>
                                                                </button>
                                                                <button className="btnDelImgItem" type="button" style={{ fontSize: "14px" }} onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    onImageRemove(index)
                                                                }
                                                                }>
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="18px"
                                                                        height="18px"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        stroke="#5A5A5A"
                                                                        strokeWidth={2}
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        className="feather feather-trash-2"
                                                                    >
                                                                        <polyline points="3 6 5 6 21 6" />
                                                                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                                                        <line x1={10} y1={11} x2={10} y2={17} />
                                                                        <line x1={14} y1={11} x2={14} y2={17} />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>


                                                {errors && (
                                                    <div>
                                                        {errors.maxNumber && (
                                                            <span style={{ color: "red" }}>Limite de {maxNumber} imagem!</span>
                                                        )}
                                                        {errors.acceptType && (
                                                            <span style={{ color: "red" }}>Formato de arquivo não suportado!</span>
                                                        )}
                                                        {errors.maxFileSize && (
                                                            <span style={{ color: "red", fontSize: 12 }}>Arquivo Excede o Tamanho Máximo Permitido!</span>
                                                        )}
                                                        {/* {errors.resolution && (
                                                            <span>Resolução muito baixa.</span>
                                                        )} */}
                                                    </div>
                                                )}

                                                {errImg && (
                                                    <div>
                                                        <span style={{ color: "red" }}>Imagem Obrigatória!</span>
                                                    </div>
                                                )
                                                }


                                            </div>

                                        )}
                                    </ImageUploading>
                                    <div style={{ display: "flex", justifyContent: "right"/*, height:"80px"*/ }}>

                                        <div className="div-buttons">
                                            <div className="continue-button">
                                                <button type="submit" disabled={savingAdicional} style={{ cursor: savingAdicional ? 'not-allowed' : '' }}>
                                                    {savingAdicional ?
                                                        <Loading type="spin" color="white" height={30} width={30}
                                                            className="zIndexForLoadingSaveButton" />
                                                        :
                                                        <><AiIcons.AiOutlineSave style={{ marginRight: "5px", fontSize: "22px", marginBottom: "2px" }} /><a>Adicionar</a></>
                                                    }
                                                </button>
                                            </div>

                                            <div className="back-button">
                                                <button type="button" onClick={closeModal}>
                                                    <SlIcons.SlActionUndo style={{ color: "#ff4c4c", marginRight: "5px", fontSize: "18px", marginBottom: "2px" }} /><a >Cancelar</a>
                                                </button>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </form>



            </DivModalContainer >

        </>


    );
};

export default ModalAddItemAdicional;