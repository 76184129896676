import { useContext, useEffect, useRef } from 'react';
import { AuthContext } from '../../contexts/auth'; // Importar AuthContext
import { toast } from 'react-toastify';
import { useLocation, Navigate } from 'react-router-dom'; // Importar useLocation para monitorar mudanças de rota
import { checkLicense } from '../../services/api';

const useCheckLicense = () => {
  const { user, hasLicense, setHasLicense, setShowToast, setRedirectToPlanos } = useContext(AuthContext);
  const location = useLocation(); // Para rastrear mudanças de rota
  const toastShown = useRef(false); // Ref para rastrear se o Toast já foi mostrado
  var pathname = window.location.pathname;

  useEffect(() => {
    const checkLicenseValidity = async () => {
        if (!user) return;          

        //if (pathname!=='planos') return;
        
        try {
          const response = await checkLicense(user._id);
          const licenseValid = response.data.hasLicense;
          //console.log("OQ VOLTOU DA API CHECK LICENSE?",licenseValid)
          if(licenseValid){
            setHasLicense(licenseValid);
            setRedirectToPlanos(false);
          }
          //setRedirectToPlanos(true);
          if (!licenseValid && !toastShown.current) {
            toast.error(
                <div>
                    <span>Nenhuma licença ativa. <a href="/planos">Clique aqui</a> para verificar os planos.</span>
                </div>,
                {
                    autoClose: 5000,
                    closeButton: true,
                    draggable: false,
                }
            );
            toastShown.current = true; // Marque o Toast como exibido
            setHasLicense(false);
            setRedirectToPlanos(true);
            setShowToast(true); // Mostrar o Toast se a licença não for válida
          } 
        } catch (error) {          
          /*if (pathname==='/planos') {            
            console.error('Error checking license:', error);
            toast.error(
                <div>
                    <span>Nenhuma licença ativa. <a href="/planos">Clique aqui</a> para verificar os planos.</span>
                </div>,
                {
                    autoClose: 3000,
                    closeButton: true,
                    draggable: false,
                }
            );
            console.log("window.location.pathname>>>",pathname)
          }*/
          console.log(error);
        }
    };
    checkLicenseValidity();
    
  }, [location, user, hasLicense, setHasLicense, setShowToast, pathname]); // Reavalie ao mudar de rota
};


export default useCheckLicense;