import React, { useEffect, useState } from 'react';
import Input from './Input';

const DECIMAL_SIZE = 2;

const InputMoney = ({ value, onChange, addonBefore = 'R$', ...props }) => {
  const [currentValue, setCurrentValue] = useState(value.toFixed(DECIMAL_SIZE).toString().replace('.', ','));

  useEffect(() => {
    const valueString = value.toString();

    if (!/\D/.test(valueString.replace('.', ''))) {
      setCurrentValue(value.toFixed(DECIMAL_SIZE).toString().replace('.', ','));
    }
  }, [value]);

  const handleOnChange = (event) => {
    const valueRemoved = event.target.value.replace(',', '');

    const sizeSlice = valueRemoved.length - DECIMAL_SIZE;
    const newValue = [valueRemoved.slice(0, sizeSlice), '.', valueRemoved.slice(sizeSlice)].join('');

        onChange({
        ...event,
        target: {
            ...event.target,
            value: newValue,
        },
        });
  };

  return (
    <Input addonBefore={addonBefore} value={currentValue} onChange={handleOnChange} {...props} />
  );
};

export default InputMoney;
