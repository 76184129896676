import CryptoJS from 'crypto-js';

const useGetUserPermission = () => {
    //logica para pegar as permissões das pessoas
    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const userEncrypted = localStorage.getItem('user')
    const user = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
    //const user = localStorage.getItem('user')
    const permissao = JSON.parse(user)
    //console.log(permissao.role);
    if(permissao.role === 'Admin'){
        return [
            //Home
            'default',
            //User
            'viewUser',
            'viewRoleAdmin',
            //Empresa
            'viewIndustry' , 
            'viewRoleEmpresa',
            'AdminOnly'
        ]
    }
    if(permissao.role === 'empresa'){
        return [
            //Home
            'default',
            //User 
            'viewUser',
            'viewRoleRevenda',
            //Revenda
            'viewRevenda',
        ]
    }
    if(permissao.role === 'revenda'){
        return [
            //Home
            'default',
            //Visualização Cliente Vendedor e Orçamento
            'viewCVO'
        ]
    }
    
}

const PermissionGate = ({ children, permissions }) => {
    const userPermissions = useGetUserPermission()

    if(
        permissions
        .every(permission =>{
            return userPermissions.includes(permission)
        })
    ){
        return children
    }

    return null

}

export default PermissionGate;