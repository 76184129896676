import React, { useState } from "react";

const ModalConfirmacaoSenha = ({ isOpen, onClose, onConfirm, hasCancelPassword }) => {
    const [senha, setSenha] = useState("");

    if (!isOpen) return null;

    const handleConfirm = () => {
        onConfirm(senha); // Chama a função de confirmação passando a senha digitada
        setSenha(""); // Limpa a senha após confirmação
    };

    return (
        <div style={modalOverlayStyles}>
            <div style={modalStyles}>
                <h5>Confirmação de Cancelamento</h5>
                {hasCancelPassword ?
                <>
                <span>Digite a senha para confirmar o cancelamento:</span>
                <input
                    type="password"
                    value={senha}
                    onChange={(e) => setSenha(e.target.value)}
                    placeholder="Digite a senha"
                    style={inputStyles}
                />
                <div style={buttonContainerStyles}>
                    <button onClick={handleConfirm} style={confirmButtonStyles}>Confirmar</button>
                    <button onClick={onClose} style={cancelButtonStyles}>Cancelar</button>
                </div>
                </>
                :
                <>
                <span>Tem certeza que deseja cancelar o pedido?</span>
                <div style={buttonContainerStyles}>
                    <button onClick={handleConfirm} style={confirmButtonStyles}>Confirmar</button>
                    <button onClick={onClose} style={cancelButtonStyles}>Cancelar</button>
                </div>
                </>
                }
            </div>
        </div>
    );
};

// Estilos simples para o modal
const modalOverlayStyles = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
};

const modalStyles = {
    width: "400px",
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
};

const inputStyles = {
    width: "100%",
    padding: "8px",
    margin: "10px 0",
    fontSize: "14px",
};

const buttonContainerStyles = {
    display: "flex",
    justifyContent: "space-between",
};

const confirmButtonStyles = {
    padding: "8px 12px",
    backgroundColor: "#4caf50",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
};

const cancelButtonStyles = {
    padding: "8px 12px",
    backgroundColor: "#f44336",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
};

export default ModalConfirmacaoSenha;
