import React, { useEffect, useState } from 'react';
import './styles.css';
import { GoogleMap, useJsApiLoader, Circle, Marker } from '@react-google-maps/api';

const libraries = ['places', 'geometry']

const Map = ({ arrayRaioEntrega, latitudeEmpresa, longitudeEmpresa }) => {

    const ApiKey = "AIzaSyDe-csDaMYbHHrjdzR-q-OyHvh04MfJnck"    


    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        libraries: libraries,
        googleMapsApiKey: ApiKey,
        language: 'pt',   // Define a linguagem como português
        region: 'BR'      // Define a região como Brasil
    })

    const mapContainerStyle = {
        height: "400px",
        width: "100%"
    }
      
    // Preciso obter lat e lng do endereço da empresa
    console.log(latitudeEmpresa)
    console.log(longitudeEmpresa)
    const center = {
        lat: latitudeEmpresa,
        lng: longitudeEmpresa
    }
      
    const options = {
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: 1000,
        zIndex: 1
    }
      
    const onLoad = circle => {
        //console.log('Circle onLoad circle: ', circle)
    }
      
    const onUnmount = circle => {
        //console.log('Circle onUnmount circle: ', circle)
    }

    return (
    <div className='mapContainer'>
    {
        isLoaded ? (
        
            <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center} 
            zoom={14}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
                className: 'custom-map',
            }}
            >
            { /* Child components, such as markers, info windows, etc. */ }
                <Marker position={center} options={{
                    label:{ 
                        text:"Meu Estabelecimento",
                        className: "mapMarker"
                    }
                }}/>
            {
            arrayRaioEntrega.length>0 ? arrayRaioEntrega.map((item, index) => (
                
                <Circle
                key={item._id}
                // optional
                onLoad={onLoad}
                // optional
                onUnmount={onUnmount}
                // required
                center={center}
                // required
                options={
                    {
                        strokeColor: '#FF0000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FF0000',
                        fillOpacity: 0.07,
                        clickable: false,
                        draggable: false,
                        editable: false,
                        visible: true,
                        radius: item.raio_entrega,
                        zIndex: 1
                    }
                }
                />
            ))
            : null
            }
            <></>
            </GoogleMap>

        ) : <></>
    }

    </div>

    )
};

export default Map;
