import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import imgTeste from '../../img/imgTeste.png'
import './styleMobile.css'
import CryptoJS from 'crypto-js';
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa";
import ModalConfirmInfoCliente from "../../components/ModalConfirmInfoCliente";
import InputMask from "react-input-mask";
import { FaLocationCrosshairs } from "react-icons/fa6";
import AsyncSelect from 'react-select/async';
import { Controller } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { GrLocation } from "react-icons/gr";
import { RiCloseCircleLine } from "react-icons/ri";
import { updateClienteAddress } from "../../services/api";
import {
    setKey,
    setDefaults,
    setLanguage,
    setRegion,
    fromAddress,
    fromLatLng,
    fromPlaceId,
    setLocationType,
    geocode,
    RequestType,
} from "react-geocode";
import {
    GoogleMap,
    Marker,
    LoadScript,
    Autocomplete,
    StandaloneSearchBox,
    DirectionsService,
    DirectionsRenderer,
} from "@react-google-maps/api";
import { toast } from "react-toastify";
const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';
const REACT_APP_GOOGLE_API_KEY = "AIzaSyDe-csDaMYbHHrjdzR-q-OyHvh04MfJnck"//import.meta.env.REACT_APP_GOOGLE_API_KEY

setDefaults({
    key: "AIzaSyDe-csDaMYbHHrjdzR-q-OyHvh04MfJnck", // Your API key here.
    language: "pt-BR", // Default language for responses.
    region: "br", // Default region for responses.
});


const isBrazilianAddress = (place) => {
    // Aqui verificamos se o endereço está localizado no Brasil
    return place.address_components.some(
        (component) => component.short_name === 'BR'
    );
};

const libraries = ["places"];

const AddEndereco = () => {

    // Obtém o external_id da URL atual, se existir
    const [searchParams] = useSearchParams();
    const externalId = searchParams.get('external_id');

    const location = useLocation();
    const { nomeEmpresaForUrl, idEmpresa } = useParams();
    const { personInfoObj, enderecoToEdit } = location.state || {};
    console.log(personInfoObj);

    const { register, control, handleSubmit, errors } = useForm();
    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';

    const empresaEncrypted = sessionStorage.getItem('sessionCompany');
    const empresaDecrypted = empresaEncrypted ? JSON.parse(CryptoJS.AES.decrypt(empresaEncrypted, secretKey).toString(CryptoJS.enc.Utf8)) : null;
    const empresa = empresaDecrypted;
    const regionType = empresa.region_type_delivery;
    const bairros_entrega = empresa.bairros_entrega;
    //console.log("STRINGY BAIRROS ENTREGA:", JSON.stringify(bairros_entrega) )
    //console.log("empresa:",empresa);

    //const stateFromItemModal = location.state;
    //var fromItemAddModal = false;
    //if(stateFromItemModal !== null){
    //    fromItemAddModal = location.state.fromItemAddModal;
    //}

    const navigate = useNavigate();
    const handleBack = () => {
        //navigate('/'+nomeEmpresaForUrl+'/'+idEmpresa+'/finalizar-pedido', {state: {personInfoObj}})
        // Navega para a página de finalizar pedido, com ou sem o external_id
        navigate(
            externalId
                ? `/${nomeEmpresaForUrl}/${idEmpresa}/finalizar-pedido?external_id=${externalId}`
                : `/${nomeEmpresaForUrl}/${idEmpresa}/finalizar-pedido`,
            { state: { personInfoObj } }
        );
    }

    const [itensCarrinho, setItensCarrinho] = useState([]);
    const [carrinho, setCarrinho] = useState([]);
    const [showConfirmInfo, setShowConfirmInfo] = useState(false);
    const [infoPersonalData, setInfoPersonalData] = useState(false);
    const [whatsappData, setWhatsappData] = useState("");
    const [namePersonData, setNamePersonData] = useState("");

    const [rua, setRua] = useState("");
    const [numero, setNumero] = useState("");
    const [bairro, setBairro] = useState("");
    const [bairroEditavel, setBairroEditavel] = useState(false);
    const [cidade, setCidade] = useState("");
    const [estado, setEstado] = useState("");
    const [cep, setCep] = useState("");
    const [complemento, setComplemento] = useState("");
    const [referencia, setReferencia] = useState("");
    const [latitudeToSave, setLatitudeToSave] = useState("");
    const [longitudeToSave, setLongitudeToSave] = useState("");
    const [addressComplete, setAddressComplete] = useState(false);


    //const [estado, setEstado] = useState("");
    // Initialize the `options` state variable
    const [options, setOptions] = useState([
        { value: "", label: "Ex: Avenida Exemplo, Setor Marista" }
    ]);
    const [address, setAddress] = useState("")
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [searchBox, setSearchBox] = useState(null);

    /*const reverseGeocode = ({ latitude: lat, longitude: lng }) => {
        const url = `${geocodeJson}?key=${REACT_APP_GOOGLE_API_KEY}&latlng=${lat},${lng}&region=BR`;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                const results = data.results;
                let cepFound = false;
                let ruaFound = false;
                console.log("RESULTS:",results)
                for (const result of results) {
                    const addressComponents = result.address_components;

                    // Resetando estados para evitar informações desatualizadas
                    setRua('');
                    setNumero('');
                    setBairro('');
                    setCidade('');
                    setEstado('');
                    setCep('');
                    console.log("addressComponents>", addressComponents)
                    console.log("LATITUDE E LONGITUDE:",lat,",",lng);
                    addressComponents.forEach(component => {
                        const { long_name, short_name, types } = component;
                        //console.log("component>",component)
                        if (types.includes("street_number") && !cepFound && !ruaFound) {
                            setNumero(long_name);
                        }
                        if (types.includes("route") && !cepFound && !ruaFound) {
                            setRua(long_name);
                            ruaFound = true; // Marca que uma Rua foi encontrada
                        }
                        if ((types.includes("sublocality") || types.includes("neighborhood")) && !cepFound && ruaFound) {
                            setBairro(long_name);
                        }
                        if (types.includes("administrative_area_level_1") && !cepFound && ruaFound) {
                            setEstado(short_name);
                        }
                        if ((types.includes("administrative_area_level_2") || types.includes("locality")) && !cepFound && ruaFound) {
                            setCidade(long_name);
                        }
                        if (types.includes("postal_code") && !cepFound && ruaFound) {
                            setCep(long_name);
                            cepFound = true; // Marca que um CEP foi encontrado
                        }
                    });

                    // Se um CEP foi encontrado, não precisa buscar nos próximos resultados
                    if (cepFound && ruaFound) {
                        console.log("Detalhes do endereço definidos.");
                        setAddressComplete(true); // Assumindo que esta função atualiza o estado para indicar que o endereço está completo
                        break; // Sai do loop uma vez que um CEP foi encontrado e as informações foram definidas
                    }
                }

                if (!cepFound && !ruaFound) {
                    console.log('Nenhum CEP ou Rua encontrado para as coordenadas fornecidas.');
                    toast('Nenhum CEP ou Rua encontrado para as coordenadas fornecidas.', { autoClose: 5000, type: 'warning' });
                    // Aqui, você pode implementar qualquer lógica para quando um CEP não é encontrado
                }
            })
            .catch(error => {
                console.error('Erro ao realizar geocodificação reversa:', error);
                toast('Erro ao realizar geocodificação reversa', { autoClose: 5000, type: 'error' });
            });
    }*/
    const reverseGeocode = ({ latitude: lat, longitude: lng }) => {
        console.log(lat, lng);
        setLatitudeToSave(lat);
        setLongitudeToSave(lng);
        const url = `${geocodeJson}?key=${REACT_APP_GOOGLE_API_KEY}&latlng=${lat},${lng}&region=BR`;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                const results = data.results;

                // Variáveis temporárias para armazenar valores
                //let numeroTemp = '';
                let ruaTemp = '';
                let bairroTemp = '';
                let cidadeTemp = '';
                let estadoTemp = '';
                let cepTemp = '';
                let cepFound = false;
                let ruaFound = false;
                let bairroFound = false;

                console.log("RESULTS:", results);

                for (const result of results) {
                    const addressComponents = result.address_components;

                    console.log("addressComponents>", addressComponents);
                    console.log("LATITUDE E LONGITUDE:", lat, ",", lng);

                    // Coleta dados de addressComponents sem modificar o estado diretamente
                    addressComponents.forEach(component => {
                        const { long_name, short_name, types } = component;

                        /*if (types.includes("street_number") && !cepFound && !ruaFound && !numeroTemp) {
                            numeroTemp = long_name;
                        }*/
                        if (types.includes("route") && !cepFound && !ruaFound && !ruaTemp) {
                            ruaTemp = long_name;
                            ruaFound = true; // Marca que uma Rua foi encontrada
                        }
                        if ((types.includes("sublocality") || types.includes("neighborhood")) && !bairroFound && !bairroTemp) {
                            bairroTemp = long_name;
                            bairroFound = true;
                            console.log("PASSOU AQUI? long_name:", long_name)
                        }
                        if (types.includes("administrative_area_level_1") && !cepFound && ruaFound && !estadoTemp) {
                            estadoTemp = short_name;
                        }
                        if ((types.includes("administrative_area_level_2") || types.includes("locality")) && !cepFound && ruaFound && !cidadeTemp) {
                            cidadeTemp = long_name;
                        }
                        if (types.includes("postal_code") && !cepFound && ruaFound && !cepTemp) {
                            cepTemp = long_name;
                            cepFound = true; // Marca que um CEP foi encontrado
                        }
                    });

                    // Se um CEP foi encontrado, não precisa buscar nos próximos resultados
                    if (cepFound && ruaFound && bairroFound) {
                        console.log("Detalhes do endereço definidos.");
                        setAddressComplete(true); // Atualiza o estado para indicar que o endereço está completo
                        break; // Sai do loop uma vez que um CEP foi encontrado e as informações foram definidas
                    }
                }

                // Agora atualiza os estados, mas só para os campos que estão vazios
                setRua((prevRua) => prevRua || ruaTemp);
                //setNumero((prevNumero) => prevNumero || numeroTemp);
                setBairro((prevBairro) => prevBairro || bairroTemp);
                setCidade((prevCidade) => prevCidade || cidadeTemp);
                setEstado((prevEstado) => prevEstado || estadoTemp);
                setCep((prevCep) => prevCep || cepTemp);

                if (!cepFound && !ruaFound) {
                    console.log('Nenhum CEP ou Rua encontrado para as coordenadas fornecidas.');
                    toast('Nenhum CEP ou Rua encontrado para as coordenadas fornecidas.', { autoClose: 5000, type: 'warning' });
                }
            })
            .catch(error => {
                console.error('Erro ao realizar geocodificação reversa:', error);
                toast('Erro ao realizar geocodificação reversa', { autoClose: 5000, type: 'error' });
            });
    };


    const findMyLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    console.log("position.coords:", position.coords);
                    reverseGeocode(position.coords);
                },
                error => {
                    console.error("Error Code = " + error.code + " - " + error.message);
                    toast("Erro ao obter sua localização: " + error.message, { autoClose: 5000, type: 'error' });
                },
                {
                    enableHighAccuracy: true,  // Alta precisão, pode consumir mais bateria
                    timeout: 10000,            // Aumenta o tempo máximo para obter a localização
                    maximumAge: 0              // Não usa posições em cache, força a localização atual
                }
            );
        } else {
            toast("O serviço de Geolocalização não está habilitado em seu navegador.", { autoClose: 5000, type: 'warning' });
        }
    };

    /*const onLoad = (ref) => {
        setSearchBox(ref);
    }

    const onPlacesChanged = () => {
        //const searchBox = searchBox;
        if (searchBox) {
          const places = searchBox.getPlaces();
          if (places.length > 0) {
            const place = places[0];
            const location = {
              lat: place.geometry?.location.lat() || 0,
              lng: place.geometry?.location.lng() || 0,
            };
            reverseGeocode({ latitude: location.lat, longitude: location.lng})
          }
        }
    }*/
    const [autocomplete, setAutocomplete] = useState(null);
    //const [address, setAddress] = useState('');

    const libraries = ["places"];

    const onLoad = (autocompleteInstance) => {
        setAutocomplete(autocompleteInstance);
    };

    /*const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            const location = {
                lat: place.geometry?.location.lat() || 0,
                lng: place.geometry?.location.lng() || 0,
            };
            reverseGeocode({ latitude: location.lat, longitude: location.lng });
            setAddress(place.formatted_address);
            //console.log('location:', location);
        } else {
            //console.log('Autocomplete is not loaded yet!');
        }
    };*/
    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();

            // Pega a localização (lat, lng)
            const location = {
                lat: place.geometry?.location.lat() || 0,
                lng: place.geometry?.location.lng() || 0,
            };

            // Extrai os componentes do endereço diretamente do place
            const addressComponents = place.address_components;

            // Resetando estados para evitar informações desatualizadas
            setRua("");
            setNumero("");
            setBairro("");
            setCidade("");
            setEstado("");
            setCep("");

            // Itera sobre os componentes de endereço do autocomplete
            addressComponents.forEach(component => {
                const { long_name, short_name, types } = component;

                if (types.includes("street_number")) {
                    setNumero(long_name);
                }
                if (types.includes("route")) {
                    setRua(long_name);
                }
                if (types.includes("sublocality") || types.includes("neighborhood")) {
                    setBairro(long_name);
                }
                if (types.includes("administrative_area_level_1")) {
                    setEstado(short_name);
                }
                if (types.includes("administrative_area_level_2") || types.includes("locality")) {
                    setCidade(long_name);
                }
                if (types.includes("postal_code")) {
                    setCep(long_name);
                }
            });

            console.log("console>", place.formatted_address);

            // Chama a geocodificação reversa apenas se precisar de algum dado adicional com base na lat/lng
            reverseGeocode({ latitude: location.lat, longitude: location.lng });
        } else {
            console.log('Autocomplete is not loaded yet!');
        }
    };

    const handleClearAddress = () => {
        setRua("");
        setNumero("");
        setBairro("");
        setCidade("");
        setEstado("");
        setCep("");
    }

    const handleSaveAddress = () => {

        if (rua !== "" && numero !== "" && bairro !== "" && cidade !== "" && estado !== "") {
            const addressObj = {
                rua: rua,
                numero: numero,
                bairro: bairro,
                cidade: cidade,
                estado: estado,
                cep: cep,
                complemento: complemento,
                referencia: referencia,
                latitude: latitudeToSave,
                longitude: longitudeToSave
            }

            updateClienteAddress(idEmpresa, personInfoObj.telefone, addressObj, enderecoToEdit).then((response) => {
                //console.log(response)
                if (response.status === 200) {
                    toast('Endereço salvo com sucesso!', { autoClose: 5000, type: 'success' });
                    ////console.log(response.data.response.endereco);
                    console.log(response)
                    personInfoObj.endereco = response.data.response.endereco;
                    //console.log(personInfoObj);
                    navigate(
                        externalId
                            ? `/${nomeEmpresaForUrl}/${idEmpresa}/finalizar-pedido?external_id=${externalId}`
                            : `/${nomeEmpresaForUrl}/${idEmpresa}/finalizar-pedido`,
                        { state: { personInfoObj } }
                    );
                } else {
                    toast('Erro ao salvar endereço!', { autoClose: 5000, type: 'error' });
                }
            });
        } else {
            toast('Por favor, preencha todos os campos obrigatórios', { autoClose: 5000, type: 'warning' });
        }
    }

    /*
    useEffect(() => {
        if(addressComplete){
            if(rua===""){toast('Digite o nome da rua!', {autoClose:5000, type: 'warning'})}
            if(bairro===""){toast("Digite o nome do bairro!", {autoClose:5000, type: 'warning'})}
            if(cidade===""){toast("Digite o nome da cidade!", {autoClose:5000, type: 'warning'})}
        }
        setAddressComplete(false);
    }, [addressComplete]);*/

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" });
        if (regionType === "bairro") {
            toast('Caso não encontre seu bairro na lista é porque infelizmente não atendemos em sua região', { autoClose: 5000, type: 'warning', position: "bottom-center" });
        }
    }, []);

    return (

        <div className="flex flex-col max-w-sm mx-auto bg-white rounded-lg shadow-md overflow-hidden md:max-w-2xl">

            <div className="carrinhoHeader">
                <div className="backButtonCarrinho" onClick={() => handleBack()}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={28}
                        height={28}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="1.8"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-chevron-left"
                    >
                        <polyline points="15 18 9 12 15 6" />
                    </svg>
                </div>
                <span>Novo Endereço</span>
            </div>

            <div className="carrinhoContainer">



                <div className="divAddEnderecoPadding">
                    <span style={{ fontSize: 20, fontWeight: '700' }}>Digite seu endereço completo com a rua, número e bairro</span>

                    {
                        regionType === "bairro" ?
                            <div className="divEnderecoCompleto">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <label style={{ fontSize: 14, fontWeight: '600' }}>Bairro*</label>
                                        <select
                                            value={bairro}
                                            onChange={(e) => setBairro(e.target.value)}
                                            className="addressField field-input pa-3 whatsappNumberFinalizarPedidoInput"
                                        >
                                            <option value="">Selecione o Bairro</option>
                                            {bairros_entrega.map((bairro, index) => (
                                                <option key={bairro._id} value={bairro.bairro_entrega}>
                                                    {bairro.bairro_entrega}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <label style={{ fontSize: 14, fontWeight: '600' }}>Rua*</label>
                                        <input value={rua} onChange={(e) => setRua(e.target.value)} className="addressField field-input pa-3 whatsappNumberFinalizarPedidoInput"
                                            placeholder="Ex.: Av. T-63" />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '28%' }}>
                                        <label style={{ fontSize: 14, fontWeight: '600' }}>Estado*</label>
                                        <input value={estado} onChange={(e) => setEstado(e.target.value)} className="addressField field-input pa-3 whatsappNumberFinalizarPedidoInput"
                                            placeholder="Ex: SP" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '68%' }}>
                                        <label style={{ fontSize: 14, fontWeight: '600' }}>Cidade*</label>
                                        <input value={cidade} onChange={(e) => setCidade(e.target.value)} className="addressField field-input pa-3 whatsappNumberFinalizarPedidoInput"
                                            placeholder="Ex.: São Paulo" />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '28%' }}>
                                        <label style={{ fontSize: 14, fontWeight: '600' }}>Numero*</label>
                                        <input value={numero} onChange={(e) => setNumero(e.target.value)} className="addressField field-input pa-3 whatsappNumberFinalizarPedidoInput"
                                            placeholder="Ex: 123" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '68%' }}>
                                        <label style={{ fontSize: 14, fontWeight: '600' }}>Complemento</label>
                                        <input value={complemento} onChange={(e) => setComplemento(e.target.value)} className="addressField field-input pa-3 whatsappNumberFinalizarPedidoInput"
                                            placeholder="Apto 101, Casa 02..." />
                                    </div>
                                </div>
                                <div style={{ paddingTop: '10px' }}>
                                    <label style={{ fontSize: 14, fontWeight: '600' }}>Ponto de Referência</label>
                                    <input value={referencia} onChange={(e) => setReferencia(e.target.value)} className="addressField field-input w-100 pa-3 whatsappNumberFinalizarPedidoInput"
                                        placeholder="Ao lado do supermercado exemplo..." />
                                </div>
                            </div>
                            :
                            rua !== "" || bairro !== "" || cidade !== "" || estado !== "" ?

                                <div className="divEnderecoCompleto">
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 10, alignItems: 'center', paddingBottom: 12, paddingTop: 12 }}>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                                            <GrLocation style={{ fontSize: 22, color: 'rgb(180,180,180)' }} />
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <span style={{ fontSize: 16, fontWeight: '600', maxWidth: '320px' }}>{rua}</span>
                                                <span style={{ fontSize: 15, color: 'rgb(180,180,180)', maxWidth: '320px' }}>{!bairro ? "" : `${bairro}, `}{!cidade ? "" : `${cidade} - `}{!estado ? "" : estado}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <RiCloseCircleLine onClick={() => handleClearAddress()} style={{ fontSize: 22, color: 'orangered', marginRight: '10px' }} />
                                        </div>
                                    </div>
                                    {bairro === "" || bairroEditavel ? (
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                                <label style={{ fontSize: 14, fontWeight: '600' }}>Bairro*</label>
                                                <input
                                                    value={bairro}
                                                    onChange={(e) => setBairro(e.target.value)}
                                                    className="addressField field-input pa-3 whatsappNumberFinalizarPedidoInput"
                                                    placeholder="Ex: Jardins..."
                                                    onFocus={() => setBairroEditavel(true)} // Manter o input visível enquanto o usuário digita
                                                    onBlur={() => bairro !== "" && setBairroEditavel(false)} // Ocultar o input se o bairro for preenchido
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '28%' }}>
                                            <label style={{ fontSize: 14, fontWeight: '600' }}>Numero*</label>
                                            <input value={numero} onChange={(e) => setNumero(e.target.value)} className="addressField field-input pa-3 whatsappNumberFinalizarPedidoInput"
                                                placeholder="Ex: 123" />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '68%' }}>
                                            <label style={{ fontSize: 14, fontWeight: '600' }}>Complemento</label>
                                            <input value={complemento} onChange={(e) => setComplemento(e.target.value)} className="addressField field-input pa-3 whatsappNumberFinalizarPedidoInput"
                                                placeholder="Apto 101, Casa 02..." />
                                        </div>
                                    </div>
                                    <div style={{ paddingTop: '10px' }}>
                                        <label style={{ fontSize: 14, fontWeight: '600' }}>Ponto de Referência</label>
                                        <input value={referencia} onChange={(e) => setReferencia(e.target.value)} className="addressField field-input w-100 pa-3 whatsappNumberFinalizarPedidoInput"
                                            placeholder="Ao lado do supermercado exemplo..." />
                                    </div>
                                </div>
                                :
                                <>
                                    <label style={{ fontWeight: '500', fontSize: 14 }}>Insira seu endereço</label>
                                    <div className="map">
                                        <LoadScript googleMapsApiKey={REACT_APP_GOOGLE_API_KEY} libraries={libraries}>
                                            <Autocomplete
                                                onLoad={onLoad}
                                                onPlaceChanged={onPlaceChanged}
                                                options={{ componentRestrictions: { country: "BR" } }}
                                            >
                                                <input
                                                    className="addressField field-input w-100 pa-3 whatsappNumberFinalizarPedidoInput"
                                                    placeholder="Digite o endereço"
                                                />
                                            </Autocomplete>
                                        </LoadScript>
                                    </div>

                                    <div onClick={findMyLocation} className="divBtnUseLocation">
                                        <FaLocationCrosshairs />
                                        <span>Usar minha localização</span>
                                    </div>
                                </>
                    }
                </div>

            </div>

            <div className="avancarDetalhe">
                <button onClick={() => handleSaveAddress()} className="btnAvancarDetalhe" style={{ justifyContent: 'center' }}>
                    <p style={{ marginBottom: "0px" }}>Salvar</p>
                </button>
            </div>


        </div>

    );
}

export default AddEndereco;