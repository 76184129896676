import React, { useState, useRef, useEffect, useContext } from "react";
import './style.css'
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import CryptoJS from 'crypto-js';
import { AuthContext } from "../../contexts/auth";
import { SidebarContext } from "../../AppRoutes";
import { getEmpresas, getEmpresa, deleteEmpresa, getCategorias, updateCategoriasOrder, deleteCategoria } from "../../services/api";

import PermissionGate from "../../services/PermissionGate";

import { useNavigate } from "react-router-dom";
import LeftMenu from "../../components/LeftMenu";
import ConfirmDialog from "../../components/ConfirmDialog";

import { toast } from "react-toastify";
import Loading from "react-loading";

import * as FiIcons from 'react-icons/fi'
import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'

import ModalEditCategoria from "../../components/ModalEditCategoria";

import { Draggable, Droppable, DragDropContext } from '@hello-pangea/dnd';

const Teste = styled.div`
    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;as
    z-Index: 9;

    @media (max-width: 880px) {
        margin-left: 0;
    }
`;


const ListCategoria = ( {list = []}) =>{
    //const { user } = useContext(AuthContext);
    const ref = useRef();
    //TESTES
    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const userEncrypted = localStorage.getItem('user')
    const user = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
    const userParse = JSON.parse(user)
    const userID = userParse._id;
    const empresa = localStorage.getItem('empresa')
    const empresaParse = JSON.parse(empresa)
    const empresaObjId = empresaParse._id;
    const idEmpresa = empresaParse.id_empresa
    const vinculo_empresa = empresaParse.cnpj

    const navigate = useNavigate();

    const [categorias, setCategorias] = useState([]);
    const [filter, setFilter] = useState('');
    const [result, setResult] = useState(false);

    const [order, setOrder] = useState(1)
    const [columnOrder, setColumnOrder] = useState("")

    const { sidebar, setSidebar } = useContext(SidebarContext);;
    
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);

    const [showEditCategoria, setEditCategoria] = useState(true);

    useEffect(() => {
        (async () => {
            const response = await getCategorias(userID, idEmpresa, vinculo_empresa, empresaObjId)//getEmpresas(user._id);
            //setEmpresas(response.data.empresa);
            setCategorias(response.data.categorias);
            setLoading(false);
            setRefresh(false);    

        })();
    },[refresh]);
    

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [shouldDelete, setShouldDelete] = useState(false);
    const [idToDelete, setIdToDelete] = useState('');
    const handleDelete = (idRecebido) => {
        // Abre Componente de Confirmação do delete
        setConfirmOpen(true)

        setIdToDelete(idRecebido)
        if(idRecebido){
            console.log("ID RECEBIDO:",idRecebido)
            setShouldDelete(true);
        }
    }
    const deleteReg = async () => {
        console.log("O ID CLICADO FOI :::",idToDelete);
        console.log("ID do Usuário que irá efetuar a exclusão", userID);

        if(shouldDelete){
            deleteCategoria( userID, idToDelete).then( cast => {
                console.log("RESULT DELETE:",cast);
                if(cast.status===201){
                    toast(cast.data.msg,{ autoClose: 5000 , type: "success"});
                    setRefresh(true);
                }else{
                    toast(cast.data.msg,{ autoClose: 5000 , type: "error"});
                }
            }).catch( err => {
                console.log("ERROR:",err);
                toast(err.response.data.msg,{ autoClose: 5000 , type: "error"});
            });
        }

    }

    const [_idCategoriaToEdit, setIdCategoriaToEdit] = useState("")
    const [id_categoria, setIdCategoria] = useState("")
    const [_titleToEdit, setTitleToEdit] = useState("")
    const [_disponibilidadeToEdit, setDisponibilidadeToEdit] = useState("")
    const [_diaHorarioDispToEdit, setDiaHorarioDispToEdit] = useState([])
    const handleEdit = async (idToEdit, id_categoria, titleToEdit, disponibilidade, dia_horario_disponibilidade) => {
        setIdCategoriaToEdit(idToEdit);
        setIdCategoria(id_categoria);
        setTitleToEdit(titleToEdit);
        setDisponibilidadeToEdit(disponibilidade);
        //setDiaHorarioDispToEdit(dia_horario_disponibilidade);

        const novoArray = dia_horario_disponibilidade.map(obj => {
            const {dia, startTime, endTime} = obj;
            const novoObj = {
                dias: new Set([dia]),
                startTime,
                endTime
            };
            return novoObj;
        });

        const teste = novoArray.reduce((acc, curr) => {
            const prevItem = acc[acc.length - 1];
            if (
            prevItem &&
            prevItem.startTime === curr.startTime &&
            prevItem.endTime === curr.endTime
            ) {
            prevItem.dias = new Set([...prevItem.dias, ...curr.dias]);
            } else {
            acc.push(curr);
            }
            return acc;
        }, []);

        //console.log("teste",teste)
        //setDiaHorarioDispToEdit(teste);
        if (teste.length === 0) {
            setDiaHorarioDispToEdit([
              {
                dias: new Set([""]),
                startTime: "00:00",
                endTime: "23:59",
              }
            ]);
        } else {
            setDiaHorarioDispToEdit(teste);
        }

        setEditCategoria(!showEditCategoria)
    }

    const handleRefresh = () => {
        setLoading(true);
        setRefresh(true);
    }

    const handleOrder = fieldName => {
        setOrder(-order)
        setColumnOrder(fieldName)
        console.log("order:",order);
        console.log("fieldName",fieldName)
    }

    /*var arrayCategorias = [];
    arrayCategorias = categorias.sort( (a, b) => {
        return a[columnOrder] < b[columnOrder] ? -order : order;
    })*/

    var arrayCategorias = categorias.sort((a, b) => {
        return a.order - b.order;
    });
    //var arrayCategorias = categorias;
    
    if (filter){
        const exp = eval(`/${filter.replace(/[^\d\w]+/,'.*')}/i`)
        list = categorias.filter( item => exp.test( item.title ) )
    }

    const handleFilter = e => {
        setFilter(e.target.value);
        setResult(!result);
    }

    const handleCadastro = () => {
        navigate("/cadastro-categoria");
    }


    const [draggedItem, setDraggedItem] = useState(null);
    const handleDragStart = (draggableId) => {
      setDraggedItem(draggableId);
    };
    const handleDragEnd = () => {
      setDraggedItem(null);
    };

    const moveRow = async (dragIndex, hoverIndex) => {
        
        const dragItem = categorias[dragIndex];
        const hoverItem = categorias[hoverIndex];
      
        // Mover o item do dragIndex para o hoverIndex
        const newCategorias = [...categorias];        
        newCategorias.splice(dragIndex, 1);
        newCategorias.splice(hoverIndex, 0, dragItem);
        // Atualizar o estado com o novo array de categorias
        
        const categoriasAtualizadas = newCategorias.map((categoria, index) => {
            return { ...categoria, order: index+1 };
        });

        const promises = categoriasAtualizadas.map( (categoriasToApi) => {
            return updateCategoriasOrder(userID, categoriasToApi._id, categoriasToApi.id_categoria, categoriasToApi.id_empresa, categoriasToApi.order).then(response => {
                return response;
            }).catch(err => {
                return err;
            })
        });
        Promise.all(promises).then(results => {
            toast("Ordem atualizada com sucesso!",{ autoClose: 3000 , type: "success"});
        }).catch(err => {
            toast("Ocorreu um erro ao tentar atualizar a ordem!",{ autoClose: 3000 , type: "error"});
        });
        setCategorias(categoriasAtualizadas);
    };

    return(

        <>
        <PermissionGate permissions={['default']}>

            <LeftMenu setSidebar={setSidebar} sidebar={sidebar}></LeftMenu>

            <ConfirmDialog
                title="Deletar Categoria?"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={deleteReg}
            >
                <div>
                    Tem certeza que deseja deletar a categoria?
                    <br />
                    <span style={{fontSize:12}}>(Os Itens e adicionais da categoria também serão exlcuídos)</span>
                </div>
            </ConfirmDialog>

            <ModalEditCategoria showEditCategoria={showEditCategoria}
            setEditCategoria={setEditCategoria}
            setRefresh={setRefresh}
            _idCategoriaToEdit={_idCategoriaToEdit}
            id_categoria={id_categoria}
            _titleToEdit={_titleToEdit}
            _disponibilidadeToEdit={_disponibilidadeToEdit}
            setDisponibilidadeToEdit={setDisponibilidadeToEdit}
            _diaHorarioDispToEdit={_diaHorarioDispToEdit}
            setDiaHorarioDispToEdit={setDiaHorarioDispToEdit}
            />

            <Teste sidebar={sidebar}>
            
                {loading && <Loading type={"spinningBubbles"} style={{marginLeft:'14%'}} className="calculandoDistanciaModalEntrega"/>}
                <div className="w-100 p-4">
                    <div className="list-header-categories">
                        <div className="title">
                            <h1>Categorias</h1>
                        </div>
                    </div>


                    <div className="flex-column flex-md-row" style={{display:"flex", justifyContent:"space-between"/*, height:"80px"*/}}>
                        <div className="input-box-list" style={{ maxWidth: "300px" }}>                
                            <input className="input-field" placeholder="Pesquisar" onChange={handleFilter}/>
                            <i className="icon"><FiIcons.FiSearch/></i>
                        </div>

                        <div className="div-buttons flex-column flex-md-row">
                            <div className="continue-button">                           
                                <button onClick={handleCadastro}>
                                    <AiIcons.AiOutlinePlusCircle style={{marginRight: "5px",fontSize: "22px",marginBottom: "2px"}}/><a>Adicionar</a> 
                                </button>                            
                            </div>

                            <div class="refresh-button align-mobile">                                                       
                                <button onClick={handleRefresh}>
                                    <SlIcons.SlRefresh style={{color:"#4281FF",marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/><a >Atualizar</a> 
                                </button>
                            </div>
                        </div>

                    </div>

                    
                    <div className="divPaiCategorias">
                        {filter ? 
                        list.map((categoria, i) => (
                            <div key={categoria._id} index={i}>                                           
                                <div
                                style={{                                
                                    backgroundColor: i % 2 === 0 ? 'white' : 'white',
                                    boxShadow: 'none',
                                }}
                                className={`${'resultCategoria'} ${i % 2 === 0 ? 'even' : 'odd'}`}
                                >
                                    <div style={{display:'flex', flexDirection:'row', gap:80, alignItems:'center'}}>
                                        <div style={{display:'flex', flexDirection:'row', gap:10}}>                                           
                                            <span>Ordem de Exibição: {categoria.order}º</span>
                                        </div>
                                        <div className="nomeCategoria">{categoria.title}</div>
                                    </div>
                                    <div>
                                        <a
                                        className="btn btn-sm btn-primary editaCategoria"
                                        onClick={(e) => handleEdit(categoria._id, categoria.id_categoria,categoria.title, categoria.disponibilidade, categoria.dia_horario_disponibilidade)}
                                        >
                                        <FiIcons.FiEdit2 style={{ color: 'white' }} />
                                        </a>
                                        <a
                                        className="btn btn-sm btn-danger deletaCategoria"
                                        style={{ marginLeft: '5px' }}
                                        onClick={(e) => handleDelete(categoria._id)}
                                        >
                                        <FiIcons.FiTrash2 style={{ color: 'white' }} />
                                        </a>
                                    </div>
                                </div>
                        
                            </div>
                        ))
                        : 
                        <>
                            <span>Para alterar a ordem de exibição no cardápio basta clicar e arrastar a categoria para a posição desejada!</span>
                            <DragDropContext onDragStart={(event) => handleDragStart(event.draggableId)} onDragEnd={({ source, destination }) => {
                            handleDragEnd();
                            if (destination) {
                                moveRow(source.index, destination.index);
                            }
                            }}>

                                <Droppable droppableId="categorias"
                                >
                                    {(provided) => (
                                        <div className="divCategorias" ref={provided.innerRef} {...provided.droppableProps}>
                                        {arrayCategorias.map((categoria, i) => (
                                            <Draggable key={categoria._id} draggableId={categoria._id} index={i}>
                                            {(provided) => (
                                                <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                //className="categoria-individual"
                                                style={{
                                                    ...provided.draggableProps.style,
                                                    backgroundColor: i % 2 === 0 ? 'white' : 'white',
                                                    boxShadow: draggedItem === categoria._id ? '1px 1px 6px #a4a4a4' : 'none',
                                                }}
                                                className={`${'resultCategoria'} ${i % 2 === 0 ? 'even' : 'odd'}`}
                                                >
                                                    <div style={{display:'flex', flexDirection:'row', gap:80, alignItems:'center' }}>
                                                        <div style={{display:'flex', flexDirection:'row', gap:10}}>
                                                            <div>
                                                                <svg
                                                                width={24}
                                                                height={24}
                                                                viewBox="0 0 16 20"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                <path
                                                                    d="M3 11C3.55228 11 4 10.5523 4 10C4 9.44772 3.55228 9 3 9C2.44772 9 2 9.44772 2 10C2 10.5523 2.44772 11 3 11Z"
                                                                    stroke="#D7D8DD"
                                                                    strokeWidth={2}
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M3 4C3.55228 4 4 3.55228 4 3C4 2.44772 3.55228 2 3 2C2.44772 2 2 2.44772 2 3C2 3.55228 2.44772 4 3 4Z"
                                                                    stroke="#D7D8DD"
                                                                    strokeWidth={2}
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M3 18C3.55228 18 4 17.5523 4 17C4 16.4477 3.55228 16 3 16C2.44772 16 2 16.4477 2 17C2 17.5523 2.44772 18 3 18Z"
                                                                    stroke="#D7D8DD"
                                                                    strokeWidth={2}
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M13 11C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9C12.4477 9 12 9.44772 12 10C12 10.5523 12.4477 11 13 11Z"
                                                                    stroke="#D7D8DD"
                                                                    strokeWidth={2}
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M13 4C13.5523 4 14 3.55228 14 3C14 2.44772 13.5523 2 13 2C12.4477 2 12 2.44772 12 3C12 3.55228 12.4477 4 13 4Z"
                                                                    stroke="#D7D8DD"
                                                                    strokeWidth={2}
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M13 18C13.5523 18 14 17.5523 14 17C14 16.4477 13.5523 16 13 16C12.4477 16 12 16.4477 12 17C12 17.5523 12.4477 18 13 18Z"
                                                                    stroke="#D7D8DD"
                                                                    strokeWidth={2}
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                </svg>
                                                            </div>
                                                            <span>Ordem de Exibição: {Number(i+1)}º</span>
                                                        </div>
                                                        <div className="nomeCategoria">{categoria.title}</div>
                                                    </div>
                                                    <div>
                                                        <a
                                                        className="btn btn-sm btn-primary editaCategoria"
                                                        onClick={(e) => handleEdit(categoria._id, categoria.id_categoria, categoria.title, categoria.disponibilidade, categoria.dia_horario_disponibilidade)}
                                                        >
                                                        <FiIcons.FiEdit2 style={{ color: 'white' }} />
                                                        </a>
                                                        <a
                                                        className="btn btn-sm btn-danger deletaCategoria"
                                                        style={{ marginLeft: '5px' }}
                                                        onClick={(e) => handleDelete(categoria._id)}
                                                        >
                                                        <FiIcons.FiTrash2 style={{ color: 'white' }} />
                                                        </a>
                                                    </div>
                                                </div>
                                            )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>  
                        </>
                        }                   
                    </div>
                            
                </div>            
            </Teste>

        </PermissionGate>       
        </>
        

    );
};


export default ListCategoria;