import styled from "styled-components";
import './style.css'
import { ImCheckboxChecked } from "react-icons/im";
import { FaMinus } from "react-icons/fa";
import { useParams, useNavigate, Link } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import { useState } from "react";
import InputMoney from "../../pages/ListItem/InputMoney";
import { toast } from "react-toastify";


const DivBGtransparent = styled.div`
  z-index:15;
  position: fixed;
  top: 0;
  left: 0;
  display: ${({ showModalPaymentCash }) => (showModalPaymentCash ? 'flex' : 'none' )};
  float: left;
  min-width: 100%;
  min-height: 100%;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 5px 0;
  list-style: none;
  background-color: rgba(0,0,0,0.5);
  background-clip: padding-box;
  align-items:center;
  justify-content:center;
  opacity: ${({ showModalPaymentCash }) => (showModalPaymentCash ? '1':'0' )};
  transition: opacity .3s ease-in-out;

  // Add keyframes for the fade-in effect
  animation: fadeIn .3s ease-in-out;
  animation-fill-mode: forwards;

  // Define the keyframes for the animation
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const DivContainer = styled.div`
    z-index:20;
    position: absolute;
    top: 20%;
    left: 8%;
    float: left;
    min-width: 80%;
    min-height: 41%;
    --max-width: 50%;
    padding: 5px 0;
    list-style: none;
    --background-color: white;
    background-color:rgb(247,247,247)!important;
    background-clip: padding-box;
    border-radius: 15px;
    padding:10px;

`;

const ModalPaymentCash = ({
    setItensCarrinho,
    itensCarrinho,
    selectedItemId,
    showModalPaymentCash,
    setShowModalPaymentCash,
    valorTroco,
    setValorTroco,
    setPaymentType,
    valorTotalPedido,
}) => {
    const navigate = useNavigate();
    const { nomeEmpresaForUrl, idEmpresa } = useParams();
    //console.log(nomeEmpresaForUrl, idEmpresa);
    const valorTotalPedidoFloat = parseFloat(valorTotalPedido.replace(',', '.'));

    const closeModalWithChange = () => {
        if (valorTroco <= valorTotalPedidoFloat && valorTroco > 0){
            toast("O valor do troco deve ser maior que o total do pedido", {autoClose:5000,type: "error"})
        }
        if(valorTroco===0){
            toast("Informe o valor do troco", {autoClose:5000,type: "error"})
        } 
        if(valorTroco > 0 && valorTroco > valorTotalPedidoFloat){
            setValorTroco(valorTroco)
            setShowModalPaymentCash(!showModalPaymentCash);
            setPaymentType("Dinheiro") 
        }
    }

    const closeModal = () => {        
        setValorTroco(0)
        setShowModalPaymentCash(!showModalPaymentCash);
        setPaymentType("Dinheiro")
    }

    const [isFocused, setIsFocused] = useState(false);
    const handleFocus = () => {
        setIsFocused(true);
    };
    
    const handleBlur = () => {
        setIsFocused(false);
    };

    const onChange = (event, nameObject, isNumber) => {
        setValorTroco(isNumber ? Number(event.target.value) : event.target.value);
        //formik.setFieldValue("preco", isNumber ? Number(event.target.value) : event.target.value );
    };

    return (
        <DivBGtransparent showModalPaymentCash={showModalPaymentCash}>
            <DivContainer>
               
                <div className="divTitleItemAdicionado">
                    <span className="divTitleItemAdicionadoSpan">Precisa de troco?</span> 
                </div>

                <div className="qtdBoxContainer">
                    <div>
                        <span>Seu pedido fechou em</span>
                        <span style={{fontWeight:600, marginLeft:5}}>R$ {valorTotalPedido}</span>   
                    </div>                 
                </div>

                <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                    <label style={{fontWeight:600}}>Troco para:</label>
                    <div style={{padding:"0px 20px 0px 20px"}}>
                    <InputMoney
                    onChange={(event) => onChange(event, 'price', true)}
                    value={valorTroco}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    //margin="0px 0px 16px 0px"                    
                    style={{
                    outline: isFocused ? '2px solid #4070f4' : 'none',
                    borderRadius:10,
                    margin: '0.6rem 0 0.2rem 0',
                    //padding:'0px 20px 0px 20px'
                    }}
                    className="inputPersonalizedTroco"
                    placeholder="Troco"
                    />  
                    </div>
                </div>

                <div className="divBtnContinueCart">
                    <button className="buttonContinue" onClick={()=> closeModal()}>Não preciso de troco</button>
                    <div style={{width:'100%'}}>
                        <button className="buttonCart" onClick={() => closeModalWithChange()}>Confirmar</button>
                    </div>
                </div>

            </DivContainer>
        </DivBGtransparent>
    );
}

export default ModalPaymentCash