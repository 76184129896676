import React, { useState, useContext, useEffect } from "react";
import './style.css'
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import { SidebarContext } from "../../AppRoutes";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import moment from 'moment'

import { AuthContext } from "../../contexts/auth";

import { getPlansIugu, deleteIuguPlan } from "../../services/api";

import PermissionGate from "../../services/PermissionGate";

import { useNavigate } from "react-router-dom";
import LeftMenu from "../../components/LeftMenu";
import ModalEditUser from "../../components/ModalEditUser";
import ConfirmDialog from "../../components/ConfirmDialog";

import { BarContext } from "../../components/LeftMenu";

import { useFormik } from "formik";
import * as Yup from "yup";

import * as FiIcons from 'react-icons/fi'
import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'
import * as FaIcons from 'react-icons/fa'
import { date } from "yup/lib/locale";

const Teste = styled.div`
    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;
    z-Index: 9;

    @media (max-width: 880px) {
        margin-left: 0;
    }
`;



const ListPlanosAdmin = ({list = []} ) =>{
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();

    const [planos, setPlanos] = useState([]);

    const { sidebar, setSidebar } = useContext(SidebarContext);;
    //console.log("LISTUSERS",sidebar);

    const [order, setOrder] = useState(1)
    const [columnOrder, setColumnOrder] = useState('title')

    const [users, setUsers] = useState([]);
    //const [usersNomeEmpresa, setUsersNomeEmpresa] = useState([]);
    const [filter, setFilter] = useState('');
    
    const [_idUserEdit, set_idUserEdit] = useState('');
    const [usernameEdit, setUsernameEdit] = useState('');
    const [emailEdit, setEmailEdit] = useState('');
    const [roleEdit, setRoleEdit] = useState('');

    const [result, setResult] = useState(false);

    const [showEditUser, setEditUser] = useState(true);
    
    const [loading, setLoading] = useState(true);

    const [refresh, setRefresh] = useState(false);

    const INITIAL_DATA = {
        value: "",
        label: 'Selecione uma empresa',
    };
            
    const [selectData, setselectData] = useState(INITIAL_DATA);

    useEffect(() => {
        (async () => {
       
            const response = await getPlansIugu(user._id);

            setPlanos(response.data.planos.planos);
            console.log(response.data)
            setLoading(false);
            setRefresh(false);
            
        })();
    },[refresh]);
  
    const handleEdit = async idToEdit => {
        
    }

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [shouldDelete, setShouldDelete] = useState(false);
    const [idToDelete, setIdToDelete] = useState('');
    const handleDelete = (idRecebido) => {
        // Abre Componente de Confirmação do delete
        setConfirmOpen(true)

        setIdToDelete(idRecebido)
        if(idRecebido){
            //console.log("ID RECEBIDO:",idRecebido)
            setShouldDelete(true);
        }
    }
  
    const deleteReg = async () => {

        if(shouldDelete){
            const userID = user._id;
            deleteIuguPlan( userID, idToDelete).then( cast => {
                console.log("RESULT DELETE:",cast);
                if(cast.status===200){
                    toast(cast.data.result.msg,{ autoClose: 5000 , type: "success"});
                    setRefresh(true);
                }else{
                    toast(cast.data.result.msg,{ autoClose: 5000 , type: "error"});
                }
            }).catch( err => {
                console.log("ERROR:",err);
                toast(err.response.data.msg,{ autoClose: 5000 , type: "error"});
            });
        }

    }


    const handleRefresh = () => {
        //window.location.reload(false);
        setLoading(true);
        setRefresh(true);
    }

    const handleCadastro = () => {
        //setSidebar(!sidebarTeste);
        navigate("/planos-admin");
        
    }

    const valorCentsConvertido = (value) => {
        const valorConvertido = value / 100;
        return valorConvertido.toFixed(2).toString().replace('.',',');
    }



    if(loading){
        return (
         
        <>
        <div className="loading"><AiIcons.AiOutlineLoading3Quarters style={{fontSize:"100px", color:"rgb(180,180,180)"}}/></div>
        <PermissionGate permissions={['AdminOnly']}>
      
            <LeftMenu setSidebar={setSidebar} sidebar={sidebar}></LeftMenu>

            <Teste sidebar={sidebar}>

                <div className="m-5">
                    <div className="list-header">
                        <div className="title">
                            <h1>Planos</h1>
                        </div>
                    </div>

                    <div style={{display:"flex", justifyContent:"end", padding:10}}>

                        <div className="div-buttons">
                            <div className="continue-button">                           
                                <button>
                                    <AiIcons.AiOutlinePlusCircle style={{marginRight: "5px",fontSize: "22px",marginBottom: "2px"}}/><a>Adicionar</a> 
                                </button>                            
                            </div>

                            <div className="refresh-button">                                                       
                                <button className="refresh-button">
                                    <SlIcons.SlRefresh style={{color:"#4281FF",marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/><a >Atualizar</a> 
                                </button>
                            </div>
                        </div>

                    </div>

                    <table className="table">
                        <thead>
                            <tr style={{borderBottom:"2px solid #4281FF"}}>
                                <th scope="col">Nome do plano</th>
                                <th scope="col">Identificador</th>
                                <th scope="col">Valor</th>                                
                                <th scope="col">Ciclo</th>
                                <th scope="col">Recorrência</th>
                                <th scope="col">Data Inclusão</th>
                                <th scope="col">Ações</th>
                            </tr>
                        </thead>

                        <tbody className={result?"result":"result search"}>
                                                
                        </tbody>
                    </table>    
                </div>
            </Teste>

        </PermissionGate>       
        </>


        );
    }

    return(

        <>
        <PermissionGate permissions={['AdminOnly']}>
  

            <LeftMenu setSidebar={setSidebar} sidebar={sidebar}></LeftMenu>

            
            <ConfirmDialog
                title="Deletar Plano?"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={deleteReg}
            >
                Tem certeza que deseja deletar o usuário?
            </ConfirmDialog>



            <Teste sidebar={sidebar}>

                <div className="m-5">
                    <div className="list-header">
                        <div className="title">
                            <h1>Planos</h1>
                        </div>
                    </div>

                    <div style={{display:"flex", justifyContent:"end", padding:10}}>

                        <div className="div-buttons">
                            <div className="continue-button">                           
                                <button onClick={handleCadastro}>
                                    <AiIcons.AiOutlinePlusCircle style={{marginRight: "5px",fontSize: "22px",marginBottom: "2px"}}/><a>Adicionar</a> 
                                </button>                            
                            </div>

                            <div className="refresh-button">                                                       
                                <button className="refresh-button" onClick={handleRefresh}>
                                    <SlIcons.SlRefresh style={{color:"#4281FF",marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/><a >Atualizar</a> 
                                </button>
                            </div>
                        </div>

    

                    </div>
                    


                    <table className="table">
                        <thead>
                            <tr /*style={{borderBottom:"2px solid #4281FF"}}*/>
                                <th scope="col">Nome do plano</th>
                                <th scope="col">Identificador</th>
                                <th scope="col">Valor</th>                                
                                <th scope="col">Ciclo</th>
                                <th scope="col">Recorrência</th>
                                <th scope="col">Duração Máxima</th>
                                <th scope="col">Data Inclusão</th>
                                <th scope="col">Ações</th>
                            </tr>
                        </thead>

                        <tbody className={result?"result":"result search"}>
                            
                        {
                            planos && planos.map((plano) => (
                               
                                <tr key={plano.id_iugu}>
                                    <td>{plano.name}</td>
                                    <td>{plano.plan_identifier}</td>
                                    <td>R$ {valorCentsConvertido(plano.valor_plano)}</td>
                                    <td>{plano.interval_type==="months"?"Mensal":"Semanal"}</td>
                                    <td>{plano.interval}</td>
                                    <td>{plano.max_cycles}</td>
                                    {/* Só mostra data se existir */}
                                    {plano.createdAt ?
                                        <td>{moment(plano.createdAt).format('DD/MM/YYYY')}</td>
                                        :
                                        <td></td>
                                    } 
                                   
                                    <td>
                                        {/*<span className="btn btn-sm btn-primary" onClick={e => handleEdit(plano._id)}><FiIcons.FiEdit2 style={{color:"white"}}/></span>*/}
                                        <span className="btn btn-sm btn-danger" style={{marginLeft:"5px"}} onClick={e => handleDelete(plano.id_iugu)}><FiIcons.FiTrash2 style={{color:"white"}}/></span>
                                    </td>
                                </tr>
                               
                            ))
                        }
                            
                        </tbody>


                    </table>

                            
                </div>
            </Teste>

        </PermissionGate>       
        </>
        

        );
    };
    
    export default ListPlanosAdmin;