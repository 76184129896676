import React, {useState, useContext} from 'react';
import imgLogoCliente from '../../img/logoBlue.png'
import imageUrl from '../../img/backgroundLogin.png'
import logoGoogle from '../../img/google_logo.png'
import './styles.css'
import { SlArrowLeft } from "react-icons/sl";

import { AuthContext } from '../../contexts/auth';

const RedefineSenhaPage = () =>{
    const { login } = useContext(AuthContext);

    const[email, setEmail] = useState("")
    const[password, setPassword] = useState("")
    const[confirmPassword, setConfirmPassword] = useState("")

    const handleSubmit = (e) =>{
        e.preventDefault();
        console.log("submit!",{email, password});

        //integracao com contexto/api
        login(email, password);
    }

    return(
    
    <div className="container">

      {/*
      <div className="wrap-header">
        <img className="imgLogo" src={imgLogoCliente}></img>
        <div className="link-center">
          <a className="link1" href="google.com">Suporte</a>
          <a className="link2" href="google.com">Termos</a>
          <a className="link3" href="google.com">Privacidade</a>
        </div>
      </div>
      */}

      <div className="container-login"
      style={{background: `url(${imageUrl})no-repeat left`, backgroundSize: `cover`, backgroundHeight: `auto`,
      backgroundSize: '100% 100%',
      //border: 2px solid #e9385a;
      //marginLeft: '50%',
      //position: 'absolute',
      //zIndex: '0',
      }}
      >
        

        <div className="wrapContent" style={{width: '90%', height: '90%', background: 'white', boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.40)', borderRadius: 8, overflow: 'hidden', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex'}}>
          <div style={{width: '50%', alignSelf: 'stretch', background: '#F5F5F5', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 8, display: 'inline-flex'}}>
            <div style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 24, display: 'flex'}}>
              <img style={{width: 321.70, height: 99.47}} src={imgLogoCliente} />
              <div style={{textAlign: 'center', color: '#074D82', fontSize: 32, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word'}}>Gerencie seus pedidos</div>
              <div style={{width: 347, textAlign: 'center', color: '#074D82', fontSize: 24, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word'}}>E automatize o seu atendimento de verdade</div>
            </div>
          </div> 

          <div style={{flex: '1 1 0', alignSelf: 'stretch', justifyContent: 'center', alignItems: 'center', gap: 8, display: 'flex'}}>

            <div style={{width: 300, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 32, display: 'inline-flex'}}>

            
            <div className="text-center">              
              <span style={{color: 'gray', textDecoration:'solid', fontSize: 24}}>Nova Senha</span>
            </div>

            <div className="text-center">
            Quase pronto, basta digitar sua nova senha abaixo e confirmar.
            </div>


            <form className="login-form" onSubmit={handleSubmit}>          

            <div className="wrap-input">
                <input 
                  className={password !== "" ? 'has-val input' : 'input'} 
                  type="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
                <span className="focus-input" data-placeholder="Digite a nova senha"></span>
              </div>

              <div className="wrap-input">
                <input 
                  className={confirmPassword !== "" ? 'has-val input' : 'input'} 
                  type="password"
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)}
                />
                <span className="focus-input" data-placeholder="Confirme a nova senha"></span>
              </div>
         

              <div className="container-login-form-btn">
                <button className="login-form-btn" type="submit">Cadastrar nova senha</button>
              </div>

            </form> 

            </div>
          </div>

        </div>
            
      </div>
      
    </div>

    
    );
};

export default RedefineSenhaPage;